import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCourseTopics } from "examsbook-react-sdk/actions";
import status from "../constants/actionStatus";

/**
 * get topic list.
 * @returns {*}
 */
export default function useTopic(courseId, subjectId) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCourseTopics(courseId, subjectId));
  }, []);
  let topicData = useSelector(state => state.topics);
  let isLoading = topicData && topicData.status === status.PENDING;
  let topicDetail = topicData && topicData.topicList;
  return {
    isLoading,
    topicDetail
  };
}