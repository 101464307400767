import React from 'react';
import { usePracticeTestList, useAnalyticsPageView } from "examsbook-react-sdk/custom-hooks";
import FilterableTestsContainer from "../components/Tabs/FilterableTestsContainer";

/**
 * Practice test entry point.
 * @param props
 * @returns {*}
 * @constructor
 */
function PracticeTests(props) {
    //analytics
    useAnalyticsPageView();
    //get scroll height
    const {
        remainingPracticeTests,
        completedPracticeTests,
        practiceTestPending,
        practiceTests
    } = usePracticeTestList();
    let data = {
        allTests: practiceTests,
        remainingTests: remainingPracticeTests,
        completedTests: completedPracticeTests
    };
    return (
        <FilterableTestsContainer
            data={data}
            isPending={practiceTestPending}
        />
    );
}

export default PracticeTests;
