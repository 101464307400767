import React, { useEffect, useState } from 'react';
import './ChangePassword.scss';
import { useDispatch } from 'react-redux';
import { useTranslations } from 'examsbook-react-sdk/custom-hooks';
import { getDefaultSupportDataAction, getSupportAction } from 'examsbook-react-sdk/actions/getSupportAction';
import { Box, Button, Grid, Icon, Input, InputLabel } from '@material-ui/core';
import { changePasswordNewUser } from 'examsbook-react-sdk/actions/getUser';
import { useHistory, useLocation } from 'react-router-dom';

const ChangePassword = () => {
  const [supportData, setSupportData] = useState([]);
  const [defaultSupportData, setDefaultSupportData] = useState([]);;
  const [bgColor, setBgColor] = useState('');
  const [textColor, setTextColor] = useState('');
  const [defaultLoginImage, setDefaultLoginImage] = useState(null);
  const [dynamicLoginImage, setDynamicLoginImage] = useState(null);
  const [productName, setProductName] = useState(null);
  const [formValues, setFormValues] = useState({newPassword: "", confirmPassword: ""});

  const dispatch = useDispatch();
  const API_URL = "/img/post/original/";
  const t = useTranslations();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const defaultLogo = defaultSupportData?.data?.Images?.find((image) => image.key === "Image")?.defaultValue;
    const dynamicLogo = supportData?.data?.find((item) => item.key === "Image")?.value;
    const defaultProductName = defaultSupportData?.data?.['Product Name'][0]?.defaultValue;
    const dynamicProductName = supportData?.data?.find(item =>  item.key === 'Product Name')?.value;
    const defaultLoginBtnColor = defaultSupportData?.data?.['Text Theme']?.find((color) => color.key === "Primary Text")?.defaultValue;
    const dynamicLoginBtnColor = supportData?.data?.find(item =>  item.key === 'Primary Bg')?.value;
    const defaultTextColor = defaultSupportData?.data?.['Text Theme']?.find((color) => color.key === "Primary Text")?.defaultValue;
    const dynamicTextColor = supportData?.data?.find(item =>  item.key === 'Primary Text')?.value;
  
    setDefaultLoginImage(defaultLogo);  
    setDynamicLoginImage(dynamicLogo);
    setProductName(dynamicProductName ? dynamicProductName : defaultProductName)
    setBgColor(dynamicLoginBtnColor ? dynamicLoginBtnColor : defaultLoginBtnColor)
    setTextColor(dynamicTextColor ? dynamicTextColor : defaultTextColor)
  },[defaultSupportData, supportData]);

useEffect(() => {
    getSettingApiData();
    getDefaultSeoSettingApiData();
  }, []);

  const getSettingApiData = async () => {
      try {
          dispatch(getSupportAction()).then((res) => {
              setSupportData(res);
          });
      } catch (error) {
        console.log('Error -', error.message);
      }
  };

  const getDefaultSeoSettingApiData = async () => {
    try {
      dispatch(getDefaultSupportDataAction()).then((res) => {
        setDefaultSupportData(res);
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const togglePasswordVisibility = (inputId, iconId) => {
    const passwordInput = document.getElementById(inputId);
    const icon = document.getElementById(iconId);
    if (passwordInput.type === 'password') {
      passwordInput.type = 'text';
      icon.classList.replace('fa-eye-slash', 'fa-eye');
    } else {
      passwordInput.type = 'password';
      icon.classList.replace('fa-eye', 'fa-eye-slash');
    }
  };

  const preventSpaceKey = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleSetPassword = async (e) => {
    e.preventDefault();
    const { confirmPassword, newPassword } = formValues;
    const { password, ...rest } = location?.state ?? {}

    const changePasswordData = {
      ...rest,
      oldPassword: password,
      confirmPassword,
      newPassword
    };

    try {
      dispatch(changePasswordNewUser(changePasswordData)).then((res) => {
        if (res?.status === 200) {
          history.push('/login');
        }
      })
    } 
    catch (error) {
      console.log('Error -', error.message);
    }
  }

  const handlePasswordChange = (event) => {
    handleChange(event)

    const checkName = event.target.name;
    const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let errMsg = "Enter valid inputs";
    let valid = true;
    if (checkName === 'newPassword' && !regex.test(event.target.value)) {
      errMsg = "Enter min 8 letters, with at least a symbol, upper and lower case letters and a number";
      valid = false;
    }

    if (!valid) {
      document.getElementById('reset-password-error').innerHTML = `<div class="errorMsg">${errMsg}</div>`;
      document.getElementById('reset-password-error').style.display = 'block';
      document.getElementById('changePasswordBtn').setAttribute("disabled", "disabled");
    } else {
      document.getElementById('reset-password-error').style.display = 'none';
      document.getElementById('changePasswordBtn').removeAttribute("disabled");
    }
  };

  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  }

  return (
    <Grid className="change-password-parent-div">
        <Grid className="change-password-child-div">
          <Box component="img" id= "login-img-learn" className="change-password-logo" src={API_URL + (dynamicLoginImage || defaultLoginImage)} alt={productName} />
        </Grid>
            <Grid className="change-password-right-side-box">
            <Box id="wrapper-title" className="mdl-card__supporting-text input-fields">
                <Box className="mdl-card__title">
                  <Box className="custom-title" style={{ color: `${bgColor}` }}>{t('Change Password')}</Box>
                </Box>
                <Box className="form-group error-msg" id="reset-password-error"></Box>
                    <Box id="form-login" className="newform">
                    <Box component="form" className="change-password-form" id="resetPasswordForm" onSubmit={handleSetPassword} method="post">
                        <Box className="otg otg-items-1 form-box">
                        <Box className="otg-item mdc-text-field mdl-js-textfield mdc-text-field--floating-label" id="wrapper">
                            <Input name="newPassword" className="mdc-text-field__input required_text" id="newPassword" type="password" onInput={handlePasswordChange} onKeyDown={preventSpaceKey} placeholder="New Password" />
                            <InputLabel className="mdc-text-field__label heading-change-password" style={{ color: `${bgColor}` }} htmlFor="newPassword">{t('New Password')}</InputLabel>
                            <Icon className="fa fa-eye-slash toggle-password-icon eye-icon-align" id="toggleNewPassword" onClick={() => togglePasswordVisibility('newPassword', 'toggleNewPassword')}></Icon>
                        </Box>
                        </Box>
                        <br/>
                        <Box className="otg otg-items-1 form-box">
                        <Box className="otg-item mdc-text-field mdl-js-textfield mdc-text-field--floating-label" id="wrapper">
                            <Input name="confirmPassword" className="mdc-text-field__input required_text" id="confirmPassword" type="password" onInput={handleChange} placeholder="Confirm Password" onKeyDown={preventSpaceKey} />
                            <InputLabel className="mdc-text-field__label heading-change-password" style={{ color: `${bgColor}` }} htmlFor="confirmPassword">{t('Confirm Password')}</InputLabel>
                            <Icon className="fa fa-eye-slash toggle-password-icon eye-icon-align" id="toggleConfirmPassword" onClick={() => togglePasswordVisibility('confirmPassword', 'toggleConfirmPassword')}></Icon>
                        </Box>
                        </Box>
                        <Box id="wrapper-btn" className="otg otg-items-1 form-box special-buttons text-center otg-v-30">
                        <Button className="mdl-js-button mdl-js-ripple-effect change-password-submit-button" id="changePasswordBtn" type="submit" style={{ backgroundColor: `${bgColor}`, color: `${textColor}` }}>{t('Submit')}</Button>
                        <span id="messageError"></span>
                        </Box>
                    </Box>
                    </Box>
                </Box>
            </Grid>
    </Grid>
  );
};

export default ChangePassword;