import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'examsbook-react-sdk/custom-hooks';
import NotificationView from './NotificationView';
import { Box, Typography } from '@material-ui/core';
import { getDeleteNotification, getNotifications } from 'examsbook-react-sdk/actions';

function NotificationDetail() {

    const t = useTranslations();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [ length, setLength ] = useState(20);
    const [deletedNotificationId, setDeletedNotificationId] = useState(null);
    const [ hasMore, setHasMore ] = useState(true);
    const [notificationList, setNotificationList] = useState ([]);
    const [showComponent, setShowComponent] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const handleDeleteNotification = (id) => {
        dispatch(getDeleteNotification(id));
        setDeletedNotificationId(id);
        setShowNotification(true);
            setTimeout(() => {
                setShowNotification(false);
            }, 2000);
    };
    
    useEffect(() => {
        if (user) {
            dispatch(getNotifications(length))
            .then(response => {
                setNotificationList(response);
            })
            .catch(error => {
                console.error('Failed to fetch notifications:', error);
            });
        }
    }, [user, dispatch, length, deletedNotificationId]);

    useEffect(() => {
        const timer = setTimeout(() => {
          setShowComponent(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;    
        if (scrollHeight - scrollTop === clientHeight) {
            if(length <= notificationList?.data?.length){
                setLength(prevLength => {
                    const newLength = prevLength + 20;
                    dispatch(getNotifications(newLength))
                    .then(response => {
                        setNotificationList(response);
                        if (response.length < length) {
                            setHasMore(false);
                        } else {
                            setLength(length + 20);
                        }
                    })
                    .catch(error => {
                        console.error('Failed to fetch notifications:', error);
                    });
                    return newLength;
                });
            }
        }
    };

    function filterByDate(notifications, date, isOlder) {
        const startOfDay = new Date(date);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(date);
        endOfDay.setHours(23, 59, 59, 999);
        return notifications?.filter(notification => {
            const notificationDate = new Date(notification?.updated?.on).getTime();
            if (isOlder) {
                const startOfToday = new Date();
                startOfToday.setHours(0, 0, 0, 0);
                return notificationDate < startOfToday && notificationDate <= endOfDay;
            } else {
                return notificationDate >= startOfDay && notificationDate <= endOfDay;
            }
        });        
    }

    const today = new Date();
    const yesterday = new Date(today);
    const olderDay = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    olderDay.setDate(yesterday.getDate() - 1);

    const todaysNotifications = filterByDate(notificationList?.data, today, false);
    const yesterdaysNotifications = filterByDate(notificationList?.data, yesterday, false);
    const olderNotifications = filterByDate(notificationList?.data, olderDay, true);
    
    return (
        <React.Fragment>
            <Box>
            {showNotification && (
                <div className='notification-delete-msg'>
                    {t('Notification deleted successfully!')}
                </div>
            )}
            {notificationList?.data?.length ? <Box className='notification-container' onScroll={handleScroll}>
                <Box>
                    <Typography variant="h6">{t("Notifications")}</Typography>
                    <Typography variant="body1" className='notification-count-text'>{t("You've got")} {notificationList?.data?.length} {t("new notifications")}</Typography>
                </Box>
                {todaysNotifications?.length > 0 && <Box>
                    <Typography variant="body2" className="notification-detail-day">{t("Today")}</Typography>
                </Box>}
                <Box className="sub-menus">
                    <NotificationView notifications={todaysNotifications} onDeleteNotification={handleDeleteNotification} />

                    {yesterdaysNotifications.length > 0 && <Box>
                        <Typography variant="body2" className="notification-detail-day">{t("Yesterday")}</Typography>
                    </Box>}
                    <NotificationView notifications={yesterdaysNotifications} onDeleteNotification={handleDeleteNotification} />

                    {olderNotifications.length > 0 && <Box>
                        <Typography variant="body2" className="notification-detail-day">{t("Later")}</Typography>
                    </Box>}
                    <NotificationView notifications={olderNotifications} onDeleteNotification={handleDeleteNotification} />

                </Box>
            </Box> : showComponent && <Typography variant="body2" className='no-new-notification-text'>{t("No_new_notification")}</Typography>}
            </Box>
        </React.Fragment>
    );
}

export default NotificationDetail;
