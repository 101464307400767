import _objectSpread from "/codebuild/output/src2290389247/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import status from '../constants/actionStatus';
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    status: status.PENDING,
    subjects: []
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'SUBJECTS_REQUEST':
      return {
        status: status.PENDING,
        subjects: action.payload
      };
    case 'SUBJECTS_SUCCESS':
      return {
        status: status.SUCCESS,
        subjects: action.payload
      };
    case 'QUESTION_RESPONSE_SUCCESS':
      try {
        let questionResponse = action.payload;
        let subjects = state.subjects.map(subject => {
          subject = _objectSpread({}, subject); // copy
          if (subject._id === questionResponse.subjectId) {
            subject.attemptedQuesCount = subject.attemptedQuesCount + 1;
            subject.topics = subject.topics.map(topic => {
              topic = _objectSpread({}, topic); // copy
              if (topic._id === questionResponse.topicId) {
                topic.attempted = topic.attempted + 1;
                return topic;
              }
              return topic;
            });
          }
          return subject;
        });
        return {
          status: status.SUCCESS,
          subjects: subjects
        };
      } catch (e) {
        return state;
      }
    case 'SUBJECTS_FAILURE':
      return {
        status: status.ERROR,
        subjects: []
      };
    default:
      return state;
  }
});