import React from "react";
import "../CoursesList/coursesList.scss";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import LiveVideoList from "../LiveVideoList/LiveVideoList";
import { useGetLiveVideo } from "examsbook-react-sdk/custom-hooks";
import NoData from "../common/Nodata/noData";

/**
 * Live Video Card
 * @param props
 * @returns {*}
 * @constructor
 */
function LiveVideoCard(props) {const t = useTranslations();
    const selectedTab = 1;
    const courseId = localStorage.getItem("dataKey");
    const liveVideoList = useGetLiveVideo(courseId);

    return (
        <React.Fragment>
        {selectedTab === 1 && (
            <>
            <div className="main-div">
            <div className="select-div-header">
                <p className="section-header">{t("CURRENT_LIVE_CLASSES")}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "row", margin: "10px 2px",flexWrap: 'wrap',padding:'10px'}}>
                {liveVideoList &&
                liveVideoList.liveVideoDetail?.length > 0 ?
                liveVideoList.liveVideoDetail.map((element, key) => (
                    <LiveVideoList testData={element} key={key} />
                )):
                <NoData message={t('No_live_Class')}/> 
                }
            </div>
            </div>
            </>
        )}
        </React.Fragment>
    );

}

export default LiveVideoCard;
