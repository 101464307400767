import React from 'react';
import ContentLoader from 'react-content-loader';

const QuestionCategoryLoader = () => (
    <div className="loader-content-list">
        <ContentLoader
            height={30}
            width={200}
            speed={6}
            primaryColor="gray"
            secondaryColor="#ecebeb"
        >
            <rect x="10" y="10" rx="5" ry="5" width="180" height="3"/>
            <rect x="10" y="15" rx="5" ry="5" width="150" height="3"/>
            <rect x="10" y="20" rx="5" ry="5" width="120" height="3"/>
        </ContentLoader>
    </div>
);

export default QuestionCategoryLoader;
