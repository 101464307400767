import React from 'react';
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import { TimeUtils } from "examsbook-react-sdk/common";

const SectionWiseResultScreen = props => {
    const { quizResults } = props;

    const sectionsTotal = quizResults && quizResults.data && quizResults.data.totalOfSections ?
        quizResults.data.totalOfSections : {};

    const sectionsData = quizResults && quizResults.data && quizResults.data.sectionWiseResult ?
        quizResults.data.sectionWiseResult : [];
    const t = useTranslations();

    function changeTimeFormat(timeSpent) {
        return TimeUtils.changeTimeFormat(timeSpent);
    }

    let totalQues = 0;
    sectionsData.map((item) => {
        return totalQues += item.total;
    });
    return (
        <div className="section-wise-result-table">
            <h4>{t('section_wise_score')}</h4>
            {
                sectionsData && sectionsData.length ? <div className="table">
                    <table className="table">
                        <tbody>
                        <tr>
                            <td><span>{t('SECTION')}</span></td>
                            <td><span>{t('score')}</span></td>
                            <td><span>{t('time')}</span></td>
                        </tr>
                        {
                            sectionsData.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>
                                                <span>{item && item.section ? item.section : ''}</span>
                                            </td>
                                            <td>
                                                <span>{item && item.score ? ((item.score).toFixed(2) + ' / ' + (item.total).toFixed(2)) : '0' + ' / ' + (item.total).toFixed(2)}</span>
                                            </td>
                                            <td>
                                                <span>{sectionsTotal && sectionsTotal.sectionTime.length ? changeTimeFormat(sectionsTotal.sectionTime[index]) + ' mins' : '00:00 mins'}</span>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })
                        }
                        <tr>
                            <td>Total</td>
                            <td>{sectionsTotal && sectionsTotal.sectionTotalScore ? sectionsTotal.sectionTotalScore.toFixed(2) + ' / ' + (totalQues).toFixed(2) : '0' + ' / ' + (totalQues).toFixed(2)}</td>
                            <td>{sectionsTotal && sectionsTotal.sectionTotalTime ? changeTimeFormat(sectionsTotal.sectionTotalTime) + ' mins' : '00:00 mins'}</td>
                        </tr>

                        </tbody>
                    </table>
                </div> : ''
            }
        </div>
    );
};

export default SectionWiseResultScreen;
