import React from 'react';
import PracticeQuestionsWrapper from "./practiceQuestionsWrapper";

const PracticeQuestionsSubjectTopic = props => {
    const { history, match } = props;
    return (
        <PracticeQuestionsWrapper history={history} match={match} isQuestionList={true}/>
    );
};

export default PracticeQuestionsSubjectTopic;
