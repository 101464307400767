import React, { useEffect, useState } from "react";
import "../CoursesList/coursesList.scss";
import TestSeriesLoader from "../common/loaders/TestSeriesLoader";
import { useHistory } from "react-router-dom";
import { useUserCourse } from "examsbook-react-sdk/custom-hooks";
import { useDispatch, useSelector } from "react-redux";
import { useSearchQuery } from "../../services/SearchQueryContext";
import NotFound from "../../pages/NotFound";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/close.svg";
import axios from "axios";
import CoursesCard from "../CoursesCard/CoursesCard";
import { getDefaultSupportDataAction, getSupportAction } from 'examsbook-react-sdk/actions/getSupportAction';
import { certificateListAction } from "examsbook-react-sdk/actions/certificateAddResponse";
import { useTranslations } from 'examsbook-react-sdk/custom-hooks';
import { makeStyles } from "@material-ui/core";
import { getCourseList } from "examsbook-react-sdk/actions";

function stripHtml(html) {
  let tmp = document.createElement("p");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText;
}

function CourseList(props) {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [contentData, setContentData] = useState("");
  const [supportData, setSupportData] = useState([]);
  const [defaultSupportData, setDefaultSupportData] = useState([]);
  const [dynamicLogos, setDynamicLogos] = useState('');
  const dispatch = useDispatch();
  const t = useTranslations();
  const [certificateListing, setCertificateListing] = useState([])
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const classes = useStyles(isFirefox);

  const user = useSelector((state) => state.user);
  const { searchQuery, setSearchQuery } = useSearchQuery();
  const { isLoading } = useUserCourse(user._id);
  const [showComponent, setShowComponent] = useState(false);

  const lowerSearchQuery = searchQuery.toLowerCase().trim();
  const filteredUserCourses =
    contentData &&
    contentData.filter((data, index) => {
      const title = data?.name;
      const description = data?.description;
      const descriptionText = stripHtml(description).toLowerCase();
      const lowerTitle = title.toLowerCase();
      return (
        lowerTitle.includes(lowerSearchQuery) ||
        descriptionText.includes(lowerSearchQuery)
      );
    });
  const coursesToDisplay =
    searchQuery && searchQuery.trim() !== ""
      ? filteredUserCourses
      : contentData;

   useEffect(() => {
    getSettingApiData();
    getDefaultSeoSettingApiData();
    getCertificateListingData();
  }, []);


  const getCourseListApiData = async (userId) => {
    try {
      dispatch(getCourseList(userId)).then((res) => {
        let sortedData = res?.data?.sort(
          (a, b) => new Date(b.created.on) - new Date(a.created.on)
        );
        setContentData(sortedData);
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (user._id) {
      getCourseListApiData(user?._id);
    }
  }, [user._id]);

  function handleTabMenu(event, value) {
    setSelectedTab(value);
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(true);
    }, 6000);
    return () => clearTimeout(timeoutId);
  }, []);

  function clearField(searchQuery) {
    if (searchQuery.length) setSearchQuery("");
  }

  useEffect(()=> {
    return () => {
      localStorage.removeItem('selectedTab');
    };
  },[])

  useEffect(() => {
    setSearchQuery('')
    return setSearchQuery('');
  }, []);

  const getSettingApiData = async () => {
    try {
        dispatch(getSupportAction()).then((res) => {
            setSupportData(res);
        });
    } catch (error) {
        console.log('Error -', error.message);
        }
    };

    const getDefaultSeoSettingApiData = async () => {
      try {
        dispatch(getDefaultSupportDataAction()).then((res) => {
          setDefaultSupportData(res);
        });
      } catch (error) {
        console.error(error.message);
      }
    };

    const getCertificateListingData = async () => {
      try {
        dispatch(certificateListAction()).then((res) => {
          setCertificateListing(res.data)
        });
      } catch (error) {
        console.error(error.message);
      }
    };

    const isDynamicLogo = dynamicLogos.split('/').pop();

    let API_URL = process.env.REACT_APP_DYNAMIC_URL;
    useEffect(() => {
      const imageValue = supportData?.data?.find(subValue => subValue.key === "Course")?.value;
      const defaultValue = defaultSupportData?.data?.['Course Image']?.find(subValue => subValue.key === "Course")?.defaultValue;
      let dynamicLogo = "";
      if (typeof API_URL != "undefined") {
        if (imageValue) {
          dynamicLogo = API_URL +'/img/post/original/' + imageValue;
        } else if (defaultValue) {
          dynamicLogo = API_URL +'/img/post/original/' + defaultValue;
        }
      }
      setDynamicLogos(dynamicLogo) 
    }, [supportData, defaultSupportData])

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowComponent(true);
      }, 300);
      return () => clearTimeout(timer);
    }, []);

  function renderList() {
    if (selectedTab === 0) {
      history.push("/dashboard/");
    }
    return (
      <React.Fragment>
        {selectedTab === 1 && (
          <>
            <div className="main-div">
              <div className="course-search-box">
                <input
                  className="inner-search-box"
                  style={{ border: "none" }}
                  type="text"
                  placeholder={t('Search')}
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
                <div className="course-search-img-box">
                  {searchQuery.length ? (
                    <button
                      onClick={() => clearField(searchQuery)}
                      style={{ border: "none" }}
                    >
                      <CrossIcon className="cross-icon" alt="Cross Icon" />
                    </button>
                  ) : (
                    <SearchIcon className="search-icon" alt="Search Icon" />
                  )}
                </div>
              </div>

              <div className="card-list-div">
                {
                  coursesToDisplay && coursesToDisplay.length > 0 ? (
                    <div className="card-view-new test-series-new ">
                      {coursesToDisplay.map((data, index) => {
                        return <CoursesCard key={index} testData={data} dueDateinDays={data?.dueDateinDays} dynamicLogos={dynamicLogos} isDynamicLogo={isDynamicLogo} certificateListing={certificateListing}/>;
                      })}
                    </div>
                  ) : showComponent && (
                    <>{<div className={classes.centerAlign}><NotFound /></div>}</>
                  )
                }
              </div>
            </div>
          </>
        )}
      </React.Fragment>
    );
  }

  function renderLoader() {
    //we will show three cards to fill this space.
    return (
      <div className="card-view test-series">
        <TestSeriesLoader />
        <TestSeriesLoader />
        <TestSeriesLoader />
        <TestSeriesLoader />
      </div>
    );
  }

  return (
    <React.Fragment>
      {!isLoading ? renderList() : coursesToDisplay?.length > 0 && renderLoader()}
    </React.Fragment>
  );
}
const useStyles = makeStyles(() => ({
  centerAlign: isFirefox => ({
    textAlign: isFirefox ? '-moz-center' : '-webkit-center',
  }),
}));
export default CourseList;
