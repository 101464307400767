import { useDispatch, useSelector } from "react-redux";
import useSelectedExam from "./useSelectedExam";
import { useEffect, useState } from "react";
import { getChildExamsList, getTestSeriesList } from "examsbook-react-sdk/actions";
import status from "../constants/actionStatus";
import useUserLanguage from "./useUserLanguage";
export default function useTestSeriesList() {
  const {
    language
  } = useUserLanguage();
  const dispatch = useDispatch();
  const selectedExam = useSelectedExam();
  const testSeriesList = useSelector(state => state.testSeriesList);
  const [selectedExamId, setSelectedExamId] = useState(selectedExam ? selectedExam._id : '');
  useEffect(() => {
    if (selectedExam && selectedExam._id) {
      setSelectedExamId(selectedExam._id);
    }
  }, [selectedExam]);
  useEffect(() => {
    if (selectedExamId) {
      dispatch(getChildExamsList(selectedExamId));
      dispatch(getTestSeriesList(selectedExamId, language));
    }
  }, [selectedExamId, language]);
  return {
    testSeriesList: testSeriesList ? testSeriesList.data : [],
    testSeriesLoading: testSeriesList ? testSeriesList.status === status.PENDING : true
  };
}