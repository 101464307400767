import { createSelector } from 'reselect';
const getExamList = state => state.examData.subjects;
const getExamsFilter = state => state.examFilterValue;
export const getFilteredExams = createSelector([getExamList, getExamsFilter], (subjects, examFilterValue) => {
  if (examFilterValue !== '') {
    let subjectArr = [];
    subjectArr = subjects.filter(sub => sub.name.toLowerCase().includes(examFilterValue));
    subjects.map(subject => {
      const topics = subject.topics.filter(topic => topic.name.toLowerCase().includes(examFilterValue));
      if (topics && topics.length) {
        const index = subjectArr.findIndex(subjectObj => {
          return JSON.stringify(subjectObj._id) === JSON.stringify(subject._id);
        });
        if (index === -1) {
          subject.topics = topics;
          subjectArr.push(subject);
        }
      }
      return [];
    });
    return subjectArr;
  } else {
    return subjects;
  }
});