import _objectSpread from "/codebuild/output/src2290389247/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import status from '../constants/actionStatus';
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    status: status.PENDING,
    quizList: []
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'SAVED_QUIZ_REQUEST':
      return {
        status: status.PENDING,
        quizList: []
      };
    case 'SAVED_QUIZ_SUCCESS':
      return {
        status: status.SUCCESS,
        quizList: action.quizList
      };
    case 'TOGGLE_QUIZ__SAVED_SUCCESS':
      {
        //change state of quiz from list of quiz being shown on screen.
        let quizs = state.quizList;
        let filteredQuizList = quizs.map(quiz => {
          quiz = _objectSpread({}, quiz); // copy
          if (quiz._id !== action.payload.quizId) {
            return quiz;
          } else {
            //toggle status.
            quiz.isSaved = !quiz.isSaved;
            return quiz;
          }
        });
        filteredQuizList = [...filteredQuizList];
        return {
          status: status.SUCCESS,
          quizList: filteredQuizList
        };
      }
    case 'SAVED_QUIZ_FAILURE':
      return {
        status: status.ERROR,
        quizList: state
      };
    default:
      return state;
  }
});