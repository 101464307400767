import React, { PureComponent } from 'react';
import '../QuizResultScreen/quizResultScreen.scss';
import { XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Area, ResponsiveContainer, ReferenceLine } from 'recharts';

export default class AreaChartGraph extends PureComponent {

    render() {

        function CustomTooltip({ payload, label, active }) {
            if (active && payload !== null) {
                return (
                    <div className="custom-tooltip">
                        <p className="label">{`${"Students"} : ${payload[0].value}`}</p>
                        <p className="label">{`${"Range"} : ${label}`}</p>

                    </div>
                );
            }

            return null;
        }

        const { t, data } = this.props;
        //range of marks of user
        let self = data.map((div) => {
            return (div.self);
        });
        let selfPoint = self[data.length - 1];
        //user obtained marks
        let userMarks = data.map((div) => {
            return (div.userMarks);
        });
        return (
            <div>
                <div className="area-chart-indicator">
                    <p className="verticle">{t('students_number')}</p>
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart
                            data={data}
                            syncId="anyId">
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="range" fontSize={15} fontStyle="italic" offset={5} padding-right="3"/>
                            <YAxis fontStyle="italic" tickCount={data.some((div) => {
                                return (div.students > 5);
                            }) === true ? 5 : 1} allowDecimals={false} fontSize={18}/>
                            <Tooltip content={<CustomTooltip/>}/>
                            <Area type="monotone" dataKey="students" stroke="#5F4BAB" strokeWidth={2} fill="#9bcff7"
                                  fillOpacity={0.5} activeDot={{ r: 9 }}/>
                            <ReferenceLine x={selfPoint} stroke="#e91e63"
                                           label={(data[data.length - 1] && data[data.length - 1].range !== selfPoint) ? {
                                               value: "YOU (" + userMarks[userMarks.length - 1].toFixed(2) + " Marks)",
                                               position: "insideTopLeft",
                                               dy: -19,
                                               fill: "#e91e63",
                                               fontSize: "15"
                                           } : {
                                               value: "YOU (" + userMarks[userMarks.length - 1] + " Marks)",
                                               position: "insideTopRight",
                                               dy: -19,
                                               fill: "#e91e63",
                                               fontSize: "15"
                                           }} strokeDasharray="0 0"/>
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    }
}
