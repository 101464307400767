import axios from "./axiosConfig";

/**
 * Update mobile number
 * @param newMobileNumber
 * @returns {response}
 */
export function updateMobileNumber(newMobileNumber) {
  let request = {
    notVerifiedNumber: newMobileNumber
  };
  return axios.put("/api/v1/update-mobile", request).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    return error;
  });
}

/**
 * change password
 * @param old_password
 * @param new_password
 * @param confirm_password
 * @param email_param
 * @returns {response}
 */
export function passwordChangedNewUser(userData) {
  const {
    email,
    mobileNumber,
    oldPassword,
    confirmPassword,
    newPassword
  } = userData;
  let request = {
    email,
    mobileNumber,
    oldPassword,
    confirmPassword,
    password: newPassword
  };
  return axios.post("/changePassword/newUser", request).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    return error;
  });
}

/**
 * request new otp
 * @returns {response}
 */
export function requestNewOtp() {
  return axios.get("/api/v1/send-otp").then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    return error;
  });
}

/**
 * verify otp
 * @param data
 * @returns {response}
 */
export const verifyOtp = function () {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return axios.post("/api/v1/verify-mobile", data).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    return error;
  });
};

/**
 * Login user
 * @param username
 * @param password
 * @param isMobileOrEmail
 * @returns {response}
 */
export const loginUser = (username, password, isMobileOrEmail, captchaToken) => {
  //TODO decide if it is phone number or email id.
  let data = {
    [isMobileOrEmail]: username,
    password: password,
    type: "",
    captcha: captchaToken
  };
  return axios.post("/login", data);
};

/**
 * register user
 * @param username
 * @param userInput
 * @param password
 * @param emailOrMobile
 * @returns {response}
 */
export const registerUser = (username, userInput, emailOrMobile) => {
  let data;
  userInput === "email" ? data = {
    name: username,
    [userInput]: emailOrMobile,
    password: "",
    mailOtp: true
  } : data = {
    name: username,
    [userInput]: emailOrMobile,
    password: ""
  };
  return axios.post("/register", data);
};

/**
 * Get new otp
 * @param mobileNumber
 * @returns {response}
 */
export const getNewOtp = (userInput, userValue) => {
  let data = {
    [userInput]: userValue
  };
  return axios.post("/send-login-otp", data);
};

/**
 * login via mobileNumber
 * @param mobileNumber,password
 * @returns {response}
 */

export const getOtpForLoginMobile = (mobileNumber, password, type) => {
  let data = {
    mobileNumber: mobileNumber,
    password: password,
    type: type
  };
  return axios.post("/registerViaOtp", data);
};

/**
 * verify otp via mobileNumber
 * @param otp
 * @returns {response}
 */

export const verifyOtpMobile = (otp, pageName, userId) => {
  let data;
  pageName === "loginEmailWithOtp" ? data = {
    otp: otp,
    pageName: "loginEmailWithOtp",
    userId: userId
  } : data = {
    otp: otp,
    userId: userId
  };
  return axios.post("/verifyOtp", data);
};

/**
 * resend otp
 * @param userInput
 * @param emailOrMobile
 * @returns {response}
 */
export const resendOtp = (userInput, emailOrMobile) => {
  let data;
  userInput === "email" ? data = {
    [userInput]: emailOrMobile,
    mailOtp: true
  } : data = {
    [userInput]: emailOrMobile
  };
  return axios.post("/resend", data);
};
export const verifyOtpPageName = (otp, pageName) => {
  let data = {
    otp: otp,
    pageName: pageName
  };
  return axios.post("/verifyOtp", data);
};

/**
 * Change password
 * @param password
 * @param confirmPassword
 * @param token
 * @returns {response}
 */
export const changePassword = (password, confirmPassword, token) => {
  let data = {
    password: password,
    confirmPassword: confirmPassword,
    token: token
  };
  return axios.post("/auth/password/verifyResetPasswordToken", data);
};

/**
 * request Otp for forgot password
 * @param userInput
 * @param emailOrMobile
 * @returns {response}
 */
export const requestOtpforForgotPassword = email => {
  return axios.post("/auth/password/resetPassword", {
    email
  }).then(response => {
    return response.data;
  });
};

/**
 * request Otp for unverified number
 * @returns {response}
 */
export function requestOtpForUnverifiedNumber() {
  return axios.get("/api/v1/send-otp-to-verify").then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    return error;
  });
}

/**
 * verify Otp of unverified number
 * @param data
 * @returns {response}
 */
export const verifyOtpOfUnverifiedNumber = function () {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return axios.post("/api/v1/verify-number", {
    otp: data
  }).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    return error;
  });
};

/**
 * Get Current User
 * @param userId
 * @returns {response}
 */
export function currentUser(userId) {
  return axios.get(userId ? "/api/v1/me?user=" + userId : "/api/v1/me").then(function (response) {
    // handle success
    return response.data;
  }).catch(function (error) {
    // handle error
    return error;
  });
}

/**
 * Update User
 * @param userId
 * @param data
 * @returns {response}
 */
export function updateSingleUser(userId, data) {
  return axios({
    method: "put",
    url: "/api/v2/user/" + userId,
    data: data
  }).then(response => {
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export const updateProfile = async data => {
  return axios({
    method: "put",
    url: "/api/v1/updateProfile/" + (data && data.id),
    data: data.value
  }).then(response => {
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
};
export const updateProfileImage = async userData => {
  let data = {
    image: userData.imageName,
    base64: userData.image
  };
  return axios({
    method: "put",
    url: "/api/v1/updateProfile/" + userData.id,
    data: data
  }).then(response => {
    return response.data;
  }).catch(error => {
    return error;
  });
};

/**
 * Get Me
 * @returns {response}
 */
export function getMe() {
  return axios.get("/api/v1/me").then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}

/**
 * Update Password
 * @param userData
 * @returns {response}
 */
export function updatePassword(userData) {
  return axios.post("/auth/password/changePassword", userData).then(response => {
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}