import { saveFeedbacksForm, saveQuizFeedbacks } from "../services/feedbackService";
export const saveQuizFeedback = (id, data) => dispatch => {
  dispatch(request(id, data));
  saveQuizFeedbacks(id, data).then(quizData => {
    // handle success
    dispatch(success(quizData));
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request(quizId, feedbackData) {
    return {
      type: "QUIZ_FEEDBACK_REQUEST",
      quizId,
      feedbackData
    };
  }
  function success(quizData) {
    return {
      type: "GET_QUIZ_FEEDBACK_SUCCESS",
      quizData
    };
  }
  function failure(error) {
    return {
      type: "GET_QUIZ_FEEDBACK_FAILURE",
      error
    };
  }
};
export const saveFeedbackForm = (data, cb) => dispatch => {
  dispatch(request(data));
  return saveFeedbacksForm(data).then(feedbackRes => {
    // handle success
    dispatch(success(feedbackRes));
    return feedbackRes;
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request(feedbackData) {
    return {
      type: "FEEDBACK_REQUEST",
      feedbackData
    };
  }
  function success(feedbackRes) {
    return {
      type: "GET_FEEDBACK_SUCCESS",
      feedbackRes
    };
  }
  function failure(error) {
    return {
      type: "GET_FEEDBACK_FAILURE",
      error
    };
  }
};