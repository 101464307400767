import { getFavouriteQuestions } from "../services/questionService";
/**
 * Un save question
 * @param id
 * @param isListView
 * @returns {function(*): *}
 */
export const toggleSavedQuestion = (id, isListView) => dispatch => {
  dispatch(request(id));
  // functionality to unsave question
  getFavouriteQuestions(id).then(response => {
    // handle success
    // dispatching success
    dispatch(success(response.data));
    // dispatching updated list of questions
    //we do not need to get list of saved questions again.
    //dispatch(getQuestions());
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request(quesId) {
    return {
      type: 'UNSAVE_QUESTION_REQUEST',
      quesId
    };
  }
  function success(response) {
    //state toggle. We can notify that is successful
    return {
      type: 'TOGGLE_QUESTION__SAVED_SUCCESS',
      payload: {
        questionId: id,
        isListView: isListView || false
      }
    };
  }

  /**
   * Got error while changing the status
   * @param error
   * @returns {{type: string, error: *}}
   */
  function failure(error) {
    console.log('error--------', error);
    return {
      type: 'TOGGLE_QUESTION__SAVED_FAILURE',
      error
    };
  }
};