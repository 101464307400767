import _objectSpread from "/codebuild/output/src2290389247/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    id: {},
    givenAnswer: []
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'CHANGE_SELECTED_QUESTION':
      {
        return action.payload;
      }
    case 'QUESTION_TIMER_START':
      {
        state.timeSpent = state.timeSpent ? state.timeSpent : 0;
        return state;
      }
    case 'UNSAVE_QUESTION_SUCCESS':
      {
        try {
          let updatedQuestionId = action.payload.questionId;
          // questions data
          let question = _objectSpread({}, state); // copy
          if (question.id._id !== updatedQuestionId) {
            return question;
          }
          question.isSaved = !question.isSaved;
          return question;
        } catch (error) {
          console.log('error-------', error);
          return state;
        }
      }
    case 'QUESTION_TIMER_TICK':
      {
        state.timeSpent = state.timeSpent + 1;
        return state;
      }
    default:
      return state;
  }
});