const COLORS = {
  OFFWHITE: "#EFF5F4",
  WHITE: "#ffffff",
  BLUE: "#0039CB",
  LIGHT_BLUE: "#5A4CA7",
  DARK_BLUE: "#039be5",
  GREEN_DIM: "#2A7C2A",
  GREEN_LIGHT: "#008E76",
  GREEN_LIVE_TEST: "#00BFA5",
  VERY_LIGHT_GREEN: "#00cd79",
  GREEN: "#5DF2D6",
  RED: "#d23029",
  DARK_RED: "#BB0000",
  YELLOW: "#FFC658",
  INTERNET_BG: "#323232",
  SOLID_BLACK: "#000000",
  BLUE_OPACITY: "#454f63",
  GREY: "#757575",
  LIGHT_GREY: "#EDECEC",
  ERROR_COLOR_BOX_PINK: "#FFDFE2",
  GOOGLE_RED: "#F34A38",
  FACEBOOK_BLUE: "#3B5998",
  THEME_COLOR: "#5F4BAB",
  SHADOW_COLOR: "#D7CCFF",
  SUCCESS: "#198754",
  DANGER: "#f00",
  PRIMARY: "#0C3450",
  LABELGREY: "#6B6767"
};
export default COLORS;