import React from 'react';
import QuizHeader from "./QuizHeader/QuizHeader";
import QuizSidebar from "./QuizSidebar/QuizSidebar";
import { useSelector } from "react-redux";

/**
 * Header and side bar of quiz module.
 * @param props
 * @returns {*}
 * @constructor
 */
const HeaderSidebar = props => {
    const {
        viewMode,
        drawerToggle,
        isLoading,
        changeSectionActive,
        quizMode,
        toggleDrawerHandler,
        setTimeTaken,
        submitQuiz,
        toggleInstructionBox,
        selectPaletteQuestion,
        showInstructionBox,
        isFullscreen,
        handleFullScreen,
        pauseQuiz,
        handleQuizPauseChange,
        handleClick,
        hideNext,
        reset,
        setReset,
        slug
    } = props;
    const activeQuestionIndex = useSelector((state) => state.activeQuestionIndex);
    const quiz = useSelector((state) => state.quiz);
    const user = useSelector((state) => state.user);
    const { name, duration, locales, result, timeTaken, startDate, endDate, totalQuestions, selectTimeFor } = quiz;
    let localeName = name;
    if (locales && locales[user.language] && locales[user.language].name) {
        localeName = locales[user.language].name;
    }

    const setTimeTakenValue =(data) => {
        setTimeTaken(data);
    }
    return (
        <React.Fragment>
            <QuizHeader usedTime={timeTaken}
                        number={activeQuestionIndex + 1}
                        drawerToggle={drawerToggle}
                        toggleDrawerHandler={toggleDrawerHandler}
                        setTimeTaken={setTimeTakenValue}
                        isLoading={isLoading}
                        viewMode={viewMode}
                        mode={quizMode}
                        submitQuiz={submitQuiz}
                        duration={duration}
                        startDate={startDate}
                        endDate={endDate}
                        name={localeName}
                        quizPage={true}
                        language={user.language || 'en'}
                        pauseQuiz={pauseQuiz}
                        handleQuizPauseChange={handleQuizPauseChange}
                        isFullscreen={isFullscreen}
                        handleFullScreen={handleFullScreen}
                        slug={slug}
                        totalQuestions={totalQuestions}
                        selectTimeFor={selectTimeFor}
                        handleClick={handleClick}
                        hideNext={hideNext}
                        reset={reset}
                        setReset={setReset}
                        />

            <QuizSidebar viewMode={viewMode}
                         drawerToggle={drawerToggle}
                         handler={toggleDrawerHandler}
                         duration={duration}
                         startDate={startDate}
                         endDate={endDate}
                         result={result}
                         mode={quizMode}
                         submitQuiz={submitQuiz}
                         quiz={quiz}
                         isLoading={isLoading}
                         toggleInstructionBox={toggleInstructionBox}
                         selectPaletteQuestion={selectPaletteQuestion}
                         timeTaken={timeTaken}
                         activeQuestionIndex={activeQuestionIndex}
                         changeSectionActive={changeSectionActive}

            />
        </React.Fragment>
    );
};

export default HeaderSidebar;
