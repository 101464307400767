import status from "../constants/actionStatus";
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    solution: {}
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case "SOLUTION_REQUEST":
      return {
        status: status.PENDING,
        solution: {}
      };
    case "SOLUTION_SUCCESS":
      return {
        status: status.SUCCESS,
        solution: action.payload
      };
    case "SOLUTION_FAILURE":
      return {
        status: status.ERROR,
        solution: {}
      };
    default:
      return state;
  }
});