import React from 'react';
import TopicWiseAnalysis from "../Reports/topicWiseAnalysisReport";
import AnswersList from "./answersList";

/**
 * TopicWiseAnalysisReport
 * @param props
 * @returns {*}
 * @constructor
 */
const TopicWiseAnalysisReport = props => {
    const { quizResults } = props;
    const topicWiseAnalysisData = quizResults && quizResults.data && quizResults.data.topicWiseResponseData ?
        quizResults.data.topicWiseResponseData : [];
    const quesResponses = quizResults && quizResults.data && quizResults.data.quesResponses ?
        quizResults.data.quesResponses : {};
    return (
        <>
            {
                topicWiseAnalysisData && topicWiseAnalysisData.length ?
                    <TopicWiseAnalysis quesResponses={quesResponses}
                                       topicWiseAnalysisData={topicWiseAnalysisData}/> :
                    <AnswersList quizResults={quizResults}/>
            }
        </>
    );
};

export default TopicWiseAnalysisReport;
