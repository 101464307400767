import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCategoryList } from "examsbook-react-sdk/actions/getCategoryList";
import { getFilteredCourseList } from "examsbook-react-sdk/actions/getCourses";
import status from "../constants/actionStatus";
import useUserLanguage from "./useUserLanguage";

/**
 * get list of all category.
 * @returns {*}
 */
export default function useCategoryList() {
  const dispatch = useDispatch();
  const {
    language
  } = useUserLanguage();
  useEffect(() => {
    dispatch(getCategoryList());
  }, [language]);
  let {
    status,
    category
  } = useSelector(state => state.categoryList);
  let isLoading = status === status.PENDING;
  // let categoryCount = category.count;
  let categoryList = category.data;
  // if (categoryList && categoryList.length > 0 && categoryList[0].subCategory && categoryList[0].subCategory.length > 0) {
  //     console.log('into category dispaatch -- ');
  //     dispatch(getFilteredCourseList(categoryList[0]._id, categoryList[0].subCategory[0]._id));
  // }
  return {
    isLoading,
    // categoryCount,
    categoryList
  };
}