import status from "../constants/actionStatus";
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    topicList: []
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case "TOPIC_REQUEST":
      return {
        status: status.PENDING,
        topicList: []
      };
    case "TOPIC_SUCCESS":
      return {
        status: status.SUCCESS,
        topicList: action.payload
      };
    case "TOPIC_FAILURE":
      return {
        status: status.ERROR,
        topicList: []
      };
    default:
      return state;
  }
});