import React from 'react';
import AreaChartGraph from "../Charts/AreaChartGraph";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import { calculatedMarkDistribution } from "examsbook-react-sdk/common";

/**
 * Quiz result marks distribution area chart.
 * @param props
 * @returns {*}
 * @constructor
 */
const MarksDistributionChart = props => {
    const { quizResults } = props;
    const t = useTranslations();
    const areaChartData = calculatedMarkDistribution(quizResults);
    return (
        <>
            <h4>{t('student_mark_Ratio_Chart')}</h4>
            <div className="area-chart">
                <AreaChartGraph t={t} data={areaChartData}/>
                <p className="horizontal">{t("Marks_Range")}</p>
            </div>
        </>
    );
};

export default MarksDistributionChart;
