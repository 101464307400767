import useTimeRemainingLiveVideo from "./useTimeRemainingLiveVideo";
import useTranslations from "./useTranslations";
export default function useLiveVideoButtonStatus(liveVideoList) {
  const timeRemaining = useTimeRemainingLiveVideo(liveVideoList);
  const t = useTranslations();
  let disabled = false;
  let label = "";
  let action = "";
  //live video already live.
  if (liveVideoList) {
    if (!!liveVideoList && liveVideoList.startDate > new Date().getTime()) {
      disabled = true;
      label = t("live_in") + "" + timeRemaining;
    } else {
      return 0;
    }
  }
  return {
    disabled,
    label,
    action
  };
}