import React from 'react';
import ContentLoader from 'react-content-loader';

const QuizCardLoader = () => (
    <div>
        <div className="card-list">
            <ContentLoader
                height={340}
                width={195}
                speed={1}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb">

                <rect x="5" y="15" rx="5" ry="5" width="60" height="15"/>
                <rect x="140" y="15" rx="5" ry="5" width="80" height="15"/>

                <rect x="5" y="45" rx="5" ry="5" width="220" height="10"/>

                <rect x="5" y="75" rx="5" ry="5" width="220" height="10"/>

                <rect x="5" y="105" rx="5" ry="5" width="220" height="10"/>

                <rect x="5" y="135" rx="5" ry="5" width="220" height="10"/>

                <rect x="5" y="165" rx="5" ry="5" width="220" height="10"/>

                <rect x="5" y="195" rx="5" ry="5" width="220" height="10"/>

                <rect x="5" y="225" rx="5" ry="5" width="220" height="10"/>


                <rect x="5" y="285" rx="5" ry="5" width="220" height="35"/>
            </ContentLoader>
        </div>
    </div>
);

export default QuizCardLoader;
