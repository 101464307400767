import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
export default function useSingleSubject(subjectId) {
  const subjects = useSelector(state => state.examData.subjects);
  const [selectedSubject, setSubject] = useState({
    topics: [],
    title: '',
    questionCount: 0
  });
  useEffect(() => {
    //setSubject()
    if (subjectId && subjects && subjects.length) {
      let filtered = subjects.filter(o => o._id === subjectId);
      if (filtered && filtered.length) {
        setSubject(filtered[0]);
      }
    }
  }, [subjectId, subjects]);
  return selectedSubject;
}