import React from "react";
import { ReactComponent as NotFoundImage } from "../assets/images/notFound.svg";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
///home/hs/Desktop/lms/examsbook-lms-core/applications/apps/web-app/src/assets/images/notfound .png
export default function NotFound() {
    const t = useTranslations();
    const classes = useStyles(styles)();
    return (
        <>
            <div className={classes.container}>
                <div className={classes.ImageContainer} >
                    <NotFoundImage className={classes.Img} alt="Not Found" />

                </div>
                <h2 className={classes.heading}>{t('No Result Found')}</h2>
                <p className={classes.content}>{t('Please try again with another')}<br />
                {t('keyword or maybe use generic term')}</p>
            </div>
        </>
    )
}
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: "60%",
        paddingTop: '10vh'
    },
    ImageContainer: {
        width: "50%",
    },
    Img: {
        // width: '23.6vw',
        width: '100%',
        height: 'auto',

    },
    heading: {
        fontWeight: '600',
        fontSize: '20px',
    },
    content: {
        fontSize: '14px',
        textAlign: 'center',
        color: '#84818A',
        margin: '-17px',
        paddingBottom: '10px',
        fontWeight: '400'
    },
    "@media (min-width: 768px)": {
        container: {
            width: "40%",
        },
        content: {
            fontSize: '15px',
            textAlign: 'center',
            color: '#84818A',
            margin: '-15px'
        }
    },
}


