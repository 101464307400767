import moment from "moment";

function useAuthorization() {
  const decodeToken = (token) => {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload;
    } catch (e) {
      console.error("Invalid token", e);
      return null;
    }
  };

  const validateToken = (token) => {
    if (!token) return false;
    
    const decodedToken = decodeToken(token);

    // TODO: remove this if condition after we start getting expiration in token
    if (decodedToken?._id) return true;

    if (decodedToken?.exp) {
      const expirationTime = moment.unix(decodedToken.exp);
      const currentTime = moment().unix();
      if (expirationTime.isAfter(currentTime)) return true;
    }
    
    return false;
  };

  return { decodeToken, validateToken };
}

export default useAuthorization;
