import React from 'react';
import MyCoursesList from "../components/MyCoursesList/MyCoursesList";
import { useAnalyticsPageView } from "examsbook-react-sdk/custom-hooks";

/**
 * Test series page
 * @param props
 * @returns {*}
 * @constructor
 */
function MyCourses(props) {
    const { history } = props;
    //analytic
    useAnalyticsPageView();

    function gotoPage() {
        history.push('/tests');
    }

    return (
        <MyCoursesList
            handleTabChange={() => gotoPage()}
            showFooter={true}
        />
    );
}

export default MyCourses;