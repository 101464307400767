import React from 'react';
import { useDispatch } from "react-redux";
import ListItem from '@material-ui/core/ListItem';
import LongMenu from "../longMenu";
import './compactQuestion.scss';
import MathJax from "react-mathjax4";
import {
    openQuestionInPlayer,
    activePracticeQuesIndex,
    moveActivePracticeIndex,
    changePracticeQuestionFilter
} from "examsbook-react-sdk/actions";
import { editor } from 'examsbook-react-sdk/common';

const classMap = {
    "CORRECT": "correct-ans",
    "INCORRECT": "incorrect-ans",
    "UN_ATTEMPTED": ""
};

/**
 * Compact question to show in question list screens.
 */

function CompactQuestion(props) {
    const {
        questionNo,
        question,
        totalQuestions,
        index,
        selectedSubjectTopic,
        selectedIndex,
        history,
        singleQuesUrl
    } = props;
    const dispatch = useDispatch();

    /**
     * Select question to show on UI
     * @param question
     */
    function selectQuestion(ques) {
        dispatch(openQuestionInPlayer(ques));
        history.push(singleQuesUrl + ques.slug);
        //if there are tabs
        if (selectedIndex) {
            props.setTestSeriesIndex(selectedIndex);
        }

    }

    function selectQuestionIndex(selectedQuestion, questionIndex) {
        history.push(singleQuesUrl + selectedQuestion.slug);
        dispatch(activePracticeQuesIndex(questionIndex));
        dispatch(changePracticeQuestionFilter('ALL'));
        let moveQuestion = questionIndex - 5;
        dispatch(moveActivePracticeIndex((moveQuestion > 0) ? moveQuestion * 42 : -0));
    }

    //show class based on status
    function getClass(status) {
        return classMap[status];
    }

    // toggle saved state of a quiz.
    function toggleSavedQuestion(event) {
        event.stopPropagation();
        props.toggleSavedQuestion();
    }

    // convert math type question readable format
    editor(question.title);

    return (
        <div>
        <ul className={"ul-new"}>
            <li onClick={toggleSavedQuestion} className={"li-new"}>
                <i className={'fa ' + (!question.isSaved ? 'fa-bookmark-o' : 'fa-bookmark')}/>
            </li>
            <li className={"li-new"}>
                <LongMenu question={question}/>
            </li>
        </ul>
        <ListItem
            onClick={selectedSubjectTopic ? () => selectQuestionIndex(question, index) : () => selectQuestion(question)}>
            <div className={"saved-questions " + (getClass(question.status))}>
                <div className={question.status === 'CORRECT' ? 'correct-option' :
                    question.status === 'INCORRECT' ? 'incorrect-option' : ''}>
                    {question.status === 'CORRECT' ? <i className="fa fa-check-circle"/> :
                        question.status === 'INCORRECT' ? <i className="fa fa-times-circle"/> : null}
                </div>
                <div className="saved-button">
                    <div className="saved-type">
                        <p>
                            {questionNo} of {totalQuestions}
                        </p>
                    </div>
                </div>
                <h4>
                    <MathJax.Context input="tex">
                        <MathJax.Html html={question.title}/>
                    </MathJax.Context>
                </h4>

            </div>
        </ListItem>
        </div>
    );
}

export default CompactQuestion;
