import _objectSpread from "/codebuild/output/src2290389247/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import status from '../constants/actionStatus';
import { setActiveQuestionIndex } from "./setActiveQuestionIndex";
import { getQuizzesData, getQuizzes } from "../services/quizService";
export const getQuiz = (slug, mode) => {
  return dispatch => {
    if (mode) {
      slug = slug + '?mode=' + mode;
    }
    dispatch({
      type: 'GET_QUIZ_REQUEST',
      payload: {
        questions: [],
        totalQuestions: 0,
        id: '',
        status: status.PENDING
      }
    });
    getQuizzesData(slug).then(formattedQuiz => {
      if (formattedQuiz.time) {
        // dispatching formattedQuiz data to reducer
        dispatch({
          type: 'INIT_TIMER',
          time: formattedQuiz.time
          // timeTaken: formattedQuiz.timeTaken
        });
      }
      dispatch({
        type: 'GET_QUIZ_SUCCESS',
        payload: formattedQuiz
      });
      //set first question as default
      return dispatch(setActiveQuestionIndex(0));
    }).catch(function (error) {
      // handle error
      dispatch({
        type: 'GET_QUIZ_ERROR',
        payload: {
          questions: [],
          totalQuestions: 0,
          id: '',
          status: status.ERROR
        }
      });
    });
  };
};
export const getPracticeQuizList = function (courseId) {
  let mode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "practice-quiz";
  return dispatch => {
    dispatch(request());
    getQuizzes(courseId, mode).then(response => {
      // handle success
      dispatch(success(response));
    }).catch(error => {
      // handle error
      dispatch(failure(error.toString()));
    });
    function request() {
      return {
        type: "PRACTICE_QUIZ_REQUEST"
      };
    }
    function success(response) {
      return {
        type: "PRACTICE_QUIZ_SUCCESS",
        payload: response
      };
    }
    function failure(error) {
      return {
        type: "PRACTICE_QUIZ_FAILURE",
        payload: error
      };
    }
  };
};
export const getLiveQuizList = function (courseId) {
  let mode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "live-test";
  return dispatch => {
    dispatch(request());
    getQuizzes(courseId, mode).then(response => {
      // handle success
      dispatch(success(response));
    }).catch(error => {
      // handle error
      dispatch(failure(error.toString()));
    });
    function request() {
      return {
        type: "LIVE_QUIZ_REQUEST"
      };
    }
    function success(response) {
      return {
        type: "LIVE_QUIZ_SUCCESS",
        payload: response
      };
    }
    function failure(error) {
      return {
        type: "LIVE_QUIZ_FAILURE",
        payload: error
      };
    }
  };
};
export const getCompletedQuizList = function (courseId, length, skip) {
  let old = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  return dispatch => {
    dispatch(request());
    getQuizzes(courseId, null, length, skip).then(response => {
      // handle success
      dispatch(success(response));
    }).catch(error => {
      // handle error
      dispatch(failure(error.toString()));
    });
    function request() {
      return {
        type: "COMPLETED_QUIZ_REQUEST",
        payload: {
          skip
        }
      };
    }
    function success(response) {
      return {
        type: "COMPLETED_QUIZ_SUCCESS",
        payload: _objectSpread(_objectSpread({}, response), {}, {
          data: [...(skip > 0 ? old.data : []), ...response.data]
        })
      };
    }
    function failure(error) {
      return {
        type: "COMPLETED_QUIZ_FAILURE",
        payload: error
      };
    }
  };
};