import React from "react";
import { Route, Redirect } from "react-router-dom";
import ErrorBlock from "../common/Errors/errorBlock";
import useAuthorization from "../../hooks/useAuthorization";

export const PublicRoute = ({ component: Component, ...rest }) => {
  const { validateToken } = useAuthorization();
  const token = localStorage.getItem("token");
  const isValid = validateToken(token);

  return (
    <Route
      {...rest}
      render={(props) =>
        isValid ? <Redirect to="/dashboard" /> : 
        <div className="main-div">
          <Component {...props} />
        </div>
      }
    />
  );
};
