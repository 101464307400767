import React, { useState } from 'react';
import Modal from "@material-ui/core/Modal/Modal";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import ResponseScreen from "../ResponseScreen/responseScreen";

/**
 * Question box area in topic analysis
 */
function TopicWiseAnalysisQuestionBox(props) {
    const [ addResponseModal, setAddResponseModal ] = useState(false);
    const [ quizResultDetailKey, setQuizResultDetailKey ] = useState(false);
    let { chapterWiseData, quesResponses, t } = props;
    let answers = quesResponses ? quesResponses.answers : [];

    function closeModal(data) {
        setAddResponseModal(false);
    }

    function changeQuestionIndex(key) {
        setQuizResultDetailKey(key);
    }

    function openModel(key) {
        setAddResponseModal(true);
        setQuizResultDetailKey(key);
    }

    return (
        <React.Fragment>
            {quesResponses && quesResponses.quesList ? quesResponses.quesList.map((question, index) => {
                return (
                    question && question.question && question.question.id.topic === chapterWiseData._id ?
                        <span onClick={() => openModel(index)}
                              className={answers[index]}
                              key={index}>{index + 1}
                                       </span> : ''
                );
            }) : ''}
            <div>
                <Modal className="response-modal"
                       aria-labelledby="simple-modal-title"
                       aria-describedby="simple-modal-description"
                       open={addResponseModal}
                       onClose={closeModal}>
                    <DialogContent>
                        <ResponseScreen
                            changeQuestionIndex={changeQuestionIndex}
                            quesResponses={quesResponses} t={t}
                            quizResultDetailKey={quizResultDetailKey}
                            closeModal={closeModal}/>
                    </DialogContent>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default TopicWiseAnalysisQuestionBox;
