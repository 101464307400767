import React from "react";
import VideoSelfLearning from "./VideoSelfLearning";
import useSelfLearningContent from "./useSelfLearningContent";
import InstructionBlock from "../../Quiz/InstructionBlock/InstructionBlock";
import { ReactComponent as SelfLearningImage } from "../../../assets/images/no-pdf.svg";
import { useStyles } from "examsbook-react-sdk/custom-hooks";

function SelfLearningContent(props) {
  const styles = {
    selfLearningImg: {
      '@media (max-width:959px)': {
        height: '18.3rem'
      } 
    }
  }
  const classes = useStyles(styles)();
  const {
    playerRef,
    defaultVideo,
    currentVideo,
    videoPlayback,
    setVideoPlayback,
    handleVideoPlayback,
    handleVideoOnReady,
    showPopup,
    closePopup,
    handleResume,
    handleStartFromBeginning,
    callProgressApi,
    defaultVideoPlayerSettings,
    dynamicVideoPlayerSettings,
    showComponent,
    toggleInstructionBox,
    startQuiz,
    quizIsStarted,
    isVideoLoaded,
    certificateUnlocked
  } = useSelfLearningContent(props);

  if (!showComponent) {
    return null;
  }

  if (defaultVideo === undefined) {
    return (
      currentVideo?.refModel === "Quiz" ? (
        <InstructionBlock
          quiz={currentVideo?.contentData}
          quizIsStarted={quizIsStarted}
          toggleInstructionBox={toggleInstructionBox}
          startQuiz={startQuiz}
          certificateUnlocked={certificateUnlocked}
        />
      ) : <SelfLearningImage alt="default-image" className={classes.selfLearningImg} style={{ width: "100%" }} />
    )
  }

  return (
    <>
      {/* Video */}
      {currentVideo?.refModel === "RecordedVideo" && (
        <VideoSelfLearning 
          playerRef={playerRef}
          currentVideo={currentVideo}
          videoPlayback={videoPlayback}
          setVideoPlayback={setVideoPlayback}
          handleVideoPlayback={handleVideoPlayback}
          handleVideoOnReady={handleVideoOnReady}
          defaultVideoPlayerSettings={defaultVideoPlayerSettings}
          dynamicVideoPlayerSettings={dynamicVideoPlayerSettings}
          showPopup={showPopup}
          closePopup={closePopup}
          handleResume={handleResume}
          handleStartFromBeginning={handleStartFromBeginning}
          callProgressApi={callProgressApi}
          isVideoLoaded={isVideoLoaded}
          thumbnail={<SelfLearningImage alt="default-image" style={{ width: "100%" }} />}
        />
      )}
      {/* Quiz Instruction */}
      {currentVideo?.refModel === "Quiz" && (
        <InstructionBlock
          quiz={currentVideo?.contentData}
          quizIsStarted={quizIsStarted}
          toggleInstructionBox={toggleInstructionBox}
          startQuiz={startQuiz}
          certificateUnlocked={certificateUnlocked}
        />
      )}
    </>
  );
}

export default SelfLearningContent;
