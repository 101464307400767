import { useEffect } from "react";

/**
 * this does not render any html other then adding a popup on reload or back icon.
 */

export default function usePopupHandlerOnUnload(isActiveQuiz, viewMode) {
  useEffect(() => {
    if (isActiveQuiz > 0 && !viewMode) {
      addPopupOnUnload();
    } else {
      removePopupOnUnload();
    }
    return () => {
      removePopupOnUnload();
    };
  }, [isActiveQuiz, viewMode]);
  function addPopupOnUnload() {
    if (!window.onbeforeunload) {
      // Warning before leaving the page (back button, or outgoinglink)
      //we may want to add propr html popup.
      console.log('addPopupOnUnload-------');
      window.onbeforeunload = function () {
        return "Do you really want to leave the quiz without completing?";
      };
    }
  }
  function removePopupOnUnload() {
    if (window.onbeforeunload) {
      window.onbeforeunload = null;
    }
  }
}