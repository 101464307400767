import { getLiveVideoDetails, liveTrackingApi } from "../services/liveVideoService";
export const getLiveVideo = courseId => dispatch => {
  dispatch(request());
  getLiveVideoDetails(courseId).then(response => {
    // handle success
    dispatch(success(response));
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: "LIVE_VIDEO_REQUEST"
    };
  }
  function success(response) {
    return {
      type: "LIVE_VIDEO_SUCCESS",
      payload: response
    };
  }
  function failure(error) {
    return {
      type: "LIVE_VIDEO_FAILURE",
      payload: error
    };
  }
};
export const addLiveTracking = data => dispatch => {
  dispatch({
    type: "TRACKING_REQUEST",
    payload: data
  });
  return liveTrackingApi(data).then(function (response) {
    // handle success
    dispatch({
      type: "TRACKING_SUCCESS",
      payload: response
    });
    return response;
  }).catch(function (error) {
    // handle error
    dispatch({
      type: "TRACKING_ERROR",
      payload: error.toString()
    });
    return error.toString();
  });
};