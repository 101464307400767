import React, { useEffect, useState } from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import '../CoursesList/coursesList.scss';
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useHistory } from "react-router-dom";
import CourseModal from "./CourseModal";
import { useDispatch, useSelector } from 'react-redux';
import { addCourse } from 'examsbook-react-sdk/actions/addCourse';
import { reactGaEvent } from "examsbook-react-sdk/actions/Common";
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { ReactComponent as Live } from "../../../src/assets/images/live.svg";
import { recentCourseAction } from 'examsbook-react-sdk/actions';
import { courseAddedNotification } from 'examsbook-react-sdk/actions/getNotifications';
import OverlayButton from '../../constants/OverlayButton';
import CommonButton from '../../constants/CommonButton';
import { useTheme } from '../../constants/themeProvider';
import moment from "moment";
import { ReactComponent as ExpiringClock } from "../../../src/assets/images/expiringclock.svg";
import { ReactComponent as CertifiedIcon } from "../../../src/assets/images/certifiedIcon.svg";

/**
 * Test series card
 * @param props
 * @returns {*}
 * @constructor
 */
function CoursesCard(props) {
    const { testData, dynamicLogos, isDynamicLogo, certificateListing, dueDateinDays } = props;
    const history = useHistory();
    const t = useTranslations();
    const classes = useStyles();
    const [isModalVisible, setModalVisible] = useState(false);
    const theme = useTheme();
    const [courseExpiryDate, setCourseEndDate] = useState();
    const [showComponent, setShowComponent] = useState(false);
    const [certificateData, setCertificateData] = useState([])
    const [showExpirationDate, setShowExpirationDate] = useState(false);
    const user = useSelector((state) => state.user);
    let userId = user?._id;
    const [courseAdded, setCourseAdded] = useState(false)
    const dispatch = useDispatch();
    const currentDate = new Date();
    const courseEndDate = new Date(currentDate);

    const callRecentApi = async () => {
        const payload = { courseId: testData._id };
        await dispatch(recentCourseAction(payload));
    };
    const openModal = () => {
        setModalVisible(true);
    };
    
    const closeModal = () => {
    setModalVisible(false);
    };


    function handleViewClick() {
        localStorage.setItem('dataKey', testData._id);
        window.dispatchEvent(new Event('storage'));
        reactGaEvent("Course Material", "View Course");
        callRecentApi();
        localStorage.setItem('courseName',testData?.name);
        localStorage.setItem('courseDescription',testData?.description);
        localStorage.setItem('courseId',testData._id);
        history.push('/course-material/content', {courseId:testData._id, subjectId: '', topicId:'', subjectDetail:testData, subjectName:'', topicName:''});
    }

    const QuizSucessful = ({ showModal, closeModal }) => {
        return (
          <div className={classes.modalBg + ` ${showModal ? 'show' : ''}`} onClick={closeModal} >
            <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
                <div className={classes.modalHeading}>
                    <h2 className={classes.modalText}>{ t("Course_added_successfully")}</h2>
                    <CommonButton onClick={closeModal} title={t('Ok')} classStyleHeading={classes.okButton}/>
                </div>
            </div>
          </div>
        );
    };

    function handleGoToTestClick() {
        const currentTime = Date.now();
        dispatch(
            addCourse({
                userId: user._id,
                courses: [{
                    courseId: testData?._id,
                    startDate: currentTime,
                    dueDateinDays: user?.userExpireInDay <= dueDateinDays ? parseInt(user?.userExpireInDay) : dueDateinDays,
                    endDate: currentTime + (dueDateinDays > 0 ? dueDateinDays-1 : dueDateinDays) * 24 * 60 * 60 * 1000
                }],
            })
        );
        setCourseAdded(true);
        let data = {
            tag: "Course Added",
            courseId: [testData?._id],
            scheduledDate: testData?.endDate,
        }
        dispatch(courseAddedNotification(userId, data));
        setShowExpirationDate(true);

    openModal();
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [expireDays, setExpireDays] = useState(false);
    const [courseExpired, setCourseExpired] = useState(false);
    const percentageCompletion = testData?.progressPercent !== undefined ? testData?.progressPercent : 0

    useEffect(() => {
        checkExpiration();
    }, []);

    useEffect(() => {
        getCertificateListing();
    },[testData, certificateListing])

    const checkExpiration = () => {
        courseEndDate.setDate(currentDate.getDate() + (testData?.dueDateinDays > 0 ? testData?.dueDateinDays : testData?.dueDateinDays));
        courseEndDate.setHours(23, 59, 59)
        setCourseEndDate(user?.userExpireInDay <= dueDateinDays ? user?.endDate : courseEndDate);
        const timeDifference = courseEndDate.getTime() - currentDate.getTime();
        setExpireDays(Math.ceil(timeDifference / (1000 * 60 * 60 * 24)))
    };

    useEffect(()=>{
        if (expireDays > 0) {
            setCourseExpired(false)
        } else{
            setCourseExpired(true)
        }
    },[expireDays])

    useEffect(() => {
        const timer = setTimeout(() => {
          setShowComponent(true);
        }, 100);
        return () => clearTimeout(timer);
    }, []);

    const getCertificateListing = () => {
        const isCourseIdPresent = certificateListing?.filter(item => item?.courseId?._id === testData?._id);
        if (isCourseIdPresent) {
            setCertificateData(certificateListing?.find(item => item?.courseId?._id === testData?._id));
        }
    }

    useEffect(() => {
        if(testData?.isUserCourse) {
            setShowExpirationDate(true);
        }
    },[testData?.isUserCourse])

    return (
        <React.Fragment>
        <div className="card-list-image">
            <div className='image-container image-fix'>
                <img style={{objectFit: 'contain', borderRadius: '4px 4px 0px 0px'}}
                    src={testData?.featured ? testData?.formattedData?.thumbnail?.jpg : isDynamicLogo && isDynamicLogo !== 'undefined' && dynamicLogos} alt="" onError={(e) => {
                        e.target.onerror = null;
                    }} />

                {testData.isUserCourse && <div className={classes.courseBottomRight} style={{background: theme?.primaryColor}}>
                    <div className="my-course-button">
                        <span className='ribbon-text' style={{color: theme?.primaryFontText}}>{t('MY_COURSE')}</span>
                    </div>
                </div>}
            </div>

            <div className='card-list'>
            <div className="course-module">
                {testData.isSelfLearning === true ? <button className="course-learning" style={{border: `1px solid ${theme?.primaryColor}`, color: theme?.primaryColor}}>{t('SELF LEARNING')}</button> : ''}
                {testData.isLive === true ? <button className="course-live-learning" style={{border: `1px solid ${theme?.primaryColor}`, color: theme?.primaryColor}}><span className='live-image'><Live/></span><span>{t('live')}</span></button> : ''}
            </div>
            <p className='card-title'>{testData.name}</p>
            <p className='card-description' dangerouslySetInnerHTML={{ __html: testData?.description }}></p>
            <div className="course-bottom-right-expire">
            {showExpirationDate && <div className="my-course-button-expire">
                    {showComponent && <div style={{display: 'flex', marginTop: '10px'}}><ExpiringClock/></div>}
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {showComponent && <span className={`ribbon-text-expire ${classes.expiringMobileText}`} style={{maxWidth: certificateData?.courseId?._id !== testData?._id ? '30vw' : '15vw', marginTop: '10px'}}>{expireDays > 0 ? `${t('Expiring on')} ${moment(courseExpiryDate).format("DD-MM-YYYY")}` : t('Expired')}</span>}
                    </div>
                </div>}
                <div style={{display: 'flex', alignItems: 'center', minHeight: '34px'}}>
                    {certificateData?.courseId?._id === testData?._id && 
                    <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
                        {showComponent && <><div style={{display: 'flex'}}><CertifiedIcon/></div>
                        <div style={{display: 'flex', alignItems: 'center'}}><span className='certified-text'>{t('Qualified')}</span></div></>}
                    </div>}
                </div>
            </div>

            <div className='card-title-progress' style={{ minHeight: '26px' }}>
                {!testData.isAddedCourse && <LinearProgressWithLabel value={percentageCompletion} />}
            </div>
            
            {testData.pricing === "FREE" ? (
                testData.isUserCourse ? (
                    <div className='free-div'>
                        <div className="card-btn-new">
                            <OverlayButton onClick={handleViewClick} disabled={courseExpired} title={t('VIEW_COURSE')}/>
                        </div>
                    </div>
                ) : courseAdded ? (
                    <div className='free-div'>
                        <div className="card-btn-new">
                            <OverlayButton onClick={handleViewClick} disabled={courseExpired} title={t('VIEW_COURSE')}/>
                        </div>
                    </div>
                ) : (
                    <div className='free-div'>
                        <div className="card-btn-new">
                            <OverlayButton onClick={handleGoToTestClick} disabled={courseExpired} title={t('ADD_COURSE')}/>
                        </div>
                    </div>
                )
            ) : testData.pricing === "PAID" &&
                testData.isAddedCourse ? (
                <div className='free-div'>
                    <div className="card-btn-new">
                        <OverlayButton onClick={handleViewClick} disabled={courseExpired} title={t('VIEW_COURSE')}/>
                    </div>
                </div>
            ) : (
                testData.isDiscount ? (
                    <div className='discount-div' onClick={handleOpen}>
                        <p style={{ textDecoration: 'line-through', lineHeight: '2.2', fontWeight: 'bold', color: 'grey', margin: 0 }}>₹{testData.price}</p>
                        <p style={{ fontWeight: 'bold', margin: 0, lineHeight: '2.2' }}>₹{testData.discountedPrice}</p>
                    </div>
                ) : (
                    <div className='price-div' onClick={handleOpen}>
                        <p style={{ fontWeight: 'bold', margin: 0, lineHeight: '2.2' }}>₹{testData.price}</p>
                    </div>
                )
            )}

            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <CourseModal testData={testData} modalFooterSrc={"dashboard"} courseAdded={courseAdded} handleGoToTestClick={handleGoToTestClick} handleClose={handleClose} handleViewClick={handleViewClick} expireDays={expireDays}/>
            </Modal>

        </div>
        {isModalVisible && <QuizSucessful showModal={isModalVisible} closeModal={closeModal} />}
        </React.Fragment>
    );
}

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    okButton: {
        borderRadius:'4px',
        padding:'6px 30px',
        fontSize:'15px',
        margin:'15px',
        cursor:'pointer',
        '&:hover': {
            backgroundColor: '#0c3450e0 !important',
        },
    },
    modalBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100% !important',
        background: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '999999'
    },   
    modalContent: {
        width: '420px',
        margin: 'auto',
        marginTop: '40vh',
        '@media (max-width:439px)': {
            width: '280px'
        }
    },
    modalHeading: {
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:'white',
        borderRadius:'10px',
        padding:'20px 40px',
        borderRadius: '6px'
    },
    modalText: {
        fontWeight:'normal',
        textAlign: 'center'
    },
    courseBottomRight: {
        position: 'absolute',
        top: '17px',
        right: '-39px',
        textAlign: 'center',
        transform: 'rotate(45deg)',
        width: '139px',
        zIndex: '0'
      },
    expiringMobileText: {
        '@media (min-width:392px) and (max-width:615px)': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '12vw'
        }
    }
}));
export default CoursesCard;
