import React from 'react';
import '../../components/common/Nodata/noData';
import { Button } from '@material-ui/core';
import comingSoon from '../../assets/images/comming-soon.svg';
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import { useHistory } from "react-router-dom";

/**
 * Release plan component.
 * @param props
 * @returns {*}
 * @constructor
 */
const ReleasePlan = props => {
    const history = useHistory();
    const t = useTranslations();
    return (
        <React.Fragment>
        <div className="no-data">
            <div className="no-data-banner"></div>
            <div className="no-data-block">
                <div className="no-data-banner"></div>
                <div className="no-data-img">
                    <img src={comingSoon} alt="comming soon"/>
                </div>
                <div className="no-data-text">
                    <Button onClick={() => history.push('/test-series')}>{t('GO_TO_COURSES')}</Button>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
};

export default ReleasePlan;
