import React from "react";
import CertificateListView from "./CertificateListView";

function CertificateList() {
  return (
    <CertificateListView/>
  );
}

export default CertificateList;
