import React from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TopicWiseAnalysisQuestionBox from './topicWiseAnalysisQuestionBox';
import CommonLinearProgress from "../CommonLinearProgress/commonLinearProgress";
import { setCurrentSubjectAndTopic } from "examsbook-react-sdk/actions/setCurrentSubjectTopic";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";

/**
 * Single topic row in topic wise analysis
 */
function SingleTopicAnalysisRow(props) {
    const { chapterWiseData, index, quesResponses } = props;
    const dispatch = useDispatch();
    const t = useTranslations();
    const history = useHistory();
    let score = parseInt(chapterWiseData.score);

    function selectSubjectAndTopic(subjectId, topicId) {
        history.push('/practice/subject/' + subjectId + '/topic/' + topicId);
        dispatch(setCurrentSubjectAndTopic({ subject: subjectId, topic: topicId }));
    }

    return (
        <React.Fragment>
            <div className="non-verbal">
                <h1>
                    <span>{index + 1}. </span>{chapterWiseData.name && chapterWiseData.name !== 'unCategorized' ? chapterWiseData.name : t('uncategorized')}
                    {!quesResponses ? <span
                        className="question-count">{chapterWiseData.attempted + ' / ' + chapterWiseData.total}</span> : ''}
                </h1>
                <div className="linear-progress">
                    <span>{t('CORRECT%')}</span>
                    <CommonLinearProgress progressPercentage={score}/>
                    <span className="right">{chapterWiseData.score}</span><span>%</span>
                </div>
            </div>
            {!quesResponses ?
                <div className="chapter-button">
                    {(chapterWiseData.attempted !== chapterWiseData.total) ?
                        <button onClick={() => selectSubjectAndTopic(chapterWiseData.subject, chapterWiseData._id)}>
                            {t('RESUME')}</button> : <button className="reviseBtn"
                                                             onClick={() => selectSubjectAndTopic(chapterWiseData.subject, chapterWiseData._id)}>
                            {t('REVISE')}</button>
                    }
                </div> : ''}
            {
                quesResponses ?
                    <div className="verbal-button">
                        <TopicWiseAnalysisQuestionBox t={t} chapterWiseData={chapterWiseData}
                                                      quesResponses={quesResponses}/></div> : ''
            }
        </React.Fragment>

    );
}

export default SingleTopicAnalysisRow;
