import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { AccordionSummary, Accordion, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelectedExam } from "examsbook-react-sdk/custom-hooks";
import { toggleSavedQuiz } from "examsbook-react-sdk/actions";
import { useDispatch } from 'react-redux';
import VideoPlayer from '../../pages/VideoPlayer';

/**
 * Video Accordion compoment.
 * @param props
 * @returns {*}
 * @constructor
 */
function VideoAccordion(props) {
    const { quizGroup, lang, singleTestSeriesLoading } = props;
    const selectedExam = useSelectedExam();
    const [ open, setOpen ] = useState(false);
    const dispatch = useDispatch();

    function handleChange() {
        setOpen(!open);
    }

    function unSaveQuiz(quiz) {
        dispatch(toggleSavedQuiz(quiz._id));
    }

    return (
        <div>
            {selectedExam ?
                (quizGroup && quizGroup.quizes && quizGroup.quizes.length ?
                    <Accordion className="accordion analysis-component"
                               expanded={!open}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} onClick={handleChange}
                                          aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>
                                {quizGroup.section[lang]}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="Accordian-detail">
                            {
                                (quizGroup.quizes).map((quiz, quizIndex) => {
                                    return (
                                        <VideoPlayer quizData={quiz} key={quizIndex}
                                                  singleTestSeriesLoading={singleTestSeriesLoading}
                                                  toggleQuizSave={() => unSaveQuiz(quiz)}/>
                                    );
                                })
                            }
                        </AccordionDetails>
                    </Accordion> : <VideoPlayer singleTestSeriesLoading={singleTestSeriesLoading}/>) :
                <Redirect to="/test-series"/>
            }
        </div>
    );
}

export default VideoAccordion;
