import React from 'react';
import FilterableTestsContainer from "../components/Tabs/FilterableTestsContainer";
import {
    useAnalyticsPageView,
    useFullMockTestList
} from "examsbook-react-sdk/custom-hooks";

/**
 * Practice test entry point.
 * @param props
 * @returns {*}
 * @constructor
 */
function FullMockTests(props) {
    useAnalyticsPageView();
    const {
        remainingMockTests,
        completedMockTests,
        mockTests,
        fullMockTestPending
    } = useFullMockTestList();

    let data = { allTests: mockTests, remainingTests: remainingMockTests, completedTests: completedMockTests };
    return (
        <FilterableTestsContainer
            data={data}
            isPending={fullMockTestPending}
        />
    );
}

export default FullMockTests;
