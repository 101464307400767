import useSelectedExam from "./useSelectedExam";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllTestList } from "examsbook-react-sdk/actions/getAllTestList";
import { getCompletedPracticeTestList, getCompletedTestList, getMockTestList, getPracticeRemainingTestList, getPracticeTestList } from "examsbook-react-sdk/selectors";
import status from "../constants/actionStatus";
import useUserLanguage from "./useUserLanguage";

/**
 * get list of all tests.
 * @returns {*}
 */
export default function useAllTestList(courseId) {
  //   const selectedExam = useSelectedExam();

  const dispatch = useDispatch();
  const {
    language
  } = useUserLanguage();
  useEffect(() => {
    if (courseId) {
      dispatch(getAllTestList(courseId));
    }
  }, [courseId, language]);
  let allTestData = useSelector(state => state.fullTestList);
  let isLoadingTest = allTestData.status === status.PENDING;
  let allTestList = allTestData.data;
  let completedTests = useSelector(state => getCompletedTestList(state));
  let remainingPracticeTestList = useSelector(state => getPracticeRemainingTestList(state));
  let completedPracticeTestList = useSelector(state => getCompletedPracticeTestList(state));
  let practiceTestList = useSelector(state => getPracticeTestList(state));
  let allMockTestList = useSelector(state => getMockTestList(state));
  return {
    isLoadingTest,
    allTestList,
    completedTests,
    remainingPracticeTestList,
    completedPracticeTestList,
    allMockTestList,
    practiceTestList
  };
}