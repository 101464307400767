import React from 'react';
import { useAnalyticsPageView } from "examsbook-react-sdk/custom-hooks";
import CourseList from './CourseList';

function Courses(props) {
    const { history } = props;
    //analytic
    useAnalyticsPageView();

    function gotoPage() {
        history.push('/tests');
    }

    return (
        <CourseList
            handleTabChange={() => gotoPage()}
            showFooter={true}
        />
    );
}

export default Courses;