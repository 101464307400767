let token = '';
const initialState = token;
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (action.type === 'USER_LOGGED_IN') {
    token = action.payload;
    return token;
  }
  return state;
});