import React from 'react';
import MathJax from "react-mathjax4";
import { editor } from 'examsbook-react-sdk/common';
/**
 * QuestionOption
 * @param props
 * @returns {*}
 * @constructor
 */
function QuestionOption(props) {
    const { t, option, index, isAttempted, isSelected, optionClass } = props;

    function selectAnswer() {
        props.selectAnswer();
    }

    // convert math type question readable format
    editor(option?.text);

    return (
        <li className={optionClass}
            onClick={!isAttempted ? selectAnswer : undefined}>
            <span className="options">{String.fromCharCode(65 + index)}</span>
            <div className="quesOptions">
                <MathJax.Context input="tex">
                    <MathJax.Html html={option?.text}/>
                </MathJax.Context>
            </div>
            <span className="checked"><i
                className={isAttempted ? !isSelected ?
                    option.correct ? 'fa fa-check' : '' :
                    option.correct ? 'fa fa-check' : 'fa fa-close' : ''}/>
                {isAttempted ? !isSelected ?
                    option.correct ? ' ' + t('correct') : '' :
                    option.correct ? ' ' + t('correct') : ' ' + t('incorrect') : ''}</span>
        </li>
    );
}

export default QuestionOption;
