export const ColorsConstant = {
    primary: "#43ac6538",
    Theme: '#f1f1f1',
    theme1:'#2A2A2B',
    theme2:'#404243',
    headerColor: "#172b56",
    cardcolor:'#fff',
    white: '#fff',
    whitelight:'#e6e6e6bd',
    black: '#000',
    Success: '#00ab66',
    SuccessLight: '#43ac6538',
    ErrorLight: '#ed41363d',
    Error: '#F44336',
    gray: "#616161",
    DarkLight: '#191c2799',
    blue:'#2F539B',
    yellow: "#ff9e22",
    nav: {
        backgroundColor: '#202124',
        active: '#fff',
        inActive: '#c489bc',
    },
    themeMode: 'default',
};


export const darkTheme = {
    Theme: '#000',
    theme1:'#1f1f1f',
    cardcolor:'#1f1f1f',
    headerColor: "#172b56",
    white: '#000',
    yellow: "#ff9e22",
    whitelight:'#080404c4',
    black: '#fff',
    SuccessLight: '#43ac6538',
    ErrorLight: '#ed41363d',
    Success: '#00ab66',
    Error: '#F44336',
    gray: "#fff",
    DarkLight: '#191c2799',
    blue:'#fff',
   
    nav: {
        backgroundColor: '#c489bc',
        active: '#fff',
        inActive: '#202124',
    },
    themeMode: 'dark',
};