import React, { useState } from 'react';
import './questionsChain.scss';
import Button from '@material-ui/core/Button';
import { useTranslations } from "examsbook-react-sdk/custom-hooks";

const QuestionFilter = [
    'ALL',
    'CORRECT',
    'INCORRECT',
    'UN_ATTEMPTED'
];

/**
 * QuestionsChain component.
 * @param props
 * @returns {*}
 * @constructor
 */
function QuestionsChain(props) {
    const {
        practiceQuestions,
        activePracticesQuesIndexVal,
        totalLength,
        incrementTranslate,
        selectQuestion,
        leftMoveSelectQuestion,
        onSelectChange
    } = props;
    const t = useTranslations();
    const [ showReportDropdown, setShowReportDropdown ] = useState();

    const toggleReportDropdown = () => {
        if(showReportDropdown){
            setShowReportDropdown(false);
        }
        else{
            setShowReportDropdown(true);
        }
    };
    const styles = {
        transform: 'translate(-' + incrementTranslate + 'px)'
    };
    let counter = 0;

    function handleChange(quesType) {
        setShowReportDropdown(false);
        onSelectChange(quesType);
    }

    function getActiveQuestionWithClass(isActive) {
        let classNames = '';
        if (isActive) {
            classNames = 'active';
        }
        return classNames;
    }

    return (
        <div className="question-chain-block">
            <div className="question-chain-box">
                <Button className="left" disabled={activePracticesQuesIndexVal === 0}
                        onClick={() => leftMoveSelectQuestion(practiceQuestions[activePracticesQuesIndexVal - 1], activePracticesQuesIndexVal - 1)}>
                    <i className="fa fa-chevron-left" aria-hidden="true"></i></Button>

                <ul className="question-chain-box-ul">
                    {
                        practiceQuestions.map((question, index) => {
                            let qindex = counter + index;
                            let classNames = getActiveQuestionWithClass(activePracticesQuesIndexVal === qindex);
                            return (

                                <li className={'question-chain-box-li ' + (classNames ? classNames : (question.status === 'CORRECT') ? 'correct' :
                                    (question.status === 'INCORRECT') ? 'incorrect' : '')} style={styles} key={index}
                                    onClick={() => selectQuestion(practiceQuestions[parseInt(index)], index)}>
                                    <p>{index + 1}</p>
                                </li>
                            );
                        })
                    }
                </ul>
                <Button className="right" disabled={activePracticesQuesIndexVal === (totalLength - 1)}
                        onClick={() => selectQuestion(practiceQuestions[activePracticesQuesIndexVal + 1], activePracticesQuesIndexVal + 1)}>
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </Button>

                <div className="showAnswer">
                    <span className="question-filter" onClick={() => toggleReportDropdown()}><i
                        className="fa fa-filter"></i></span>
  
                    {showReportDropdown ?
                        (
                            <ul className={'question-dropdown ' + (showReportDropdown ? 'question-dropdown-open' : '')} onMouseLeave={() => toggleReportDropdown()}>
                                {QuestionFilter.map((quesType) =>
                                    <li key={quesType} onClick={() => handleChange(quesType)}>
                                        {t(quesType)}
                                    </li>
                                )}
                            </ul>
                        ) : (null)}

                </div>
            </div>
        </div>
    );
}

export default QuestionsChain;
