let drawer = false;
const initialState = !!drawer;
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'SHOW_DRAWER':
      drawer = state;
      return action.payload;

    // case 'HIDE_DRAWER':
    //     drawer = state;
    //     return action.drawer;

    default:
      return state;
  }
});