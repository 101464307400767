const initialState = {
  "duration": 0,
  "timeTaken": 0,
  "init": false
};
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (action.type === 'INIT_TIMER') {
    return {
      duration: action.duration,
      timeTaken: action.timeTaken,
      init: true
    };
  } else if (action.type === 'CLEAR_TIMER') {
    return {
      duration: 0,
      timeTaken: 0,
      init: false
    };
  } else if (action.type === 'TICK_SECOND') {
    return {
      duration: state.duration,
      timeTaken: state.timeTaken + 1,
      init: true
    };
  }
  return state;
});