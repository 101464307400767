import { getQuestionLocationIndex } from "examsbook-react-sdk/common";
let timer = null;
const startQuestionTimer = () => {
  return (dispatch, getState) => {
    //if there is any old timer. clear it.
    if (timer) {
      clearInterval(timer);
    }
    timer = setInterval(() => dispatch(tick()), 1000);
    return dispatch({
      type: 'QUESTION_TIMER_START'
    });
  };
};
const tick = () => {
  return {
    type: 'QUESTION_TIMER_TICK'
  };
};
const stopQuestionTimer = questionIndex => {
  return (dispatch, getState) => {
    //if there is any old timer clear it.
    if (timer) {
      clearInterval(timer);
    }
    let {
      quiz,
      isViewMode,
      selectedQuestion
    } = getState();
    let {
      sectionNo,
      questionNo
    } = getQuestionLocationIndex(quiz, questionIndex);
    let questionsBlock = quiz.questions;
    if (!isViewMode && questionsBlock[sectionNo]) {
      //mark selected question visited.
      questionsBlock[sectionNo].questions[questionNo].timeSpent = selectedQuestion.timeSpent + 1;
      quiz.questions = questionsBlock;
      //dispatch
      dispatch({
        type: 'QUIZ_UPDATED',
        payload: quiz
      });
    }
    return dispatch({
      type: 'QUESTION_TIMER_STOP'
    });
  };
};
export { startQuestionTimer, stopQuestionTimer };