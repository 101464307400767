import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { getDefaultSupportDataAction, getSupportAction } from "examsbook-react-sdk/actions/getSupportAction";
import { useDispatch } from "react-redux";
import { useTheme } from "../../constants/themeProvider";


const CourseSideInfo = (props) => {
    const {courseCount, selectedCategory} = props;
    const t = useTranslations();
    const theme = useTheme();
    const classes = useStyles();
    const [showComponent, setShowComponent] = useState(false);
    const [dynamicSeoData, setDynamicSeoData] = useState([]);
    const [defaultSupportData, setDefaultSupportData] = useState([]);
    const [dynamicLogos, setDynamicLogos] = useState('');
    const dispatch = useDispatch();
    const [randomKey, setRandomKey] = useState(Math.random());

    const getSupportApiData = async() => {
      try {
       await dispatch(getSupportAction()).then((res) => {
          setDynamicSeoData(res);
        });
      } catch (error) {
        console.log('Error -', error.message);
      }
    };
  
    const getDefaultSeoSettingApiData = async () => {
      try {
          dispatch(getDefaultSupportDataAction()).then((res) => {
          setDefaultSupportData(res);
          });
      } catch (error) {
          console.error(error.message);
      }
    };
  
    useEffect(() => {
      getSupportApiData();
      getDefaultSeoSettingApiData();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
          setShowComponent(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

  let API_URL = process.env.REACT_APP_DYNAMIC_URL;
  useEffect(() => {
    const imageValue = dynamicSeoData?.data?.find(subValue => subValue.key === "Course")?.value;
    const defaultValue = defaultSupportData?.data?.['Course Image']?.find(subValue => subValue.key === "Course")?.defaultValue;
    let dynamicLogo = "";
    if (typeof API_URL != "undefined") {
      if (imageValue) {
        dynamicLogo = API_URL +'/img/post/original/' + imageValue;
      } else if (defaultValue) {
        dynamicLogo = API_URL +'/img/post/original/' + defaultValue;
      }
    }
    setDynamicLogos(dynamicLogo) 
  }, [dynamicSeoData, defaultSupportData])


    const validItems = courseCount?.sort((a, b) => new Date(b?.startDate) - new Date(a?.startDate))?.filter(item => {
      const currentDate = Date.now();
      const endDate = item?.courseId?.endDate || item?.endDate;
      const differenceInDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
      return ((selectedCategory === 'assigned' && item) ||
          (selectedCategory === 'expiring' && differenceInDays <= 7 && differenceInDays > 0) ||
          (selectedCategory === 'completed' && (item?.courseId?.progressPercent === 100 || currentDate > endDate)));
    });
  
    useEffect(() => {
      if (selectedCategory && validItems?.length) {
          setRandomKey(Math.random());
      }
    }, [selectedCategory]);

    return (
        <Grid className={classes.parentDiv} container key={randomKey}>
          <Box className={classes.parentHeading}>
              <Box>
                <Typography variant="subtitle1" className={classes.headingText}>
                  {selectedCategory === 'assigned' ? t('Courses Assigned') : selectedCategory === 'expiring' ? t('Courses Expiring') : t('Courses Completed')}
                </Typography>
              </Box>
          </Box>
          <Box className={classes.dataScroll}>
            {validItems && validItems.length ? validItems.map((item, index) => {
            const currentDate = Date.now();
            const endDate = item?.courseId?.endDate || item?.endDate;
            const courseImage = item?.featured?.largeImage?.jpg;
            const differenceInDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
            if ((selectedCategory === 'assigned' && item) ||
                (selectedCategory === 'expiring' && differenceInDays <= 7 && differenceInDays > 0) ||
                (selectedCategory === 'completed' && (item?.progressPercent === 100 || currentDate > endDate))) {
                return (
                    <Box className={`${classes.dataBlock + ' ' + classes.dataScrollVisible}`}>
                        <Box className={classes.leftHighlight}>
                          <Box component="img" src={courseImage?.split('/').pop() === '.jpg' || courseImage?.split('/').pop() === '.jpeg' || courseImage?.split('/').pop() === '.png' || courseImage?.split('/').pop() === '.webp' ? dynamicLogos: courseImage} className={classes.imageAlign}/>
                        </Box>
                        <Box className='content-child-div' style={{ width: '100%' }}>
                            <Box>
                            <Typography variant="subtitle1" className={classes.textFix}>{item?.courseId?.name}</Typography>
                                <Typography variant="subtitle2" style={{color: theme?.secondaryColor}} className={classes.percentageBlock}>{Math.round(item?.progressPercent)}% {t('Completed')}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )
                }
            }) : showComponent && <Box className={classes.noRecord}><Typography variant="subtitle2" className={classes.noData}>{t('No record found')}</Typography></Box>}
          </Box>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    parentDiv: {
      border: '1px solid rgba(12, 52, 80, 0.20)',
      background: '#FFF',
      display: 'block',
      height:'100%',
      borderRadius: '6px',
        '@media (max-width:959px)': { 
            maringTop: "20px",
        },
    },
    parentHeading: {
      width: '100%',
      alignSelf: 'center',
      padding: '0px 16px',
      borderBottom: '1px solid rgba(12, 52, 80, 0.10)'
    },
    headingText: {
      margin: '16px 0px',
      fontWeight: '600',
    },
    dataBlock: {
      display: 'flex',
      width: '100%',
      margin: '16px',
      height: '50px',
      gap: '16px'
    },
    leftHighlight: {
      minWidth: '50px',
      minHeight: '50px',
      background: '#0c345017',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imageAlign: {
      width: '40px',
      height: '40px',
      objectFit: 'contain'
    },
    noRecord: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      minHeight: '280px'
    },
    fontDark: {
      fontWeight: '500'
    },
    percentageBlock: {
      display: 'block',
      fontSize: '1em',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '80%',
    },
    noData: {
      display: 'block',
      margin: '16px',
      width: '100%',
      textAlign: 'center',
      color: '#00000080',
      fontSize: '1rem',
      fontWeight: '400'
    },
    dataScroll: {
        maxHeight: '330px',
        overflowX: 'hidden',
        height: '100%'
    },
    dataScrollVisible: {
      animation: 'fadeIn 1s',
    },
    '@keyframes fadeIn': {
      '0%': { opacity: 0 },
      '50%': { opacity: 0.5 },
      '100%': { opacity: 1 },
    },
    textFix: {
      fontSize: '1rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '80%',
      '@media (min-width:960px) and (max-width:1023px)': {
        maxWidth: '13vw',
      },
      '@media (min-width:1024px) and (max-width:1150px)': { 
        maxWidth: '10ch !important'
      },
      '@media (min-width:1151px) and (max-width:1440px)': {
        maxWidth: '12vw',
      }, 
    }
  }));
export default CourseSideInfo;