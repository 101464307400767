import status from "../constants/actionStatus";
const initialState = {
  status: status.PENDING
};
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'RESET_QUIZ_SUBMISSION':
      return initialState;
    case 'SAVE_QUIZ_SUCCESS':
      return action.payload;
    case 'SAVE_QUIZ_ERROR':
      return action.payload;
    default:
      return state;
  }
});