import React from 'react';
import './question.scss';
import LongMenu from "../longMenu";
import MathJax from 'react-mathjax4';
import QuestionOption from "./questionOption";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import { saveQuestionResponse } from "examsbook-react-sdk/actions/saveQuestionResponse";
import { useDispatch } from "react-redux";
import { editor } from 'examsbook-react-sdk/common';

/**
 * Single question in player
 */
function Question(props) {
    const { question, index, totalLength, openFeedbackModel, toggleSavedQuestion } = props;
    const t = useTranslations();
    const correctAnswer = question.options.find((option, i) => option.correct === true);
    const correctAnswerIndex = question.options.indexOf(correctAnswer);
    const isAttempted = (question && question.givenAnswer.length > 0);
    const directionClass = question && question.directionId && (question.directionId.type).toUpperCase() === 'LONG_TEXT' ? 'direction-block' : '';
    const dispatch = useDispatch();

    function toggleQuestionStatus() {
        toggleSavedQuestion();
    }

    function selectAnswer(optionId, quesId) {
        let quesResponse = {
            question: quesId,
            givenAnswer: [ optionId ]
        };
        dispatch(saveQuestionResponse(quesResponse));
    }

    function getCorrectOptionClass(isAttemptedQuestion, isSelectedOption, isCorrectOption) {
        let optionClass = '';
        if (isAttemptedQuestion && isCorrectOption) {
            optionClass = 'correct-option';
        } else if (isSelectedOption) {
            optionClass = 'incorrect-option';
        }
        return optionClass;
    }

    // convert math type question readable format
    editor(question.title);

    return (
        <React.Fragment>
            {question.directionId && question.directionId.name && (question.directionId.type).toUpperCase() === 'LONG_TEXT' ?
                <div id="direction" className={"saved-questions question-blocks " + directionClass}>
                    <h4>

                        <MathJax.Context input="tex">
                            <MathJax.Html html={question.directionId.direction}/>
                        </MathJax.Context>
                    </h4>
                </div> : null
            }
            <div className={"saved-questions question-blocks " + directionClass}>
                <div className="saved-button">
                    <div className="saved-type">
                        <p>
                            {index} of {totalLength}
                        </p>
                    </div>

                    <ul>
                        <li onClick={toggleQuestionStatus}><i
                            className={'fa ' + (!question.isSaved ? 'fa-bookmark-o' : 'fa-bookmark')}/></li>
                        <li>
                            <LongMenu question={question} t={t} openFeedbackModel={openFeedbackModel}/>
                        </li>
                    </ul>
                </div>
                {question.directionId && question.directionId.name && (question.directionId.type).toUpperCase() === 'LONG_TEXT' ?
                    <div className="mobile-view-direction">
                        <h4>
                            <MathJax.Context input="tex">
                                <MathJax.Html html={question.directionId.direction}/>
                            </MathJax.Context>
                        </h4>
                    </div> : null
                }
                {question.directionId && question.directionId.name && (question.directionId.type).toUpperCase() === 'SHORT_TEXT' ?
                    <h4>
                        <MathJax.Context input="tex">
                            <MathJax.Html html={question.directionId.direction}/>
                        </MathJax.Context>
                    </h4> : null
                }

                <h4>
                    <MathJax.Context input="tex">
                        <MathJax.Html html={question.title}/>
                    </MathJax.Context>
                </h4>
                <div className="question-with-option">
                    <ul>
                        {
                            question.options.map((option, i) => {
                                return (
                                    <QuestionOption
                                        t={t}
                                        key={i}
                                        index={i}
                                        option={option}
                                        selectAnswer={() => selectAnswer(option._id, question._id)}
                                        isAttempted={isAttempted}
                                        optionClass={getCorrectOptionClass(isAttempted, question.givenAnswer.indexOf(option._id) !== -1, option.correct)}
                                        isSelected={isAttempted && question.givenAnswer.indexOf(option._id) !== -1}/>
                                );
                            })
                        }
                    </ul>
                </div>
                <div className="explain-question">

                    {
                        isAttempted ? <React.Fragment>
                            <p className="correct-answer-option">{t('Correct_Answer')}<span>{'(' + String.fromCharCode(65 + correctAnswerIndex) + ') ' + correctAnswer.text}</span>
                            </p>
                            {question.explanation ?
                                <React.Fragment><h4>{t('explanation')}</h4>
                                    <MathJax.Context input="tex">
                                        <MathJax.Html html={question.explanation}/>
                                    </MathJax.Context></React.Fragment> : ''
                            }
                        </React.Fragment> : ''
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default Question;
