import React from 'react';
import PracticeQuestionsWrapper from "./practiceQuestionsWrapper";

const PracticeQuestions = props => {
    const { history, match } = props;
    return (
        <PracticeQuestionsWrapper history={history} match={match} isQuestionList={false}/>
    );
};

export default PracticeQuestions;
