import { getCategory } from "../services/categoryService";
export const getCategoryList = data => dispatch => {
  dispatch(request());
  getCategory(data).then(response => {
    // handle success
    dispatch(success(response));
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: "CATEGORY_REQUEST"
    };
  }
  function success(response) {
    return {
      type: "CATEGORY_SUCCESS",
      payload: response
    };
  }
  function failure(error) {
    return {
      type: "CATEGORY_FAILURE",
      payload: error
    };
  }
};