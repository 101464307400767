import React, { useEffect, useState } from "react";
import ScoreCard from "./scores";
import AttemptCard from "./attempts";
import DistributionCards from "./distribution";
import "./reports.scss";
import { courseResult, courseResultByCourseId } from "examsbook-react-sdk/actions/courseResult";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";

function Reports() {
  const location = useLocation();
  const [courseResultData, setCourseResultData] = useState();
  const dispatch = useDispatch();
  let courseId = localStorage.getItem('courseId')

  useEffect(() => {
    if (location?.state?.pageType === "Instruction") {
      dispatch(
        courseResultByCourseId(location?.state?.slug, location?.state?.courseId)
      ).then((data) => {
        setCourseResultData(data?.payload?.data);
      });
    } else {
      dispatch(courseResult(location?.state?.courseId, courseId)).then((data) => {
        setCourseResultData(data?.payload?.data);
      });
    }
  }, []);

  return (
    <>
      <div className="header-title">
        {courseResultData?.courseName}
      </div>
      {courseResultData && <ScoreCard courseResultData={courseResultData} />}
      {courseResultData && (
        <DistributionCards courseResultData={courseResultData} />
      )}
      {courseResultData && <AttemptCard courseResultData={courseResultData} />}
    </>
  );
}

export default Reports;
