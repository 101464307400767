import React from 'react';
import SingleTopicAnalysisRow from './singleTopicAnalysisRow';
import './topicWiseAnalysisTab.scss';

/**
 * Tab plane of Topicwise analyis
 */

function TopicWiseAnalysisTab(props) {
    const { topicWiseAnalysisData, tabList, quesResponses, selectedIndex, handleTabChange } = props;
    let strong, avg, weakChap, unCategories;
    if(tabList && tabList.length) {
    tabList.forEach((tab, i) => {
        if (tab.ref === 'strongChapters') {
            strong = i;
        }
        if (tab.ref === 'averageChapters') {
            avg = i;
        }
        if (tab.ref === 'weekChapters') {
            weakChap = i;
        }
        if (tab.ref === 'unCategorizedChapters') {
            unCategories = i;
        }
    });
}
    let selectedTopics = [];
    if (selectedIndex === strong) {
        selectedTopics = topicWiseAnalysisData.strongChap;
    } else if (selectedIndex === avg) {
        selectedTopics = topicWiseAnalysisData.avgChap;
    } else if (selectedIndex === weakChap) {
        selectedTopics = topicWiseAnalysisData.weakChap;
    } else if (selectedIndex === unCategories) {
        selectedTopics = topicWiseAnalysisData.unCategories;
    }
    return (
        <div>
            <div className="week-chapters-table">
                <ul>
                    {selectedTopics && selectedTopics.length ?
                        selectedTopics.map((strongChap, index) => {
                            return (
                                <li key={index}>
                                    <SingleTopicAnalysisRow key={index} index={index}
                                                            chapterWiseData={strongChap}
                                                            handleTabChange={handleTabChange}
                                                            quesResponses={quesResponses}/>
                                </li>
                            );
                        })
                        : ''
                    }
                </ul>
            </div>
        </div>
    );
}

export default TopicWiseAnalysisTab;
