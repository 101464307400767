import status from "../constants/actionStatus";
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    courseMeta: {}
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case "ADD_MY_COURSE_REQUEST":
      return {
        status: status.PENDING,
        courseMeta: {}
      };
    case "ADD_MY_COURSE_SUCCESS":
      return {
        status: status.SUCCESS,
        course: action.payload
      };
    case "ADD_MY_COURSE_FAILURE":
      return {
        status: status.ERROR,
        courseMeta: {}
      };
    default:
      return state;
  }
});