import { getAllPracticeTests } from "../services/quizService";
export const getPracticeTestList = examId => dispatch => {
  dispatch({
    type: 'GET_PRACTICE_TEST_WAITING',
    payload: []
  });
  getAllPracticeTests(examId).then(apiResponse => {
    // handle success
    dispatch({
      type: 'GET_PRACTICE_TEST_SUCCESS',
      payload: apiResponse.data
    });
  }).catch(error => {
    // handle error
    dispatch({
      type: 'GET_PRACTICE_TEST_ERROR',
      payload: []
    });
  });
};