function fullScreenMode() {
  //jQuery("#fullscreenMode").one('click', distractedShow);
  var el = document.getElementById('fullscreenMode');
  if (el) {
    el.addEventListener('click', distractedShow);
  }
}
function toggleFullScreen(elem) {
  if (document.fullScreenElement !== undefined && document.fullScreenElement === null || document.msFullscreenElement !== undefined && document.msFullscreenElement === null || document.mozFullScreen !== undefined && !document.mozFullScreen || document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen) {
    if (elem.requestFullScreen) {
      elem.requestFullScreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}
function distractedShow() {
  toggleFullScreen(document.body);
  var element = document.getElementById('fullscreenMode');
  element.setAttribute("title", "Exit Fullscreen mode");
  element.addEventListener("click", distractedHide);
}
function distractedHide() {
  if (document.cancelFullScreen) {
    document.cancelFullScreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
  var element = document.getElementById('fullscreenMode');
  element.setAttribute("title", "Fullscreen mode");
  element.addEventListener("click", distractedShow);
}
export default fullScreenMode;