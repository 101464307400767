export default function getQuestionLocationIndex(quiz, activeQuestionIndex) {
  let questions = quiz.questions;
  let obj = {
    sectionNo: 0,
    questionNo: 0
  };
  let index = activeQuestionIndex;
  let temp = 0;
  for (let i = 0; i < questions.length; i++) {
    if (temp <= index && temp + questions[i].questions.length > index) {
      obj.sectionNo = i;
      let total = temp + (questions[i] && questions[i].questions ? questions[i].questions.length : 0) - index;
      obj.questionNo = (questions[i] && questions[i].questions ? questions[i].questions.length : 0) - total;
      break;
    } else {
      temp = temp + questions[i].questions.length;
    }
  }
  return obj;
}