import i18n from "../translations";
import { getMe, updateSingleUser, updatePassword, updateProfile, updateProfileImage, passwordChangedNewUser, requestOtpforForgotPassword } from "../services/userService";
export const getUser = () => dispatch => {
  dispatch(request({
    loggedIn: true
  }));
  getMe().then(user => {
    // handle success
    if (user && user.dateOfBirth) {
      const dateObj = new Date(user.dateOfBirth);
      user.dateOfBirth = dateObj.getFullYear() + "-" + ("0" + (dateObj.getMonth() + 1)).slice(-2) + "-" + ("0" + dateObj.getDate()).slice(-2);
    }
    user.loggedIn = true;
    let dynamicLanguage = localStorage.getItem('default_language');
    localStorage.setItem("language", dynamicLanguage);
    i18n.changeLanguage(dynamicLanguage);
    dispatch(success(user));
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request(user) {
    return {
      type: "GET_USER_REQUEST",
      user
    };
  }
  function success(user) {
    return {
      type: "GET_USER_SUCCESS",
      user
    };
  }
  function failure(error) {
    return {
      type: "GET_USER_FAILURE",
      error
    };
  }
};

/**
 * Update user Action.
 * @param id
 * @param data
 * @returns {function(*): *}
 */
export const updateUser = (id, data) => (dispatch, getState) => {
  const {
    user
  } = getState();
  const previousLang = user.language;
  updateSingleUser(id, data).then(user => {
    user.loggedIn = true;
    const lang = user.language.length > 0 ? user.language : "en";
    localStorage.setItem("language", lang);
    i18n.changeLanguage(user.language);
    if (previousLang !== lang) {
      dispatch({
        type: "USER_LANGUAGE_UPDATED"
      });
    }
    dispatch({
      type: "USER_UPDATE",
      payload: user
    });
  }).catch(error => {
    // handle error
    dispatch({
      type: "USER_UPDATE",
      payload: []
    });
  });
};
export const changePassword = (id, oldPassword, password) => dispatch => {
  const userData = {
    id: id,
    oldPassword: oldPassword,
    password: password
  };
  updatePassword(userData).then(data => {
    dispatch({
      type: "CHANGE_PASSWORD",
      payload: data
    });
  }).catch(error => {
    // handle error
    dispatch({
      type: "CHANGE_PASSWORD",
      payload: []
    });
  });
};
export const changePasswordNewUser = userData => dispatch => {
  passwordChangedNewUser(userData).then(data => {
    dispatch({
      type: "CHANGE_PASSWORD_NEW_USER",
      payload: data
    });
  }).catch(error => {
    // handle error
    dispatch({
      type: "CHANGE_PASSWORD_NEW_USER",
      payload: []
    });
    console.error("Error:", error);
  });
};
export const forgotPassword = email => dispatch => {
  return requestOtpforForgotPassword(email).then(data => {
    dispatch({
      type: "FORGOT_PASSWORD_NEW_USER",
      payload: data
    });
    return {
      status: 200,
      message: "Email sent! Please check your mail."
    };
  }).catch(error => {
    // handle error
    dispatch({
      type: "FORGOT_PASSWORD_NEW_USER",
      payload: []
    });
    console.error("Error:", error);
    return {
      status: 400,
      message: "Please enter a valid email id."
    };
  });
};
export const updateProfileDetails = data => dispatch => {
  return updateProfile(data).then(updatedUserData => {
    updatedUserData.loggedIn = true;
    dispatch({
      type: "USER_UPDATE",
      payload: updatedUserData
    });
    return updatedUserData;
  }).catch(error => {
    console.error("Error:", error);
  });
};
export const updateProfileImageDetails = (id, image, imageName) => dispatch => {
  const userData = {
    id,
    image,
    imageName
  };
  return updateProfileImage(userData).then(updatedUserData => {
    updatedUserData.loggedIn = true;
    dispatch({
      type: "USER_UPDATE",
      payload: updatedUserData
    });
    return updatedUserData;
  }).catch(error => {
    console.error("Error:", error);
  });
};
export const updateProfileDetailsMobile = data => dispatch => {
  updateProfile(data).then(data => {
    data.loggedIn = true;
    dispatch({
      type: "USER_UPDATE",
      payload: data
    });
  }).catch(error => {});
};