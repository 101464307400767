import { checkTransectionStatus } from "../services/transactionService";
export const getTransectionStatus = data => dispatch => {
  dispatch(request());
  checkTransectionStatus(data).then(data => {
    // handle success
    dispatch(success(data));
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: "TRANSECTION_STATUS_WAITING"
    };
  }
  function success(data) {
    return {
      type: "TRANSECTION_STATUS_SUCCESS",
      payload: data
    };
  }
  function failure(error) {
    return {
      type: "TRANSECTION_STATUS_ERROR",
      payload: error
    };
  }
};