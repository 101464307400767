import React from 'react';
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { ReactComponent as LiveClassVector } from "../../../assets/images/liveClass.svg";

function LiveClassBlock () {
    const classes = useStyles(styles)();
    const t = useTranslations();
    return (
        <div className={classes.divLiveClass}>
            <LiveClassVector className={classes.imgAlign} />
            <h2 className={classes.textHeading}>{t('Start your session')}</h2>
            <p className={classes.textPara}>{t('Please click on the active link to get')} <br /> {t('started with your live session')}</p>
        </div>
    );

};
const styles = (theme) => ({
    divLiveClass: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop:'3rem',
        marginRight: '0 !important',
        '@media (max-width: 1024px)': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin:'0px'   
        }
    },
    imgAlign: {
        height: '17rem',
        '@media (max-width: 960px)': {
            height: '134px',
            marginTop: '5%',
            marginLeft: '4%'
        },
        '@media (min-width: 961px) and (max-width: 999px)': {
            height: '25vh',
            marginTop: '5%',
            marginLeft: '4%'
        },
        '@media (min-width: 1000px) and (max-width: 1060px)': {
            marginTop: '18% !important',
            marginLeft: '5%',
            width: '90%',
            height: '100%',
        }
    },
    textPara: {
        fontWeight: '400',
        fontSize: '14px',
        color: '#84818A',
        lineHeight: '17.15px',
        '@media (max-width: 600px)': {
            fontSize: '11px',
            display: 'flex',
            paddingLeft: '18px',
            paddingRight: "18px",
            margin: '0px',
            marginBottom: '2rem',
            textAlign: 'center',
        },
        '@media (min-width: 601px) and (max-width:960px)': {
            marginBottom: '2rem',
            margin: '0px',
            textAlign: 'center'
        }
    },
    textHeading: {
        color: '#000000',
        fontWeight: '700',
        textAlign: 'center',
        lineHeight: '37.5px',
        fontSize: '32px',
        '@media (max-width: 600px)': {
            margin: '0px',
            marginTop: '2rem',
            fontSize: '20px'
        },
        '@media (min-width: 601px) and (max-width:960px)': {
            margin: '0px',
            marginTop: '2rem',
        }
    },
    '@media (min-width: 1024px) and (max-width: 1600px)': {
        imgAlign: {
            marginTop: 'calc(10% - 50px)',
            marginLeft: '5%',
        },
        textHeading: {
            fontWeight: '700',
            textAlign: 'center'
        },
        textPara: {
            fontSize: '14px',
            fontWeight: '400',
            margin: '-12px',
            textAlign: 'center'
        }
    },
    '@media (min-width: 1601px) and (max-width: 2000px)': {
        imgAlign: {
            marginTop: '15%',
            marginLeft: '5%',
        },
        textHeading: {
            fontSize: '32px',
            fontWeight: '700',
            textAlign: 'center'
        },
        textPara: {
            fontSize: '14px',
            fontWeight: '400',
            margin: '-12px',
            textAlign: 'center'
        }
    },
    '@media (min-width: 2001px)': {
        imgAlign: {
            marginTop: '14%',
            marginLeft: '5%'
        },
        textHeading: {
            fontSize: '32px',
            fontWeight: '700',
            textAlign: 'center'
        },
        textPara: {
            fontSize: '14px',
            fontWeight: '400',
            margin: '-12px',
            textAlign: 'center'
        }
    },
  });

export default LiveClassBlock;