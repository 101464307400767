import React from 'react';
import QuestionBox from "./QuestionBox/QuestionBox";
import QuizFooter from "./QuizFooter/QuizFooter";
import { useSelector } from "react-redux";

/**
 * Question box with footer
 * @param props
 * @returns {*}
 * @constructor
 */
const QuestionPlayground = props => {
    const {
        mode, isLoading, drawerToggle, viewMode, slug, handleClick, toggleClick, handlerOpenCheckScore,
        handleQuizPauseChange, pauseQuiz, setPauseQuiz, reset, setReset
    } = props;
    const total = useSelector((state) => state.quiz.totalQuestions);
    const activeQuestionIndex = useSelector((state) => state.activeQuestionIndex);
    return (
        <React.Fragment>
            <QuestionBox mode={mode} isLoading={isLoading}
                         drawerToggle={drawerToggle}
                         viewMode={viewMode}
                         slug={slug}
                         activeQuestionIndex={activeQuestionIndex}
                         handleClick={toggleClick}
                         total={total}
                         pauseQuiz = {pauseQuiz}
                         setPauseQuiz = {setPauseQuiz}
                         handleQuizPauseChange = {handleQuizPauseChange}
                         number={activeQuestionIndex + 1}/>
            <QuizFooter mode={mode} viewMode={viewMode} hideNext={activeQuestionIndex === (total - 1)}
                        drawerToggle={drawerToggle}
                        handlerOpenCheckScore={handlerOpenCheckScore}
                        hidePrev={activeQuestionIndex === 0}
                        handleClick={handleClick}
                        reset={reset}
                        setReset={setReset}
                        activeQuestionIndex={activeQuestionIndex}
                        handleQuizPauseChange={handleQuizPauseChange}/>
        </React.Fragment>
    );
};

export default QuestionPlayground;
