import status from '../constants/actionStatus';
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    readAllNotificationLists: []
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'GET_READ_NOTIFICATION_REQUEST':
      return {
        status: status.PENDING,
        readAllNotificationLists: []
      };
    case 'GET_RAED_NOTIFICATION_SUCCESS':
      return {
        status: status.SUCCESS,
        readAllNotificationLists: action.readAllNotificationLists
      };
    case 'GET_READ_NOTIFICATION_FAILURE':
      return {
        status: status.ERROR,
        readAllNotificationLists: state
      };
    default:
      return state;
  }
});