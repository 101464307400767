import React, { useState } from 'react';
import './quizResultScreen.scss';
import QuizQuestionDistributionChart from './QuizQuestionDistributionChart';
import QuizResultHeader from "./quizResultHeader";
import PerformanceReport from "./performanceReport";
import RankerList from "./rankerList";
import QuizResultComparison from "./QuizResultComparison";
import QuizResultPending from "./quizResultPending";
import SectionWiseResultScreen from "./sectionWiseResultScreen";
import ResultsButtonGroup from "./ResultsButtonGroup";
import MarksDistributionChart from './MarksDistributionChart';
import { useSelectedExam, useDidUpdateEffect, useGetQuizResult } from "examsbook-react-sdk/custom-hooks";
import TopicWiseAnalysisReport from "./TopicWiseAnalysisReport";

/**
 * Single quiz result screen
 */
function QuizResultScreen(props) {
    const { quizId } = props;
    const quizResult = useGetQuizResult(quizId);
    const selectedExam = useSelectedExam();
    //if selected test is not inside the exam then move to all test page.
    const [ selectedExamId, setSelectedExamId ] = useState('');
    useDidUpdateEffect(() => {
        if (selectedExamId && selectedExam && selectedExam._id) {
            props.history.push('/tests');
        } else if (selectedExam && selectedExam._id) {
            setSelectedExamId(selectedExam._id);
        }
    }, [ selectedExam ]);

    let liveLater = false;
    if (quizResult) {
        if (quizResult.data && quizResult.data.error) {
            throw new Error(quizResult.data.error);
        }
        if (quizResult.data && quizResult.data.liveLater) {
            liveLater = true;
        }
    }
    return (
        <>{
            !liveLater ? Object.keys(quizResult).length !== 0 ? <div>
                <QuizResultHeader quizId={quizId} quizResults={quizResult}/>
                <div className="result-screen-second">
                    <PerformanceReport quizResults={quizResult}/>
                    <div className="small-detail">
                        <QuizQuestionDistributionChart quizResults={quizResult}/>
                        <SectionWiseResultScreen quizResults={quizResult}/>
                    </div>
                    <div className="toppers-section">
                        <QuizResultComparison quizResults={quizResult}/>
                    </div>
                    <TopicWiseAnalysisReport quizResults={quizResult}/>

                    <div className="area-chart-section">
                        <MarksDistributionChart quizResults={quizResult}/>

                    </div>
                    <RankerList quizResults={quizResult}/>
                </div>
                <ResultsButtonGroup quizResults={quizResult} quizId={quizId} downloadPdf={true}/>

            </div> : null : <QuizResultPending quizData={quizResult.data.quiz}/>
        }
        </>
    );
}

export default QuizResultScreen;
