import React from "react";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { convertToDate } from "examsbook-react-sdk/common";
import { Button } from "@material-ui/core";
import { useLiveVideoButtonStatus } from "examsbook-react-sdk/custom-hooks";

/**
 * Live Video List Card
 * @param props
 * @returns {*}
 * @constructor
 */
function LiveVideoList(props) {
  const { testData } = props;
  const t = useTranslations();

  const classes = useStyles(styles)();
  const { disabled, label } = useLiveVideoButtonStatus(testData);

  return (
    <div className={classes.mainDiv}>
      <div className={classes.liveVideoSection}>
        <div className={classes.arrow}></div>
        <label className={classes.latest}>{t('LIVE')}</label>
      </div>
      <div className={classes.listItem}>
        <p className="live-class-title">{testData.title}</p>
      </div>
      <div className={classes.date}>
        <p className={classes.dateValue}>
          {t("LIVE_FROM")}: {convertToDate(testData.startDate)}
        </p>
        <p className={classes.dateValue}>
          {t("EXPIRES_ON")}: {convertToDate(testData.endDate)}
        </p>
      </div>
      <div className="card-btn">
      <Button
          disabled={disabled}
          onClick={() => {
          window.open(testData.url, '_blank');
          }}
        >
          <PlayCircleFilledIcon className={classes.iconBtn} />
          {label?<span className={classes.btnValue}>{label}</span>:<span className={classes.btnValueHide}>l</span>}
        </Button>
      </div>
    </div>
  );
}

const styles = (theme) => ({
  mainDiv: {
    width: "22%",
    height:'100%',
    margin: "15px 1.5%",
    borderRadius: "6px",
    boxShadow: theme.shadows[3],
    '@media (max-width:375px)': { 
      minWidth: "95%"
    },
    '@media (min-width:376px) and (max-width:491px)': { 
      width: '45%',
      margin: "15px 2%"
    },
    '@media (min-width:492px) and (max-width:610px)': { 
      width: '28%',
      margin: "15px 2%"
    },
    '@media (min-width:611px) and (max-width:833px)': { 
      width: '29%',
      margin: "15px 2%"
    }
  },
  box: {
    display: "flex",
    position: "absolute",
    left: "0",
    top: "5px",
  },
  latest: {
    color: "#fff",
    background: "#5F4BAB",
    fontSize: "14px",
    padding: "1px 10px 1px 10px",
    margin: "0px",
  },
  arrow: {
    borderTop: "12px solid",
    borderBottom: "12px solid",
    borderTopColor: "#5F4BAB",
    borderBottomColor: "#5F4BAB",
    borderLeft: "12px solid transparent",
  },
  liveVideoSection: {
    display: "flex",
    justifyContent: "flex-end",
  },
  listItem: {
    display: "flex",
    margin: "10px 10px 2px 10px",
  },
  titleData: {
    color: "#000",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "0px",
  },
  date: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 10px 0px 10px",
    minHeight:'80px'
  },
  dateValue: {
    color: "grey",
    margin: "5px 0px",
    fontSize: "13px"
  },
  iconBtn: {
    margin: "0px 5px",
    fontSize: "20px",
  },
  btnValue: {
    fontSize: "12px",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace:'nowrap'
  },
  btnValueHide: {
    fontSize: "12px",
    color:"#5F4BAB",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace:'nowrap'
  },
});

export default LiveVideoList;
