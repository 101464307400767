import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CTabs from "../../Tabs/CTabs";
import QuestionList from "../../QuestionList/questionList";
import status from "examsbook-react-sdk/constants/actionStatus";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { getPracticeQuestions } from "examsbook-react-sdk/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from '@material-ui/core/AppBar';

/**
 * Practice question list container.
 * @param props
 * @returns {*}
 * @constructor
 */
function PracticeQuestionsListContainer(props) {
    const { history } = props;
    const isNativeApp = window.isNativeApp || false;
    const selectedSubjectTopic = useSelector((state) => state.selectedSubjectTopic);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const questionsStatus = useSelector((state) => state.practiceQuestions.status);
    const questionList = useSelector((state) => state.practiceQuestions && state.practiceQuestions.questionList ? state.practiceQuestions.questionList : []);
    const savedQuestions = useSelector((state) => state.practiceQuestions && state.practiceQuestions.savedQuestions ? state.practiceQuestions.savedQuestions : []);
    const t = useTranslations();
    const [ value, setValue ] = useState(0);

    const language = user.language;
    useEffect(() => {
        dispatch(getPracticeQuestions(selectedSubjectTopic));
    }, [ dispatch, language, selectedSubjectTopic ]);

    function handleTabChange(event, val) {
        setValue(val);
    }

    function selectAllQuestions() {
        setValue(0);
    }

    let savedList = savedQuestions ? savedQuestions.filter((question) => {
        return question.isSaved;
    }) : [];
    let questionUrl = '/practice/subject/' + selectedSubjectTopic.subject + '/topic/' + selectedSubjectTopic.topic;
    if (value === 1) {
        questionUrl += '/saved/';
    } else {
        questionUrl += '/view/';
    }
    let questionsLoading = (questionsStatus === status.PENDING);
    let questions = [];
    if (value === 0) {
        questions = questionList;
    } else {
        questions = savedList;
    }
    const tabData = {
        setActiveTab: value,
        handleTabChange: handleTabChange,
        nav: [ {
            label: t('questions') + ' (' + questionList.length + ')',
            ref: 'questions'
        }, {
            label: t('saved_questions') + ' (' + savedList.length + ')',
            ref: 'savedQuestions'
        } ]
    };
    let title = 'Practice Questions';
    if (questions && questions[0] && questions[0].topic) {
        title = questions[0].topic.name;
    }

    function goBack() {
        history.push('/practice/subject/' + selectedSubjectTopic.subject);
    }

    return (
        <React.Fragment>
            <div className={isNativeApp ? "web-back-to-test" : "mobile-back-to-test"}>
                <ArrowBackIcon onClick={goBack}/>
                <span>{title}</span>
            </div>
            <div className="web-tab-view">
                <CTabs {...tabData}/>
            </div>
            <div className="dashboard-mobile-view remove-height">
                <div className={'mock-tabs'}>
                    <AppBar position="static" className="tabs">
                        <CTabs {...tabData}/>
                    </AppBar>
                </div>
            </div>
            <div className="saved-question-list">
                <QuestionList history={history}
                              totalQuestions={questions.length} questionList={questions}
                              questionsLoading={questionsLoading}
                              selectedSubjectTopic={selectedSubjectTopic}
                              activeTab={value} singleQuesUrl={questionUrl}
                              selectAllQuestions={selectAllQuestions}/>
            </div>
        </React.Fragment>
    );
}

export default PracticeQuestionsListContainer;
