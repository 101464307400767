/**
 * openQuestionInPlayer action. We update list of questions on question opening.
 * @param id
 * @returns {Function}
 */
export const openQuestionInPlayer = question => dispatch => {
  dispatch({
    type: 'UPDATE_SAVED_QUESTIONS_LIST',
    payload: question
  });
};