import React, { useState } from 'react';
import '../common/cardView.scss';
import './quizCard.scss';
import { useHistory } from "react-router-dom";
import { reactGaEvent } from "examsbook-react-sdk/actions/Common";
import { useTranslations, useQuizButtonStatus } from "examsbook-react-sdk/custom-hooks";
import { ReactComponent as TestImg } from "../../assets/images/evaluationTestIcon.svg";

function QuizCard(props) {
    const { quizData, singleTestSeriesLoading, onQuizCardClick } = props;
    const t = useTranslations();
    const history = useHistory();
    const [isModalVisible, setModalVisible] = useState(false);
    const { disabled, action } = useQuizButtonStatus(quizData);
    const bestScore = quizData.quizAttempts !== 0 ? Math.max(...quizData.testScore) : 0

    const hideModal = () => {
        setModalVisible(false);
    };

    function handleClickAction() {
        if (action === 'PAYMENT') {
            history.push("/payments");
        }
        else if (action === 'START') {
            if (quizData.quizAttempts >= 3) {
                setModalVisible(true);
            }
            else {
            onQuizCardClick(quizData.testId);
            reactGaEvent("Live Test", "Start Quiz");
            }
        } else {
            history.push('/tests/result/' + quizData._id);
        }
    }

    const QuizSucessful = () => {
        return (
            <div className='modal-bg'>
                <div className='modal-content' onClick={(e) => e.stopPropagation()}>
                    <div className='modal-heading'>
                        <h2 className='modal-heading-text'>{t('You have exceeded the number of quiz attempts!')}</h2>
                        <div className='ok-align'>
                            <div onClick={hideModal}
                                className='ok-btn'>{t('Ok')}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    function renderList() {
        return (
            <>
                <div className='certificate-box' onClick={handleClickAction} disabled={disabled}>
                    <div className='scrollable-container'>
                        <div>
                            <div className="certificate-text-div">
                                <div className='certificate-main-div'>
                                    <TestImg className='certificate-img'/>
                                    <div className='live-text'>
                                        <p className='genus-text'>{quizData.testId.name}</p>
                                        <p className='best-score'>{`Best Score: ${bestScore}/${quizData.testId.mark}`}</p>
                                    </div>
                                </div>
                                <div className='completion-box'>
                                    <p className='completion'>{`${quizData.quizAttempts}/3`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isModalVisible &&
                    <QuizSucessful />
                }
            </>
        );
    }

    function renderLoader() {
        //we will show four cards to fill this space.
        return (
            <></>
        );
    }

    return (
        <React.Fragment>
            {
                !singleTestSeriesLoading ? renderList() : renderLoader()
            }
        </React.Fragment>
    );
}

export default QuizCard;
