export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'GET_QUIZ_RESPONSE_REQUEST':
      return action.payload;
    case 'GET_QUIZ_RESPONSE_SUCCESS':
      return action.payload;
    case 'GET_QUIZ_RESPONSE_ERROR':
      return action.payload;
    default:
      return state;
  }
});