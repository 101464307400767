import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSubExamsList } from "examsbook-react-sdk/actions";
import status from "../constants/actionStatus";
import useSelectedExam from "./useSelectedExam";
export default function useSubExamsList() {
  const subExamsQuizReport = useSelector(state => state.subExamsQuizReport);
  const dispatch = useDispatch();
  //load list of live test
  const selectedExam = useSelectedExam();
  useEffect(() => {
    if (selectedExam) {
      getSubExamsListData();
    }
  }, [selectedExam]);
  function getSubExamsListData() {
    dispatch(getSubExamsList(selectedExam._id));
  }
  let subExamsQuizReportLoading = subExamsQuizReport.status === status.PENDING;
  return {
    subExamsQuizReport,
    subExamsQuizReportLoading
  };
}