import { getPracticeQuestions } from "./getPracticeQuestions";

/**
 * change state of selected Subject and Topic
 * @param currentSubjectAndTopic
 */
export const setCurrentSubjectAndTopic = currentSubjectAndTopic => dispatch => {
  dispatch({
    type: 'CURRENT_SUBJECT_TOPIC',
    payload: currentSubjectAndTopic
  });
  return dispatch(getPracticeQuestions(currentSubjectAndTopic));
};