import React from 'react';
import ContentLoader from 'react-content-loader';

const TestSeriesLoader = () => (
    <div className="card-list" style={{marginTop: '0px', borderRadius: '0px'}}>
        <div>
            <ContentLoader
                height={180}
                width={149}
                speed={1}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb">

                <rect x="7" y="7" rx="5" ry="0" width="134" height="69"/>

                <rect x="7" y="81" rx="5" ry="5" width="80" height="10"/>

                <rect x="90" y="81" rx="5" ry="5" width="50" height="10"/>

                <rect x="7" y="99" rx="5" ry="5" width="130" height="10"/>

                <rect x="7" y="116" rx="5" ry="5" width="130" height="10"/>

                <rect x="7" y="140" rx="5" ry="5" width="130" height="10"/>
                
                <rect x="50" y="157" rx="5" ry="0" width="50" height="20"/>
            </ContentLoader>
        </div>
    </div>
);

export default TestSeriesLoader;
