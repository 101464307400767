import React from 'react';
import LiveVideoCard from "../components/LiveVideoCard/LiveVideoCard";
import { useAnalyticsPageView, useTestSeriesList } from "examsbook-react-sdk/custom-hooks";


function LiveClasses(props) {
    const { history } = props;
    const { testSeriesList, testSeriesLoading } = useTestSeriesList();
    //analytic
    useAnalyticsPageView();

    function gotoPage() {
        history.push('/tests');
    }

    return (
        <LiveVideoCard
        testSeriesList={testSeriesList}
        testSeriesLoading={testSeriesLoading}
        handleTabChange={() => gotoPage()}
        showFooter={true}
    />
    );
}

export default LiveClasses;
