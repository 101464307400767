import React from 'react';
import QuestionCategoryLoader from "./QuestionCategoryLoader";

function SubjectsListLoader() {
    return (
        <div className="loader-for-subject">
            <div className="loader-content">
                <QuestionCategoryLoader/>
                <QuestionCategoryLoader/>
                <QuestionCategoryLoader/>
                <QuestionCategoryLoader/>
                <QuestionCategoryLoader/>
                <QuestionCategoryLoader/>
                <QuestionCategoryLoader/>
            </div>
        </div>
    );
}

export default SubjectsListLoader;
