import { useEffect } from "react";
import { saveRazorOrderId } from "../actions";
import { useDispatch, useSelector } from "react-redux";
export default function useInitiateTransaction(courseId, code) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (courseId) {
      dispatch(saveRazorOrderId(courseId, code));
    }
  }, [courseId, code]);
  const {
    razorOrderData,
    success,
    loading
  } = useSelector(state => state.razorOrderData);
  return {
    razorOrderData,
    success,
    loading
  };
}