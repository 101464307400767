import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    video: {
      margin: "0px 0px 10px 0px",
    },
    titleBox: {
      margin: "10px 0px 10px 0px",
    },
    videoTitle: {
      fontWeight: "600",
      fontSize: "24px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
    videoDescription: {
      width: "100%",
      color: "#84818A",
      fontWeight: "400",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        backgroundColor: "white",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      MobileContainer: {
        backgroundColor: "#ffffff",
      },
    },
    videoOverlay: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      zIndex: '1',
    },
    yesButton: {
      borderRadius:'4px',
      padding:'6px 30px',
      fontSize:'15px',
      margin:'15px',
      cursor:'pointer',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#0c3450e0 !important',
    },
    },
    noButton: {
      borderRadius:'4px',
      border: '1px solid #E7E7E7',
      fontWeight: '400',
      padding:'6px 30px',
      fontSize:'15px',
      margin:'15px',
      cursor:'pointer',
      textTransform: 'none'
    },
    modalBg: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100% !important',
      background: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '999999'
    },   
    modalContent: {
      width: '420px',
      marginTop: '40vh',
      margin: 'auto',
      '@media (max-width:439px)': {
        width: '280px'
      }
    },
    modalHeading: {
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:'white',
      borderRadius:'6px',
      padding:'20px 40px'
    },
    modalText: {
      fontWeight:'normal',
      textAlign: 'center',
      '@media (max-width:550px)': {
        fontSize: '1.3rem'
      }
    },
    buttonGroup: {
      display: 'flex'
    }
  }));

  export default useStyles;