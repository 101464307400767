import React from 'react';
import './noData.scss';

/**
 * NoData component.
 * @param props
 * @returns {*}
 * @constructor
 */
function NoData(props) {
    return (
        <div className="no-data">
        <h3>No data found!</h3>
        </div>
    );
}

export default NoData;
