import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import '../TestSeriesList/testSeriesList.scss';
import TestSeriesCardButtonGroup from "./TestSeriesCardButtonGroup";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { reactGaEvent } from "examsbook-react-sdk/actions/Common";
import { useHistory } from "react-router-dom";

/**
 * Test series card
 * @param props
 * @returns {*}
 * @constructor
 */
function TestSeriesCard(props) {
    const { testData } = props;
    const history = useHistory();
    const t = useTranslations();

    function handleGoToTestClick() {
        reactGaEvent("Test Series", "Go To Test");
        history.push('/test-series/' + testData._id + '/tests');
    }

    let imageJpg;
    if (testData.featured) {
        imageJpg = testData.featured.thumbnail.jpg;
    }

    return (
        <div className="card-list" onClick={handleGoToTestClick}>
            <NavLink to={'/test-series/' + testData._id + '/tests'}>
                <img
                    src={imageJpg} alt={imageJpg} onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/img/test-series-error.jpg";
                }}/>

                <Tooltip placement="top-start" title={testData.name} aria-label={testData.name}>
                    <h4 className="tool-tip">{testData.name}</h4>
                </Tooltip>
            </NavLink>
            <TestSeriesCardButtonGroup t={t} testData={testData}/>
        </div>

    );
}

export default TestSeriesCard;
