import React from 'react';
import ContentLoader from 'react-content-loader';

const SavedQuestionLoader = () => (
    <div className="loader-content-list">
        <ContentLoader
            height={30}
            width={400}
            speed={6}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <circle cx="20" cy="15" r="8"/>
            <rect x="390" y="5" rx="4" ry="4" width="2" height="2"/>
            <rect x="390" y="7" rx="4" ry="4" width="2" height="2"/>
            <rect x="390" y="9" rx="4" ry="4" width="2" height="2"/>
            <rect x="35" y="10" rx="5" ry="5" width="350" height="10"/>
        </ContentLoader>
    </div>
);

export default SavedQuestionLoader;
