import axios from "./axiosConfig";
export function certificateAddApi(data) {
  return axios.post("/api/v1/unlockCertificate", data).then(function (response) {
    // handle success
    let data = response.data;
    return data;
  }).catch(function (error) {
    // handle error
    return error;
  });
}
export function getCourseCertificateApi(courseId) {
  return axios.get("/api/v1/getCertificateList?courseId=".concat(courseId)).then(function (response) {
    // handle success
    let data = response.data;
    return data;
  }).catch(function (error) {
    // handle error
    return error;
  });
}
export function getCertificatesApi() {
  return axios.get("/api/v1/getCertificateList").then(function (response) {
    // handle success
    let data = response.data;
    return data;
  }).catch(function (error) {
    // handle error
    return error;
  });
}
export function downloadCertificatesApi(data) {
  return axios.post("/api/v1/getCertificateTemplate", data).then(function (response) {
    // handle success
    let data = response.data;
    return data;
  }).catch(function (error) {
    // handle error
    return error;
  });
}