import React from 'react';
import ContentLoader from 'react-content-loader';

function PracticeQuestionDashboardLoader() {
    return (
        <div>
            <div className="card-list">
                <ContentLoader
                    height={340}
                    width={195}
                    speed={1}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb">
                    <rect x="0" y="2" rx="0" ry="0" width="97" height="7"/>
                    <rect x="99" y="2" rx="0" ry="0" width="98" height="7"/>

                    <rect x="0" y="13" rx="0" ry="0" width="200" height="20"/>
                    <rect x="0" y="36" rx="0" ry="0" width="200" height="20"/>

                    <rect x="0" y="67" rx="0" ry="0" width="63" height="75"/>
                    <rect x="65" y="67" rx="0" ry="0" width="63" height="75"/>
                    <rect x="130" y="67" rx="0" ry="0" width="65" height="75"/>

                </ContentLoader>
            </div>
        </div>
    );
}

export default PracticeQuestionDashboardLoader;


