import status from '../constants/actionStatus';
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    status: status.PENDING,
    overAllPerformance: {}
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (action.type === 'GET_TEST_SERIES_ANALYSIS_DATA') {
    return {
      status: status.SUCCESS,
      overAllPerformance: action.payload.overAllPerformance
    };
  }
  return state;
});