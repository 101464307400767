import status from "../constants/actionStatus";
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    status: status.PENDING,
    category: []
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case "CATEGORY_REQUEST":
      return {
        status: status.PENDING,
        category: []
      };
    case "CATEGORY_SUCCESS":
      return {
        status: status.SUCCESS,
        category: action.payload
      };
    case "CATEGORY_FAILURE":
      return {
        status: status.ERROR,
        category: []
      };
    default:
      return state;
  }
});