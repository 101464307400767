import React, { useEffect, useState } from 'react';
import './ForgotPassword.scss';
import { getDefaultSupportDataAction, getSupportAction } from 'examsbook-react-sdk/actions/getSupportAction';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, Input, InputLabel, Link } from '@material-ui/core';
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { forgotPassword } from 'examsbook-react-sdk/actions/getUser';

const ForgotPassword = () => {
  const [bgColor, setBgColor] = useState('');
  const [textColor, setTextColor] = useState('');
  const [defaultLoginImage, setDefaultLoginImage] = useState(null);
  const [dynamicLoginImage, setDynamicLoginImage] = useState(null);
  const [email, setEmail] = useState('');
  const [productName, setProductName] = useState(null);
  const dispatch = useDispatch();
  const API_URL = "/img/post/original/";
  const t = useTranslations();
   const supportData = useSelector((state) => state.dynamicSeoSettings.dynamicSeoSettings);
  const defaultSupportData = useSelector((state) => state.defaultSeoSettings.defaultSeoSettings);
  
  useEffect(() => {
    const defaultLogo = defaultSupportData?.data?.Images?.find((image) => image.key === "Image")?.defaultValue;
    const dynamicLogo = supportData?.data?.find((item) => item.key === "Image")?.value;
    const defaultProductName = defaultSupportData?.data?.['Product Name'][0]?.defaultValue;
    const dynamicProductName = supportData?.data?.find(item =>  item.key === 'Product Name')?.value;
    const defaultLoginBtnColor = defaultSupportData?.data?.['Text Theme']?.find((color) => color.key === "Primary Text")?.defaultValue;
    const dynamicLoginBtnColor = supportData?.data?.find(item =>  item.key === 'Primary Bg')?.value;
    const defaultTextColor = defaultSupportData?.data?.['Text Theme']?.find((color) => color.key === "Primary Text")?.defaultValue;
    const dynamicTextColor = supportData?.data?.find(item =>  item.key === 'Primary Text')?.value;
  
    setDefaultLoginImage(defaultLogo);  
    setDynamicLoginImage(dynamicLogo);
    setProductName(dynamicProductName ? dynamicProductName : defaultProductName)
    setBgColor(dynamicLoginBtnColor ? dynamicLoginBtnColor : defaultLoginBtnColor)
    setTextColor(dynamicTextColor ? dynamicTextColor : defaultTextColor)
  },[defaultSupportData, supportData]);

  useEffect(() => {
    if(!supportData)  {
        dispatch(getSupportAction());
    } if (!defaultSupportData) {
        dispatch(getDefaultSupportDataAction())
    }
  }, [supportData, defaultSupportData]);

  const focusTextField = (fieldId) => { // check if we want it.
    const field = document.getElementById(fieldId);
    if (field) {
      field.focus();
    }
  };

  const handleEmailChange = (event) => {
    let inputValue = event.target.value;
    setEmail(inputValue);
    const isValidEmail = (email) =>
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.toLowerCase());
    const isMobileNumber = (str) => /^\d{10}$/.test(str);
    const containsOnlyNumbers = (str) => /^\d+$/.test(str);
    let errMsg = "Please enter a valid email id. ";
    let emailValid = true;

    if (!isValidEmail(inputValue)) { // check if we want this function
        focusTextField("userForgotPassEmail");
        errMsg = "Please enter a valid email id.";
        emailValid = false;
    }
    if (
        (containsOnlyNumbers(inputValue) && !isMobileNumber(inputValue)) ||
        (!containsOnlyNumbers(inputValue) && !isValidEmail(inputValue))
      ) {
        errMsg = "Please enter a valid email id.";
        emailValid = false;
      }
    if (!emailValid) {
      document.getElementById('reset-password-error').innerHTML = `<div class="errorMsg">${errMsg}</div>`;
      document.getElementById('reset-password-error').style.display = 'block';
      document.getElementById('changePasswordBtn').setAttribute("disabled", "disabled");
    } else {
      document.getElementById('reset-password-error').style.display = 'none';
      document.getElementById('changePasswordBtn').removeAttribute("disabled");
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    dispatch(forgotPassword(email)).then((res) => {
      if (res.status) {
        document.getElementById('reset-password-error').innerHTML = `<div class="successMsg">${res.message}</div>`;
        document.getElementById('reset-password-error').style.display = 'block';
        document.getElementById('changePasswordBtn').setAttribute("disabled", "disabled");
      }
    })
  }

  return (
    <Grid className="forgot-password-parent-div">
        <Grid className="forgot-password-child-div">
          <Box component="img" id="login-img-learn" className="forgot-password-logo" src={API_URL + (dynamicLoginImage || defaultLoginImage)} alt={productName} />
        </Grid>
        <Grid className="forgot-password-right-side-box">
        <Box className="form-group error-msg" id="reset-password-error"></Box>
            <Box className="mdl-card__supporting-text input-fields">
                <Box className="custom-title" style={{ color: `${bgColor}` }}>{t('Forgot Password')}</Box>
                <Box>
                    <Box component="p" className="custom-sub-title">{t('Enter your email address and we will send a link to reset your password.')}</Box>
                </Box>
                <Box component="form" id="userForgotPassForm" className="form-horizontal" onSubmit={handleForgotPassword}>
                    <Box className="mdc-text-field mdc-text-field--outlined" id="forgot-input">
                    <Input type="text" className="mdc-text-field__input required_text" id="userForgotPassEmail" placeholder="Email" onChange={handleEmailChange} />
                    <Box>
                        <InputLabel className="mdc-text-field__label" style={{ color: `${bgColor}` }} htmlFor="userForgotPassEmail">{t('Email')}</InputLabel>
                    </Box>
                    </Box>
                    <Box className="otg otg-items-1 text-center verify-proceed-button send-reset-link-div">
                      <Button type="submit" className="send-reset-link-button" id="changePasswordBtn" style={{ backgroundColor: `${bgColor}`, color: `${textColor}`}}>
                          {t('Send Reset Link')}
                      </Button>
                    </Box>
                    <Box className="back-login">
                    <Link className="back-login" href="/login" style={{ color: `${bgColor}` }}>{t('Back to Login')}</Link>
                    </Box>
                </Box>
            </Box>
        </Grid>
    </Grid>
  );
};

export default ForgotPassword;