import React from 'react';
import PerformanceSummary from "../PerformanceSummary/performanceSummary";
import PerformanceSummaryLoader from "../common/loaders/PerformanceSummaryLoader";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";

/**
 * PerformanceCard component.
 * @param props
 * @returns {*}
 * @constructor
 */
function PerformanceCard(props) {
    const { performanceSummary, avgPerformanceData, status } = props;

    const t = useTranslations();

    function renderList() {
        let performanceArray = [];
        if (performanceSummary) {
            if (performanceSummary.rank) {
                performanceArray.push({
                    value: (performanceSummary.rank.value) ? performanceSummary.rank.value : '0',
                    outOf: (performanceSummary.rank.outOf) ? performanceSummary.rank.outOf : '0',
                    title: (avgPerformanceData !== 'avgPerformanceData') ? t('rank') : t('average_rank'),
                    icon: 'fa fa-star',
                    background: '#21b3b3'
                });
            }
            performanceArray.push({
                value: performanceSummary.score && !isNaN(performanceSummary.score) ? performanceSummary.score.toFixed(2) : 0,
                outOf: (performanceSummary.mark),
                title: (avgPerformanceData !== 'avgPerformanceData') ? t('score') : t('average_score'),
                icon: 'fa fa-trophy',
                background: '#4288dd'
            }, {
                value: (performanceSummary.correct + performanceSummary.incorrect),
                outOf: performanceSummary.total,
                title: (avgPerformanceData !== 'avgPerformanceData') ? t('attempted') : t('average_attempted'),
                icon: 'fa fa-sticky-note-o',
                background: '#7c6bd4'
            }, {
                value: (performanceSummary.accuracy && !isNaN(performanceSummary.accuracy) ? performanceSummary.accuracy.toFixed(2) : 0) + '%',
                title: (avgPerformanceData !== 'avgPerformanceData') ? t('accuracy') : t('average_accuracy'),
                icon: 'fa fa-assistive-listening-systems',
                background: '#00cd79'
            }, {
                value: performanceSummary.percentile ? performanceSummary.percentile + ' %' : '0 %',
                title: (avgPerformanceData !== 'avgPerformanceData') ? t('percentile') : t('average_percentile'),
                icon: 'fa fa-percent',
                background: '#113551'
            });
        }
        return (
            <div className="icon1-performance-block">
                {
                    performanceArray.map((data, key) => {
                        return (
                            <PerformanceSummary {...data} key={key}/>
                        );
                    })
                }
            </div>
        );
    }

    function renderLoader() {
        return (
            <div className="loader-for-performance">
                <div className="loader-content">
                    <PerformanceSummaryLoader/>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            {
                status !== 'PENDING' && performanceSummary.rank ? renderList() : renderLoader()
            }
        </React.Fragment>
    );
}

export default PerformanceCard;
