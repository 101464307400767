import React from 'react';
import ContentLoader from 'react-content-loader';

const QuizSidebarLoader = () => (
    <div className="question-number-block">
        <ContentLoader
            height={300}
            width={230}
            speed={1}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="5" y="15" rx="5" ry="5" width="80" height="10"/>
            <rect x="145" y="15" rx="5" ry="5" width="80" height="10"/>
            <rect x="5" y="45" rx="5" ry="5" width="220" height="10"/>
            <rect x="5" y="75" rx="5" ry="5" width="220" height="10"/>
            <rect x="5" y="105" rx="5" ry="5" width="220" height="10"/>
            <rect x="5" y="135" rx="5" ry="5" width="220" height="10"/>
            <rect x="5" y="165" rx="5" ry="5" width="220" height="10"/>
            <rect x="5" y="195" rx="5" ry="5" width="220" height="10"/>
            <rect x="5" y="225" rx="5" ry="5" width="220" height="10"/>
            <rect x="5" y="255" rx="5" ry="5" width="220" height="10"/>
            <rect x="5" y="285" rx="5" ry="5" width="220" height="10"/>
            <rect x="5" y="315" rx="5" ry="5" width="220" height="10"/>
        </ContentLoader>
    </div>
);

export default QuizSidebarLoader;
