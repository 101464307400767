import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from "@material-ui/core/Button/Button";
import { CNavLink } from "../components/common/Header/c/CHeader";
import Question from '../components/Question/question';
import { getTestSeriesSavedQuestions, toggleSavedQuestion } from "examsbook-react-sdk/actions";
import { useTranslations, useAnalyticsPageView } from "examsbook-react-sdk/custom-hooks";

/**
 * TestSeriesQuestionView
 * @param props
 * @returns {*}
 * @constructor
 */
function TestSeriesQuestionView(props) {
    const t = useTranslations();
    useAnalyticsPageView();
    const { match, history } = props;
    const testSeriesId = useSelector((state) => state.testSeriesId);
    const questionList = useSelector((state) => state.testSeriesSavedQuestions && state.testSeriesSavedQuestions.savedQuestionsList ?
        state.testSeriesSavedQuestions.savedQuestionsList : []);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTestSeriesSavedQuestions(testSeriesId));
    }, [ testSeriesId ]);
    //based on type we may want to use practiceQuestions or savedQuestions

    let index;
    for (const i in questionList) {
        if (questionList[i].slug === match.params.slug) {
            index = i;
        }
    }

    function unSaveQues(ques) {
        dispatch(toggleSavedQuestion(ques._id));
        history.push('/test-series/' + testSeriesId + '/tests');
    }

    function selectQuestion(question) {
        history.push('/test-series/' + testSeriesId + '/questions/' + question.slug);
    }

    return (
        <React.Fragment>{
            questionList && questionList.length ?
                <div className="saved-question-list">
                    <Question toggleSavedQuestion={() => unSaveQues(questionList[parseInt(index, 10)])}
                              index={parseInt(index, 10) + 1} question={questionList[parseInt(index, 10)]}
                              totalLength={questionList.length} t={t}/>
                    <div className="solution-right-part">
                        <CNavLink to={'/test-series/' + match.params.id + '/tests'}>
                            <div className="bar-icon">
                                <i className="fa fa-bars"/>
                            </div>
                        </CNavLink>
                        <Button disabled={parseInt(index, 10) === 0}
                                onClick={() => selectQuestion(questionList[parseInt(index, 10) - 1])}><i
                            className="fa fa-chevron-left"/>{t('previous')}</Button>
                        <Button disabled={parseInt(index, 10) === (questionList.length - 1)}
                                onClick={() => selectQuestion(questionList[parseInt(index, 10) + 1])}>
                            {t('next')}<i className="fa fa-chevron-right"/>
                        </Button>
                    </div>

                </div> : null
        }
        </React.Fragment>
    );
}

export default TestSeriesQuestionView;
