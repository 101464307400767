import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getLiveVideo } from "examsbook-react-sdk/actions/getLiveVideo";
import status from "../constants/actionStatus";

/**
 * get list of all category.
 * @returns {*}
 */
export default function useLiveVideo(courseId) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLiveVideo(courseId));
  }, [courseId]);
  let courseData = useSelector(state => state.liveVideoDetail);
  let isLoading = courseData.status === status.PENDING;
  let liveVideoDetail = courseData.liveVideoDetail.data;
  return {
    isLoading,
    liveVideoDetail
  };
}