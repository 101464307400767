import _objectSpread from "/codebuild/output/src2290389247/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import staticUrl from "examsbook-react-sdk/constants/staticUrl";
import { isUrlAbsolute } from "../common";
import status from "../constants/actionStatus";
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    courses: []
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case "FILTERED_COURSES_REQUEST":
      return {
        status: status.PENDING,
        courses: []
      };
    case "FILTERED_COURSES_SUCCESS":
      return {
        status: status.SUCCESS,
        courses: action.payload.map(course => {
          return _objectSpread(_objectSpread({}, course), {}, {
            featuredMobile: isUrlAbsolute(course.featured.thumbnail.jpg) ? course.featured.thumbnail.jpg : staticUrl.STAGE_URL + course.featured.thumbnail.jpg
          });
        })
      };
    case "FILTERED_COURSES_FAILURE":
      return {
        status: status.ERROR,
        courses: []
      };
    default:
      return state;
  }
});