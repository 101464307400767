import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useTranslations,
  useScreenWidth,
} from "examsbook-react-sdk/custom-hooks";
import AppBar from "@material-ui/core/AppBar";
import CTabs from "../components/Tabs/CTabs";
import CourseCard from "../components/Tabs/InternalCourse";
import TopicMaterialContainer from "../components/Tabs/TopicMaterialContainer";
import TabsLandingPage from "../components/Tabs/TabsLandingPage";
import NotificationBar from "../components/common/Header/NotificationBar";
import { Box, Breadcrumbs } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ReactComponent as SelfLearning } from "../../../web-app/src/assets/images/new-self.svg";
import { ReactComponent as Live } from "../assets/images/new-live.svg";
import { ReactComponent as MockTest } from "../../../web-app/src/assets/images/new-eval-test.svg";
import { ReactComponent as Certificate } from "../../../web-app/src/assets/images/new-certify.svg";
import ".././components/common/Header/Header.scss";
import ActiveSelfLearn from "../assets/images/Group.png";
import ActiveLiveClass from "../assets/images/LiveclassImg.png";
import ActiveMockTest from "../assets/images/MockText.png";
import ActiveCertificate from "../assets/images/ActiveCertificate.png";
import { getUserCourse } from "examsbook-react-sdk/actions";
import { getSelfLearningAction } from "examsbook-react-sdk/actions/getSelfLearningAction";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    zIndex: "auto",
    "& div": {
      "& div": {
        "& span": {
          "&.PrivateTabIndicator-colorPrimary-34": {
            backgroundColor: "#fff !important",
          },
        },
      },
    },
  },
  card: {
    background: "#fff",
  },
  card1: {
    [theme.breakpoints.up("md")]: {
      background: "#fff",
    },
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      right: 0,
      background: "#fff",
      paddingRight: "37px",
    },
  },
  fragment: {
    overflowX: 'hidden'
  },
  mainFragmentExpand: {},
  cardExpand: {
    "@media (min-width: 960px) and (max-width: 1000px)": {
      width: "calc(371px - 7px)",
    },
    "@media (min-width: 1001px) and (max-width: 1023px)": {
      width: "calc(391px - 7px)",
    },
  },
  mainDiv: {
    background: "#FAFAFA",
    "@media (max-width: 959px)": {
      paddingRight: "16px",
      paddingLeft: "16px",
    },
  },
  contentDiv: {
    backgroundColor: "#fff",
    "@media (max-width: 959px)": {
      paddingTop: "10px",
      paddingRight: "16px",
      paddingLeft: "16px",
    },
    "@media (min-width: 960px)": {
      width: "450px",
      right: "0",
      paddingTop: "80px",
      position: "fixed",
    },
  },
  activeIcon: {
    height: "30px",
    width: "35px",
  },
  breadcrumsAlign: {
    textDecoration: "none !important",
    textTransform: "capitalize",
    fontSize: "24px",
    fontWeight: "600",
    color: "#202020",
    whiteSpace: 'nowrap'
  },
  selfLearningIcon: {
    height: "32px",
    width: "32px",
    "@media (max-width: 1200px )": {
      height: "24px",
      width: "24px"
    },
  },
  liveClassesIcon: {
    width: "32px",
    height: "22px",
    "@media (max-width: 1200px )": {
      height: "24px",
      width: "24px"
    },
  },
  evaluationTestIcon: {
    width: '32px',
    height: '32px',
    "@media (max-width: 1200px )": {
      height: "24px",
      width: "24px"
    },
  },
  certificateTestIcon: {
    width: '30px',
    height: '28px',
    "@media (max-width: 1200px )": {
      height: "24px",
      width: "24px"
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  "@media (max-width: 768px )": {
    heading: {
      fontSize: "16px",
    }
  },
}));

/**
 * Topic Material Tab-screen
 * @returns {*}
 * @constructor
 */
function TopicMaterial(props) {
  const location = useLocation();
  const subjectDetail = location.state.subjectDetail;
  const course_id = localStorage.getItem("courseId");
  const subject_id = location.state.subjectId;
  const topic_id = location.state.topicId; 
  const [isMobile, setIsMobile] = useState(false);
  const [videoProgress, setVideoProgress] = useState({});
  const handleVideoProgress = (videoKey, percent) => {
    if (videoKey) {
      setVideoProgress((prev) => ({
        ...prev,
        [videoKey]: percent
      }))
    }
  }

  const [openSidebar, setOpenSidebar] = useState(false);
  const windowScreenWidth = useScreenWidth();
  const screenWidth = 900;

  function closeDrawer() {
    setOpenSidebar(false);
  }

  const [selectedQuizCard, setSelectedQuizCard] = useState(null);
  const [quizAttemptForResult, setQuizAttemptForResult] = useState(null);
  const [activeItem, setActiveItem] = useState(false);
  const [mySectionData, setMySectionData] = useState("");
  const t = useTranslations();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const classes = useStyles();
  const [courseData, setCourseData] = useState('')
  const [testQuiz, setTestQuiz] = useState(false);
  const [videoRefresh, setVideoRefresh] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [sectionChanged, setSectionChanged] = useState(false);

useEffect(() => {
  user?._id && dispatch(getUserCourse(user?._id)).then((res) => {
    setCourseData(res?.data)
  })
}, []);

  useEffect(() => {
    const savedTab = localStorage.getItem('selectedTab');
    if (savedTab) {
      setSelectedTab(Number(savedTab));
    }
  }, [testQuiz]);

  const handleVideoRefresh = (value) => {
    setVideoRefresh(value);
  };

  const handlePdfOpen = (value) => {
    setPdfOpen(value);
  }

  function handleTabChange(value) {
    setSelectedTab(value);
  }

  let tabBarList = [
    {
      label: t("SELF LEARNING"),
      icon: <SelfLearning className={classes.selfLearningIcon}/>,
      activeIcon: (
        <img alt="" src={ActiveSelfLearn} className={classes.selfLearningIcon} />
      ),
      customBadgeClass: "customBadge1",
    },
    {
      label: t("LIVE CLASSES"),
      icon: <Live className={classes.liveClassesIcon} />,
      activeIcon: (
        <img alt="" src={ActiveLiveClass} className={classes.liveClassesIcon} />
      ),
      customBadgeClass: "customBadge2",
    },
    {
      label: t("EVALUATION TEST"),
      icon: <MockTest className={classes.evaluationTestIcon}/>,
      activeIcon: <img alt="" src={ActiveMockTest} className={classes.evaluationTestIcon} />,
      customBadgeClass: "customBadge3",
    },
    {
      label: t("CERTIFICATE"),
      icon: <Certificate className={classes.certificateTestIcon}/>,
      activeIcon: (
        <img alt='' src={ActiveCertificate} className={classes.certificateTestIcon} />
      ),
      customBadgeClass: "customBadge4",
    },
  ];
  const [isFeedbackModelOpen, setIsFeedbackModelOpen] = useState(false);
  const [downloadCertificateButton, setdownloadCertificateButton] =
    useState(false);

  function goToDashboard() {
    history.goBack();
  }
  function openFeedback() {
    setIsFeedbackModelOpen(true);
  }
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 959);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function onQuizCardClick(data) {
    setSelectedQuizCard(data);
    setTestQuiz(true);
  }

  useEffect(() => {
    dispatch(getSelfLearningAction(course_id)).then((res) => {
      setMySectionData(res);
      setSelectedQuizCard(res?.evaluationTest[0]?.testId);
      setQuizAttemptForResult(res?.evaluationTest[0]?.quizAttempts);
    });
  }, [course_id, videoRefresh, pdfOpen]);

  useEffect(() => {
    document.title = `LMS | ${
        selectedTab === 0
            ? t("SELF LEARNING")
            : selectedTab === 1
            ? t("LIVE_CLASS")
            : selectedTab === 2
            ? t("EVALUATION TEST")
            : selectedTab === 3
            ? t("CERTIFICATE")
            : ""
    }`;
  }, [selectedTab]);
  useEffect(() => {
    localStorage.setItem('selectedTab', selectedTab);
  }, [selectedTab]);

  const drawerToggle = JSON.parse(localStorage.getItem("openDrawerStatus"));
  return (
    <>
      {isMobile ? (
        <Grid container spacing={0} className={classes.fragment}>
          <div
            style={{
              padding: "0px",
              marginBottom: "0px",
              margin: "80px 0px 0px 20px",
              display: "flex",
              alignItems: "center",
              height: "60px",
              width: "100%",
            }}
            className={"container " + (openSidebar ? "open-drawer" : "")}
            onClick={
              windowScreenWidth <= screenWidth
                ? closeDrawer.bind(this)
                : () => null
            }
          >
            <div className={classes.heading}>
              {mySectionData?.courseId?.name &&
                <Breadcrumbs aria-label="breadcrumb">
                  <span color="inherit" className={classes.breadcrumsAlign}>
                    {mySectionData?.courseId?.name}
                  </span>
                  <span color="inherit">
                    {selectedTab === 0
                      ? t("SELF LEARNING")
                      : selectedTab === 1
                        ? t("LIVE_CLASS")
                        : selectedTab === 2
                          ? t("EVALUATION TEST")
                          : selectedTab === 3
                            ? t("CERTIFICATE")
                            : ""}
                  </span>
                </Breadcrumbs>
              }
            </div>
          </div>
          <Grid item md={8} xs={12} className={classes.mainDiv}>
            <>
              <TabsLandingPage
                selectedIndex={selectedTab}
                course_id={course_id}
                subject_id={subject_id}
                topic_id={topic_id}
                subjectDetail={subjectDetail}
                history={history}
                downloadCertificateButton={downloadCertificateButton}
                goToDashboard={goToDashboard}
                selectedQuizCard={selectedQuizCard}
                setActiveItem={setActiveItem}
                activeItem={activeItem}
                onVideoRefresh={handleVideoRefresh}
                videoRefresh={videoRefresh}
                sectionChanged={sectionChanged}
                setSectionChanged={setSectionChanged}
                courseName={mySectionData?.courseId?.name}
                quizAttemptForResult={quizAttemptForResult != null && quizAttemptForResult}
                quizAttempCourseId={mySectionData?.courseId?._id}
                coursePercentage={mySectionData?.progressPercent}
                evaluationUnlockPercentage={selectedQuizCard?.EvaluationUnlockPercent}
                pdfOpen={pdfOpen}
                videoProgress={videoProgress}
                handleVideoProgress={handleVideoProgress}
              />
            </>
          </Grid>
          <Grid item md={2} xs={12} className={classes.contentDiv}>
            <div
              className={classes.card}
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#ffffff",
              }}
            >
              <Box
                className="notification-bar"
                style={{ marginLeft: "auto", marginRight: "0px" }}
              >
                <NotificationBar openFeedbackModel={openFeedback} />
              </Box>
              <div className="backgroundDiv">
                {mySectionData !== '' &&
                  <CourseCard
                    courseData={mySectionData?.courseId}
                    progressPercent={mySectionData?.progressPercent}
                  />
                }

                <AppBar position="static" className={classes.root}>
                  <CTabs
                    nav={tabBarList}
                    setActiveTab={selectedTab}
                    handleTabChange={handleTabChange}
                  />
                </AppBar>
              </div>
            </div>
            <div className={classes.card1}>
              <TopicMaterialContainer
                selectedIndex={selectedTab}
                course_id={course_id}
                showImg={"UIImg"}
                subject_id={subject_id}
                topic_id={topic_id}
                subjectDetail={subjectDetail}
                history={history}
                setdownloadCertificateButton={setdownloadCertificateButton}
                downloadCertificateButton={downloadCertificateButton}
                goToDashboard={goToDashboard}
                onQuizCardClick={onQuizCardClick}
                setActiveItem={setActiveItem}
                activeItem={activeItem}
                onVideoRefresh={handleVideoRefresh}
                onPdfOpen={handlePdfOpen}
                pdfOpen={pdfOpen}
                videoRefresh={videoRefresh}
                sectionChanged={sectionChanged}
                setSectionChanged={setSectionChanged}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={0}
          className={
            drawerToggle ? classes.mainFragmentExpand : classes.fragment
          }
        >
          <div
            className={
              "container sticky-header" + (openSidebar ? "open-drawer" : "")
            }
            onClick={
              windowScreenWidth <= screenWidth
                ? closeDrawer.bind(this)
                : () => null
            }
          >
            <div className={classes.heading}>
              {mySectionData?.courseId?.name &&
                <Breadcrumbs aria-label="breadcrumb">
                  <span color="inherit" className={classes.breadcrumsAlign}>
                    {mySectionData?.courseId?.name}
                  </span>
                  <span color="inherit">
                    {selectedTab === 0
                      ? t("SELF LEARNING")
                      : selectedTab === 1
                        ? t("LIVE_CLASS")
                        : selectedTab === 2
                          ? t("EVALUATION TEST")
                          : selectedTab === 3
                            ? t("CERTIFICATE")
                            : ""}
                  </span>
                </Breadcrumbs>
              }
            </div>
            <Box
              className="notification-bar"
              style={{ marginLeft: "auto", marginRight: "0px" }}
            >
              <NotificationBar openFeedbackModel={openFeedback} />
            </Box>
          </div>
          <div className="main-division">
            <div className="main-body">
              <>
                <TabsLandingPage
                  selectedIndex={selectedTab}
                  course_id={course_id}
                  subject_id={subject_id}
                  topic_id={topic_id}
                  subjectDetail={subjectDetail}
                  history={history}
                  downloadCertificateButton={downloadCertificateButton}
                  goToDashboard={goToDashboard}
                  selectedQuizCard={selectedQuizCard}
                  setActiveItem={setActiveItem}
                  activeItem={activeItem}
                  onVideoRefresh={handleVideoRefresh}
                  videoRefresh={videoRefresh}
                  sectionChanged={sectionChanged}
                  setSectionChanged={setSectionChanged}
                  courseName={mySectionData?.courseId?.name}
                  quizAttemptForResult={quizAttemptForResult != null && quizAttemptForResult}
                  quizAttempCourseId={mySectionData?.courseId?._id}
                  coursePercentage={mySectionData?.progressPercent}
                  evaluationUnlockPercentage={selectedQuizCard?.EvaluationUnlockPercent}
                  pdfOpen={pdfOpen}
                  videoProgress={videoProgress}
                  handleVideoProgress={handleVideoProgress}
                />
              </>
            </div>
            <div className="main-right-sidebar">
              <div
                className={
                  drawerToggle && window.innerWidth <= 959
                    ? classes.cardExpand
                    : classes.card
                }
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "16px",
                  position: "sticky",
                  height:" calc(100vh - 60px)"
                }}
              >
                {mySectionData !== '' &&
                  <CourseCard
                    courseData={mySectionData?.courseId}
                    progressPercent={mySectionData?.progressPercent}
                  />
                }

                <AppBar position="static" className={classes.root}>
                  <CTabs
                    nav={tabBarList}
                    setActiveTab={selectedTab}
                    handleTabChange={handleTabChange}
                  />
                </AppBar>

                <TopicMaterialContainer
                  selectedIndex={selectedTab}
                  course_id={course_id}
                  showImg={"UIImg"}
                  subject_id={subject_id}
                  topic_id={topic_id}
                  subjectDetail={subjectDetail}
                  history={history}
                  setdownloadCertificateButton={setdownloadCertificateButton}
                  downloadCertificateButton={downloadCertificateButton}
                  goToDashboard={goToDashboard}
                  onQuizCardClick={onQuizCardClick}
                  setActiveItem={setActiveItem}
                  activeItem={activeItem}
                  onVideoRefresh={handleVideoRefresh}
                  videoRefresh={videoRefresh}
                  onPdfOpen={handlePdfOpen}
                  pdfOpen={pdfOpen}
                  sectionChanged={sectionChanged}
                  setSectionChanged={setSectionChanged}
                />
              </div>
            </div>
          </div>
        </Grid>
      )}
    </>
  );
}

export default TopicMaterial;