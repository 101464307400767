import React from "react";
import { useAnalyticsPageView, useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import ReactHlsPlayer from "react-hls-player";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

/**
 * Setting component. right now it has only user profile section and selected exams of a user.
 * @param props
 * @returns {*}
 * @constructor
 */
function VideoPlayer(props) {
    const { activeVideo } = props;
    const videoUrl = activeVideo.url;
    const t = useTranslations();
    const history = useHistory();
    useAnalyticsPageView();
    function goToAllTest() {
        history.goBack();
    }
    const classes = useStyles(styles)();
    return (
        <>
            <div className={classes.quizHeader}>
                <div className={classes.solutionMode} onClick={goToAllTest}>
                    <ArrowBackIcon />
                    <span>{t("Back")}</span>
                </div>
            </div>
            <div>
                <ReactHlsPlayer src={videoUrl} autoPlay={false} controls={true} width="100%" height="400px" />
            </div>
        </>
    );
}

const styles = (theme) => ({
    quizHeader: {
        height: 66,
        marginBottom: 0,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        display: "flex",
        justifyContent: "space-between",
        "@media (max-width: 767px)": {
            height: 55,
            alignItems: "center",
            justifyContent: "flex-end",
            zIndex: 9999,
        },
    },
    solutionMode: {
        display: "none",
        "@media (max-width: 767px)": {
            display: "flex",
            alignItems: "center",
            left: 10,
            position: "absolute",
            color: "#fff",
            fontSize: 16,
            zIndex: 1,
            "& i": {
                color: "#fff",
                marginRight: 10,
                fontSize: 16,
            },
        },
    },
});

export default VideoPlayer;
