import axios from "./axiosConfig";
import status from "../constants/actionStatus";
import { shuffle } from 'lodash';
export function getAllTestsList(courseId) {
  return axios.get("/api/v2/quizzes/" + courseId).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getAllTestsCount(examsId) {
  return axios.get("/api/v2/quizzes-count/" + examsId).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getAllLiveTests(condition) {
  return axios.get("/api/v1/live-tests/" + condition).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getAllPracticeTests(examId) {
  let condition = "exam=" + examId + "&types=practice-quiz";
  return axios.get("/api/v2/quizzes?" + condition).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getNextQuiz(selectedExamId) {
  let URL = "/api/v1/next-tests/" + selectedExamId;
  return axios.get(URL).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getQuizzesData(slug) {
  return axios.get("/api/v1/quizScreenData/" + slug).then(response => {
    // handle success
    let data = response;
    let formattedQuiz = formatQuiz(data, slug);
    return formattedQuiz;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getQuizResponses(slug) {
  return axios.get("/api/v1/quizResponse/" + slug).then(function (response) {
    // handle success
    let data = response.data;
    let formattedQuiz = formatQuizResponse(data);
    return formattedQuiz;
  }).catch(function (error) {
    // handle error
    return error;
  });
}
export function getFavouriteQuizList() {
  return axios.get("/api/v1/getFavouriteQuizList").then(function (response) {
    // handle success
    return response.data.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function saveQuizResponses(responseObj) {
  return axios.post("/api/v1/quizResponse", responseObj).then(function (resp) {
    // handle success
    return resp;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function toggleSaveQuiz(id) {
  return axios.get("/api/v1/fav-quiz/" + id).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getQuizzes(courseId, mode, length, skip) {
  let URL = mode ? "/api/v2/quizzes/".concat(courseId, "?mode=").concat(mode) : "/api/v2/quizzes/".concat(courseId, "?isCompleted=true&limit=") + length + "&skip=" + skip;
  return axios.get(URL).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
function formatQuiz(response, slug) {
  let data = response.data;
  let alreadyAttempted = data.attempted;
  if (alreadyAttempted) {
    // if this is the case the we should not allow user working on the test again.
    //redirect user to solution mode.
    window.location.href = "/quiz/view/" + slug;
  }
  let selectTimeFor = data.selectTimeFor;
  let questionsGroups = data.questionsGroup;
  //open first question

  const shuffledQuiz = questionsGroups && questionsGroups.map(section => {
    const data = section;
    data.questions = shuffle(section.questions);
    return data;
  });
  if (shuffledQuiz && shuffledQuiz[0] && shuffledQuiz[0].questions && shuffledQuiz[0].questions[0]) {
    shuffledQuiz[0].questions[0].visited = true;
    shuffledQuiz[0].questions[0].active = true;
  }
  return {
    questions: shuffledQuiz,
    selectTimeFor: selectTimeFor,
    totalQuestions: data.total,
    id: data._id,
    slug: data.slug,
    instructions: data.instructions,
    name: data.name,
    duration: data.time,
    startDate: data.startDate,
    endDate: data.endDate,
    status: status.SUCCESS,
    locales: data.locales,
    timeTaken: data.timeTaken,
    mark: data.mark,
    mode: data.mode
  };
}
function formatQuizResponse(data) {
  let questions = data.response;
  let quiz = data.quiz;
  let result = data.results;
  let totalQuestions = 0;
  if (questions && questions[0] && questions[0].questions && questions[0].questions[0]) {
    questions[0].questions[0].visited = true;
    questions[0].questions[0].active = true;
    totalQuestions = questions.reduce((total, item) => {
      total += item.questions.length;
      return total;
    }, 0);
  }
  return {
    result: result,
    questions: questions,
    totalQuestions: totalQuestions,
    id: quiz ? quiz._id : "",
    slug: quiz ? quiz.slug : "",
    instructions: quiz ? quiz.instructions : "",
    name: quiz ? quiz.name : "",
    mark: quiz.mark,
    duration: data.quiz.duration,
    startDate: data.quiz.startDate,
    endDate: data.quiz.endDate,
    status: status.SUCCESS,
    locales: quiz.locales,
    timeTaken: data.timeTaken
  };
}