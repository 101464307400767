import React from 'react';
import QuizResultComparisonRow from './QuizResultComparisonRow';
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";

/**
 * results comparision with topper and avg.
 * @returns {*}
 * @constructor
 */
function QuizResultComparison(props) {
    const { quizResults } = props;
    const t = useTranslations();
    const comparisonList = quizResults && quizResults.data && quizResults.data.comparisonChart ?
        quizResults.data.comparisonChart : {};
    return (
        <>
            <h4>{t('compare_with_topper')}</h4>
            <div className="topper-compare-table">
                <div className="table">
                    <table className="table">
                        <tbody>
                        <tr>
                            <td></td>
                            <td><span>{t('score')}</span></td>
                            <td><span>{t('accuracy')}</span></td>
                            <td><span>{t('correct')}</span></td>
                            <td><span>{t('wrong')}</span></td>
                            <td><span>{t('time')}</span></td>
                        </tr>
                        <QuizResultComparisonRow quizUserResults={comparisonList.self} name={t('YOU')}/>
                        <QuizResultComparisonRow quizUserResults={comparisonList.topper} name={t('TOPPER')}/>
                        <QuizResultComparisonRow quizUserResults={comparisonList.average} name={t('AVERAGE')}/>
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    );
}

export default QuizResultComparison;
