import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CTabs from "../components/Tabs/CTabs";
import { useHistory } from "react-router-dom";

/**
 * Practice test entry point.
 * @param props
 * @returns {*}
 * @constructor
 */
function NavBar(props) {
    const { selectedTab, setSelectedTab, navBarList } = props;
    const history = useHistory();

    //on tab change
    function handleTabChange(event, value) {
        if (value) {
            setSelectedTab(value);
            window.scrollTo(0, 0);
        } else {
            history.push('/dashboard/');
        }
    }

    return (
        <div className="mock-test-section">
            <div className="top-header">
                <AppBar position="static" className="tabs">
                    <CTabs nav={navBarList} setActiveTab={selectedTab} handleTabChange={handleTabChange}/>
                </AppBar>
            </div>
        </div>
    );
}

export default NavBar;
