import { getRecentCourses } from "../services/courseService";
export const recentCourseListAction = data => dispatch => {
  dispatch(request());
  return getRecentCourses(data).then(response => {
    // handle success
    dispatch(success(response));
    return response;
  }).catch(error => {
    console.log("error", error);
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: "RECENT_COURSE_LIST_REQUEST"
    };
  }
  function success(response) {
    return {
      type: "RECENT_COURSE_LIST_SUCCESS",
      payload: response
    };
  }
  function failure(error) {
    return {
      type: "RECENT_COURSE_LIST_FAILURE",
      payload: error
    };
  }
};