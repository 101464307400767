import React, { useState } from 'react';
import CTabs from "../../Tabs/CTabs";
import PracticeQuestionsHeaderPage from './PracticeQuestionsHeaderPage';
import PracticeQuestionsResultSimplification from "./PracticeQuestionsResultSimplification";
import './practice.scss';
import PracticeQuestionProgressStatusTab from './PracticeQuestionProgressStatusTab';
import { getAggregateCount, accuracyCountFn, progressCountFn } from "examsbook-react-sdk/custom-hooks";

/**
 * Practice question dashboard component.
 * @param props
 * @returns {*}
 * @constructor
 */
function PracticeQuestionsDashboard(props) {
    const { subjects, history } = props;
    const aggregateCount = getAggregateCount(subjects);
    const accuracyCount = accuracyCountFn(subjects);
    const progressCount = progressCountFn(subjects);
    const attempted = answeredCount(subjects);
    const [ value, setValue ] = useState(0);

    function handleTabChange(event, val) {
        setValue(val);
    }

    const tabData = {
        setActiveTab: value,
        handleTabChange: handleTabChange,
        nav: [ {
            label: 'home',
            ref: 'Home'
        }, {
            label: 'analysis',
            ref: 'analysis'
        } ]
    };
    return (
        <React.Fragment>
            <div>
                <CTabs {...tabData}/>
            </div>

            <div className="practice-banner">
                {
                    (value === 0) ?
                        (attempted && attempted.attemptedQuesCount.length === 0) ?
                            <PracticeQuestionsHeaderPage subjects={subjects}/> :
                            attempted.attemptedQuesCount.map((topic, index) => {
                                return (
                                    (topic !== 0) ?
                                        <PracticeQuestionsResultSimplification
                                            key={index}
                                            topicName={attempted.name[index]}
                                            correctAnsCount={attempted.correctAnsCount[index]}
                                            incorrectAnsCount={attempted.incorrectAnsCount[index]}
                                            notAnsweredCount={attempted.notAnsweredCount[index]}
                                            attemptedQuesCount={attempted.attemptedQuesCount[index]}
                                            totalQuestion={attempted.totalQuestion[index]}
                                            subjectAndTopicIds={attempted.subjectAndTopicIds[index]}
                                            history={history}/> : ''
                                );
                            }) : ''
                }
                <div className="overall-progress-status">

                    <PracticeQuestionProgressStatusTab subjects={subjects} aggregateCount={aggregateCount}
                                                       accuracyCount={accuracyCount} progressCount={progressCount}/>

                </div>
            </div>
        </React.Fragment>
    );
}

function answeredCount(subjects) {
    let correctAnsCount = [];
    let incorrectAnsCount = [];
    let notAnsweredCount = [];
    let attemptedQuesCount = [];
    let topicName = [];
    let totalQuestion = [];
    let subjectAndTopicIds = [];
    subjects.forEach((subject) => {
        subject.topics.forEach(topic => {
            if (topic.incorrect !== 0 || topic.correct !== 0) {
                subjectAndTopicIds.push({ subjectId: subject._id, topicId: topic._id });
                topicName.push(topic.name);
                attemptedQuesCount.push(topic.attempted);
                correctAnsCount.push(topic.correct);
                incorrectAnsCount.push(topic.incorrect);
                notAnsweredCount.push(topic.total - topic.attempted);
                totalQuestion.push(topic.total);
            }
        });
    });
    return {
        name: topicName,
        attemptedQuesCount: attemptedQuesCount,
        correctAnsCount: correctAnsCount,
        incorrectAnsCount: incorrectAnsCount,
        notAnsweredCount: notAnsweredCount,
        totalQuestion: totalQuestion,
        subjectAndTopicIds: subjectAndTopicIds
    };
}

export default PracticeQuestionsDashboard;
