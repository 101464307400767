import React from 'react';
import ReleasePlan from "../ReleasePlan/ReleasePlan";
import TestListTab from "./TestListTab";
import VideoListTab from './VideoListTab';

/**
 * TestSeriesQuiz toggle component.
 * @param props
 * @returns {*}
 * @constructor
 */
function TestSeriesQuiz(props) {
    const { quizGroups, lang, selectedIndex, singleTestSeriesLoading } = props;
    return (
        <div className="analysis-component">
            {selectedIndex === 0 &&
            <TestListTab quizGroups={quizGroups} lang={lang} singleTestSeriesLoading={singleTestSeriesLoading}/>
            }
            {selectedIndex === 1 &&
            <VideoListTab quizGroups={quizGroups} lang={lang} singleTestSeriesLoading={singleTestSeriesLoading}/>
            }
            {selectedIndex === 2 && <ReleasePlan groups={quizGroups} lang={lang}/>}
        </div>
    );
}

export default TestSeriesQuiz;
