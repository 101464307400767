import { toggleSaveQuiz } from "../services/quizService";
/**
 * Un save question
 * @param id
 * @returns {function(*): *}
 */
export const toggleSavedQuiz = id => dispatch => {
  // functionality to unsave question
  toggleSaveQuiz(id).then(response => {
    // dispatching success
    dispatch(success(response.data));
    // dispatching updated list of questions
    //we do not need to get list of saved questions again.
    //dispatch(getQuestions());
  }).catch(error => {
    console.log("error", error);
    // handle error
    //  dispatch(failure(error.toString()));
  });
  function success(response) {
    //state toggle. We can notify that is successful
    return {
      type: 'TOGGLE_QUIZ__SAVED_SUCCESS',
      payload: {
        quizId: id
      }
    };
  }

  /**
  * Got error while changing the status
  * @param error
  * @returns {{type: string, error: *}}
  */
  function failure(error) {
    console.log('error--------', error);
    return {
      type: 'TOGGLE_QUESTION__SAVED_FAILURE',
      error
    };
  }
};