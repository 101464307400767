import useAllTestList from "./useAllTestList";
export default function useAllTestListByExam(examId) {
  const {
    isLoading,
    allTestList
  } = useAllTestList();
  let filteredList = [];
  if (allTestList) {
    filteredList = allTestList.filter(test => {
      let isMatched = false;
      if (test && test.exams) {
        isMatched = test.exams.some(exam => {
          return exam._id === examId;
        });
      }
      return isMatched;
    });
  }
  return {
    isLoading,
    allTest: filteredList,
    remainingTests: filteredList ? filteredList.filter(t => !t.attempted) : [],
    completedTests: filteredList ? filteredList.filter(t => t.attempted) : []
  };
}