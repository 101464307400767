import React from 'react';
import TopicListItem from "./TopicListItem";

/**
 * Topic list component
 */
function TopicList(props) {
    const { topics, subjectId, selectedSubjectTopic, selectSubjectAndTopic } = props;
    return (
        <React.Fragment>
            {
                topics && topics.length ?
                    topics.map((topic, index) => {
                        return (
                            <TopicListItem key={index}
                                           topic={topic}
                                           subjectId={subjectId}
                                           selectedSubjectTopic={selectedSubjectTopic}
                                           selectSubjectAndTopic={selectSubjectAndTopic}/>
                        );
                    }) : ''
            }
        </React.Fragment>

    );
}

export default TopicList;
