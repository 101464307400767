import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExamsWithSubjects, setCurrentSubjectAndTopic } from 'examsbook-react-sdk/actions';
import { PracticeQuestionsRightArea, PracticeQuestionSidebar } from "../components/PracticeQuestions";
import { useTranslations, useAnalyticsPageView, useSelectedExam } from "examsbook-react-sdk/custom-hooks";

/**
 * Practice Questions page.
 * @param props
 * @returns {*}
 * @constructor
 */
function PracticeQuestionsWrapper(props) {
    const { history, match, isQuestionList } = props;
    const t = useTranslations();
    useAnalyticsPageView();
    const [ sidebarOpened, setSidebarOpen ] = useState(false);
    const selectedSubjectTopic = useSelector((state) => state.selectedSubjectTopic);
    const selectedExam = useSelectedExam();
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedExam && selectedExam._id) {
            dispatch(getExamsWithSubjects(selectedExam._id));
        }
    }, [ dispatch, selectedExam ]);
    useEffect(() => {
        //auto select a topic on reload.
        if (!selectedSubjectTopic.subject && match
            && match.params
            && match.params.subject
            && match.params.topic) {
            dispatch(setCurrentSubjectAndTopic({
                subject: match.params.subject,
                topic: match.params.topic
            }));
        }
    }, [ dispatch, match, selectedSubjectTopic ]);

    function setCurrentSubjectWithTopic(data) {
        dispatch(setCurrentSubjectAndTopic({
            subject: data.subject,
            topic: data.topic
        }));
    }

    // make practice question side responsive by apply toggle button
    function toggleSidebar() {
        setSidebarOpen(!sidebarOpened);
    }

    return (
        <div className="practice-question-block">
            <PracticeQuestionsRightArea
                history={history}
                isQuestionList={isQuestionList}
                setCurrentSubjectAndTopic={setCurrentSubjectWithTopic}/>

            <PracticeQuestionSidebar
                sidebarOpens={sidebarOpened}
                subjectSideBarOpensHandler={toggleSidebar}/>
            <div className="mobile-sidebar-btn" onClick={toggleSidebar}>
                <span>{t('practice_questions')}</span>
            </div>
        </div>
    );
}

export default PracticeQuestionsWrapper;
