import React from 'react';
import ContentLoader from 'react-content-loader';
import { useWindowSize } from "examsbook-react-sdk/custom-hooks";

function LiveTestLoaderAllTest() {
    const windowSize = useWindowSize();
    return (

        <div className="mock-test-card test-hover loader-content">
            {windowSize && windowSize.width <= 488 ?
                <ContentLoader
                    height={90}
                    width={230}
                    speed={1}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb">
                    <rect x="5" y="15" rx="2" ry="2" width="80" height="10"/>
                    <rect x="145" y="15" rx="2" ry="2" width="80" height="10"/>
                    <rect x="5" y="35" rx="2" ry="2" width="220" height="10"/>

                    <rect x="5" y="68" rx="2" ry="2" width="220" height="20"/>
                </ContentLoader> : <ContentLoader
                    height={230}
                    width={230}
                    speed={1}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb">
                    <rect x="5" y="15" rx="5" ry="5" width="80" height="15"/>
                    <rect x="145" y="15" rx="5" ry="5" width="80" height="15"/>
                    <rect x="5" y="55" rx="5" ry="5" width="220" height="14"/>
                    <rect x="5" y="78" rx="5" ry="5" width="220" height="14"/>

                    <rect x="5" y="135" rx="5" ry="5" width="220" height="12"/>
                    <rect x="5" y="175" rx="5" ry="5" width="220" height="30"/>
                </ContentLoader>
            }
        </div>
    );
}

export default LiveTestLoaderAllTest;
