import React from 'react';
import Typography from "@material-ui/core/Typography/Typography";

export default function TabContainer(props) {
    return (
        <div className={props.className}>
            <Typography component="div" style={{ }}>
                {props.children}
            </Typography>
        </div>
    );
}
