import React from "react";
import { CNavLink } from "./c/CHeader";
import CUserImageIcon from "../../CUserImageIcon";
import {
  useTranslations
} from "examsbook-react-sdk/custom-hooks";
import { useHistory } from "react-router-dom";

/**
 * UserMenuList
 * @param props
 * @returns {*}
 * @constructor
 */
function UserMenuList(props) {
  const { user, profile } = props;
  const history = useHistory();
  const t = useTranslations();

  const logout = () => {
    localStorage.clear();
    history.replace('/login');
  };

  return (
    <React.Fragment>
      {profile ? (
        <div id="userSectionId">
          <CUserImageIcon src={user?.image?.largeImage?.webP} />
          <ul className="sub-menus">
            <li>
              <CNavLink to="/my-profile">{t("my_profile")}</CNavLink>
            </li>
            <li onClick={() => logout()}>{t("logout")}</li>
          </ul>
        </div>
      ) : (
        <div
          id="contentBlockId"
        >
          <p id="contentId">{user.name}</p>
          <p id="content2Id">{user.city}</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default UserMenuList;
