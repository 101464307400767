import { getDeleteNotifications } from "../services/deleteNotifications";
import { getNotifications } from "./getNotifications";

/**
 * Get list of all notifications
 * @returns {function(*): *}
 */
export const getDeleteNotification = id => dispatch => {
  dispatch(request());
  // function to get all notifications.
  getDeleteNotifications(id).then(deleteNotifications => {
    // dispatching notification list to reducer.
    dispatch(success(deleteNotifications));
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: 'GET_DELETE_NOTIFICATION_REQUEST'
    };
  }
  function success(deleteNotifications) {
    return {
      type: 'GET_DELETE_NOTIFICATION_SUCCESS',
      deleteNotifications
    };
  }
  function failure(error) {
    return {
      type: 'GET_DELETE_NOTIFICATION_FAILURE',
      error
    };
  }
};