import React from 'react';
import { NavLink } from "react-router-dom";

function NotFoundPage(props) {
    return (
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1>:(</h1>
                </div>
                <h2>404 - Page not found</h2>
                <p>The page you are looking for might have been removed had its name changed or is temporarily
                    unavailable.</p>
                <NavLink to="/dashboard/">
                    Home
                </NavLink>
            </div>
        </div>
    );
}

export default NotFoundPage;
