import status from '../constants/actionStatus';
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    status: status.PENDING,
    data: []
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'GET_SUB_EXAMS_LIST_WAITING':
      return {
        status: status.PENDING,
        data: action.payload
      };
    case 'GET_SUB_EXAMS_LIST_SUCCESS':
      return action.payload;
    default:
      return state;
  }
});