export default function calculatedMarkDistribution(quizResults) {
  let areaChartData = [];
  if (quizResults.data && quizResults.data.markDistribution) {
    const self = quizResults.data.comparisonChart.self.score;
    let userRange;
    let duplicateRange;
    areaChartData = quizResults.data.markDistribution.map(data => {
      if (self >= data.range[0] && self <= data.range[1]) {
        // to reduce duplicate range for user
        if (data.range[0] === data.range[1]) {
          duplicateRange = data.range[1];
          userRange = parseInt(data.range[0]) + ' - ' + parseInt(data.range[1]);
        } else if (data.range[0] !== data.range[1] && duplicateRange !== data.range[0]) {
          userRange = parseInt(data.range[0]) + ' - ' + parseInt(data.range[1]);
        }
      }
      return {
        range: parseInt(data.range[0]) + ' - ' + parseInt(data.range[1]),
        students: data.value,
        self: userRange,
        userMarks: self
      };
    });
  }
  return areaChartData;
}