import { saveQuestionResponses, saveQuestionResponsesMobile } from "../services/questionService";
function request(resData) {
  return {
    type: 'QUESTION_RESPONSE_REQUEST',
    resData
  };
}
function success(questionResponse) {
  return {
    type: 'QUESTION_RESPONSE_SUCCESS',
    payload: questionResponse
  };
}
function failure(error) {
  return {
    type: 'QUESTION_RESPONSE_FAILURE',
    error
  };
}
export const saveQuestionResponse = data => dispatch => {
  dispatch(request(data));
  saveQuestionResponses(data).then(questionResponseData => {
    // handle success
    dispatch(success(questionResponseData));
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
};
export const saveQuestionResponsesApp = data => dispatch => {
  dispatch(request(data));
  saveQuestionResponsesMobile(data).then(questionResponseData => {
    // handle success
    dispatch(success(questionResponseData));
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
};