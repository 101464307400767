import _objectSpread from "/codebuild/output/src2290389247/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import status from '../constants/actionStatus';
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    status: status.PENDING,
    testSeries: {}
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'GET_TEST_SERIES_WAITING':
      return {
        status: status.PENDING,
        testSeries: action.payload
      };
    case 'GET_TEST_SERIES':
      return {
        status: status.SUCCESS,
        testSeries: action.payload.testSeries,
        lastUpdated: action.lastUpdated,
        quizGroupId: action.quizGroupId
      };
    case 'GET_UPDATED_TEST_SERIES':
      const oldTestData = state.testSeries;
      const updatedTestData = action.payload.testSeries;
      let newQuizData = JSON.parse(JSON.stringify(updatedTestData));
      oldTestData.quizGroup.forEach((item, index) => {
        item.quizes.forEach(quizItem => {
          const isDuplicateItem = updatedTestData.quizGroup[index].quizes.some(quiz => {
            return quiz._id === quizItem._id;
          });
          if (!isDuplicateItem) {
            newQuizData.quizGroup[index].quizes.push(quizItem);
          }
        });
      });
      return {
        status: status.SUCCESS,
        testSeries: newQuizData,
        lastUpdated: action.lastUpdated,
        quizGroupId: action.quizGroupId
      };
    case 'TOGGLE_QUIZ__SAVED_SUCCESS':
      let testSeriesList = state.testSeries;
      testSeriesList.quizGroup && testSeriesList.quizGroup.forEach(element => {
        let quizList = element.quizes.map(quiz => {
          quiz = _objectSpread({}, quiz); // copy
          if (quiz._id !== action.payload.quizId) {
            return quiz;
          } else {
            //toggle status.
            quiz.isSaved = !quiz.isSaved;
            return quiz;
          }
        });
        element.quizes = [...quizList];
      });
      return {
        status: testSeriesList.quizGroup ? status.SUCCESS : status.PENDING,
        testSeries: testSeriesList
      };
    case 'GET_TEST_SERIES_ERROR':
      return {
        status: status.ERROR,
        testSeries: action.payload
      };
    default:
      return state;
  }
});