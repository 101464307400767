import { getPracticeQuestionList } from "../services/questionService";

/**
 * Get list of practice questions.
 * @param query
 * @returns {Function}
 */
export const getPracticeQuestions = query => dispatch => {
  //we need to show loader in this case.
  dispatch({
    type: 'PRACTICE_QUESTIONS_REQUEST',
    payload: {}
  });
  // function to get all questions saved by user
  if (query && query.subject && query.topic) {
    getPracticeQuestionList(query).then(response => {
      // dispatching question list to reducer
      dispatch({
        type: 'PRACTICE_QUESTIONS_SUCCESS',
        payload: {
          questionList: response.questionList,
          savedQuestions: response.savedQuestions
        }
      });
    }).catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      dispatch({
        type: 'PRACTICE_QUESTIONS_FAILURE',
        payload: {
          error: error.message
        }
      });
    });
  } else {
    dispatch({
      type: 'PRACTICE_QUESTIONS_SUCCESS',
      payload: {
        questionList: [],
        savedQuestions: []
      }
    });
  }
};