import React from 'react';
import VideoAccordion from '../TestAccordion/VideoAccordion';

/**
 * Video List tab with accordian.
 * @param props
 * @returns {*}
 * @constructor
 */
const VideoListTab = props => {
    const { quizGroups, lang, singleTestSeriesLoading } = props;
    return (
        <React.Fragment>
            {
                quizGroups && quizGroups.length ?
                    quizGroups.map((data, index) => {
                        return (
                            <VideoAccordion quizGroup={data} key={index} index={index} lang={lang}
                                           singleTestSeriesLoading={singleTestSeriesLoading}/>
                        );
                    }) : <VideoAccordion singleTestSeriesLoading={singleTestSeriesLoading}/>
            }
        </React.Fragment>
    );
};
export default VideoListTab;