import axios from "./axiosConfig";
const defaultCondition = "orderby=updated&fields=name,metaDesc,featured,description,isUserCourse,keypoints,totalQuizes,exams,categories,subjects,topics,quizGroup,pricing,price,isDiscount,discountedPrice,mode,created,updated,startDate,endDate,completedQuizes,totalQuizes,status&types=1";
export function getAllCourse() {
  let condition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultCondition;
  return axios.get("/api/v1/quiz-groups?" + condition).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getCourseDetails(courseId) {
  return axios.get("/api/v1/quiz-group/" + courseId).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getSubjects(courseId) {
  return axios.get("/api/v1/quiz-group/courses/" + courseId).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getTopics(courseId, subjectId) {
  return axios.get("/api/v1/quiz-group/course/".concat(courseId)).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getUserCourses(userId) {
  return axios.get("/api/v1/userCourses/" + userId).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getAllUserCourses(id) {
  return axios.get("/api/v1/quiz-group-data?userId=" + id + "&core=true").then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function addToCourseApi(courseId, userId) {
  return axios.put("/api/v1/add-course-to-mycourse/" + courseId + "?userId=" + userId).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function addMyCourse(data) {
  return axios.post("/api/v1/userCourse", data).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function courseResultData(data, courseId) {
  return axios.get("/api/v1/quizResult/".concat(data, "?courseId=").concat(courseId)).then(response => {
    // handle success
    return response;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function courseResultDataByCourseId(data, courseId) {
  return axios.get("/api/v1/quizResult/".concat(data, "?courseId=").concat(courseId)).then(response => {
    // handle success
    return response;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getFilteredCourses(id, examId) {
  return axios.get("/api/v1/quiz-group/primary-category/".concat(id, "/exams/").concat(examId)).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getCourseContent(courseId, subjectId, topicId) {
  return axios.get("/api/v1/studyMaterials/".concat(courseId)).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function addToRecentCourse(data) {
  return axios.post("/api/v1/addRecentCourse", data).then(response => {
    // handle success

    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getRecentCourses(id) {
  return axios.get("api/v1/recentCourseList?userId=" + id).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}