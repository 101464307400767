import React from 'react';
import ContentLoader from 'react-content-loader';
import './../QuestionBox/questionBox.css';

const QuizLoader = () => (
    <ContentLoader
        height={300}
        width={230}
        speed={1}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
        <rect x="5" y="15" rx="5" ry="5" width="80" height="10"/>
        <rect x="145" y="15" rx="5" ry="5" width="80" height="10"/>
        <rect x="5" y="45" rx="5" ry="5" width="220" height="10"/>
        <rect x="5" y="60" rx="5" ry="5" width="220" height="10"/>
        <rect x="5" y="75" rx="5" ry="5" width="220" height="10"/>
        <rect x="5" y="90" rx="5" ry="5" width="220" height="10"/>
    </ContentLoader>
);

export default QuizLoader;
