import { getSavedQuestionList } from "../services/questionService";

/**
 * Get list of saved questions
 * @returns {function(*): *}
 */
export const getSavedQuestions = () => dispatch => {
  dispatch(request());
  // function to get all questions saved by user
  getSavedQuestionList().then(questionList => {
    // dispatching question list to reducer
    dispatch(success(questionList));
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: 'SAVED_QUESTIONS_REQUEST'
    };
  }
  function success(questionList) {
    return {
      type: 'SAVED_QUESTIONS_SUCCESS',
      questionList
    };
  }
  function failure(error) {
    return {
      type: 'SAVED_QUESTIONS_FAILURE',
      error
    };
  }
};