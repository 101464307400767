import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import certificateImage from "../assets/images/lmsCertificate.png";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { getDefaultSupportDataAction, getSupportAction } from "examsbook-react-sdk/actions/getSupportAction";

const useStyles = makeStyles(theme => ({
  userName: {
    fontFamily: "Alex-Brush",
    fontSize: "42px",
    position: "absolute",
    bottom: "50%",
    width: '100%',
    [theme.breakpoints.up('1600')]: {
      fontSize: "80px",
    },
    [theme.breakpoints.between('900', '1200')]: {
      fontSize: "26px",
    },
    [theme.breakpoints.down('600')]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down('350')]: {
      fontSize: "15px",
    },
  },
  dataContent: {
    position: "absolute",
    bottom: "40%",
    width: '100%',
    [theme.breakpoints.up('1600')]: {
      bottom: "40%",
    },
    [theme.breakpoints.between('900', '1200')]: {
      bottom: "32%",
    },
    [theme.breakpoints.down('600')]: {
      bottom: "36%",
    },
    [theme.breakpoints.down('350')]: {
      bottom: "34%",
    },
  },
  content1: {
    fontFamily: "Literata",
    fontSize: "20px",
    [theme.breakpoints.up('1600')]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between('900', '1200')]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down('600')]: {
      fontSize: "8px",
    },
  },
  content2: {
    fontFamily: "Literata",
    fontSize: "20px",
    textAlign: 'center',
    [theme.breakpoints.down('600')]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down('350')]: {
      fontSize: "12px",
    },
  },
  certificateContainer: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  date: {
    fontSize: '30px',
    position: 'absolute',
    bottom: '36%',
    left: '55%',
    width: '22%',
    [theme.breakpoints.down('600')]: {
      fontSize: '10px',
      left:'8%',
      width: '20%'
    }
  }
}));

export default function DownloadCertificate(props) {
  const user = useSelector((state) => state.user);
  const classes = useStyles();
  const { sectionData } = props
  const [supportData, setSupportData] = useState([]);
  const [defaultSupportData, setDefaultSupportData] = useState([]);
  const [dynamicLogos, setDynamicLogos] = useState('');
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(false);
  
  const getSettingApiData = async () => {
    try {
    dispatch(getSupportAction()).then((res) => {
    setSupportData(res);
    });
    } catch (error) {
      console.log('Error - ', error.message);
    }
    };

    const getDefaultSeoSettingApiData = async () => {
      try {
          dispatch(getDefaultSupportDataAction()).then((res) => {
          setDefaultSupportData(res);
          });
      } catch (error) {
          console.error(error.message);
      }
    };

    useEffect(() => {
      getSettingApiData();
      getDefaultSeoSettingApiData();
    }, []);

    const isDynamicLogo = dynamicLogos.split('/').pop();
    let API_URL = process.env.REACT_APP_DYNAMIC_URL;
    useEffect(() => {
      const imageValue = supportData?.data?.find(subValue => subValue.key === "Certificate")?.value;
      const defaultValue = defaultSupportData?.data?.Certificate?.find(subValue => subValue.key === "Certificate")?.defaultValue;
      let dynamicLogo = "";
      if (typeof API_URL != "undefined") {
        if (imageValue) {
          dynamicLogo = API_URL +'/img/post/original/' + imageValue;
        } else if (defaultValue) {
          dynamicLogo = API_URL +'/img/post/original/' + defaultValue;
        }
      }
      setDynamicLogos(dynamicLogo) 
    }, [supportData, defaultSupportData])

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowComponent(true);
      }, 300);
      return () => clearTimeout(timer);
    }, []);

  return (
    <div id='certificateId' className={classes.certificateContainer}>
      <img alt="" src={(isDynamicLogo && isDynamicLogo !== 'undefined' ? dynamicLogos : showComponent && certificateImage)} style={{ width: '100%' }} />
      <div className={classes.userName}>{user?.name}</div>
      <div className={classes.dataContent}>
        <div className={classes.content1}>
        {`You have successfully completed `}<b>{sectionData && sectionData?.courseId?.name.toUpperCase()}</b>{` Course from`}
        </div>
        <div className={classes.content2}><b>LEARNIFY</b> on {moment(sectionData?.completedOn).format("DD-MM-YYYY")}</div>
      </div>
    </div>
  );
}

