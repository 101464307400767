export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'GET_CURRENT_USER':
      return action.payload;
    case 'USER_UPDATE':
      return action.payload;
    default:
      return state;
  }
});