import React from 'react';
import './performanceSummary.scss';

/**
 * Performance summery card.
 * @param props
 * @returns {*}
 * @constructor
 */
function PerformanceSummary(props) {
    const { icon, title, value, outOf, background } = props;
    const style = 'linear-gradient( to bottom,' + background + 'bd,' + background + ')';
    return (
        <React.Fragment>
            <div className="icon1-list">
                <div className="icon1" style={{ background: background }}>
                    <span className={icon}></span>
                </div>

                <div className="icon1-details">
                    <span className="icon1-category">{title}</span>
                    <h5>{value} {outOf ? <span>/ {(outOf).toFixed(2) || 0}</span> : null}</h5>
                </div>
            </div>
            <div className="icon1-list icon1-mobile-view-list" style={{ backgroundImage: style }}>
                <div className="icon1" style={{ background: background }}>
                    <span className={icon}></span>
                </div>

                <div className="icon1-details">
                    <span className="icon1-category">{title}</span>
                    <h5>{value} {outOf ? <span>/ {outOf}</span> : null}</h5>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PerformanceSummary;
