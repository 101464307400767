import React from 'react';
import TabContainer from "./TabContainer";
import AllTestTab from "../AllTestTab/AllTestTab";
import TestSeriesList from "../TestSeriesList/TestSeriesList";
import FilteredQuizList from "../QuizList/FilteredQuizList";
import { useAllTestList, useTestSeriesList } from "examsbook-react-sdk/custom-hooks";

/**
 * All test container.
 * @param props
 * @returns {*}
 * @constructor
 */
function AllTestContainer(props) {
    const { selectedIndex, subExamList, handleTabChange } = props;
    const { allTestList, completedTests, remainingPracticeTestList } = useAllTestList();
    const { testSeriesList, testSeriesLoading } = useTestSeriesList();
    return (
        <div>
            {selectedIndex === 1 &&
            <TabContainer className="AllTest">
                <AllTestTab
                    quizList={allTestList}
                    subExamList={subExamList} ßß
                    handleTabChange={handleTabChange}
                    activeIndex={selectedIndex}
                    testSeriesList={testSeriesList}
                    testSeriesLoading={testSeriesLoading}
                    noDataMessage="No quizzes selected in this exam"/>
            </TabContainer>}
            {selectedIndex === 2 && <TabContainer className="Attempted">
                <FilteredQuizList quizList={completedTests}
                                  activeIndex={selectedIndex}
                                  subExamList={subExamList}
                                  noDataMessage="You have not completed any test so far. Take a test now."
                                  handleTabChange={handleTabChange}/>
            </TabContainer>}
            {selectedIndex === 3 && <TabContainer className="MyTest">
                <TestSeriesList handleTabChange={handleTabChange}
                                testSeriesList={testSeriesList}
                                testSeriesLoading={testSeriesLoading}/>
            </TabContainer>}
            {selectedIndex === 4 && <TabContainer className="Quizes">
                <FilteredQuizList quizList={remainingPracticeTestList}
                                  activeIndex={selectedIndex}
                                  subExamList={subExamList}
                                  noDataMessage="Look like no tests present for your selected exam, go to All tests tab or select another exam."
                                  handleTabChange={handleTabChange}/>
            </TabContainer>}
        </div>
    );
}

export default AllTestContainer;
