import React from 'react';
import LiveTestLoaderAllTest from "../common/loaders/LiveTestLoaderAllTest";
import { useTranslations, useLiveTestDashboardData } from "examsbook-react-sdk/custom-hooks";
import QuizListSection from "../QuizList/QuizListSection";

function LiveTestList(props) {
    const { liveTestFullList, liveTestLoading } = useLiveTestDashboardData();
    const t = useTranslations();

    function renderLoader() {
        //we will show three cards to fill this space.
        return (
            <div className="mock-test-strip">
                <div className="loader-content">
                    <LiveTestLoaderAllTest/>
                    <LiveTestLoaderAllTest/>
                    <LiveTestLoaderAllTest/>
                    <LiveTestLoaderAllTest/>
                </div>
            </div>
        );
    }

    function renderList() {
        if (liveTestFullList && liveTestFullList.length > 0) {
            return (
                <div>
                    <QuizListSection quizSectionList={liveTestFullList} sectionName={t('Suggested_tests')}
                                     hideViewMore={false}/>
                </div>
            );
        } else {
            return "";
        }
    }

    return (
        <React.Fragment>
            {
                !liveTestLoading ? renderList() : renderLoader()
            }
        </React.Fragment>
    );
}

export default LiveTestList;
