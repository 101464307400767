import axios from './axiosConfig';

/**
 * Get Single Test Series Data
 * @param condition
 * @returns {response}
 */
export function getSingleTestSeriesData(condition) {
  return axios.get('/api/v2/quiz-group/' + condition).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}

/**
 * Get All Test Series
 * @param condition
 * @returns {response}
 */
export function getAllTestSeries(condition, lang) {
  return axios.get('/api/v2/quiz-groups?' + condition).then(response => {
    // handle success
    let data = formatTestSeries(response.data, lang);
    return data;
  }).catch(error => {
    // handle error
    return error;
  });
}

/**
 * Get All Test Series
 * @param condition
 * @returns {response}
 */
// export function getTestSeriesAnalysisList(id) {
//     return axios.get('/api/v2/quiz-group-analysis-data/' + id)
//         .then((response) => {
//             // handle success
//            return response.data;
//         })
//         .catch((error) => {
//             // handle error
//            return error;
//         })
// }

// export function getSavedTestSeriesQuestions(id) {
//     return axios.get('/api/v2/quiz-group-saved-question/' + id)
//         .then((response) => {
//             // handle success
//             return response.data;
//         })
//         .catch((error) => {
//             // handle error
//             return error;
//         })
// }
export function getTestSeriesAnalysisList(id) {
  return axios.get('/api/v2/quiz-group-analysis-data/' + id).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getSavedTestSeriesQuestions(id) {
  return axios.get('/api/v2/quiz-group-saved-question/' + id).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
function formatTestSeries(testSeriesList, language) {
  let formattedList = testSeriesList.map(testSeries => {
    if (testSeries.endDate) {
      const dateObj = new Date(testSeries.endDate);
      testSeries.endDate = dateObj.getFullYear() + '-' + ('0' + (dateObj.getMonth() + 1)).slice(-2) + '-' + ("0" + dateObj.getDate()).slice(-2);
    }
    let totalTest = 0;
    let sections = [];
    if (testSeries.quizGroup && testSeries.quizGroup.length) {
      testSeries.quizGroup.forEach(data => {
        let name = data.section;
        if (typeof data.section === 'object') {
          name = data.section[language];
        }
        let section = {
          name: name,
          quizCount: data.quizes.length
        };
        sections.push(section);
        totalTest += data.quizes.length;
      });
    }
    testSeries.totalTest = totalTest;
    testSeries.sections = sections;
    return testSeries;
  });
  return formattedList;
}