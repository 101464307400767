import React from 'react';
import { useDispatch } from "react-redux";
import { setCurrentSubjectAndTopic } from "examsbook-react-sdk/actions/setCurrentSubjectTopic";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";

/**
 * PracticeQuestionsResultSimplification
 * @param props
 * @returns {*}
 * @constructor
 */
function PracticeQuestionsResultSimplification(props) {
    const {
        topicName,
        correctAnsCount,
        incorrectAnsCount,
        notAnsweredCount,
        attemptedQuesCount,
        totalQuestion,
        subjectAndTopicIds,
        history
    } = props;
    const t = useTranslations();
    const dispatch = useDispatch();

    function selectSubjectAndTopic(subjectId, topicId) {
        history.push('/practice/subject/' + subjectId + '/topic/' + topicId);
        dispatch(setCurrentSubjectAndTopic({ subject: subjectId, topic: topicId }));
    }

    return (
        <div className="recent-topics-page">
            <div className="recent-topics">
                <div className="recent-topics-title">
                    <h3>{topicName}</h3>
                    <p>{attemptedQuesCount}/{totalQuestion}</p>
                </div>
                <div className="recent-post-category">
                    <p><span>&#10003;</span><span className="number">{correctAnsCount}</span></p>
                    <p><span className="wrong">&#215;</span><span className="number">{incorrectAnsCount}</span></p>
                    <p><span className="unAttempt">&#x270E;</span><span className="number">{notAnsweredCount}</span></p>
                </div>
                {(attemptedQuesCount !== totalQuestion) ?
                    <button
                        onClick={() => selectSubjectAndTopic(subjectAndTopicIds.subjectId, subjectAndTopicIds.topicId)}
                        className={subjectAndTopicIds.topicId === subjectAndTopicIds.subjectId ? 'active' : ''}>
                        {t('RESUME')}</button> : <button className="reviseBtn"
                                                         onClick={() => selectSubjectAndTopic(subjectAndTopicIds.subjectId, subjectAndTopicIds.topicId)}>
                        {t('REVISE')}</button>
                }
            </div>
        </div>
    );
}

export default PracticeQuestionsResultSimplification;
