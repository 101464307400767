import { genericLogin } from "../services/loginService";
export const loginAction = data => dispatch => {
  dispatch(request());
  return genericLogin(data).then(response => {
    // handle success
    dispatch(success(response));
    return response;
  }).catch(error => {
    console.log("error", error);
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: "LOGIN_REQUEST"
    };
  }
  function success(response) {
    return {
      type: "LOGIN_SUCCESS",
      payload: response
    };
  }
  function failure(error) {
    return {
      type: "LOGIN_FAILURE",
      payload: error
    };
  }
};