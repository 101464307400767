import React from 'react';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

function BreadcrumbBar(props) {
    const { selectedSubject, parentLink, selectedTopic, handleBreadcrumbLink } = props;

    function handleClick(event) {
        event.preventDefault();
        handleBreadcrumbLink();
    }

    return (
        <div className="breadcrumb-test-series">
            <Paper elevation={0}>
                {selectedSubject ?
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
                        <Link color="inherit" href="javascript.void(0)" onClick={handleClick}>
                            {parentLink}
                        </Link>
                        <Typography className="breadcrumb">{selectedSubject}</Typography>
                        <Typography className="breadcrumb">{selectedTopic}</Typography>

                    </Breadcrumbs> :
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
                        <Link color="inherit">{parentLink}</Link>
                    </Breadcrumbs>
                }
            </Paper>
        </div>
    );
}

export default BreadcrumbBar;
