import React from 'react';
import PerformanceCard from "./performanceCard";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";

/**
 * Performance report of a user.
 * @param props
 * @returns {*}
 * @constructor
 */
const PerformanceReport = props => {
    const { quizResults } = props;
    const t = useTranslations();
    const performanceSummary = quizResults && quizResults.data && quizResults.data.performanceSummary ?
        quizResults.data.performanceSummary : {};
    return (
        <>
            <h4>{t('performance_summary')}</h4>
            <PerformanceCard performanceSummary={performanceSummary} status={quizResults.status}/>
        </>
    );
};

export default PerformanceReport;
