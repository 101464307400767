export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    data: [],
    isLoading: true
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (action.type === 'GET_SITE_STATS') {
    return {
      data: action.payload,
      isLoading: false
    };
  }
  return state;
});