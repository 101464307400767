import { useSelector, useDispatch } from "react-redux";
import { getFilteredExams } from "examsbook-react-sdk/selectors";
import { useEffect } from "react";
import useSelectedExam from "./useSelectedExam";
import { getExamsWithSubjects } from "examsbook-react-sdk/actions/getExams";
import status from "../constants/actionStatus";

/**
 * get practice module questions.
 * @returns {*}
 */
export default function usePracticeModuleData() {
  const selectedExam = useSelectedExam();
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedExam && selectedExam._id) {
      dispatch(getExamsWithSubjects(selectedExam._id));
    }
  }, [selectedExam]);
  const subjects = useSelector(state => getFilteredExams(state));
  const subjectStatus = useSelector(state => state.examData.status);
  let subjectsLoading = subjects && subjectStatus === status.PENDING;
  return {
    subjects,
    subjectStatus,
    subjectsLoading
  };
}