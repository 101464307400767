import { createSelector } from 'reselect';
const getPracticeTests = state => state.practiceTests;
export const getRemainingPracticeTest = createSelector([getPracticeTests], practiceTests => {
  return practiceTests && practiceTests.data ? practiceTests.data.filter(t => !t.attempted) : [];
});
export const getCompletedPracticeTest = createSelector([getPracticeTests], practiceTests => {
  return practiceTests && practiceTests.data ? practiceTests.data.filter(t => t.attempted) : [];
});
export const getPracticeTest = createSelector([getPracticeTests], practiceTests => {
  return practiceTests.data;
});