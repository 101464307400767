import { useEffect, useRef } from "react";

/**
 * use this hook if you want to exclude first render.
 * @param fn
 * @param inputs
 */
export default function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) fn();else didMountRef.current = true;
  }, inputs);
}