import status from '../constants/actionStatus';
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    status: status.PENDING,
    data: []
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'GET_TEST_SERIES_LIST_REQUEST':
      return {
        status: status.PENDING,
        data: action.payload
      };
    case 'GET_TEST_SERIES_LIST_SUCCESS':
      return {
        status: status.SUCCESS,
        data: action.payload,
        lastUpdated: action.lastUpdated,
        examId: action.examId
      };
    case 'GET_UPDATED_TEST_SERIES_LIST_SUCCESS':
      let olderQuizGroupLIst = state.data;
      let updatedQuizGroupList = action.payload;
      let newQuizGroupList = [...updatedQuizGroupList];
      if (olderQuizGroupLIst && olderQuizGroupLIst.length) {
        olderQuizGroupLIst.forEach(item => {
          const isDuplicateItem = newQuizGroupList.some(data => data._id === item._id);
          if (!isDuplicateItem) {
            newQuizGroupList.push(item);
          }
        });
      }
      return {
        status: status.SUCCESS,
        data: newQuizGroupList,
        lastUpdated: action.lastUpdated,
        examId: action.examId
      };
    case 'GET_TEST_SERIES_LIST_ERROR':
      return {
        status: status.ERROR,
        data: []
      };
    case 'USER_LANGUAGE_UPDATED':
      return {
        status: status.PENDING,
        data: []
      };
    default:
      return state;
  }
});