import React from 'react';
import RankerListItem from "../RankerListItem/RankerListItem";
import RankerListLoader from "../common/loaders/RankerListLoader";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";

/**
 * Top 5 ranker list.
 * @param props
 * @returns {*}
 * @constructor
 */
function RankerList(props) {
    const { quizResults } = props;
    const status = quizResults.status;
    let topRankerList = [];

    if (quizResults.data && quizResults.data.topFiveRankers) {
        topRankerList = quizResults.data.topFiveRankers.map(data => {
            return ({
                title: data.user && data.user.name ? data.user.name : '',
                rank: {
                    value: (data.totalMarks).toFixed(2),
                    outOf: (data.quiz.mark).toFixed(2)
                },
                image: data.user && data.user.image ? '/img/users/thumbnail/' + data.user.image : '/img/user.png',
            });
        });
    }

    const t = useTranslations();

    function renderLoader() {
        return (
            <div className="loader-for-topper">
                <div className="loader-content">
                    <RankerListLoader/>
                </div>
            </div>
        );
    }

    function renderList() {
        return (
            <div className="rankers-block">
                <h4>{t('top_rankers')}</h4>
                <ol className="rankers">
                    {
                        topRankerList.map((user, key) => {
                            return (
                                <li key={key}>
                                    <RankerListItem rankerData={user}/>
                                </li>
                            );
                        })
                    }
                </ol>
            </div>
        );
    }

    return (
        <React.Fragment>
            {
                status !== 'PENDING' && topRankerList && topRankerList.length ?
                    renderList() : renderLoader()
            }
        </React.Fragment>
    );
}

export default RankerList;
