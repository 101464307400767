/**
 * TODO: Update the function later on.
 * Format question for quiz plane.
 * @param question
 * @param viewMode
 * @param language
 * @returns {{type: *, explanation: *, _id: *, title: *, mark: *, penalty: *, givenAnswer: *, timeSpent: *, localesDirection: *, localesTitle: *, direction: *, markObtain: *, className: *, options: Array}}
 */
export default function formatQuestion(question, viewMode, language) {
  let {
    options,
    explanation,
    _id,
    title,
    locales,
    direction
  } = question;
  let {
    type
  } = question.id;
  if (viewMode && question.id && question.id.directionId) {
    direction = question.id.directionId;
  }
  let localesTitle,
    localesDirection,
    className,
    localeOptions = [];
  let markObtain = 0;
  if (locales && locales[language] && locales[language].title) {
    localesTitle = locales[language].title;
  }
  if (locales && locales[language] && locales[language].options && options && options.length) {
    locales[language].options.map((option, i) => {
      localeOptions.push({
        _id: options[i]._id,
        text: option.text,
        correct: viewMode ? option.correct : null
      });
    });
  }
  const optionArr = localeOptions && localeOptions.length ? localeOptions : options;
  if (direction && direction.locales && direction.locales[language]) {
    localesDirection = direction.locales[language].direction;
  }
  const {
    mark,
    penalty,
    givenAnswer,
    timeSpent
  } = question;
  if (options && options.length) {
    for (const option of options) {
      if (givenAnswer && givenAnswer.length) {
        for (const correctAnswer of givenAnswer) {
          if (correctAnswer === option._id && option.correct) {
            markObtain = mark;
            className = 'marks-badge';
          } else if (correctAnswer === option._id && !option.correct) {
            markObtain = -penalty;
            className = 'marks-deduct';
          }
        }
      }
    }
  }
  return {
    _id,
    type,
    explanation,
    title,
    localesTitle,
    direction,
    localesDirection,
    className,
    options: optionArr,
    mark,
    penalty,
    givenAnswer,
    timeSpent,
    markObtain,
    marked: question.marked
  };
}