import _objectSpread from "/codebuild/output/src2290389247/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
let user = {};
const initialState = user ? {
  loggedIn: true,
  user
} : {};
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'GET_USER_REQUEST':
      user = state;
      user.loggedIn = action.user.loggedIn;
      user.completedExams = state.completedExams;
      return action.user;
    case 'GET_USER_SUCCESS':
      user = action.user;
      user.completedExams = state.completedExams;
      return action.user;
    case 'VERIFY_OTP':
      // eslint-disable-next-line no-case-declarations
      let userData = _objectSpread({}, state);
      userData.isMobileVerified = true;
      return userData;
    case 'GET_USER_FAILURE':
      return {};
    case 'USER_UPDATE':
      user = action.payload;
      user['verified'] = 'true';
      user.completedExams = state.completedExams;
      return user;
    case 'ADD_EXAM':
      user = action.payload;
      user.completedExams = state.completedExams;
      return user;
    default:
      return state;
  }
});