import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCourseContentData } from "examsbook-react-sdk/actions";
import status from "../constants/actionStatus";

/**
 * get list of all courses content.
 * @returns {*}
 */
export default function useCourseContent(courseId, subjectId, topicId, refresh) {
  const courseContent = useSelector(state => state.courseContent);
  const isLoading = courseContent && courseContent.status === status.PENDING;
  const {
    courseContent: content
  } = courseContent;
  return {
    content,
    isLoading
  };
}