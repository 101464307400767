import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from "@material-ui/core/Button/Button";
import { CNavLink } from "../components/common/Header/c/CHeader";
import Question from '../components/Question/question';
import QuestionChain from "../components/QuestionChain/questionsChain";
import {
    getPracticeQuestions,
    changePracticeQuestionFilter,
    activePracticeQuesIndex,
    moveActivePracticeIndex,
    setCurrentSubjectAndTopic,
    toggleSavedQuestion
} from "examsbook-react-sdk/actions";
import { getFilteredPracticeQuestions, getFilteredSavedQuestions } from 'examsbook-react-sdk/selectors';
import PracticeQuestionChainLoader from './../components/common/loaders/practiceQuestionChainLoader';
import { useAnalyticsPageView, useTranslations } from "examsbook-react-sdk/custom-hooks";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

/**
 *
 * Question player in practice mode.
 * Still very complex code.
 */
function PracticeQuestionView(props) {
    const isNativeApp = window.isNativeApp || false;
    useAnalyticsPageView();
    const t = useTranslations();
    const [ type, setType ] = useState('view');
    const { history, match } = props;

    useEffect(() => {
        if (match && match.params && match.params.type === 'saved') {
            setType('saved');
        }
    }, [ match ]);

    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const activePracticesQuesIndexVal = useSelector((state) => state.activePracticesQuesIndexVal);
    const selectedSubjectTopic = useSelector((state) => state.selectedSubjectTopic);
    const practiceQuesFilter = useSelector((state) => state.practiceQuesFilter);
    const loadingStatus = useSelector((state) => state.practiceQuestions.status);
    const practiceQuestionsData = useSelector((state) => getFilteredPracticeQuestions(state));
    const savedQuestions = useSelector((state) => getFilteredSavedQuestions(state));
    const moveActivePracticeIndexVal = useSelector((state) => state.moveActivePracticeIndex);

    useEffect(() => {
        if (practiceQuestionsData && practiceQuestionsData.length === 0) {
            if (match && match.params && match.params.subject && match.params.topic) {
                dispatch(setCurrentSubjectAndTopic({
                    subject: match.params.subject,
                    topic: match.params.topic
                }));
            } else {
                dispatch(getPracticeQuestions(selectedSubjectTopic));
            }
        }
    }, [ practiceQuestionsData, match, dispatch, selectedSubjectTopic ]);

    const language = user.language;
    useEffect(() => {
        dispatch(getPracticeQuestions(selectedSubjectTopic));
    }, [ dispatch, language, selectedSubjectTopic ]);

    function toggleSavedQuestions(ques) {
        dispatch(toggleSavedQuestion(ques._id, type !== 'saved'));
    }

    function selectQuestion(question, questionIndex) {

        history.push('/practice/subject/'
            + selectedSubjectTopic.subject
            + '/topic/' + selectedSubjectTopic.topic
            + '/view/' + question.slug);
        dispatch(activePracticeQuesIndex(questionIndex));
        let moveQuestion = questionIndex - 5;
        if (moveQuestion > 0) {
            dispatch(moveActivePracticeIndex(moveQuestion * 42));
        } else {
            dispatch(moveActivePracticeIndex(-0));
        }
    }

    function leftMoveSelectQuestion(question, quesIndex) {
        history.push('/practice/subject/'
            + selectedSubjectTopic.subject
            + '/topic/' + selectedSubjectTopic.topic
            + '/view/' + question.slug);
        dispatch(activePracticeQuesIndex(quesIndex));
        if (moveActivePracticeIndexVal > -1) {
            dispatch(moveActivePracticeIndex(moveActivePracticeIndexVal - 42));
        }
    }

    function filterQuestions(status) {
        dispatch(changePracticeQuestionFilter(status));
        dispatch(activePracticeQuesIndex(index));
        dispatch(moveActivePracticeIndex(-0));
    }

    function resetFilter() {
        dispatch(changePracticeQuestionFilter('ALL'));
    }

    //based on type we may want to use practiceQuestions or savedQuestions
    let practiceQuestions = type === 'saved' ? savedQuestions : practiceQuestionsData;

    let index = 0;
    for (const i in practiceQuestions) {
        if (practiceQuestions[i].slug === match.params.slug) {
            index = i;
        }
    }
    let quesMessage;
    if (practiceQuesFilter === 'CORRECT' && !practiceQuestions.length) {
        quesMessage = t('you_have_not_correct_any_questions_yet');
    } else if (practiceQuesFilter === 'INCORRECT' && !practiceQuestions.length) {
        quesMessage = t('you_have_not_incorrect_any_questions_yet');
    } else {
        quesMessage = t('you_have_not_unAttempted_any_questions_yet');
    }
    const className = practiceQuestions && practiceQuestions.length && practiceQuestions[parseInt(index, 10)] && practiceQuestions[parseInt(index, 10)].directionId && (practiceQuestions[parseInt(index, 10)].directionId.type).toUpperCase() === 'LONG_TEXT' ? 'direction-block' : '';

    function goBack() {
        if (type === 'saved') {
            history.push("/saved-questions");
        } else {
            history.push("/practice/subject/" + selectedSubjectTopic.subject + "/topic/" + selectedSubjectTopic.topic);
        }
    }

    let title = type === 'saved' ? t('saved_questions') : 'Practice Questions';
    if (type !== 'saved' && practiceQuestions && practiceQuestions[0] && practiceQuestions[0].topic) {
        title = practiceQuestions[0].topic.name;
    }
    return (
        <>
            <div className={isNativeApp ? "web-back-to-test" : "mobile-back-to-test"}>
                <ArrowBackIcon onClick={goBack}/>
                <span>{title}</span>
            </div>
            {
                practiceQuestions && practiceQuestions.length ?
                    <div className="saved-question-list">
                        <QuestionChain practiceQuestions={practiceQuestions} t={t} onSelectChange={filterQuestions}
                                       activePracticesQuesIndexVal={activePracticesQuesIndexVal}
                                       selectQuestion={selectQuestion} leftMoveSelectQuestion={leftMoveSelectQuestion}
                                       incrementTranslate={moveActivePracticeIndexVal}
                                       totalLength={practiceQuestions.length}/>
                        <div className={"marginTop50 " + className}>
                            <Question
                                toggleSavedQuestion={() => toggleSavedQuestions(practiceQuestions[parseInt(index, 10)])}
                                index={activePracticesQuesIndexVal + 1}
                                question={practiceQuestions[parseInt(index, 10)]}
                                totalLength={practiceQuestions.length}/>
                        </div>
                        <div className="solution-right-part">
                            <CNavLink
                                to={'/practice/subject/' + selectedSubjectTopic.subject + '/topic/' + selectedSubjectTopic.topic}>
                                <div className="bar-icon">
                                    <i className="fa fa-bars"/>
                                </div>
                            </CNavLink>
                            <Button disabled={parseInt(index, 10) === 0}
                                    onClick={() => selectQuestion(practiceQuestions[activePracticesQuesIndexVal - 1], activePracticesQuesIndexVal - 1)}>
                                <i className="fa fa-chevron-left"/>{t('previous')}
                            </Button>
                            <Button disabled={parseInt(index, 10) === (practiceQuestions.length - 1)}
                                    onClick={() => selectQuestion(practiceQuestions[activePracticesQuesIndexVal + 1], activePracticesQuesIndexVal + 1)}>
                                {t('next')}<i className="fa fa-chevron-right"/>
                            </Button>
                        </div>

                    </div> :
                    (!(practiceQuestions && practiceQuestions.length) && loadingStatus === 'SUCCESS' ?
                        <div className="questions-yet">
                            <h2>{quesMessage}</h2>
                            <Button onClick={resetFilter}>{t('reset_filter')}</Button>
                        </div> : <PracticeQuestionChainLoader/>)

            }
        </>

    );
}

export default PracticeQuestionView;
