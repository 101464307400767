import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import '../CoursesList/coursesList.scss';
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import ClearIcon from '@material-ui/icons/Clear';
import CheckoutModal from '../PaymentSection/CheckoutModal';

/**
 * Test series card
 * @param props
 * @returns {*}
 * @constructor
 */
function CourseModal(props) {
    const { testData, handleClose, handleGoToTestClick, modalFooterSrc, courseAdded, handleViewClick, expireDays} = props;
    const t = useTranslations();
    const [checkout, setCheckout] = useState(false);
    const [courseExpired, setCourseExpired] = useState(false);
    
    let imageJpg;
    if (testData.featured) {
        imageJpg = testData.featured.thumbnail.jpg;
    }

    const navigateToCheckout = () =>{
        setCheckout(!checkout);
    }
    useEffect(()=>{
        if (expireDays === 0) {
            setCourseExpired(true)
        } else{
            setCourseExpired(false)
        }
    },[expireDays])

    return (
        !checkout?
        <div className='card-modal-box'>
                <div className='card-modal-header'>
                <div style={{width:'100%',justifyContent:'flex-end',display:'flex',paddingRight:'10px'}}>
                    <ClearIcon fontSize='small' style={{color:'white'}} onClick={handleClose}/>               
                </div>
                <img
                    src={imageJpg} alt={imageJpg} onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/img/test-series-error.jpg";
                }}/>
                <p>{testData.name}</p>
                </div>
                <div className='card-modal-content'>
                {testData.keypoints.length ? (
                    <div>
                        <p>{t("Course_Overview")}</p>
                        <ul>
                        {testData.keypoints.map(
                            (data, index) =>
                                data && (
                                    <li>{data}</li>
                                )
                        )}
                        </ul>
                    </div>
                ): (
                    <></>
                )}
                {testData.description ? (
                    <div>
                        <p>{t("Description")}</p>
                        <div dangerouslySetInnerHTML={{ __html: `<html><head><meta name="viewport" content="width=device-width, initial-scale=1.0"></head><body>${testData.description ? testData.description : ""}</body></html>` }} />
                    </div>
                ): (
                    <></>
                )}
                </div>
                {modalFooterSrc === "dashboard" ? (
                <div className='card-modal-footer'>
                {testData.pricing === "FREE" ? (
                testData.isAddedCourse ? (
                    <div className='free-div-modal'>
                        <div className="card-btn-new">
                            <Button onClick={handleViewClick} disabled={courseExpired}>
                                {t('VIEW_COURSE')}
                            </Button>
                        </div>
                    </div>
                ) : courseAdded ? (
                    <div className='free-div-modal'>
                        <div className="card-btn-new">
                            <Button onClick={handleViewClick} disabled={courseExpired}>
                                {t('VIEW_COURSE')}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className='free-div-modal'>
                        <div className="card-btn-new">
                            <Button onClick={handleGoToTestClick} disabled={courseExpired}>
                                {t('ADD_COURSE')}
                            </Button>
                        </div>
                    </div>
                )
                ) : testData.pricing === "PAID" &&
                testData.isAddedCourse ? (
                    <div className='free-div-modal'>
                        <div className="card-btn-new">
                            <Button onClick={handleViewClick} disabled={courseExpired}>
                                {t('VIEW_COURSE')}
                            </Button>
                        </div>
                    </div>
                ) : (
                    testData.isDiscount ? (
                        <div className='discount-div-modal'>
                            <div>
                            <p style={{textDecoration: 'line-through',marginBottom:0,fontWeight:'bold',color:'grey'}}>₹{testData.price}</p>
                            <p style={{fontWeight:'bold',fontSize:'14px',marginTop:0}}>₹{testData.discountedPrice}</p>
                            </div>
                            <div>
                            <div className="card-btn-modal">
                            <Button onClick={navigateToCheckout}>
                                {t('BUY_NOW')}
                            </Button>
                            </div>
                            </div>
                        </div>
                    ) : (
                        <div className='discount-div-modal'>
                            <div>
                            <p style={{fontWeight:'bold'}}>₹{testData.price}</p>
                            </div>
                            <div>
                            <div className="card-btn-modal">
                            <Button onClick={navigateToCheckout}>
                                {t('BUY_NOW')}
                            </Button>
                            </div>
                            </div>
                        </div>
                    )
                )}
                </div>
                ):<div className='card-modal-footer'>
                <div className='free-div-modal'>
                        <div className="card-btn-new">
                            <Button onClick={handleClose}>
                            {t('Back')}
                            </Button>
                        </div>
                    </div>    
                </div>}
        </div>
        :
        <CheckoutModal activeCourse={testData} navigateToCheckout={navigateToCheckout}/>
    );
}

export default CourseModal;
