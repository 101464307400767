import axios from "./axiosConfig";
export function getTransactionData(transactionId) {
  return axios.get('/api/v1/transactions/' + transactionId).then(response => {
    // handle success
    return response;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function getTransactionsList() {
  return axios.get('/api/v1/transactions-per-user').then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}

/**
 * initiate internal transaction token & razorpay orderId
 * @param data
 * @returns {response}
 */
export function getRazorOrderId(courseId, code) {
  let platform = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "mobile";
  return axios.get("/api/v1/initiate/payment/".concat(platform, "/").concat(courseId, "?code=").concat(code)).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
;
export function checkTransectionStatus(data) {
  let platform = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "mobile";
  return axios.post("/api/v1/check-response/payment/".concat(platform), data).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
;