import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useTranslations,
  useStyles
} from "examsbook-react-sdk/custom-hooks";
import { ReactComponent as StartQuiz } from "../../../assets/images/start-quiz.svg";
import { ReactComponent as StartMock } from "../../../assets/images/start-mock.svg";
import { useHistory } from "react-router-dom";
import { getCourseSectionApiData } from "examsbook-react-sdk/actions/getSelfLearningAction";
import { useDispatch } from "react-redux";
import OverlayButton from "../../../constants/OverlayButton";
import { useTheme } from "../../../constants/themeProvider";
import moment from 'moment';
import CommonButton from "../../../constants/CommonButton";

/**
 * Instructions block.
 * @param props
 * @returns {*}
 * @constructor
 */
function InstructionBlock(props) {
  let dispatch = useDispatch();
  const [quizData, setQuizData] = useState(null);
  const [endDate, setEndDate] = useState("");
  const [mySectionData, setMySectionData] = useState([]);
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(styles)();
  const t = useTranslations();
  let course_id = localStorage.getItem("courseId");
  const [testDisabled, setTestDisabled] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [quizTimeDifference, setQuizTimeDifference] = useState('');

  const { startQuiz, quiz, quizAttemptForResult, unlockEvaluationTest, evaluationUnlockPercentage, certificateUnlocked, quizEndDate, startButtonClicked, selectedQuizCard } = props;
  const currentDateTime = new Date();
  // const isQuizCheck = quizEndDate < moment(currentDateTime).format("DD-MM-YYYY HH:mm:ss");
  const isQuizCheck = moment(quizEndDate, "DD-MM-YYYY HH:mm:ss").isBefore(currentDateTime);

  const convertedQuizEndDate = moment(quizEndDate, "DD-MM-YYYY HH:mm:ss");
  const convertedQuizCurrentDateTime =  moment(currentDateTime, "DD-MM-YYYY HH:mm:ss");
  const difference = moment.duration(convertedQuizEndDate.diff(convertedQuizCurrentDateTime));
  const formattedDifference = `${difference.days()} days, ${difference.hours()} hours, ${difference.minutes()} minutes, ${difference.seconds()} seconds`;

  const fetchData = async () => {
    dispatch(getCourseSectionApiData(course_id)).then((response) => {
      try {
        setMySectionData(response.evaluationTest[0]);
      } catch (error) {
        console.log('Error -', error.message);
      }
    });
  };

  const isTestDisabled = unlockEvaluationTest === false || mySectionData?.quizAttempts >= 3 || certificateUnlocked === true;

  useEffect(() => {
    if (course_id !== undefined) {
      fetchData();
    }
  }, [quiz]);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
      setModalVisible(false);
  };

  const Modal = ({ showModal, closeModal }) => {
      return (
          <div className={classes.modalBg + ` ${showModal ? 'show' : ''}`} onClick={closeModal} >
              <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
                  <div className={classes.heading}>
                      <h2 className={classes.headingText}>{t('Your quiz is expired!')}</h2>
                      <div className={classes.okAlign}>
                          <CommonButton onClick={() => history.push('/course-list')} classStyleHeading={classes.okBtn} title={t('Ok')}/>
                      </div>
                  </div>
              </div>
          </div>
      );
  };

  useEffect(() => {
    if(startButtonClicked && isQuizCheck){
      openModal();
    }
    if(!isQuizCheck && startButtonClicked) {
      setQuizTimeDifference(formattedDifference);
      history.push('/quiz/' + selectedQuizCard.mode + '/' + selectedQuizCard.slug);
    }
  },[isQuizCheck, startButtonClicked])

  useEffect(() => {
    if (quiz?._id) {
      axios
        .get(`/api/v1/quizDataForQuizId?quizId=${quiz?._id}`)
        .then((response) => {
          setQuizData(response.data.data);

          const endDate = response.data.data.endDate;

          const formattedEndDate = new Date(endDate)
            .toLocaleString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })
            .replace(/\//g, "-");
          setEndDate(formattedEndDate);
  
          // Compare endDate with current date
          const endDateTime = new Date(endDate);
          if (endDateTime < currentDateTime || isTestDisabled) {
            setTestDisabled(true);
          } else {
            setTestDisabled(false);
          }
        })

        .catch((error) => {
          console.error("Error fetching quiz data:", error);
        });
    }
  }, [quiz?._id, isTestDisabled]);

  useEffect(() => {
    if (quiz?.endDate) {
      const endDate = new Date(quiz?.endDate)
        .toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
        .replace(/\//g, "-");
      setEndDate(endDate);

    // Compare endDate with current date
    const endDateTime = new Date(quiz?.endDate);
    const currentDateTime = new Date();
    if (endDateTime < currentDateTime) {
      setTestDisabled(true);
    } else {
      setTestDisabled(false);
    }
    }
  }, [quiz.endDate]);

  const showResult = ()=>{
    history.push('/reports',  {courseId:course_id, slug:quiz?.slug, pageType: "Instruction"  })
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.instructionText}>{t("General Instructions")}</div>
      <div className={classes.upperBlock}>
        <div>
          <ul className={classes.blockInstruction}>
            <li>
              {t("YOU_WILL_GET_ONLY")} {t("THREE_ATTEMPTS")}{" "}
              {t("PLEASE_ENSURE_THAT_YOU_HAVE_RELIABLE_INTERNET")}
            </li>
            <li>{t("EVALUTE_ALL_OPTIONS_CAREFULLY")}</li>
            <li>
              {t("THIS_TEST_HAS")} {t("NEGATIVE_MARKING")}{" "}
              {t("YOU_WILL_SEE_NEGATIVE_POSITIVE_NEGATIVE_MARK")}
              {"."}
            </li>
            <li style={{color: '#d71a20', fontWeight: '400'}}>
              {t("If you change the tab, return to a previous page or minimize the browser during the test, it will be automatically submitted")}
            </li>
            <li style={{color: '#d71a20', fontWeight: '400'}}>
              {t("Once the certificate is unlocked, you cannot attempt the evaluation test again")}
            </li>
          </ul>
        </div>
        <div>
          {quiz.mode === "mock-test" ? (
            <StartMock alt="start-mock" className={classes.showImage} />
          ) : (
            <StartQuiz alt="start-quiz" className={classes.showImage} />
          )}
        </div>
      </div>

      {quiz.mode === "mock-test" ? (
        <>
          <div className={classes.bottomBlock}>
            <div className={classes.questionDuration}>
              <div className={classes.quizBlock}>
                <div className={classes.dataCount} style={{color: theme?.secondaryColor}}>{quiz.total}</div>
                <div className={classes.countTitle}>{t("Question")}</div>
              </div>
              <div className={classes.marksBlock}>
                <div className={classes.dataCount} style={{color: theme?.secondaryColor}}>
                  {quiz.duration}
                  {":00"} {t("MIN")}
                </div>
                <div className={classes.countTitle}>{t("Duration")}</div>
              </div>
            </div>
            <div className={classes.marksExpiry}>
              <div className={classes.expiryBlock}>
                <div className={classes.dataCount} style={{color: theme?.secondaryColor}}>{quiz.mark}</div>
                <div className={classes.countTitle}>{t("total_marks")}</div>
              </div>
              <div className={classes.timeBlock}>
                <div className={classes.dataCount} style={{color: theme?.secondaryColor}}>{endDate?.split(',')[0]} {endDate?.split(',')[1]?.slice(0, -3)}</div>
                <div className={classes.countTitle}>{t("expires_on")}</div>
              </div>
            </div>
          </div>
          <div className={classes.buttonBlock}>
            <OverlayButton onClick={startQuiz} disabled={testDisabled} title={t("START_TEST")} classStyleHeading={!testDisabled ? classes.startBtn : classes.startBtn + " " + classes.buttonDisabled}/>
            {quizAttemptForResult != null && quizAttemptForResult > 0 && (
              <OverlayButton onClick={showResult} title={t("view_result")} classStyleHeading={classes.startBtn}/>
            )}
          </div>
          {evaluationUnlockPercentage && <div className={classes.evaluationMessage}>
            <span>{t('Complete minimum')} <b> {evaluationUnlockPercentage}% </b> {t('of your course to unlock Evaluation Test')}</span>
          </div>}
        </>
      ) : (
        <>
          <div className={classes.bottomBlock}>
            <div className={classes.questionDuration} style={{display: 'flex'}}>
              <div className={classes.quizBlock}>
                <div className={classes.dataCount} style={{color: theme?.secondaryColor}}>{quiz.totalQuestions}</div>
                <div className={classes.countTitle}>{t("Question")}</div>
              </div>
              <div className={classes.timeBlock} style={{borderTop: '0px'}}>
                <div className={classes.dataCount} style={{color: theme?.secondaryColor}}>
                  {quiz.duration}
                  {":00"} {t("MIN")}
                </div>
                <div className={classes.countTitle}>{t("Duration")}</div>
              </div>
            </div>
          </div>
          <div className={classes.buttonBlock}>
              <OverlayButton onClick={startQuiz} title={t("START_TEST")} classStyleHeading={classes.startBtn}/>
          </div>
        </>
      )}
      {
          isModalVisible && <Modal
              open={isModalVisible}
              onClose={closeModal}
          >
              {Modal}
          </Modal>
      }
    </div>
  );
}

const styles = (theme) => ({
  wrapper: {
    border: "1px solid #E3E3E3",
    boxShadow: "2px 4px 8px 0px rgba(198, 198, 198, 0.25)",
    padding: "35px 16px 40px",
    display: "block",
    "@media (min-width:280px) and (max-width: 767px)": {
      padding: "15px 16px 0px 16px",
      height: "100%",
    },
  },
  questionDuration: {
    display: "flex",
    "@media (max-width: 767px)": {
      display: "block",
    },
    "@media (max-width: 960px)": {
      borderTop: "1px solid #E3E3E3"
    },
  },
  marksExpiry: {
    display: "flex",
    "@media (min-width:280px) and (max-width: 767px)": {
      display: "block",
    },
    "@media (max-width: 960px)": {
      borderTop: "1px solid #E3E3E3"
    },
  },
  upperBlock: {
    display: "flex",
    borderBottom: "1px solid #E3E3E3",
    "@media (max-width: 960px)": {
      borderBottom: "none"
    },
  },
  instructionText: {
    color: "#0C3450",
    fontSize: '1.5rem',
    fontWeight: 700,
    "@media (max-width: 767px)": {
      fontSize: '1.2rem'
    },
  },
  blockInstruction: {
    color: "rgba(0, 0, 0, 0.50)",
    fontSize: '1.2rem',
    fontWeight: 300,
    padding: "0px 20px",
    "& li": {
      paddingBottom: "15px",
    },
    "@media (max-width: 767px)": {
      fontSize: '1rem'
    },
  },
  showImage: {
    "@media (max-width:767px)": {
      display: "none",
    },
    "@media (min-width:960px) and (max-width: 1230px)": {
      display: "none",
    },
  },
  bottomBlock: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 8,
  },
  quizBlock: {
    padding: "20px",
    borderRight: "1px solid #E3E3E3",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  marksBlock: {
    padding: "20px",
    borderRight: "1px solid #E3E3E3",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    "@media (max-width: 767px)": {
      borderTop: "1px solid #E3E3E3"
    }
  },
  expiryBlock: {
    padding: "20px",
    borderRight: "1px solid #E3E3E3",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    "@media (max-width: 767px)": {
      borderRight: "0px",
    },
  },
  timeBlock: {
    padding: "20px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    "@media (max-width: 767px)": {
      borderTop: "1px solid #E3E3E3"
    }
  },
  dataCount: {
    textAlign: "center",
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: "25px",
    paddingBottom: "5px",
    "@media (max-width: 767px)": {
      fontSize: '1.2rem'
    },
  },
  countTitle: {
    color: "rgba(0, 0, 0, 0.50)",
    textAlign: "center",
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: "22px",
  },
  startBtn: {
    "&:hover": {
      background: "#0c3450e0 !important",
    },
    width: "320px",
    height: "50px",
    textTransform: 'capitalize',
    fontSize: '1.5rem',
    fontWeight: 600,
    cursor: "pointer",
    "@media (min-width:280px) and (max-width: 767px)": {
      width: 140,
      fontSize: 16,
    },
    "@media (min-width:960px) and (max-width: 1230px)": {
      width: 140,
      fontSize: 16,
    },
  },
  buttonBlock: {
    gap: '4px',
    textTransform: 'capitalize',
    display: "flex",
    justifyContent: "center",
    "@media (min-width:280px) and (max-width: 767px)": {
      paddingBottom: 30,
    },
    "@media (min-width:768px) and (max-width: 1024px)": {
      paddingTop: 30,
    },
    "@media (min-width:1231px) and (max-width: 1650px)": {
      paddingTop: 10,
    },
  },
  selectLanguageContainer: {
    display: "flex",
    alignItems: "center",
    position: "fixed",
    bottom: 0,
    left: 0,
    margin: "auto",
    right: 0,
    width: "70%",
    padding: "10px 0",
    borderTop: "1px solid #ddd",
    justifyContent: "space-between",
    background: "#fff",
    "@media (min-width:768px) and (max-width: 1200px)": {
      width: "90%",
    },
    "& button": {
      position: "static",
      display: "flex",
      background: "#5F4BAB",
      color: "#fff",
      padding: "10px 20px",
      fontSize: 14,
      outline: "none",
      cursor: "pointer",
      boxShadow: "none",
      border: 0,
      borderRadius: 5,
      justifyContent: "center",
      letterSpacing: "2px",
      alignItems: "center",
      "& > svg": {
        marginLeft: 4,
        width: 20,
        height: 20,
        transform: "rotate(180deg)",
        color: "#fff",
      },
    },
  },
  instructionsMobileView: {
    display: "none",
    "@media (max-width: 767px)": {
      display: "flex",
      flexDirection: "column",
      "& h2": {
        textAlign: "center",
        width: "100%",
        fontSize: 16,
        color: "#000",
        margin: "25px 0",
      },
      "& ul": {
        listStyleType: "disc",
        padding: "0 25px",
        marginTop: 3,
        "& li": {
          fontSize: 13,
          color: "#000",
          marginBottom: 20,
        },
      },
      "& button": {
        position: "fixed",
        bottom: 20,
        width: "90%",
        margin: "auto",
        left: 0,
        right: 0,
        display: "flex",
        background: "#5F4BAB",
        textTransform: "uppercase",
        color: "#fff",
        padding: "16px 0",
        fontSize: 14,
        outline: "none",
        minHeight: "52px",
        boxShadow: "none",
        border: 0,
        borderRadius: 5,
        justifyContent: "center",
        letterSpacing: "2px",
        alignItems: "center",
        "& > svg": {
          marginLeft: 4,
          width: 20,
          height: 20,
          transform: "rotate(180deg)",
          color: "#fff",
        },
      },
    },
  },
  mobileBackHeader: {
    "& svg": {
      color: "#757575",
      width: 26,
      height: 26,
    },
  },
  instructionsBackBtn: {
    float: "left",
    width: "100%",
    lineHeight: "45px",
    textAlign: "left",
    "& a": {
      display: "inline-block",
      textDecoration: "none",
      color: "#039be5",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
      "& img": {
        width: 20,
        display: "inline-block",
        verticalAlign: "text-bottom",
        marginRight: 3,
      },
    },
  },
  questionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    borderBottom: "1px solid #ddd",
    padding: "30px 0",
    marginBottom: 30,
  },
  quesTag: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      width: 26,
      height: 26,
      marginRight: 10,
      color: "#5F4BAB",
      opacity: 0.5,
      "@media (min-width: 768px)": {
        opacity: 1,
      },
    },
    "& div": {
      "& span": {
        color: "#000",
        fontSize: 14,
        fontWeight: "bold",
        "@media (min-width: 768px)": {
          fontSize: 16,
        },
      },
      "& h4": {
        color: "rgba(0,0,0,0.4)",
        fontWeight: 400,
        fontSize: 14,
        margin: 0,
        paddingTop: 5,
        "@media (min-width: 768px)": {
          fontSize: 16,
        },
      },
    },
  },
  languageswitcher: {
    "& h4": {
      margin: 0,
      fontWeight: "bold",
      color: "rgba(0,0,0,0.4)",
      fontSize: 12,
      marginBottom: 15,
      "@media (min-width: 768px)": {
        fontSize: 14,
      },
      "& + div": {
        flexDirection: "row",
        "& label": {
          marginRight: 36,
          "& span": {
            fontSize: 14,
            "@media (min-width: 768px)": {
              fontSize: 14,
            },
            "& svg": {
              width: 22,
              height: 22,
              minWidth: 22,
            },
          },
        },
        "& .MuiRadio-colorSecondary.Mui-checked": {
          color: "#5F4BAB",
        },
      },
    },
  },
  languageswitcherWeb: {
    display: "flex",
    alignItems: "center",
    "& h4": {
      marginBottom: 0,
      marginRight: 20,
    },
  },
  buttonDisabled: {
    opacity: "0.4",
    cursor: "not-allowed",
  },
  evaluationMessage: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '20px',
    color: '#d71a20',
    fontSize: '1.3rem',
    "@media (max-width: 767px)": {
      fontSize: '1rem'
    },
  },
  modalBg: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9999'
},   
modalContent: {
    width: '460px',
    margin: 'auto',
    marginTop: '40vh',
    '@media (max-width:439px)': {
        width: '280px'
    }
},
heading: {
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#fff',
    padding:'20px 40px',
    borderRadius: '6px'
},
headingText: {
    fontWeight:'normal',
    color: 'black'
},
okBtn: {
    fontWeight: '500',
    padding:'10px 30px',
    fontSize:'15px',
    borderRadius: '4px',
    margin:'15px',
    backgroundColor:'#0C3450',
    color:'white',
    cursor:'pointer',
    '&:hover': {
        backgroundColor: '#0c3450e0 !important',
    },
},
okAlign: {
    display:'flex',
    flexDirection:'row'
},
});
export default InstructionBlock;
