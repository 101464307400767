import status from "../constants/actionStatus";
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    recentCourse: {}
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case "ADD_TO_RECENT_COURSE_REQUEST":
      return {
        status: status.PENDING,
        recentCourse: {}
      };
    case "ADD_TO_RECENT_COURSE_SUCCESS":
      return {
        status: status.SUCCESS,
        recentCourse: action.payload
      };
    case "ADD_TO_RECENT_COURSE_FAILURE":
      return {
        status: status.ERROR,
        recentCourse: {}
      };
    default:
      return state;
  }
});