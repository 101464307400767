/**
 * function to filter out quiz and exams to show.
 * this is being called at intial load and state change.
 * @param mainQuizList
 * @param relatedExams
 * @param filteredExams
 */
export default function filterQuizAndGroup(mainQuizList) {
  let relatedExams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let filteredExams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  let hideAttempted = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  //only show related exams to main exam. so we are filtering out.
  let allowedExams = [];
  if (relatedExams && relatedExams.length > 0) {
    relatedExams.forEach(exam => {
      if (filteredExams && filteredExams.length > 0) {
        if (filteredExams.indexOf(exam._id) === -1) {
          allowedExams.push(exam._id);
        }
      } else {
        allowedExams.push(exam._id);
      }
    });
  }
  //find out list of quizes which are only needed in case of filter changes.
  if (mainQuizList) {
    let quizList = [];
    if (filteredExams && filteredExams.length > 0) {
      quizList = mainQuizList.filter(quiz => {
        return quiz.exams.some(exam => {
          return allowedExams.indexOf(exam._id) > -1;
        });
      });
    } else {
      quizList = mainQuizList;
    }
    //group by subjects.
    let quizData = dataGroupBy(quizList, 'subjects', hideAttempted);
    return {
      quizData: quizData,
      quizKeysData: Object.keys(quizData)
    };
  }
}

/**
 * strange function. we may need to reactor it at some moment.
 * @param data
 * @param key
 * @param hideAttempted
 * @returns {*}
 */
function dataGroupBy(data, key) {
  let hideAttempted = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return data.reduce((innerData, innerKey) => {
    if (!innerKey[key] || innerKey[key].length !== 1) {
      if (innerKey[key].length) {
        if (!hideAttempted) {
          (innerData["Full Tests"] = innerData["Full Tests"] || []).push(innerKey);
        } else {
          if (!innerKey.attempted) {
            (innerData["Full Tests"] = innerData["Full Tests"] || []).push(innerKey);
          }
        }
      } else {
        if (!hideAttempted) {
          (innerData.uncategorized = innerData.uncategorized || []).push(innerKey);
        } else {
          if (!innerKey.attempted) {
            (innerData.uncategorized = innerData.uncategorized || []).push(innerKey);
          }
        }
      }
    } else {
      if (!hideAttempted) {
        (innerData[innerKey[key][0].name] = innerData[innerKey[key][0].name] || []).push(innerKey);
      } else {
        if (!innerKey.attempted) {
          (innerData[innerKey[key][0].name] = innerData[innerKey[key][0].name] || []).push(innerKey);
        }
      }
    }
    return innerData;
  }, {});
}