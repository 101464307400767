import React, { useEffect } from "react";
import ReactPlayerFile from "react-player/file";
import ReactPlayerYoutube from "react-player/youtube";
import Box from "@material-ui/core/Box";
import Lottie from "lottie-react";
import loadingBlue from "./loading-blue-circle.json";
import styles from "./styles";

const VideoPlayer = (props) => {
  const {
    type,
    url,
    playing,
    onReady,
    onPause,
    onEnded,
    controls = true,
    isVideoLoaded,
    videoPlayerRef,
    handlePlaying,
  } = props;
  
    const ReactPlayer = {
    file: ReactPlayerFile,
    url: ReactPlayerYoutube,
  }[type];

  // pause the video when the tab changes or browser minimises.
  useEffect(() => {
    const handleVisibilityChange = () => {
      const internalPlayer = videoPlayerRef.current?.getInternalPlayer();
      if (type === 'url' && document.hidden && internalPlayer && typeof internalPlayer.pauseVideo === 'function') {
        internalPlayer.pauseVideo();
        handlePlaying(false);
      } else if (type === 'file' && document.hidden) {
        handlePlaying(false)
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('blur', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('blur', handleVisibilityChange);
    };
  }, [videoPlayerRef]);

  return (
    <Box sx={styles.container} onClick={handlePlaying}>
    {!isVideoLoaded && (
      <Box sx={styles.loaderContainer}>
        <Lottie animationData={loadingBlue} loop autoplay />
      </Box>
    )}
    <Box sx={{ display: !isVideoLoaded && "none" }}>
      {/* Transparent overlay to capture clicks */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "92%",
          zIndex: 1,
          cursor: "pointer",
          background: "transparent"
        }}
        onClick={handlePlaying}
      />
      
      <ReactPlayer
        url={url}
        config={{
          youtube: {
            playerVars: {
              disablekb: 1,
            },
          },
        }}
        width="100%"
        height="100%"
        ref={videoPlayerRef}
        controls={controls}
        playing={playing}
        onReady={onReady}
        onPause={onPause}
        onEnded={onEnded}
        style={styles.video}
        playsinline={true}
        stopOnUnmount={true}
        preload="auto"
      />
    </Box>
  </Box>
  );
};

export default VideoPlayer;
