import React from 'react';
import Button from "@material-ui/core/Button/Button";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import './ButtonGroup.scss';
import QuizResultPdf from '../../pages/CreatePdf/QuizResultPdf';

/**
 * Results actions.
 * @param props
 * @returns {*}
 * @constructor
 */
const ResultsButtonGroup = props => {
    const t = useTranslations();
    const { quizResults, downloadPdf, quizId } = props;
    const headerData = quizResults && quizResults.data && quizResults.data.headerData ?
        quizResults.data.headerData : {};

    //change location.
    function changeLocation(location) {
        window.open(location);
    }

    return (
        <div className="continue-button">
            <Button onClick={() => changeLocation('/quiz/view/' + headerData.slug)} className="view-solution">
                {t('view_solution')}
            </Button>
            {downloadPdf &&
            <QuizResultPdf quizId={quizId}/>
            }
        </div>
    );
};

export default ResultsButtonGroup;
