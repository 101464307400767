import React, { useEffect, useState } from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import { LinearProgressWithLabel } from '../../components/MyCourseCard/MyCoursesCard';
import { makeStyles } from '@material-ui/core/styles';
import { reactGaEvent, recentCourseListAction } from 'examsbook-react-sdk/actions';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ErrorImage from "../../../src/assets/images/test-series-error.jpg";
import { getDefaultSupportDataAction, getSupportAction } from 'examsbook-react-sdk/actions/getSupportAction';
import { useTranslations } from 'examsbook-react-sdk/custom-hooks';
import { useTheme } from '../../constants/themeProvider';

const useStyles = makeStyles(theme => ({
    wrapper: {
        border: '1px solid rgba(12, 52, 80, 0.20)',
        background: '#FFF',
        margin: '0px 16px',
        borderRadius: '6px'
    },
    fragment: {
        padding: '16px',
        borderBottom: '1px solid rgba(12, 52, 80, 0.10)',
    },
    title: {
        color: '#0C3450',
        fontSize: '2rem',
        fontWeight: 600,
        [theme.breakpoints.down('325')]: {
            fontSize: '24px',
        },
    },
    subtitle: {
        color: 'rgba(0, 0, 0, 0.50)',
        fontSize: '1.2rem',
        fontWeight: 400,
        [theme.breakpoints.down('600')]: {
            fontSize: '1rem'
        },
    },
    mainDiv: {
        padding: '24px 16px 30px 16px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('600')]: {
            padding: '20px 16px',
        },
    },
    heading: {
        color: '#0C3450',
        fontSize: '18px',
        fontWeight: 500,
        textTransform: 'capitalize',
        [theme.breakpoints.up('1600')]: {
            fontSize: '26px',
        },
        [theme.breakpoints.down('900')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.down('600')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.between('900', '1025')]: {
            fontSize: '16px',
            marginLeft: '16px'
        },
    },
    progressBarAlign: {
        [theme.breakpoints.between('900', '960')]: {
            marginLeft: '16px'
        },
    },
    noCourseFound: {
        color: '#00000080',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.2rem',
        textTransform: 'capitalize',
        [theme.breakpoints.down('600')]: {
            fontSize: '1rem',
        },
    },
    subHeading: {
        color: 'rgba(0, 0, 0, 0.50)',
        fontSize: '16px',
        fontWeight: 275,
        [theme.breakpoints.up('1600')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.down('900')]: {
            fontSize: '13px',
        },
        [theme.breakpoints.down('600')]: {
            fontSize: '10px',
        },
        [theme.breakpoints.between('900', '1025')]: {
            fontSize: '12px',
            marginLeft: '16px'
        },
    },
    continueBtn: {
        borderRadius: '22px',
        border: '2px solid',
        textTransform: 'capitalize',
        fontSize: '18px',
        fontWeight: 600,
        padding: '4px 20px',
        [theme.breakpoints.up('1400')]: {
            padding: '4px 40px',
            minWidth: 'max-content'
        },
        [theme.breakpoints.between('900', '1200')]: {
            fontSize: '14px',
            padding: '4px 12px'
        },
        [theme.breakpoints.down('900')]: {
            fontSize: '14px',
            minWidth: 'max-content'
        },
        [theme.breakpoints.down('600')]: {
            fontSize: '12px',
            padding: '4px',
            minWidth: 'max-content'
        }
    },
    courseContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 28,
        [theme.breakpoints.down('900')]: {
            justifyContent: 'space-between',
        },
        [theme.breakpoints.between('1331', '1599')]: {
            width: 'calc(100% + 16px)'
        },

    },
    continueGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    dataGrid: {
        marginLeft: '-30px',
        [theme.breakpoints.up('1200')]: {
            marginLeft: '0px',
        },
        [theme.breakpoints.between('900', '1316')]: {
            marginLeft: '0px',
        },
        [theme.breakpoints.down('900')]: {
            marginLeft: '0px',
        },
        [theme.breakpoints.down('600')]: {
            marginLeft: '-10px',
        },
        [theme.breakpoints.down('350')]: {
            marginLeft: '-30px',
        },
    },
    dataRoot: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.between('900', '1200')]: {
            marginLeft: 'auto',
        },
        [theme.breakpoints.down('900')]: {
            marginLeft: 'auto',
            width: '70%',
            marginRight: 'auto',
        },
        [theme.breakpoints.down('600')]: {
            marginLeft: 'auto',
            width: '100%',
            marginRight: 'auto',
        },
    },
    imgData: {
        width: '150px',
        height: '92px',
        objectFit: 'contain',
        maxWidth: '-webkit-fill-available',
        background: '#f1f1f1',
        borderRadius: "4px",
        [theme.breakpoints.down('650')]: {
            width: '100%',
            objectFit:'contain',
            height: '80px',
        },
    },
    disableContinueBtn: {
        opacity: '0.5'
    }
}));

function CourseCard(props) {
    const classes = useStyles();
    const { dynamicLogos, isDynamicLogo } = props;
    var history = useHistory();
    const t = useTranslations();
    const [recentCourseExpiry, setRecentCourseExpiry] = useState(false)
    var recentCourseData = props?.item;
    const [showFullDescription, setShowFullDescription] = useState(false);
    const theme = useTheme();

    useEffect(()=>{
        if (props?.item?.endDate) {
            let currentDate = new Date()
            let endDate = new Date(props?.item?.endDate)
            if (currentDate > endDate) {
                setRecentCourseExpiry(true)
            }
          }
    },[])

    function handleGoToTestClick() {
        localStorage.setItem('dataKey', recentCourseData.courseId);
        window.dispatchEvent(new Event('storage'));
        reactGaEvent("Course Material", "Go To Course");
        localStorage.setItem('courseName', recentCourseData?.name);
        localStorage.setItem('courseDescription', recentCourseData?.description);
        localStorage.setItem('courseId', recentCourseData?._id);
        history.push('/course-material/content', { courseId: recentCourseData.courseId, subjectId: '', topicId: '', subjectDetail: recentCourseData, subjectName: '', topicName: '' });
    }

    return (
        <Grid container spacing={2} className={classes.courseContainer}>
            <Grid item xs={3}>
                <img src={recentCourseData?.featured ? recentCourseData?.formattedData?.thumbnail?.jpg : isDynamicLogo && isDynamicLogo !== 'undefined' && dynamicLogos} alt="Course" className={classes.imgData} />
            </Grid>
            <Grid item xs={7} className={classes.dataGrid}>
                <Grid container spacing={2} className={classes.dataRoot}>
                    <Grid item md={8} xs={12}>
                        <div className={classes.heading}>{recentCourseData?.name}</div>
                        <p className='card-description card-description-align' dangerouslySetInnerHTML={{ __html: recentCourseData?.description }}></p>
                    </Grid>
                    <Grid item md={4} xs={12} className={classes.progressBarAlign}>
                        <LinearProgressWithLabel value={recentCourseData?.progressPercent !== undefined ? recentCourseData?.progressPercent : 0} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2} className={classes.continueGrid} >
                <Button onClick={handleGoToTestClick} disabled={recentCourseExpiry} className={recentCourseExpiry ? classes.continueBtn + ' ' + classes.disableContinueBtn: classes.continueBtn} style={{color: theme?.primaryColor}}>{t('Continue')}</Button>
            </Grid>
        </Grid>
    );
}

function CurrentCourse() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const t = useTranslations();
    const user = useSelector((state) => state.user);
    const [supportData, setSupportData] = useState([]);
    const [defaultSupportData, setDefaultSupportData] = useState([]);
    const [dynamicLogos, setDynamicLogos] = useState('');

    useEffect(() => {
        getSettingApiData();
        getDefaultSeoSettingApiData();
    }, []);

    useEffect(() => {
        user?._id && dispatch(recentCourseListAction(user?._id));
    }, [user?._id]);

    let recentCourseData = useSelector((state) => state.recentCourseList);

    const getSettingApiData = async () => {
        try {
            dispatch(getSupportAction()).then((res) => {
                setSupportData(res);
            });
        } catch (error) {
            console.log('Error - ', error.message);
            }
        };

    const getDefaultSeoSettingApiData = async () => {
        try {
            dispatch(getDefaultSupportDataAction()).then((res) => {
            setDefaultSupportData(res);
            });
        } catch (error) {
            console.error(error.message);
        }
    };

        const isDynamicLogo = dynamicLogos.split('/').pop();

        let API_URL = process.env.REACT_APP_DYNAMIC_URL;
        useEffect(() => {
            const imageValue = supportData?.data?.find(subValue => subValue.key === "Course")?.value;
            const defaultValue = defaultSupportData?.data?.['Course Image']?.find(subValue => subValue.key === "Course")?.defaultValue;
            let dynamicLogo = "";
            if (typeof API_URL != "undefined") {
                if (imageValue) {
                dynamicLogo = API_URL +'/img/post/original/' + imageValue;
                } else if (defaultValue) {
                dynamicLogo = API_URL +'/img/post/original/' + defaultValue;
                }
            }
          setDynamicLogos(dynamicLogo) 
        }, [supportData, defaultSupportData])

    return (
        <Box className={classes.wrapper}>
            <Box className={classes.fragment}>
                <div className={classes.title}>{t('COURSES')}</div>
                <div className={classes.subtitle}>{t('Your recent courses')}</div>
            </Box>
            <Box className={classes.mainDiv}>
                {recentCourseData.recentCourseList?.length ?
                    recentCourseData?.recentCourseList.map((item, i) => {
                        return <CourseCard item={item} key={i} dynamicLogos={dynamicLogos} isDynamicLogo={isDynamicLogo}/>
                    }) : <Grid item md={8} xs={12}>
                        <div className={classes.noCourseFound}>{t('No recent courses found!')}</div>
                    </Grid>
                }
            </Box>
        </Box>
    );
}

export default CurrentCourse;
