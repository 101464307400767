import React from 'react';
import TestSeriesList from "../components/TestSeriesList/TestSeriesList";
import { useAnalyticsPageView, useTestSeriesList } from "examsbook-react-sdk/custom-hooks";

/**
 * Test series page
 * @param props
 * @returns {*}
 * @constructor
 */
function TestSeries(props) {
    const { history } = props;
    const { testSeriesList, testSeriesLoading } = useTestSeriesList();
    //analytic
    useAnalyticsPageView();

    function gotoPage() {
        history.push('/tests');
    }

    return (
        <TestSeriesList
            testSeriesList={testSeriesList}
            testSeriesLoading={testSeriesLoading}
            handleTabChange={() => gotoPage()}
            showFooter={true}
        />
    );
}

export default TestSeries;
