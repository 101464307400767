import React from 'react';
import PracticeQuestionProgressStatus from './PracticeQuestionProgressStatus';
import { useTranslations } from "examsbook-react-sdk/custom-hooks";

/**
 * PracticeQuestionProgressStatusTab
 * @param props
 * @returns {*}
 * @constructor
 */
function PracticeQuestionProgressStatusTab(props) {
    const { subjects, aggregateCount, accuracyCount, progressCount } = props;
    const t = useTranslations();
    if (!subjects || subjects.length < 1) {
        return ("");
    } else {
        const reportName = { accuracy: t('ACCURACY'), progress: t('PROGRESS'), aggregate: t('AGGREGATE') };
        const accuracyLabels = { correct: t('correct'), incorrect: t('incorrect'), skipped: t('NOT_ANSWERED') };
        const progressLabels = { correct: t('COMPLETED'), incorrect: t('ONGOING'), skipped: t('UNSEEN') };
        const cast = { firstType: t('Topics'), secondType: t('Completed'), thirdType: t('Subjects') };
        return (
            <div>
                <h4>{t('overall_progress_status')}</h4>
                <div className="overall-progress-status">
                    <PracticeQuestionProgressStatus reportName={reportName.aggregate} Labels={progressLabels}
                                                    cast={cast.thirdType}
                                                    firstCount={aggregateCount.subCompletePercent}
                                                    secondCount={aggregateCount.totalSubjects}
                                                    thirdCount={aggregateCount.completeSubjectsCount}
                                                    fourthCount={aggregateCount.ongoingSubjectsCount}
                                                    fifthCount={aggregateCount.unseenSubjectsCount}/>
                    <PracticeQuestionProgressStatus reportName={reportName.progress} Labels={progressLabels}
                                                    cast={cast.firstType}
                                                    firstCount={progressCount.CompletedPercent}
                                                    secondCount={progressCount.topicCount}
                                                    thirdCount={progressCount.completedTopicsCount}
                                                    fourthCount={progressCount.ongoingTopicsCount}
                                                    fifthCount={progressCount.unseenTopicsCount}/>
                    <PracticeQuestionProgressStatus reportName={reportName.accuracy} Labels={accuracyLabels}
                                                    cast={cast.secondType}
                                                    firstCount={accuracyCount.percentCount}
                                                    secondCount={accuracyCount.answeredCount}
                                                    thirdCount={accuracyCount.correctAnsCount}
                                                    fourthCount={accuracyCount.incorrectAnsCount}
                                                    fifthCount={accuracyCount.notAnsweredCount}/>
                </div>
            </div>
        );
    }
}

export default PracticeQuestionProgressStatusTab;
