import { createSelector } from 'reselect';
const getQuestionList = state => state.questionList.questions;
const getPracticeQuestionList = state => state.practiceQuestions.questionList;
const getSavedQuestionList = state => state.practiceQuestions.savedQuestions;
const getQuestionFilter = state => state.questionFilterValue;
const getPracticeQuesFilter = state => state.practiceQuesFilter;
export const getFilteredQuestions = createSelector([getQuestionList, getQuestionFilter], (questionList, questionFilterValue) => {
  if (questionFilterValue !== 'ALL') {
    return questionList.filter(ques => ques.status === questionFilterValue);
  } else {
    return questionList;
  }
});
export const getFilteredPracticeQuestions = createSelector([getPracticeQuestionList, getPracticeQuesFilter], (practiceQuestionList, practiceQuesFilter) => {
  if (practiceQuesFilter !== 'ALL') {
    return practiceQuestionList.filter(ques => ques.status === practiceQuesFilter);
  } else {
    return practiceQuestionList;
  }
});
export const getFilteredSavedQuestions = createSelector([getSavedQuestionList, getPracticeQuesFilter], (savedQuestionList, practiceQuesFilter) => {
  if (practiceQuesFilter !== 'ALL') {
    return savedQuestionList.filter(ques => ques.status === practiceQuesFilter);
  } else {
    return savedQuestionList;
  }
});