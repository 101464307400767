import React from 'react';
import { makeStyles, LinearProgress } from '@material-ui/core';
import { useAnalyticsPageView, usePracticeModuleData, useSingleSubject } from "examsbook-react-sdk/custom-hooks";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

/**
 * Practice test entry point.
 * @param props
 * @returns {*}
 * @constructor
 */
function TopicItem(props) {
    const { topic, index, subjectId } = props;
    const classes = useStyles();
    let num = index + 1;
    let progressValue = Math.ceil(topic.attempted / topic.total * 100);
    const history = useHistory();

    function goToPracticePage() {
        history.push('/practice/subject/' + subjectId + "/topic/" + topic._id);
    }

    return (
        <li>
            <div className={classes.imageContainer}>
                <span>{("0" + num).slice(-2)}</span>
            </div>
            <div className={classes.testName} onClick={goToPracticePage}>
                <h4>{topic.name}</h4>
                <div>
                    <LinearProgress variant="determinate" value={progressValue}/>
                    <span><i className="fa fa-chevron-right" aria-hidden="true"/></span>
                </div>
                <p>{topic.attempted}/{topic.total} Questions</p>
            </div>
        </li>
    );
}

function PracticeTestTopics(props) {
    useAnalyticsPageView();
    const classes = useStyles();
    const history = useHistory();
    const subjectId = props.match.params.subjectId;
    const subjectData = useSingleSubject(subjectId);
    usePracticeModuleData();
    const { name, topics, questionCount, image, _id } = subjectData;

    function goBack() {
        history.push('/dashboard');
    }

    return (
        <div className={classes.container}>
            <div className="mobile-back-to-test">
                <ArrowBackIcon onClick={goBack}/>
                <span>{name}</span>
            </div>
            <div className={classes.backBtnSection}>
                <img src={image && image.imageUrl ? image.imageUrl : '/categories/thumbnail/Reasoning.webp'}
                     alt=""/>
                <ul>
                    <li>{topics.length} Chapters</li>
                    <li>.</li>
                    <li>{questionCount} Questions</li>
                </ul>
            </div>

            <div className={classes.testListing}>
                <ul>
                    {
                        topics.map((topic, index) => {
                            return (
                                <TopicItem key={topic._id} topic={topic} index={index} subjectId={_id}/>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    container: {},
    backBtnSection: {
        width: 'calc(100% + 30px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20,
        borderBottom: '1px solid #e1e2e1',
        position: 'relative',
        top: -15,
        left: -15,
        right: -15,
        background: '#fff',
        '& svg': {
            fill: '#757575',
            position: 'absolute',
            top: 10,
            left: 10,
            fontSize: 30,
        },
        '& img': {
            width: 80,
            marginBottom: 15,
        },
        '& h4': {
            fontSize: 22,
            margin: 0,
            marginBottom: 15,
            fontWeight: 'bold',
            color: '#000'
        },
        '& ul': {
            display: 'flex',
            margin: 0,
            padding: 0,
            alignItems: 'center',
            listStyle: 'none',
            '& li': {
                color: '#707070',
                fontSize: 14,
                paddingRight: 10,
                fontWeight: 600
            }
        }
    },
    heading: {
        widh: '100%',
        display: 'flex',
        justifyContent: 'center',
        '& h4': {
            fontSize: '24px',
            fontWeight: 'bold',
            textAlign: 'center',
            color: '#000',
            margin: 0,
            marginBottom: 30,
            '& span': {
                color: '#008E76'
            }
        }
    },
    testListing: {
        '& ul': {
            margin: 0,
            padding: 0,
            '& li': {
                display: 'flex',
                alignItems: 'center',
                marginBottom: 30,
            }
        }
    },
    imageContainer: {
        marginRight: 20,
        marginBottom: 15,
        '& span': {
            color: '#00BFA5',
            fontSize: 22,
            fontWeight: 700
        }
    },
    testName: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderBottom: '1px solid #e1e2e1',
        paddingBottom: 15,
        '& > div': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& > div': {
                margin: '10px 0',
                height: 6,
                borderRadius: 20,
                backgroundColor: '#e1e2e1',
                width: '85%',
                '& .MuiLinearProgress-barColorPrimary': {
                    backgroundColor: '#00BFA5'
                },
            },
            '& span': {
                color: '#e1e2e1'
            }
        },
        '& h4': {
            margin: 0,
            color: '#000',
            fontSize: 14,
            fontWeight: 'bold'
        },
        '& p': {
            color: '#00BFA5',
            margin: 0,
            fontSize: 12,
            fontWeight: 'bold'
        }
    }
}));

export default PracticeTestTopics;


