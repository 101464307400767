import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslations, useFeedbackModel, useStyles } from "examsbook-react-sdk/custom-hooks";
import FeedbackModel from "./Feedback/FeedbackModel";
import Button from '@material-ui/core/Button';
import feedbackImage from "../../src/assets/images/FeedbackImage.png"     

const QuestionErrors = [
    'wrong_question',
    'wrong_answer',
    'other_problem'
];

const ITEM_HEIGHT = 48;

function LongMenu(props) {
    const [ anchorEl, setAnchorEl ] = useState();
    const { question, isIconButtonComponent = true } = props;
    const t = useTranslations();
    const classes = useStyles(styles)();

    const {
        isFeedbackModelOpen,
        openFeedbackModel,
        reportErrorFn,
        closeFeedBackModel
    } = useFeedbackModel();

    function openMenu(event) {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }

    function closeMenu(event) {
        event.stopPropagation();
        setAnchorEl(null);
    }

    function reportError(errorMsg, event) {
        const errorData = {
            title: question?.id?.title,
            questionId: question?.id?._id,
            message: errorMsg
        };
        openFeedbackModel(errorData);
        closeMenu(event);
    }

    const open = Boolean(anchorEl);
    return (
        <>
          {isIconButtonComponent ? (
            <IconButton
                id="long-menu"
                aria-label="More"
                aria-owns={open ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={openMenu}
                style={{display: 'none'}}
                onClose={closeMenu}
                paperprops={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: 200,
                    },
                  }}
            >
            </IconButton>
          ) : (
            <Button 
                onClick={openMenu} 
                className={classes.feedbackButton} 
                variant="outlined"
                id="long-menu"
                aria-label="More"
                aria-owns={open ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClose={closeMenu}
                paperprops={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: 200,
                    },
                  }}>
              <img src={feedbackImage} alt="tableImage" />
              <span className={classes.feedbackText}>{t('feedback')}</span>
            </Button>
          )}
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
            }}
          >
            {QuestionErrors.map((option) => (
              <MenuItem
                key={option}
                selected={option === "Pyxis"}
                onClick={(event) => reportError(t(option), event)}
              >
                {t(option)}
              </MenuItem>
            ))}
          </Menu>
          {isFeedbackModelOpen ? (
            <FeedbackModel
              isFeedbackModelOpen={isFeedbackModelOpen}
              closeFeedBackModel={closeFeedBackModel}
              submitForm={reportErrorFn}
            />
          ) : (
            ""
          )}
        </>
      );
}
const styles = (theme) => ({
    feedbackButton: {
        color: "#0C3450",
        fontSize: "18px",
        fontStyle: "normal",
        textAlign: "center",
        fontWeight: "400",
        lineHeight: "113.5%",
        textTransform: "capitalize",
        border: "1px solid #E7E7E7",
        background: "#FFF",
        boxShadow: "4px 4px 8px 0px rgba(198, 198, 198, 0.25)",
        margin: "9px",
        position: "relative",
        right: "9%",
        display: 'none',
        '@media (min-width:280px) and (max-width:320px)': {
          fontSize: "0px",
          right: 0,
          borderRadius: 23,
          marginTop: 8,
          boxShadow: "none",
          border: "none",
          display: 'none'
        },
        '@media (min-width:321px) and (max-width:767px)': {
          fontSize: "0px",
          right: 0,
          borderRadius: 23,
          marginTop: 8,
          boxShadow: "none",
          border: "none",
          display: 'none'
        },
        '@media (min-width:768px) and (max-width:1024px)': {
          fontSize: "15px",
          right: "auto",
          display: 'none'
        }
    },
    feedbackText: {
      display: 'none',
      '@media (min-width:768px)': {
        paddingLeft: "12px"
      }
    }
  });
export default LongMenu;
