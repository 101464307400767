import React, { useState } from 'react';
import CTabs from "../Tabs/CTabs";
import AppBar from '@material-ui/core/AppBar';
import TopicWiseAnalysisTab from './topicWiseAnalysisTab';
import '../PracticeQuestions/RightSection/practice.scss';
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";

/**
 * TopicWiseAnalysisReport
 * @param props
 * @returns {*}
 * @constructor
 */
function TopicWiseAnalysisReport(props) {
    const { quesResponses, topicWiseAnalysisData } = props;
    const t = useTranslations();
    const [ selectedTab, setSelectedTab ] = useState(0);

    function generateTopicWiseResponse(topics) {
        let result = { unCategories: [], strongChap: [], avgChap: [], weakChap: [] };
        if (topics && topics.length) {
            topics.forEach((topic) => {
                //if attempted questions less then 3 then it is un categories chapter.
                if (topic.total > 0) {
                    let correctPercentage = topic.attempted ? ((topic.correct / topic.attempted) * 100).toFixed(2) : 0;
                    let formatted = {
                        _id: topic._id,
                        attempted: topic.attempted,
                        correct: topic.correct,
                        incorrect: topic.incorrect,
                        name: topic.name,
                        total: topic.total,
                        score: correctPercentage,
                        subject: topic.subject
                    };
                    if (topic.attempted <= 3) {
                        result.unCategories.push(formatted);
                    } else if (correctPercentage <= 33.33) {
                        result.weakChap.push(formatted);
                    } else if (correctPercentage > 33.33 && correctPercentage <= 66) {
                        result.avgChap.push(formatted);
                    } else {
                        result.strongChap.push(formatted);
                    }
                }
            });
        }
        return result;
    }

    function handleTabChange(event, value) {
        setSelectedTab(value);
    }

    let topicWiseAnalysisDataFormatted = generateTopicWiseResponse(topicWiseAnalysisData);
    let tabBarList = [];
    if (topicWiseAnalysisDataFormatted && topicWiseAnalysisDataFormatted.strongChap && topicWiseAnalysisDataFormatted.strongChap.length) {
        tabBarList.push({
            label: t('strong_chapters'),
            ref: 'strongChapters'
        });
    }
    if (topicWiseAnalysisDataFormatted && topicWiseAnalysisDataFormatted.avgChap && topicWiseAnalysisDataFormatted.avgChap.length) {
        tabBarList.push({
            label: t('average_chapters'),
            ref: 'averageChapters'
        });
    }
    if (topicWiseAnalysisDataFormatted && topicWiseAnalysisDataFormatted.weakChap && topicWiseAnalysisDataFormatted.weakChap.length) {
        tabBarList.push({
            label: t('weak_chapters'),
            ref: 'weekChapters'
        });
    }
    if (topicWiseAnalysisDataFormatted && topicWiseAnalysisDataFormatted.unCategories && topicWiseAnalysisDataFormatted.unCategories.length) {
        tabBarList.push({
            label: t('unCategorized_chapters'),
            ref: 'unCategorizedChapters'
        });
    }
    return (

        <div className="topic-wise-tabs">
            <AppBar position="static" className="analysis-tab">
                <CTabs nav={tabBarList} setActiveTab={selectedTab}
                       handleTabChange={handleTabChange}/>
                <TopicWiseAnalysisTab tabList={tabBarList} topicWiseAnalysisData={topicWiseAnalysisDataFormatted}
                                      quesResponses={quesResponses}
                                      selectedIndex={selectedTab} handleTabChange={handleTabChange}/>
            </AppBar>
        </div>
    );
}

export default TopicWiseAnalysisReport;
