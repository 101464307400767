import React from 'react';
import { CNavLink } from "./c/CHeader";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import { useHistory } from 'react-router-dom';

/**
 * MobileMenuList
 * @param props
 * @returns {*}
 * @constructor
 */
function MobileMenuList(props) {
    const { t, openDrawerStatus, screenWidth, handleDrawerClose, openFeedbackModel } = props;
    const history = useHistory();
    function logout() {
        localStorage.clear();
        history.replace('/login');
    }

    return (
        <React.Fragment>
            <CNavLink to="/my-profile">
                <Tooltip placement="right-end" title={openDrawerStatus ? '' : 'Settings'} aria-label="Settings">
                    <ListItem button
                              onClick={screenWidth && screenWidth <= 800 ? handleDrawerClose.bind(this) : () => null}>
                        <i className="fa fa-cog m-r-18"/>
                        <ListItemText primary={t('settings')}/>
                    </ListItem>
                </Tooltip>
            </CNavLink>

            <div onClick={openFeedbackModel.bind(this)}>
                <Tooltip placement="right-end" title={openDrawerStatus ? '' : 'Feedback'} aria-label="Feedback"
                         className="bottom-link">
                    <ListItem button
                              onClick={screenWidth && screenWidth <= 800 ? handleDrawerClose.bind(this) : () => null}>
                        <i className="fa fa-comments-o m-r-16"/>
                        <ListItemText primary={t('feedback')}/>
                    </ListItem>
                </Tooltip>
            </div>
            <div onClick={logout}>
                <Tooltip placement="right-end" title={openDrawerStatus ? '' : 'Logout'} aria-label="Logout"
                         className="bottom-link">
                    <ListItem button
                              onClick={screenWidth && screenWidth <= 800 ? handleDrawerClose.bind(this) : () => null}>
                        <i className="fa fa-sign-out m-r-18"/>
                        <ListItemText primary={t('logout')}/>
                    </ListItem>
                </Tooltip>
            </div>
        </React.Fragment>
    );
}

export default MobileMenuList;
