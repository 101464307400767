import React from 'react';
import { TimeUtils } from "examsbook-react-sdk/common";

function QuizResultComparisonRow(props) {
    const { quizUserResults, name } = props;

    function changeTimeFormat(spentTime) {
        return TimeUtils.changeTimeFormat(spentTime);
    }

    let score = 'score score-' + ((quizUserResults && quizUserResults.score) ? Math.ceil((quizUserResults.score * 100) / (quizUserResults.mark).toFixed(2)) : '0');
    let accuracy = 'accuracy accuracy-' + ((quizUserResults && quizUserResults.accuracy) ? Math.ceil(quizUserResults.accuracy) : '0');
    let correct = 'correct correct-' + ((quizUserResults && quizUserResults.correct) ? Math.ceil((quizUserResults.correct * 100) / quizUserResults.total) : '0');
    let incorrect = 'incorrect incorrect-' + ((quizUserResults && quizUserResults.incorrect) ? Math.ceil((quizUserResults.incorrect * 100) / quizUserResults.total) : '0');
    let timeSpent = 'timeSpent timeSpent-' + (quizUserResults && quizUserResults.timeSpent ? (Math.ceil((((quizUserResults.timeSpent / 60) * 100) / quizUserResults.totalTime))) : '0');
    return (
        <tr>
            <td><h4>{name}</h4></td>
            <td>
                <span>{quizUserResults && quizUserResults.score ? (quizUserResults.score).toFixed(2) : '0'}</span> / <span>{quizUserResults && quizUserResults.mark ? (quizUserResults.mark).toFixed(2) : '0'}</span>
                <div className={score}></div>
            </td>
            <td>
                <span>{quizUserResults && quizUserResults.accuracy ? (quizUserResults.accuracy).toFixed(2) : '0'}</span>
                <span>%</span>
                <div className={accuracy}></div>
            </td>
            <td>
                <span>{quizUserResults && quizUserResults.correct ? (quizUserResults.correct).toFixed(0) : '0'}</span> / <span>{quizUserResults && quizUserResults.total ? quizUserResults.total : '0'}</span>
                <div className={correct}></div>
            </td>
            <td>
                <span>{quizUserResults && quizUserResults.incorrect ? (quizUserResults.incorrect).toFixed(0) : '0'}</span> / <span>{quizUserResults && quizUserResults.total ? quizUserResults.total : '0'}</span>
                <div className={incorrect}></div>
            </td>
            <td>
                <span>{quizUserResults && quizUserResults.timeSpent ? changeTimeFormat(quizUserResults.timeSpent) : '0'}</span> / <span>{quizUserResults && quizUserResults.totalTime ? quizUserResults.totalTime : '0'}</span><span>Mins</span>
                <div className={timeSpent}></div>
            </td>
        </tr>
    );
}

export default QuizResultComparisonRow;
