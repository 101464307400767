import _objectSpread from "/codebuild/output/src2290389247/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import status from "../constants/actionStatus";
const initialState = {
  status: status.PENDING,
  data: []
};
export default function commonTestListFn(type) {
  return function () {
    let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    switch (action.type) {
      case 'GET_' + type + '_WAITING':
        return initialState;
      case 'USER_LANGUAGE_UPDATED':
        return initialState;
      case 'GET_' + type + '_SUCCESS':
        return {
          status: status.SUCCESS,
          data: action.payload,
          lastUpdated: action.lastUpdated,
          examId: action.examId
        };
      case 'TOGGLE_QUIZ__SAVED_SUCCESS':
        let quizs = state.data;
        let quizList = quizs.map(quiz => {
          quiz = _objectSpread({}, quiz); // copy
          if (quiz._id !== action.payload.quizId) {
            return quiz;
          } else {
            //toggle status.
            quiz.isSaved = !quiz.isSaved;
            return quiz;
          }
        });
        quizList = [...quizList];
        return {
          status: status.SUCCESS,
          data: quizList
        };
      case 'GET_' + type + '_ERROR':
        return {
          status: status.ERROR,
          data: action.payload
        };
      case 'GET_LIVE_TEST_UPDATED_SUCCESS':
        let olderQuizzes = state.data;
        let updatedQuizzes = action.payload;
        let newQuizData = [...updatedQuizzes];
        if (olderQuizzes && olderQuizzes.length) {
          olderQuizzes.forEach(item => {
            const isDuplicateItem = newQuizData.some(data => data._id === item._id);
            if (!isDuplicateItem) {
              newQuizData.push(item);
            }
          });
        }
        return {
          status: status.ERROR,
          data: newQuizData,
          lastUpdated: action.lastUpdated,
          examId: action.examId
        };
      default:
        return state;
    }
  };
}