import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import SectionCard from './section';
import {TimeSpent, QuestionDistribution} from './time-spent';
import './reports.scss';

function DistributionCards(props) {
    const {courseResultData} = props;
    const [activeAttempts, setActiveAttempts] = useState('')
    const [activeKey, setActiveKey] = useState(0)

    return (
        <Grid container spacing={0} className='distribution-grid'>
            <Grid item xs={12} sm={6} md={5} className="section-data-grid">
               {courseResultData && <SectionCard setActiveKey={setActiveKey} setActiveAttempts={setActiveAttempts} courseResultData={courseResultData?.result} />}
            </Grid>

            <Grid item xs={12} sm={6} md={3} className="data-grid">
            {activeAttempts && <TimeSpent activeKey={activeKey} courseResultData={courseResultData} activeAttempts={activeAttempts} />}
            </Grid>

            <Grid item xs={12} sm={6} md={3} className="data-grid">
               {courseResultData && activeAttempts && <QuestionDistribution courseResultData={courseResultData} activeAttempts={activeAttempts} activeKey={activeKey} />}
            </Grid>
        </Grid>
    );
}

export default DistributionCards;
