import { getReadAllNotifications } from "../services/readAllNotifications";

/**
 * Get list of all notifications
 * @returns {function(*): *}
 */
export const getReadAllNotification = () => dispatch => {
  dispatch(request());
  // function to get all notifications.
  return getReadAllNotifications().then(readAllNotificationLists => {
    // dispatching notification list to reducer.
    dispatch(success(readAllNotificationLists));
    return readAllNotificationLists;
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
    throw error;
  });
  function request() {
    return {
      type: 'GET_READ_NOTIFICATION_REQUEST'
    };
  }
  function success(readAllNotificationLists) {
    return {
      type: 'GET_READ_NOTIFICATION_SUCCESS',
      readAllNotificationLists
    };
  }
  function failure(error) {
    return {
      type: 'GET_READ_NOTIFICATION_FAILURE',
      error
    };
  }
};