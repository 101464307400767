import React from 'react';
import classNames from 'classnames';
import { AccordionSummary, Accordion, AccordionDetails, Typography } from "@material-ui/core";
import QuestionsMap from "./questionsMap";
import { useStyles, useUserLanguage } from "examsbook-react-sdk/custom-hooks";

/**
 * QuizSectionMap
 * @param props
 * @returns {*}
 * @constructor
 */
const QuizSectionMap = props => {
    const { index, counter, item, selectPaletteQuestion, viewMode, handleSectionChange, quiz } = props;
    const { language } = useUserLanguage();
    const classes = useStyles(styles)();
    const [ expanded, setExpanded ] = React.useState(true);

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary
                className={classNames(classes.expansionSummaryPanel)}
                aria-controls="panel1a-content"
                id="panel1a-header"
                expanded={"true"}
                onClick={() => handleSectionChange(index)}>
                <Typography
                    className={classes.heading}>{item.section && !item.section[language] ? item.section : item.section[language]}</Typography>
            </AccordionSummary>
            <AccordionDetails
                className={classNames(classes.expansionDetailsPanel)}>
                <QuestionsMap questions={item.questions} viewMode={viewMode}
                              selectPaletteQuestion={selectPaletteQuestion}
                              counter={counter} quiz={quiz}
                              index={index}/>

            </AccordionDetails>
        </Accordion>
    );
};

const styles = theme => ({
    expansionSummaryPanel: {
        minHeight: 'inherit !important',
        padding: 0,
        paddingLeft: 17,
        paddingRight: 3,
        pointerEvents: 'none',
        background: '#f3f3f3',
        height: '30px'
    },
    expansionDetailsPanel: {
        padding: '10px 17px',
        paddingBottom: 10,
        paddingTop: 0,
        textAlign: 'left',
    },
    heading: {
        fontSize: 15,
        color: '#000',
        textAlign: 'left',
        textTransform: 'capitalize'
    }
});

export default QuizSectionMap;
