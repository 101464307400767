import { createSelector } from 'reselect';
const getFullTestList = state => state.fullTestList.data;
export const getCompletedTestList = createSelector([getFullTestList], fullTestList => {
  return fullTestList && fullTestList.length > 0 ? fullTestList.filter(t => t.attempted) : [];
});
export const getPracticeRemainingTestList = createSelector([getFullTestList], fullTestList => {
  //mode: "practice-quiz"
  return fullTestList && fullTestList.length > 0 ? fullTestList.filter(t => {
    return t.mode === 'practice-quiz' && !t.attempted;
  }) : [];
});
export const getCompletedPracticeTestList = createSelector([getFullTestList], fullTestList => {
  //mode: "practice-quiz"
  return fullTestList && fullTestList.length > 0 ? fullTestList.filter(t => {
    return t.mode === 'practice-quiz' && t.attempted;
  }) : [];
});
export const getPracticeTestList = createSelector([getFullTestList], fullTestList => {
  return fullTestList && fullTestList.length > 0 ? fullTestList.filter(t => {
    return t.mode === 'practice-quiz';
  }) : [];
});

// it is showing list of all test (non-attempted)
export const getRemainingTestList = createSelector([getFullTestList], fullTestList => {
  return fullTestList && fullTestList.length > 0 ? fullTestList.filter(t => {
    return !t.attempted;
  }) : [];
});
export const getLiveTestList = createSelector([getFullTestList], fullTestList => {
  return fullTestList && fullTestList.length > 0 ? fullTestList.filter(t => {
    return t.mode === 'live-test';
  }) : [];
});
export const getMockRemainingTestList = createSelector([getFullTestList], fullTestList => {
  return fullTestList && fullTestList.length > 0 ? fullTestList.filter(t => {
    return (t.mode === 'mock-test' || t.mode === 'live-test') && t.attempted;
  }) : [];
});
export const getMockCompletedTestList = createSelector([getFullTestList], fullTestList => {
  return fullTestList && fullTestList.length > 0 ? fullTestList.filter(t => {
    return (t.mode === 'mock-test' || t.mode === 'live-test') && !t.attempted;
  }) : [];
});
export const getMockTestList = createSelector([getFullTestList], fullTestList => {
  return fullTestList && fullTestList.length > 0 ? fullTestList.filter(t => {
    return t.mode === 'mock-test' || t.mode === 'live-test';
  }) : [];
});