
import React, { useState, useEffect } from "react";
import { Drawer, Divider, List, ListItem, ListItemText, Box, Typography, Link, withStyles } from '@material-ui/core';
import './MobieSidebar.scss';
import logo from "../../../assets/images/LearnifyLogoDark.png";
import { ReactComponent as DashboardIconDark } from "../../../assets/images/dashboardIconDark.svg";
import { ReactComponent as DashboardIconLight } from "../../../assets/images/dashboardIconLight.svg";
import { ReactComponent as AllCoursesIconDark } from "../../../assets/images/allCoursesIconDark.svg";
import { ReactComponent as AllCoursesIconLight } from "../../../assets/images/allCoursesIconlight.svg";
import { ReactComponent as MyCoursesIconDark } from "../../../assets/images/myCoursesIconDark.svg";
import { ReactComponent as MyCoursesIconLight } from "../../../assets/images/myCoursesIconLight.svg";
import { ReactComponent as SurveyIconDark } from "../../../assets/images/surveyIconDark.svg";
import { ReactComponent as SurveyIconLight } from "../../../assets/images/surveyIconLight.svg";
import { ReactComponent as SupportIconDark } from "../../../assets/images/supportIconDark.svg";
import { ReactComponent as SupportIconLight } from "../../../assets/images/supportIconLight.svg";
import { ReactComponent as NotificationIconDark } from "../../../assets/images/notificationIconDark.svg";
import { ReactComponent as NotificationIconLight } from "../../../assets/images/notificationIconLight.svg";
import { ReactComponent as LanguageIconDark } from "../../../assets/images/languageIconDark.svg";
import { ReactComponent as LanguageIconLight } from "../../../assets/images/languageIconLight.svg";
import { ReactComponent as LogoutIconDark } from "../../../assets/images/logoutIconDark.svg";
import { useTranslations, useUserLanguage } from "examsbook-react-sdk/custom-hooks";
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getDefaultSupportDataAction, getSupportAction } from "examsbook-react-sdk/actions/getSupportAction";
import { useDispatch } from "react-redux";



function MobileViewSideBar({ openToggler, toggleDrawer, classes }) {
  const history = useHistory();
  const t = useTranslations();
  const [open, setOpen] = useState(false)
  const [openLanguage, setOpenLanguage] = useState(false)
  const { language, setUserLanguage } = useUserLanguage();
  const [supportData, setSupportData] = useState([]);
  const [defaultSupportData, setDefaultSupportData] = useState([]);
  const [showComponent, setShowComponent] = useState(false);
  const [isError, setIsError] = useState('');
  const [dynamicLogos, setDynamicLogos] = useState('');
  const [selectedItem, setSelectedItem] = useState('dashboard');
  const [selectedIcon, setSelectedIcon] = useState('dashboardIcon');

  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.clear();
    toggleDrawer(false)
    history.replace('/login');
  };

  const handleLogoRedirection = () => {
    toggleDrawer(false)
    history.push('/dashboard')
  };

  const getSettingApiData = async () => {
    try {
      dispatch(getSupportAction()).then((res) => {
      setSupportData(res);
      });
    } catch (error) {
      console.log('Error -', error.message);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const getDefaultSeoSettingApiData = async () => {
    try {
      dispatch(getDefaultSupportDataAction()).then((res) => {
        setDefaultSupportData(res);
      });
    } catch (error) {
      setIsError(error.message);
    }
  };

  useEffect(() => {
    getSettingApiData();
    getDefaultSeoSettingApiData();
  }, []);

  const handleItemClick = (item, icon) => {
    setSelectedItem(item);
    setSelectedIcon(icon);
    if(item !=='language') {
      toggleDrawer(false);
    }
  };

  const isDynamicLogo = dynamicLogos.split('/').pop();
  let API_URL = process.env.REACT_APP_DYNAMIC_URL;
  useEffect(() => {
    const imageValue = supportData?.data?.find(subValue => subValue.key === "Image")?.value;
    const defaultValue = defaultSupportData?.data?.Images?.find(subValue => subValue.key === "Image")?.defaultValue;
    let dynamicLogo = "";
    if (typeof API_URL != "undefined") {
      if (imageValue) {
        dynamicLogo = API_URL +'/img/post/original/' + imageValue;
      } else if (defaultValue) {
        dynamicLogo = API_URL +'/img/post/original/' + defaultValue;
      }
    }
    setDynamicLogos(dynamicLogo) 
  }, [supportData, defaultSupportData])

  const list = (
    <div>
      <List>
        {showComponent && <div className="listDiv">
            <img src={isDynamicLogo && isDynamicLogo !== 'undefined' ? dynamicLogos : logo} className="logo-size" onClick={handleLogoRedirection}/>
            <ArrowBackIcon onClick={() => toggleDrawer(false)} />
        </div>}
        <ListItem button className={`listItem ${selectedItem === 'dashboard' ? classes.selected : ''}`} onClick={() => handleItemClick('dashboard', 'dashboardIcon')}>
          <Box className='icon-height'>
            {selectedIcon === 'dashboardIcon' ? <DashboardIconLight/> : <DashboardIconDark/>}
          </Box>
          <ListItemText primary={t('dashboard')} className="listItemText" onClick={() => history.push('/dashboard')} />
        </ListItem>
        <ListItem button className={`listItem ${selectedItem === 'courses' ? classes.selected : ''}`} onClick={() =>  {handleItemClick('courses', 'courseIcon'); setOpen(!open)}}>
          <Box className='icon-height'>
            {selectedIcon === 'courseIcon' ? <AllCoursesIconLight/> : <AllCoursesIconDark/>}
          </Box>
          <ListItemText primary={t('COURSES')} className="listItemText" onClick={() => history.push('/course-list')} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        {open ? (<>
          <div className="languagetabs">
            <ListItem button className={`listItem ${selectedItem === 'mycourses' ? classes.selected : ''}`} onClick={() => handleItemClick('mycourses', 'myCourseIcon')}>
            <Box className='icon-height'>
              {selectedIcon === 'myCourseIcon' ? <MyCoursesIconLight/> : <MyCoursesIconDark/>}
            </Box>
              <ListItemText primary={t('MY_COURSES')} className="listItemText" onClick={() => history.push('/my-courses')} />
            </ListItem>
          </div>
        </>) : ""}
        <ListItem button className={`listItem ${selectedItem === 'language' ? classes.selected : ''}`} onClick={() => { handleItemClick('language', 'languageIcon'); setOpenLanguage(!openLanguage)}}>
        <Box className='icon-height'>
          {selectedIcon === 'languageIcon' ? <LanguageIconLight/> : <LanguageIconDark/>}
        </Box>
          <ListItemText primary={t('LANGUAGE')} className="listItemText" />
          {openLanguage ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        {openLanguage ? (
          <>
            <div className="languagetabs">
              <ListItem button className="listItem" >
                <ListItemText primary={t('english')} className="listItemText" onClick={() => {setUserLanguage('en'); toggleDrawer(false)}} />
              </ListItem>
              <ListItem button className="listItem" >
                <ListItemText primary={t('hindi')} className="listItemText" onClick={() => {setUserLanguage('hn'); toggleDrawer(false)}} />
              </ListItem>
            </div>
          </>
        ) : ""}

        <ListItem button className={`listItem ${selectedItem === 'survey' ? classes.selected : ''}`} onClick={() => handleItemClick('survey', 'surveyIcon')}>
          <Box className='icon-height'>
          {selectedIcon === 'surveyIcon' ? <SurveyIconLight/> : <SurveyIconDark/>}
          </Box>
          <ListItemText primary={t('Surveys')} className="listItemText" onClick={() => history.push('/surveys')} />
        </ListItem>
        <ListItem button className={`listItem ${selectedItem === 'support' ? classes.selected : ''}`} onClick={() => handleItemClick('support', 'supportIcon')}>
          <Box className='icon-height'>
          {selectedIcon === 'supportIcon' ? <SupportIconLight/> : <SupportIconDark/>}
          </Box>
          <ListItemText primary={t('Support')} className="listItemText" onClick={() => history.push('/support-ticket')} />
        </ListItem>
        <ListItem button className={`listItem ${selectedItem === 'notification' ? classes.selected : ''}`} onClick={() => handleItemClick('notification', 'notificationIcon')}>
          <Box className='icon-height'>
            {selectedIcon === 'notificationIcon' ? <NotificationIconLight/> : <NotificationIconDark/>}
          </Box>
          <ListItemText primary={t('Notifications')} className="listItemText" onClick={() => history.push('/notification-detail')} />
        </ListItem>
        <Divider className="divider-align"></Divider>
        <ListItem button className="listItem" onClick={() => handleLogout()}>
          <Box className='icon-height'>
            <LogoutIconDark/>
          </Box>
          <ListItemText primary={t('logout')} className="listItemText" />
        </ListItem>
        <Box>
            <Typography className='powered-by'>
              &copy; {t('Powered by')}&nbsp;
              <Link href="http://www.habilelabs.io/" target="_blank" id='habilelabs-text'>{t('HabileLabs')}</Link>
            </Typography>
        </Box>
      </List>
    </div>
  );
  return (

    <Drawer open={openToggler} className='drawe' onClose={() => toggleDrawer(false)} style={{ width: '100% !important' }}>
      {list}
    </Drawer>


  );
}
const useStyles = () => {
  const themeData =  localStorage.getItem('theme');
  const theme = JSON?.parse(themeData) 
  let primaryColor = ""
  let primaryFontText = ""
  if (typeof theme != 'undefined') {
    primaryColor = JSON.parse(themeData)?.primaryBg
    primaryFontText = JSON.parse(themeData)?.primaryFontColor
  }
  return{
    selected: {
        background: primaryColor + '!important',
        color: primaryFontText + '!important'
    },
  }
};

export default withStyles(useStyles, { withTheme: true })(MobileViewSideBar);;
