import React from "react";
import "../../components/PdfCreation/quiz.scss";
import QuestionTitle from "../../components/Quiz/QuestionBox/QuestionTitle";
import { formatQuestion, TimeUtils } from "examsbook-react-sdk/common";
import useStyles from "examsbook-react-sdk/custom-hooks/useStyles";
import useUserLanguage from "examsbook-react-sdk/custom-hooks/useUserLanguage";
import QuestionOptionList from "../../components/Quiz/QuestionBox/QuestionOptionList";
import './Pdf.scss';

const ReportTabularView = (props) => {
    const { questions, totalMarks, t } = props;
    const { language } = useUserLanguage();
    const classes = useStyles(styles)();

    function onRadioChange() {
        return;
    }

    function changeTimeFormat(spentTime) {
        return TimeUtils.changeTimeFormat(spentTime);
    }

    return (
        <div id="quizTable">
            {!questions || questions.length === 0 ? (
                "You currently have no report"
            ) : (
                <table className="table">
                    <thead>
                    <tr className="table-column">
                        <th></th>
                        <th>{t("QUESTION")}</th>
                        <th></th>
                    </tr>
                    </thead>
                    {
                        <tbody>
                        {questions.map((ques, index) => {
                            const {
                                type,
                                localesTitle,
                                title,
                                mark,
                                penalty,
                                givenAnswer,
                                timeSpent,
                                direction,
                                options,
                                markObtain,
                                className,
                                localesDirection,
                            } = formatQuestion(ques, true, language);
                            return (
                                <tr className="td" key={index}>
                                    <td></td>
                                    <QuestionTitle
                                        isPdf={true}
                                        mark={markObtain ? markObtain : "0"}
                                        questionMark={mark}
                                        penalty={penalty}
                                        classNameInView={
                                            className ? className : classes.marksBadges
                                        }
                                        timeSpent={
                                            timeSpent ? changeTimeFormat(timeSpent) : "00:00"
                                        }
                                        viewMode={true}
                                        direction={direction}
                                        title={title}
                                        total={ques.length}
                                        number={index + 1}
                                        localesTitle={localesTitle}
                                        localesDirection={localesDirection}
                                    />

                                    <QuestionOptionList
                                        options={options}
                                        type={type}
                                        showAnswer={true}
                                        viewMode={true}
                                        givenAnswer={givenAnswer}
                                        mode={true}
                                        onRadioChange={onRadioChange}
                                    />
                                    <td></td>
                                </tr>
                            );
                        })}
                        <tr className={classes.tableFooter}>
                            <td/>
                            <td> Total Marks</td>
                            <td>{totalMarks ? totalMarks : 0}</td>
                        </tr>
                        </tbody>
                    }
                </table>
            )}
        </div>
    );
};

const styles = (theme) => ({

    marksBadges: {
        backgroundColor: "#ffc65820",
        color: "#ffc658",
        border: "1px solid #ffc658",
        borderRadius: 2,
        padding: "2px 6px",
        fontSize: 13,
        textAlign: "center",
        whiteSpace: "nowrap",
        marginRight: 0,
        display: "flex",
    },
    tableFooter: {
        color: '#009879',
        fontWeight: 'bold',
        fontSize: '20px',
        textAlign: 'left'
    }
});

export default ReportTabularView;
