import React from 'react';
import ContentLoader from 'react-content-loader';

const QuizFooterLoader = () => (
    <div className="quiz-footer">
        <ContentLoader
            height={45}
            width={230}
            speed={1}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="50" y="3" rx="5" ry="5" width="20" height="5"/>
            <rect x="75" y="3" rx="5" ry="5" width="30" height="5"/>
            <rect x="110" y="3" rx="5" ry="5" width="40" height="5"/>
            <rect x="155" y="3" rx="5" ry="5" width="50" height="5"/>

        </ContentLoader>
    </div>
);

export default QuizFooterLoader;
