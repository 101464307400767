import { getNotificationList, postNotificationData } from "../services/notificationService";

/**
 * Get list of all notifications
 * @returns {function(*): *}
 */
export const getNotifications = length => dispatch => {
  dispatch(request());
  // function to get all notifications.
  return getNotificationList(length).then(notificationList => {
    // dispatching notification list to reducer.
    dispatch(success(notificationList));
    return notificationList;
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
    throw error;
  });
  function request() {
    return {
      type: "GET_NOTIFICATION_REQUEST"
    };
  }
  function success(notificationList) {
    return {
      type: "GET_NOTIFICATION_SUCCESS",
      notificationList
    };
  }
  function failure(error) {
    return {
      type: "GET_NOTIFICATION_FAILURE",
      error
    };
  }
};
export const courseAddedNotification = (userId, data) => dispatch => {
  dispatch(request(userId, data));
  return postNotificationData(userId, data).then(notificationRes => {
    // handle success
    dispatch(success(notificationRes));
    // fetch latest notifications
    dispatch(getNotifications(20));
    return notificationRes;
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request(notificationData) {
    return {
      type: "NOTIFICATION_REQUEST",
      notificationData
    };
  }
  function success(notificationRes) {
    return {
      type: "COURSE_ADDED_NOTIFICATION_SUCCESS",
      notificationRes
    };
  }
  function failure(error) {
    return {
      type: "GET_NOTIFICATION_FAILURE",
      error
    };
  }
};