import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredExams } from "examsbook-react-sdk/selectors";
import useSelectedExam from "./useSelectedExam";
import { getTopicWiseAnalysisReport } from "examsbook-react-sdk/actions/getTopicWiseAnalysisReport";
import { getExamsWithSubjects } from "examsbook-react-sdk/actions";
import status from "../constants/actionStatus";

/**
 * Use reports data.
 * @returns {{subjects: *, topicWiseAnalysisData: Array}}
 */
export function useReportsData() {
  const dispatch = useDispatch();
  const topicWiseAnalysisInfo = useSelector(state => state.topicWiseAnalysisData);
  const topicWiseAnalysisDataProp = topicWiseAnalysisInfo.data;
  const topicWiseAnalysisDataLoading = topicWiseAnalysisInfo.isLoading;
  const subjects = useSelector(state => getFilteredExams(state));
  const selectedExam = useSelectedExam();
  const examData = useSelector(state => state.examData);
  const isLoading = examData.status === status.PENDING;
  //fetch data from db.
  useEffect(() => {
    if (selectedExam && selectedExam._id) {
      dispatch(getTopicWiseAnalysisReport(selectedExam._id));
      dispatch(getExamsWithSubjects(selectedExam._id));
    }
  }, [selectedExam]);
  let topicWiseAnalysisData = [];
  if (topicWiseAnalysisDataProp && topicWiseAnalysisDataProp.length > 0) {
    topicWiseAnalysisData = topicWiseAnalysisDataProp.filter(topic => {
      return topic.attempted > 0;
    });
  }
  let overallLoading = isLoading || topicWiseAnalysisDataLoading;
  return {
    isLoading: overallLoading,
    subjects,
    topicWiseAnalysisData,
    aggregateCount: getAggregateCount(subjects),
    accuracyCount: accuracyCountFn(subjects),
    progressCount: progressCountFn(subjects)
  };
}

// accuracy of student practice
export function accuracyCountFn(subjects) {
  let correctAnsCount = 0;
  let incorrectAnsCount = 0;
  let notAnsweredCount = 0;
  let percentCount = 0;
  let answeredCount = 0;
  if (subjects && subjects.length > 0) {
    subjects.forEach(subject => {
      subject.topics.forEach(topic => {
        correctAnsCount = correctAnsCount + topic.correct;
        incorrectAnsCount = incorrectAnsCount + topic.incorrect;
        notAnsweredCount = notAnsweredCount + (topic.total - topic.attempted);
      });
      answeredCount = correctAnsCount + incorrectAnsCount;
    });
  }
  percentCount = answeredCount > 0 ? parseFloat((correctAnsCount / answeredCount * 100).toFixed(1)) : 0;
  return {
    correctAnsCount: correctAnsCount,
    incorrectAnsCount: incorrectAnsCount,
    notAnsweredCount: notAnsweredCount,
    answeredCount: answeredCount,
    percentCount: percentCount
  };
}

// progress of student practice

export function progressCountFn(subjects) {
  //topic count element variables
  let completedTopicsCount = 0;
  let ongoingTopicsCount = 0;
  let unseenTopicsCount = 0;
  let topicCount = 0;
  let total = 0;
  let totalTopic = 0;
  let CompletedPercent = 0;
  subjects.forEach(subject => {
    topicCount = topicCount + subject.topics.length;
    subject.topics.forEach(topic => {
      total = topic.correct + topic.incorrect;
      if (total === topic.total && total !== 0) {
        completedTopicsCount = completedTopicsCount + 1;
      }
      if (topic.attempted === 0) {
        unseenTopicsCount = unseenTopicsCount + 1;
      }
      if (topic.attempted !== topic.total && topic.attempted !== 0) {
        ongoingTopicsCount = ongoingTopicsCount + 1;
      }
    });
    totalTopic = completedTopicsCount + unseenTopicsCount + ongoingTopicsCount;
    CompletedPercent = parseFloat((completedTopicsCount / totalTopic * 100).toFixed(1));
  });
  return {
    completedTopicsCount: completedTopicsCount,
    unseenTopicsCount: unseenTopicsCount,
    ongoingTopicsCount: ongoingTopicsCount,
    topicCount: topicCount,
    CompletedPercent: CompletedPercent
  };
}

//aggregate count of student practice
export function getAggregateCount(subjects) {
  let completeSubjectsCount = 0;
  let ongoingSubjectsCount = 0;
  let unseenSubjectsCount = 0;
  let totalSubjects = 0;
  let subCompletePercent = 0;
  if (subjects && subjects.length) {
    subjects.forEach(subject => {
      //count of subject attempted
      if (subject.attemptedQuesCount === subject.questionCount && subject.questionCount !== 0 && subject.attemptedQuesCount !== 0) {
        completeSubjectsCount = completeSubjectsCount + 1;
      } else if (subject.attemptedQuesCount !== subject.questionCount && subject.questionCount !== 0 && subject.attemptedQuesCount !== 0) {
        ongoingSubjectsCount = ongoingSubjectsCount + 1;
      } else if (subject.attemptedQuesCount === 0) {
        unseenSubjectsCount = unseenSubjectsCount + 1;
      }
    });
    totalSubjects = completeSubjectsCount + unseenSubjectsCount + ongoingSubjectsCount;
    subCompletePercent = parseFloat((completeSubjectsCount / totalSubjects * 100).toFixed(1));
  }
  return {
    completeSubjectsCount: completeSubjectsCount,
    ongoingSubjectsCount: ongoingSubjectsCount,
    unseenSubjectsCount: unseenSubjectsCount,
    totalSubjects: totalSubjects,
    subCompletePercent: subCompletePercent
  };
}