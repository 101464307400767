import axios from './axiosConfig';
export function getDeleteNotifications(id) {
  return axios.delete('/api/v1/userNotifications', {
    params: {
      id: id
    }
  }).then(response => {
    //handle success
    return response.data;
  }).catch(error => {
    //handle error
    return error;
  });
}