import React, { useState } from 'react';
import QuizList from "./QuizList";
//import QuizFilter from "./quizFilter";
// import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";

/**
 * FilteredQuizList for web.
 * @param props
 * @returns {*}
 * @constructor
 */
function FilteredQuizList(props) {
    const { quizList, subExamList, activeIndex, buttonText, handleTabChange, isPending, noDataMessage } = props;
    const [ filteredExams ] = useState([]);
    return (
        <div>
            <QuizList filteredExams={filteredExams} subExamList={subExamList}
                      quizList={quizList}
                      noDataMessage={noDataMessage}
                      handleTabChange={handleTabChange} isPending={isPending}
                      activeIndex={activeIndex}
                      buttonText={buttonText}/>
        </div>
    );
}

export default FilteredQuizList;
