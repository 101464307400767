import useSelectedExam from "./useSelectedExam";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getMockRemainingTestList, getMockCompletedTestList, getMockTestList } from "examsbook-react-sdk/selectors";
import status from "../constants/actionStatus";
import useUserLanguage from "./useUserLanguage";
import { getAllTestList } from "../actions/getAllTestList";

/**
 * get list of all tests.
 * @returns {*}
 */
export default function useFullMockTestList() {
  const selectedExam = useSelectedExam();
  const {
    language
  } = useUserLanguage();
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedExam && selectedExam._id) {
      dispatch(getAllTestList(selectedExam._id));
    }
  }, [selectedExam, language]);
  let remainingMockTests = useSelector(state => getMockCompletedTestList(state));
  let completedMockTests = useSelector(state => getMockRemainingTestList(state));
  let mockTests = useSelector(state => getMockTestList(state));
  let allTestData = useSelector(state => state.fullTestList);
  let isLoading = allTestData.status === status.PENDING;
  return {
    remainingMockTests,
    completedMockTests,
    mockTests,
    fullMockTestPending: isLoading
  };
}