import React, { createContext, useContext, useEffect, useState } from "react";
import { getDefaultSupportDataAction, getSupportAction } from "examsbook-react-sdk/actions/getSupportAction";
import { useDispatch } from "react-redux";

const ThemeContext = createContext();
function ThemeProvider({ children }) {
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');

  const [primaryFontText, setPrimaryFontText] = useState('');
  const [secondaryFontText, setSecondaryFontText] = useState('');

  const dispatch = useDispatch()
  const [settingsData, setSettingsData] = useState([]);
  const [primaryBg, setPrimaryBg] = useState();
  const [secondaryBg, setSecondaryBg] = useState();
  const [primaryFontColor, setPrimaryFontColor] = useState();
  const [secondaryFontColor, setSecondaryFontColor] = useState();
  

  const getSettingApiData = async () => {
    try {
      const res = await dispatch(getSupportAction());
      const defaultValueRes = await dispatch(getDefaultSupportDataAction());      
      setPrimaryBg(res?.data?.find(subValue => subValue.key === "Primary Bg")?.value || defaultValueRes?.data?.['Background Theme']?.find(subValue => subValue.key === "Primary Bg")?.defaultValue)
      setSecondaryBg(res?.data?.find(subValue => subValue.key === "Secondary Bg")?.value || defaultValueRes?.data?.['Background Theme']?.find(subValue => subValue.key === "Secondary Bg")?.defaultValue)
      setPrimaryFontColor(res?.data?.find(subValue => subValue.key === "Primary Text")?.value || defaultValueRes?.data?.['Text Theme']?.find(subValue => subValue.key === "Primary Text")?.defaultValue)
      setSecondaryFontColor(res?.data?.find(subValue => subValue.key === "Secondary Text")?.value || defaultValueRes?.data?.['Text Theme']?.find(subValue => subValue.key === "Secondary Text")?.defaultValue)
      setSettingsData(res);
    } catch (error) {
      console.log('Error - ', error.message);
    }
  };

  useEffect(() => {
    getSettingApiData();
  }, []);

  let API_URL = process.env.REACT_APP_DYNAMIC_URL;

  useEffect(() => {
    if(primaryBg && secondaryBg && primaryFontColor && secondaryFontColor){  
      const dynamicBgColorPrimary = typeof API_URL != "undefined" && API_URL +'/img/post/original/' + primaryBg;
      const dynamicBgColorSecondary = typeof API_URL != "undefined" && API_URL +'/img/post/original/' + secondaryBg;
     
      const dynamicFontColorPrimary = typeof API_URL != "undefined" && API_URL +'/img/post/original/' + primaryFontColor;
      const dynamicFontColorSecondary = typeof API_URL != "undefined" && API_URL +'/img/post/original/' + secondaryFontColor;
  
      setPrimaryColor(dynamicBgColorPrimary.split('/').pop());
      setSecondaryColor(dynamicBgColorSecondary.split('/').pop());
  
      setPrimaryFontText(dynamicFontColorPrimary.split('/').pop());
      setSecondaryFontText(dynamicFontColorSecondary.split('/').pop());
    }
    let theme = {
      primaryBg: primaryBg, 
      secondaryBg: secondaryBg, 
      primaryFontColor: primaryFontColor,
      secondaryFontColor: secondaryFontColor
    }
    localStorage.setItem("theme", JSON.stringify(theme))
  }, [primaryBg, secondaryBg, primaryFontColor, secondaryFontColor])

    // Don't render the children if the theme data is not available
  if (!primaryColor || !secondaryColor || !primaryFontText || !secondaryFontText) {
    return null;
  }

  return (
    <ThemeContext.Provider value={{ primaryColor, secondaryColor, primaryFontText, secondaryFontText }}>
      {children}
    </ThemeContext.Provider>
  );
}
export const useTheme = () => useContext(ThemeContext);

export default ThemeProvider;
