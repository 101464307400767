import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getNotifications } from "examsbook-react-sdk/actions";

/**
 * get list of all notifications.
 * @returns {*}
 */
export default function useNotifications(refresh, length) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  useEffect(() => {
    getNotification();
  }, [user, refresh, length]);
  function getNotification() {
    dispatch(getNotifications(length));
  }
  const notificationList = useSelector(state => state.notificationList.notification);
  return {
    notificationList
  };
}