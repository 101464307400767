import status from '../constants/actionStatus';
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    status: status.PENDING
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'QUESTION_REQUEST':
      return {
        status: status.PENDING
      };
    case 'UNSAVE_QUESTIONS_FAILURE':
      return {
        status: status.ERROR
      };
    default:
      return state;
  }
});