import status from "../constants/actionStatus";
import { getAllTestsList } from "../services/quizService";
export const getAllTestList = courseId => (dispatch, getState) => {
  const {
    fullTestList
  } = getState();
  const isLoaded = fullTestList.status === status.SUCCESS;
  //clean the list only if data never loaded before or exam changed.
  if (!isLoaded || courseId !== fullTestList.examId) {
    dispatch({
      type: "GET_ALL_TEST_PENDING",
      payload: []
    });
  }
  let condition = "";
  if (!isLoaded || courseId !== fullTestList.examId) {
    condition = "exam=" + courseId;
  } else {
    condition = "exam=" + courseId + "&updatedAfter=" + fullTestList.lastUpdated;
  }
  getAllTestsList(courseId).then(response => {
    // handle success
    let apiResponse = response;
    if (!isLoaded) {
      dispatch({
        type: "GET_ALL_TEST_SUCCESS",
        payload: apiResponse.data,
        examId: courseId,
        lastUpdated: new Date().toISOString()
      });
    } else {
      dispatch({
        type: "GET_ALL_UPDATED_TEST",
        payload: apiResponse.data,
        examId: courseId,
        lastUpdated: new Date().toISOString()
      });
    }
  }).catch(error => {
    // handle error
    dispatch({
      type: "GET_ALL_TEST_ERROR",
      payload: []
    });
  });
};