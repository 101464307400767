import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getChildExamsList } from "examsbook-react-sdk/actions/getCategory";
import useSelectedExam from "./useSelectedExam";
export default function useSubExamList() {
  const dispatch = useDispatch();
  const selectedExam = useSelectedExam();
  useEffect(() => {
    if (selectedExam && selectedExam._id) {
      dispatch(getChildExamsList(selectedExam._id));
    }
  }, [selectedExam]);
  const subExamList = useSelector(state => state.subExamList);
  return subExamList;
}