import React, { createContext, useState, useContext} from 'react';

const SearchQueryContext = createContext();

export function SearchQueryProvider({ children }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [isCourseSubMenuOpen,setIsCourseSebMenuOpen] = useState(true);
  const [isFeedbackModelOpen, setIsFeedbackModelOpen ] = useState(false);
  const [newFlag, setNewFlag] = useState(false);

  return (
    <SearchQueryContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        isCourseSubMenuOpen,
        setIsCourseSebMenuOpen,
        isFeedbackModelOpen,
        setIsFeedbackModelOpen,
        newFlag,
        setNewFlag,
      }}
    >
      {children}
    </SearchQueryContext.Provider>
  );
}

export function useSearchQuery() {
  const context = useContext(SearchQueryContext);
  if (!context) {
    throw new Error('useSearchQuery must be used within a SearchQueryProvider');
  }
  return context;
}
