import { getUnreadNotificationList } from "../services/unreadNotificationService";

/**
 * Get list of all notifications
 * @returns {function(*): *}
 */
export const getUnreadNotifications = () => dispatch => {
  dispatch(request());
  // function to get all notifications.
  // getUnreadNotificationList().then((unreadNotificationList) => {
  //         // dispatching notification list to reducer.
  //         dispatch(success(unreadNotificationList));
  //     })
  //     .catch((error) => {
  //         // handle error
  //         dispatch(failure(error.toString()));
  //     });

  function request() {
    return {
      type: 'GET_UNREAD_NOTIFICATION_REQUEST'
    };
  }
  function success(unreadNotificationList) {
    return {
      type: 'GET_UNREAD_NOTIFICATION_SUCCESS',
      unreadNotificationList
    };
  }
  function failure(error) {
    return {
      type: 'GET_UNREAD_NOTIFICATION_FAILURE',
      error
    };
  }
};