import React, { useState } from 'react';
import Modal from "@material-ui/core/Modal/Modal";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import ResponseScreen from "../ResponseScreen/responseScreen";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";

/**
 * Plain answer list component. this will display question train with status of questions.
 */
function AnswersList(props) {
    const { quizResults } = props;
    const t = useTranslations();
    const [ addResponseModal, setAddResponseModal ] = useState(false);
    const [ quizResultDetailKey, setQuizResultDetailKey ] = useState();

    function changeQuestionIndex(key) {
        setQuizResultDetailKey(key);
    }

    const quesResponses = quizResults && quizResults.data && quizResults.data.quesResponses ?
        quizResults.data.quesResponses : {};

    function closeModal() {
        setAddResponseModal(false);
    }

    function openModel(quizResult, key) {
        setAddResponseModal(true);
        setQuizResultDetailKey(key);
    }

    return (
        <div className="your-answered quiz-answered">
            <p>{t('your_answers')}</p>
            <ul>
                {quesResponses && quesResponses.answers ?
                    quesResponses && quesResponses.answers.map((data, key) => {
                        return (
                            <li onClick={() => openModel(key)} className={data} key={key}>{key + 1}</li>
                        );
                    }) : null
                }
            </ul>
            <div>
                <Modal className="response-modal"
                       aria-labelledby="simple-modal-title"
                       aria-describedby="simple-modal-description"
                       open={addResponseModal}
                       onClose={closeModal}>
                    <DialogContent>
                        <ResponseScreen
                            quesResponses={quesResponses}
                            quizResultDetailKey={quizResultDetailKey}
                            changeQuestionIndex={changeQuestionIndex}
                            closeModal={closeModal}/>
                    </DialogContent>
                </Modal>
            </div>
        </div>
    );
}

export default AnswersList;
