import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import './reports.scss';

function ChartComponent({ title, data, COLORS }) {
    const t = useTranslations();

    const legendData = data.map((entry, index) => ({
        name: entry.name,
        color: COLORS[index % COLORS.length],
    }));

    const renderTooltip = ({ payload }) => {
        if (payload && payload.length === 1) {
            const { value } = payload[0];
            const percentage = value;

            return (
                <div className="custom-tooltip">
                    <p>{`${percentage.toFixed(2)}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <Box>
            <div className='title-heading'>{t(title)}</div>
            <PieChart width={250} height={190} className='chart-data'>
                <Pie
                    dataKey="value"
                    data={data}
                    cx={130}
                    cy={90}
                    innerRadius={55}
                    outerRadius={90}
                    fill="#8884d8"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip content={renderTooltip} />
            </PieChart>
            
            <div className="legend">
                {legendData.map((entry, index) => (
                    <div key={`legend-${index}`} className="legend-item">
                        <div className="legend-circle" style={{ backgroundColor: entry.color }}></div>
                        <p>{t(entry.name)}</p>
                    </div>
                ))}
            </div>
        </Box>
    );
}

function QuestionDistribution(props) {
    let rightAns = 0
    let wrongAns = 0
    let unAnswered = 0
    let queData = props?.activeAttempts && props?.activeAttempts?.map((item, index)=>{
        wrongAns += item?.wrongAns;
        rightAns += item?.rightAns;
        unAnswered =  props?.courseResultData?.attemptsField[props?.activeKey]?.unAnsweredQuestion
    })
    const data = [
        { name: 'Correct_Answer', value: rightAns },
        { name: 'Unanswered', value: unAnswered },
        { name: 'incorrect_answer', value: wrongAns },
    ];

    const COLORS = ['#698192', '#0C3450', '#3D5D73'];

    return <ChartComponent title="question_distribution" data={data} COLORS={COLORS} />;
}

function TimeSpent(props) {
    let timeTaken = (props?.courseResultData?.attemptsField[props.activeKey]?.timeSpent)?.toFixed(2)
    let newTime = timeTaken/60
    let rightAns = props?.activeAttempts?.attemptsField?.map((item, index)=>{
         timeTaken += item?.timeSpent
         newTime = timeTaken/60

         return newTime.toFixed(2)
    })
    const data = [
        { name: 'total_time', value: props?.courseResultData?.duration},
        { name: 'time_taken', value: newTime},
    ];

    const COLORS = ['#698192', '#0C3450'];

    return <ChartComponent title='time_spent' data={data} COLORS={COLORS} />;
}

export { QuestionDistribution, TimeSpent };
