import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import TestSeriesQuiz from '../components/TestSeriesQuiz/testSeriesQuiz';
import CTabs from "../components/Tabs/CTabs";
import BreadcrumbBar from "../components/breadcrumbBar";
import {
    useAnalyticsPageView,
    useSingleTestSeries,
    useTranslations,
    useUserLanguage
} from "examsbook-react-sdk/custom-hooks";
import useSelectedExam from "examsbook-react-sdk/custom-hooks/useSelectedExam";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// import {reactGaEvent} from "examsbook-react-sdk/actions/Common";
/**
 * Single test series page.
 * @param props
 * @returns {*}
 * @constructor
 */
function TestSeriesDetail(props) {
    const { history } = props;
    const activeTestSeriesIndex = useSelector((state) => state.activeTestSeriesIndexVal);
    const t = useTranslations();
    const { language } = useUserLanguage();
    const [ selectedTab, setSelectedTab ] = useState(0);
    //analytics page view.
    useAnalyticsPageView();

    const testSeries = useSingleTestSeries(props.match.params.id);
    const selectedExam = useSelectedExam();
    const selectedExamId = selectedExam && selectedExam._id;
    const isLoading = testSeries.singleTestSeriesLoading;

    //use effect.
    useEffect(() => {
        if (activeTestSeriesIndex) {
            setSelectedTab(activeTestSeriesIndex);
        }
    }, [ activeTestSeriesIndex ]);

    useEffect(() => {
        if (!isLoading && selectedExamId) {
            const data = testSeries.singleTestSeries;
            if (data && data.exams.indexOf(selectedExamId) === -1) {
                //selected exam is not part of test series, redirect use to test series page.
                history.push('/test-series');
            }
        }
    }, [ isLoading, selectedExamId ]);

    function handleTabChange(event, value) {
        setSelectedTab(value);
    }

    function handleBreadcrumbLink() {
        history.push('/test-series');
    }

    function handleGoToTestClick() {
        history.push('/test-series/');
    }

    let tabBarList = [ 
        {
            label: t('test'),
            ref: 'all',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
                d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
            </svg>
        },
        {
            label: t('VIDEO'),
            ref: 'all',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
                d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
            </svg>
        },
    
        {
            label: t('Notes'),
            ref: 'mytest',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                    d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9H9V9h10v2zm-4 4H9v-2h6v2zm4-8H9V5h10v2z"/>
            </svg>,
        },
    ];
    return (
        <div className="card-view test-series mock-test-cards">
            <div className="test-details-series">
                <div className="mobile-back-to-test">
                    <ArrowBackIcon onClick={handleGoToTestClick}/>
                    <span>{testSeries.singleTestSeries.name}</span>
                </div>
                <React.Fragment>
                    <BreadcrumbBar history={history}
                                   selectedSubject={testSeries && testSeries.singleTestSeries && testSeries.singleTestSeries.name}
                                   parentLink="Test Series" handleBreadcrumbLink={handleBreadcrumbLink}/>
                    <CTabs nav={tabBarList} setActiveTab={selectedTab} handleTabChange={handleTabChange}/>
                    <TestSeriesQuiz selectedIndex={selectedTab}
                                    history={history} lang={language}
                                    handleTabChange={handleTabChange} quizGroups={testSeries.singleTestSeries.quizGroup}
                                    testSeriesId={testSeries.singleTestSeries._id}
                                    singleTestSeriesLoading={testSeries.singleTestSeriesLoading}/>

                </React.Fragment>

            </div>
        </div>
    );
}

export default TestSeriesDetail;
