import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import QuizFeedbackModal from "./FeedbackModal/quizFeedbackModal";
import feedback from "../../constants/feedbackResponse";
import QuizResultHeaderLoader from "../common/loaders/QuizResultHeaderLoader";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { saveQuizFeedback } from "examsbook-react-sdk/actions/saveFeedback";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";
import { useScreenWidth } from "examsbook-react-sdk/custom-hooks";

/**
 * Quiz results header.
 * @param props
 * @returns {*}
 * @constructor
 */
function QuizResultHeader(props) {
    const history = useHistory();
    const [ isMobile, setIfMobile ] = useState(false);
    const screenWidth = useScreenWidth();
    useEffect(() => {
        setIfMobile(screenWidth <= 767);
    }, [ screenWidth ]);

    const t = useTranslations();
    const dispatch = useDispatch();
    let { quizResults, quizId } = props;
    let status = quizResults.status;
    quizResults = quizResults && quizResults.data && quizResults.data.headerData ?
        quizResults.data.headerData : {};
    const [ isFeedbackPopupOpen, setIsFeedbackPopupOpen ] = useState(false);
    const [ popupCloseAfterSubmit, setPopupCloseAfterSubmit ] = useState(true);
    const [ ratingIndex, setRatingIndex ] = useState(0);

    function openFeedbackModal(index) {
        setIsFeedbackPopupOpen(true);
        setRatingIndex(index + 1);
    }

    function saveQuizFeedbackFn(id, data) {
        dispatch(saveQuizFeedback(id, data));
        setPopupCloseAfterSubmit(false);
        setIsFeedbackPopupOpen(false);
    }

    function closeQuizFeedbackModel() {
        setRatingIndex(0);
        setIsFeedbackPopupOpen(false);
    }

    function renderLoader() {
        return (
            <div className="loader-box">
                <div className="loader-content">
                    <QuizResultHeaderLoader/>
                </div>
            </div>
        );
    }

    function renderList() {
        let rating = ratingIndex;
        if (quizResults && quizResults.rating) {
            rating = quizResults.rating;
        }

        function goToDashboard() {
            history.push('/dashboard');
        }

        return (
            <React.Fragment>
                <div className="mobile-back-to-test">
                    <ArrowBackIcon onClick={goToDashboard}/>
                    <h4>{quizResults.name}</h4>
                </div>
                <div className="rating-screen">
                    {!isMobile ? <h4>{quizResults.name}</h4> : null}
                    <div className="rating-star">
                        <span
                            onClick={!quizResults.rating && popupCloseAfterSubmit ? () => openFeedbackModal() : undefined}>{t('RATE_QUIZ')}
                        </span>
                        <ul>
                            {
                                feedback.ratings.map((value, index) => {
                                    return (<li key={index}
                                                onClick={!quizResults.rating && popupCloseAfterSubmit ? () => openFeedbackModal(index) : undefined}>
                                        <button data-tooltip={t(value)}><i
                                            className={'fa ' + (index < rating ? 'fa-star' : 'fa-star-o')}/></button>
                                    </li>);
                                })
                            }
                        </ul>
                    </div>
                </div>

                <QuizFeedbackModal rating={rating}
                                   quizId={quizId}
                                   closeQuizFeedbackModel={closeQuizFeedbackModel}
                                   openFeedbackModal={openFeedbackModal}
                                   isFeedbackPopupOpen={isFeedbackPopupOpen}
                                   popupCloseAfterSubmit={popupCloseAfterSubmit}
                                   saveQuizFeedback={saveQuizFeedbackFn}
                />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {
                status !== 'PENDING' && quizResults.name ? renderList() : renderLoader()
            }
        </React.Fragment>
    );
}

export default QuizResultHeader;
