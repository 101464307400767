import status from "../constants/actionStatus";
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    status: status.PENDING,
    data: {}
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'GET_QUIZ_RESULT_START':
      return {
        status: 'PENDING',
        data: action.payload
      };
    case 'GET_QUIZ_RESULT_SUCCESS':
      return {
        status: 'SUCCESS',
        data: action.payload
      };
    case 'GET_QUIZ_RESULT_FAIL':
      return {
        status: 'ERROR',
        data: action.payload
      };
    default:
      return state;
  }
});