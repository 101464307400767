import { useDispatch, useSelector } from "react-redux";
import i18n from "examsbook-react-sdk/translations";
import { updateUser } from "examsbook-react-sdk/actions/getUser";

/**
 * Hook to set default language of user.
 * this returns language and setter.
 * @returns {{defaultLanguage: (*|string), setUserLanguage: setUserLanguage}}
 */
export default function useUserLanguage() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const language = user.language || localStorage.getItem("language");
  function setUserLanguage(lang) {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
    if (lang) {
      dispatch(updateUser(user._id, {
        language: lang
      }));
    }
  }
  return {
    language,
    setUserLanguage
  };
}