import React from 'react';
import { useSelector } from "react-redux";

import TabContainer from "./../Tabs/TabContainer";
import FilteredQuizList from "../QuizList/FilteredQuizList";

import {
    useAllTestList,
    useTranslations
} from "examsbook-react-sdk/custom-hooks";

/**
 * CompletedTestList main page component.
 */

function CompletedTestList(props) {
    const user = useSelector((state) => state.user);
    const { completedTests } = useAllTestList();
    const t = useTranslations();
    const { selectedIndex, subExamList, handleTabChange } = props;
    return (
        <div className="completed-test-container">
            {props.selectedIndex === 2 && <TabContainer className="Attempted">
                <FilteredQuizList user={user} quizList={completedTests}
                                  activeIndex={selectedIndex}
                                  subExamList={subExamList}
                                  noDataMessage="You have not completed any test so far. Take a test now." t={t}
                                  handleTabChange={handleTabChange}/>
            </TabContainer>}

        </div>
    );
}

export default CompletedTestList;
