import React from 'react';
import Chart from '../Charts/PieChart';
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";

/**
 * Quiz question distribution simple bar chart.
 * @param props
 * @returns {*}
 * @constructor
 */
const QuizQuestionDistributionChart = props => {
    const t = useTranslations();
    const { quizResults } = props;
    const chartData = quizResults && quizResults.data && quizResults.data.chartData ?
        quizResults.data.chartData : {};

    const timeSpentAnalaysis = chartData.timeSpentAnalaysis ? chartData.timeSpentAnalaysis : {};

    const pieChartData = [ { name: t('correct'), value: chartData.correct },
        { name: t('incorrect'), value: chartData.incorrect },
        { name: t('unattempted'), value: chartData.notAnswered } ];

    const pieChartData2 = [ { name: t('correct_answer'), value: parseFloat(timeSpentAnalaysis.correctTimeSpent) },
        { name: t('incorrect_answer'), value: parseFloat(timeSpentAnalaysis.inCorrectTimeSpent) },
        { name: t('unattempted_answer'), value: parseFloat(timeSpentAnalaysis.unAnsweredTimeSpent) },
        { name: t('unused_time'), value: parseFloat(timeSpentAnalaysis.unUsedTime) } ];

    const Colors = [ '#5F4BAB', '#E74C3C', '#F0B27A', "#D7DBDD" ];
    const ChartDesign = { width: 340, height: 300 };
    const pieDesign = {
        cx: 160,
        cy: 125,
        innerRadius: 60,
        outerRadius: 80,
        fill: '#8884d8',
        paddingAngle: 10,
        dataKey: 'value'
    };

    return (
        <div className="question-bargraph">
            <h4>{t('question_distribution')}</h4>
            <div className="area-chart">
                <span style={{ display: 'inline-block', position: 'relative' }}>
                     <Chart pieChartData={pieChartData} Colors={Colors} ChartDesign={ChartDesign}
                            pieDesign={pieDesign}/>
                   <div className="progress-content" style={{ top: 215 }}><b>{t('ANSWER_DISTRIBUTION')}</b></div>
                </span>

                <span style={{ display: 'inline-block', position: 'relative' }}>
                    <Chart pieChartData={pieChartData2} Colors={Colors} ChartDesign={ChartDesign}
                           pieDesign={pieDesign}/>
                     <div className="progress-content"><b>Time Spent</b></div>
                </span>
                <div>
                    <h5><i className="fa fa-arrow-right "></i> Answer Distribution</h5>
                    <p> {t('YOU_HAVE_ATTEMPTED')} {chartData.correct + chartData.incorrect} {t('QUESTIONS_OUT_OF_WHICH')} {chartData.correct} {t('QUESTIONS_ARE_CORRECT')}, {chartData.incorrect} {t('QUESTIONS_ARE_INCORRECT_AND')} {chartData.notAnswered} {t('QUESTIONS_ARE_UNATTEMPTED')}. </p>
                </div>
            </div>
        </div>
    );
};

export default QuizQuestionDistributionChart;
