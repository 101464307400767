import React from 'react';
import './../QuestionBox/questionBox.css';
import baselineCloud from '../../../assets/images/baseline-cloud.png';
import { reactGaEvent } from "examsbook-react-sdk/actions/Common";
import { CNavLink } from "../../common/Header/c/CHeader";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";

function QuizError() {
    const t = useTranslations();
    return (
        <div className="quiz-error">
            <div className="quiz-error-block">

                <div className="quiz-error-img">
                    <img src={baselineCloud} alt=""/>
                </div>
                <div className="quiz-error-text">
                    <h4>We have notified our awesome team. We will sort out this issue soon.</h4>
                </div>
                <div className="quiz-error-btn" onClick={reactGaEvent("Test Error", "Go To Tests")}>
                    <CNavLink to="/dashboard">
                        {t('Go to Tests')}
                    </CNavLink>
                </div>
            </div>
        </div>
    );
}

export default QuizError;
