import React from 'react';
import Chart from '../../Charts/PieChart';

function PracticeQuestionProgressStatus(props) {
    const { firstCount, secondCount, thirdCount, fourthCount, fifthCount, reportName, Labels, cast } = props;
    const pieChartData = [ { name: Labels.correct, value: thirdCount },
        { name: Labels.incorrect, value: fourthCount },
        { name: Labels.skipped, value: fifthCount } ];
    const Colors = [ '#2a7c2a', '#bb0000', '#899287' ];
    const ChartDesign = { width: 260, height: 250 };
    const pieDesign = {
        cx: 110,
        cy: 90,
        innerRadius: 42,
        outerRadius: 47,
        fill: '#8884ff',
        paddingAngle: 10,
        dataKey: 'value'
    };
    return (
        <React.Fragment>
            <div className="col-md-4">
                <div className="tb-card">
                    <h4>{reportName} : {firstCount ? firstCount : 0}%</h4>
                    <div className="progress-chart">
                        {(firstCount !== 0 || secondCount !== 0 || thirdCount !== 0) ?
                            <Chart pieChartData={pieChartData} Colors={Colors} ChartDesign={ChartDesign}
                                   pieDesign={pieDesign}/> :
                            <Chart pieChartData={[ { name: 'no data', value: 1 } ]} Colors={[ "#C2C4BB" ]}
                                   ChartDesign={ChartDesign}
                                   pieDesign={pieDesign}/>
                        }
                        <div className="progress-content">
                            <h4>{thirdCount + fourthCount + ' / ' + (thirdCount + fourthCount + fifthCount)}</h4>{cast}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PracticeQuestionProgressStatus;
