import { useGetQuizResult, useTranslations } from "examsbook-react-sdk/custom-hooks";
import React, { useRef } from "react";
import QuizResultReportTable from "./QuizResultReportTable";
import ReactToPrint from 'react-to-print';
import Button from "@material-ui/core/Button/Button";

import '../../components/QuizResultScreen/ButtonGroup.scss';

class ComponentToPrint extends React.PureComponent {
    render() {
        const { questions, totalMarks, quizId, t } = this.props;
        return (
            <QuizResultReportTable
                t={t}
                totalMarks={totalMarks}
                quizId={quizId}
                questions={questions}
            />
        );
    }
}

function QuizResultPdf(props) {
    const { quizId } = props;
    const quizResult = useGetQuizResult(quizId);
    const componentRef = useRef();
    const t = useTranslations();

    const questions =
        quizResult &&
        quizResult.data &&
        quizResult.data.quesResponses &&
        quizResult.data.quesResponses.quesList &&
        quizResult.data.quesResponses.quesList.map((ques) => ques.question);

    const totalMarks = questions && questions
        .map((ques, i) => ques.mark)
        .reduce(function (acc, val) {
            return acc + val;
        }, 0);
    return (
        <>
            <ReactToPrint
                documentTitle={quizId}
                trigger={() => <Button className="view-solution">{t('download_pdf')}</Button>}
                content={() => componentRef.current}
            />
            <div style={{ display: 'none' }}>
                <ComponentToPrint ref={componentRef}
                                  t={t}
                                  questions={questions}
                                  totalMarks={totalMarks}
                                  quizId={quizId}/></div>
        </>
    );
}

export default QuizResultPdf;
