import React from 'react';
import ContentLoader from 'react-content-loader';

function PracticeQuestionChainLoader() {
    return (
        <div>
            <div className="card-list">
                <ContentLoader
                    height={340}
                    width={195}
                    speed={1}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb">
                    <rect x="7" y="0" rx="0" ry="0" width="190" height="10"/>
                    <circle cx="13" cy="13.5" r="2.5"/>
                    <rect x="19" y="12" rx="5" ry="5" width="170" height="4"/>
                    <circle cx="13" cy="20" r="2.5"/>
                    <rect x="19" y="18" rx="5" ry="5" width="170" height="4"/>
                    <circle cx="13" cy="26" r="2.5"/>
                    <rect x="19" y="24" rx="5" ry="5" width="170" height="4"/>
                    <circle cx="13" cy="32" r="2.5"/>
                    <rect x="19" y="30" rx="5" ry="5" width="170" height="4"/>

                </ContentLoader>
            </div>
        </div>
    );
}

export default PracticeQuestionChainLoader;
