import React from 'react';
import { NavLink } from 'react-router-dom';

export const CHeader = ({ children, classes }) => {
    return (
        <div className={classes.root}>
            {children}
        </div>
    );
};

export const CNavLink = ({ children, to, href, isExact }) => {
    if (href) {
        return (
            <a href={href}>
                {children}
            </a>
        )
    }
    return (
        <NavLink exact={isExact || false} to={to}>
            {children}
        </NavLink>
    );
};
