/**
 * APi wrapper on exams for frontend.
 */
import axios from './axiosConfig';

/**
 * Get top level exams list
 * @param queryCondition
 * @returns {Function}
 */
export function mainExamList() {
  let queryCondition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return axios.get('/api/v1/main-exam-list').then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    return error;
  });
}

/**
 * Get child level exams list of one particular category.
 * @param id
 * @returns {Function}
 */
export function childExamList(id) {
  return axios.get('/api/v1/categories?parentCat=' + id + '&type=EXAM&fields=_id,name,shortDescription,quizCount').then(response => {
    // handle success
    let data = response.data;
    // we only need exams which has more then one quiz
    if (data.category) {
      data.category = data.category.filter(cat => {
        return cat.quizCount > 0;
      });
    }
    return data;
  }).catch(error => {
    return error;
  });
}

/**
 * Get exam with subjects list.
 * @param id
 * @returns {Function}
 */
export function getSubjectList(selectedExamId) {
  return axios.get('/api/v1/subject-list/' + selectedExamId).then(response => {
    // handle success
    return response.data;
  });
}

/**
 * Get exam with subjects list.
 * @param id
 * @returns {Function}
 */
export function examWithSubjects(id) {
  return axios.get('/api/v1/subject-topics/' + id).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    return error;
  });
}

/**
 * Get subExams list.
 * @param selectedExamId
 * @returns {Function}
 */
export function getSubExams(selectedExamId) {
  return axios.get('/api/v1/sub-exams-list/' + selectedExamId).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    return error;
  });
}

/**
 * Get topic wise ananlysis report.
 * @param selectedExamId
 * @returns {Function}
 */
export function getTopicsWiseAnalysisReport(selectedExamId) {
  return axios.get('/api/v1/question-topic-wise-analysis/' + selectedExamId).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    return error;
  });
}