import { getProgressBarDetails } from "examsbook-react-sdk/actions/getSelfLearningAction";
import { selfLearningVideoUrl } from "examsbook-react-sdk/actions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getDefaultSupportDataAction, getSupportAction } from "examsbook-react-sdk/actions/getSupportAction";

const baseUrl = process.env.REACT_APP_DYNAMIC_URL + "/videos/uploaded-videos/";

function useSelfLearningContent(props = {}) {
  const { 
    activeItem, 
    selectedCourseId, 
    videoProgress, 
    handleVideoProgress,
    pdfOpen,
    videoRefresh,
    onVideoRefresh,
  } = props;
  
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedVideo = useSelector((state) => state.selfLearningVideoUrl);
  const defaultVideo = activeItem?.data?.courseSectionData.find(
    (subValue) => subValue.refModel === "RecordedVideo"
  );

  // Quiz
  const [showComponent, setShowComponent] = useState(false)
  const [quizIsStarted, setQuizStarted] = useState(false);
  const [showInstructionBox, setInstructionBox] = useState(true);
  // Video
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [currentVideo, setCurrentVideo] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [videoPlayback, setVideoPlayback] = useState(false);
  const currentVideoRef = useRef(null)
  const playerRef = useRef(null);
  const onReadyCalled = useRef(false);
  const user = useSelector((state) => state.user);
  const [defaultSupportData, setDefaultSupportData] = useState([]);
  const [dynamicSeoData, setDynamicSeoData] = useState([]);

  // Video Functions
  const handleVideoPlayback = (value) => {
    if (typeof value === "boolean") {
      setVideoPlayback(value)
    } else {
      setVideoPlayback(!videoPlayback);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  }

  const handleResume = () => {
        setShowPopup(false);
    setVideoPlayback(true);
    if (playerRef.current && currentVideo) {
      const videoPercent = videoProgress?.[currentVideo.videoKey] ?? currentVideo.percent;
      const totalDuration = playerRef.current.getDuration();
      const currentTime = (videoPercent / 100) * totalDuration;
      playerRef.current.seekTo(currentTime);
    }
  };

  const getSupportApiData = async () => {
    try {
      await dispatch(getSupportAction()).then((res) => {
        setDynamicSeoData(res);
      });
    } catch (error) {
      console.log("Error -", error.message);
    }
  };

  const getDefaultSeoSettingApiData = async () => {
    try {
      dispatch(getDefaultSupportDataAction()).then((res) => {
        setDefaultSupportData(res);
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getSupportApiData();
    getDefaultSeoSettingApiData();
  }, [user]);

  const handleStartFromBeginning = () => {
    setShowPopup(false);
    setVideoPlayback(true);
    if (playerRef.current) {
      playerRef.current.seekTo(0);
    }
  };

  const handleVideoOnReady = () => {
    setIsVideoLoaded(true);
    if (!onReadyCalled.current && currentVideo) {
      if (currentVideo.percent > 0 && currentVideo.percent < 100) {
        setVideoPlayback(false);
        setShowPopup(true);
      } else {
        setVideoPlayback(true);
      }
      onReadyCalled.current = true;
    }
  };

  const callProgressApi = ({ prevVideoKey, videoRefreshFn, videoRefreshVal, prevSectionId, prevVideoId }) => {
    const currentTime = playerRef.current?.getCurrentTime();
    const totalDuration = playerRef.current?.getDuration();
    const percentageWatched = Math.floor((currentTime / totalDuration) * 100);

    const data = {
      courseId: currentVideoRef.current?.courseId,
      sectionId: prevSectionId ?? currentVideoRef.current?.sectionId,
      dataId: prevVideoId ?? currentVideoRef.current?.id,
      percent: percentageWatched,
    };

    const localVideoRefresh = videoRefreshVal ?? videoRefresh;
    const localVideoRefreshFn = videoRefreshFn ?? onVideoRefresh;
    dispatch(getProgressBarDetails(data));
    localVideoRefreshFn(!localVideoRefresh);

    if (percentageWatched > 0) {
      const localVideoKey = prevVideoKey ?? currentVideoRef.current.videoKey;
      handleVideoProgress(localVideoKey, percentageWatched);
    }
  };

  const dynamicVideoPlayerSettings = dynamicSeoData?.data ?.find((subValue) => subValue.key === "Video Player")?.value.toLowerCase().trim();
  const defaultVideoPlayerSettings = defaultSupportData?.data?.["Video Player"]?.find((subValue) => subValue.key === "Video Player")?.defaultValue;

  const handleCurrentVideo = () => {
    let videoData = {};
    let contentData = {};

    if (selectedVideo?.data?._id && selectedVideo?.sectionId) {
      const videoKey = `${activeItem?.data?._id}-${selectedVideo.data._id}`;
      videoData = {
        id: selectedVideo.data._id,
        name: selectedVideo.data.name,
        percent: videoProgress?.[videoKey] ?? selectedVideo.data.percent ?? 0,
        videoKey,
        contentData: selectedVideo.data.contentData[0],
        refModel: selectedVideo.data.refModel,
      };
      contentData = selectedVideo.data.contentData[0];
    } else if (selectedVideo === 0 && defaultVideo?._id) {
      const videoKey = `${activeItem?.data?._id}-${defaultVideo._id}`;
      videoData = {
        id: defaultVideo._id,
        name: defaultVideo.name,
        percent: videoProgress?.[videoKey] ?? defaultVideo.percent,
        videoKey,
        contentData: defaultVideo.contentData[0],
        refModel: defaultVideo.refModel,
      };
      contentData = defaultVideo.contentData[0];
    }

    if (contentData?.file) {
      videoData.type = "file";
      videoData.videoUrl = baseUrl + contentData.file;
    } else if (contentData?.url) {
      videoData.type = "url";
      videoData.videoUrl = contentData.url;
    }

    videoData.courseId = selectedCourseId
    videoData.sectionId = activeItem?.data?._id

    setCurrentVideo((prev) => {
      // to stop video and save progress when switching
      if (prev?.refModel !== 'Quiz' && prev.id !== videoData.id) {
        setVideoPlayback(false);
        callProgressApi({ prevVideoKey: prev.videoKey, prevSectionId: prev.sectionId, prevVideoId: prev.id });
      }
      return videoData
    });

    if (videoData.refModel !== 'Quiz') {
      currentVideoRef.current = videoData;
    }

    // to prevent infinite call of onReady function
    if (selectedVideo?.sectionId && currentVideo?.id && videoData.id !== currentVideo.id) {
      onReadyCalled.current = false;
      setIsVideoLoaded(false);
      setVideoPlayback(false);
    }
  }

  useEffect(() => {
      setVideoPlayback(false);
  }, [pdfOpen]);

  useEffect(() => {
    if (selectedVideo === 0 || selectedVideo?.sectionId) {
      handleCurrentVideo();
    }
  }, [defaultVideo?._id, selectedVideo?.data?._id, activeItem, selectedCourseId]);

  // Quiz Functions
  const toggleInstructionBox = () => {
    setInstructionBox(!showInstructionBox);
  }

  const startQuiz = () => {
    history.push({
      pathname: "/quiz/" + currentVideo.contentData.mode + "/" + currentVideo.contentData.slug,
      state: currentVideo.contentData,
    });
    setQuizStarted(true);
    setInstructionBox(false);
  }

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowComponent(true);
  //   }, 1000);
  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    dispatch(selfLearningVideoUrl(0));

    return () => {
      onReadyCalled.current = false;
      callProgressApi({ videoRefreshVal: videoRefresh, videoRefreshFn: onVideoRefresh });
    }
  }, [])
  
  return {
    playerRef,
    defaultVideo,
    selectedVideo,
    currentVideo,
    videoPlayback,
    setVideoPlayback,
    handleVideoPlayback,
    handleVideoOnReady,
    showPopup,
    closePopup,
    handleResume,
    handleStartFromBeginning,
    callProgressApi,
    defaultVideoPlayerSettings,
    dynamicVideoPlayerSettings,
    showComponent,
    toggleInstructionBox,
    startQuiz,
    quizIsStarted,
    isVideoLoaded,
  };
}

export default useSelfLearningContent;
