import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from "@material-ui/core/Button/Button";
import { CNavLink } from "../components/common/Header/c/CHeader";
import Question from '../components/Question/question';
import { useTranslations, useAnalyticsPageView } from "examsbook-react-sdk/custom-hooks";
import { getSavedQuestions, toggleSavedQuestion } from 'examsbook-react-sdk/actions';

/**
 * View for saved question view
 */
function SavedQuestionView(props) {
    const { selectedIndex, match } = props;
    const t = useTranslations();
    useAnalyticsPageView();
    const questionsObj = useSelector((state) => state.questionList);
    const questionList = questionsObj.questions;
    //get question number
    let index = getQuestionIndex(questionList, match);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSavedQuestions());
    }, [ user ]);

    /**
     * Remove saved question.
     * @param ques
     */
    function toggleSavedQuestionFn(ques) {
        dispatch(toggleSavedQuestion(ques._id));
    }

    function selectQuestion(question) {
        props.history.push('/saved-questions/' + question.slug);
    }

    return (
        <React.Fragment>
            {
                questionList && questionList.length ?
                    <div className="saved-question-list">
                        <Question toggleSavedQuestion={() => toggleSavedQuestionFn(questionList[parseInt(index, 10)])}
                                  index={parseInt(index, 10) + 1}
                                  question={questionList[parseInt(index, 10)]} totalLength={questionList.length} t={t}
                                  setTestSeriesIndex={props.setTestSeriesIndex}
                                  selectedIndex={selectedIndex}/>
                        <div className="solution-right-part">
                            <CNavLink to="/saved-questions">
                                <div className="bar-icon">
                                    <i className="fa fa-bars"/>
                                </div>
                            </CNavLink>
                            <Button disabled={parseInt(index, 10) === 0}
                                    onClick={() => selectQuestion(questionList[parseInt(index, 10) - 1])}>
                                <i className="fa fa-chevron-left"/>{t('previous')}
                            </Button>
                            <Button disabled={parseInt(index, 10) === (questionList.length - 1)}
                                    onClick={() => selectQuestion(questionList[parseInt(index, 10) + 1])}>
                                {t('next')}<i className="fa fa-chevron-right"/>
                            </Button>
                        </div>

                    </div> : null
            }
        </React.Fragment>
    );
}

function getQuestionIndex(questionList, match) {
    let index;
    for (let i in questionList) {
        if (questionList[i].slug === match.params.slug) {
            index = i;
        }
    }
    return index;
}

export default SavedQuestionView;
