export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (action.type === 'PROMOTIONS_WAITING') {
    return {
      pending: true,
      success: false,
      data: []
    };
  } else if (action.type === 'PROMOTIONS_SUCCESS') {
    return {
      pending: false,
      success: true,
      data: action.payload
    };
  } else if (action.type === 'PROMOTIONS_ERROR') {
    return {
      pending: false,
      success: false,
      data: []
    };
  }
  return state;
});