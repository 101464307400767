import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, Legend, Label } from 'recharts';

export default class Chart extends PureComponent {

    render() {
        const { pieChartData, Colors, ChartDesign, pieDesign, pieChartName } = this.props;
        const data = pieChartData;
        const COLORS = Colors;
        return (
            <PieChart width={ChartDesign.width} height={ChartDesign.height} onMouseEnter={this.onPieEnter}>
                <Pie
                    data={data}
                    cx={pieDesign.cx}
                    cy={pieDesign.cy}
                    innerRadius={pieDesign.innerRadius}
                    outerRadius={pieDesign.outerRadius}
                    fill={pieDesign.fill}
                    paddingAngle={pieDesign.paddingAngle}
                    dataKey={pieDesign.dataKey}
                    label>
                    {
                        data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>)
                    }
                    <Label value={pieChartName} position="center"/>
                </Pie>

                <Legend/>
            </PieChart>
        );
    }
}
