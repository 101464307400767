export default {
    responses: [
        {
            "key": "EXACTLY_SAME_AS_ACTUAL_EXAM",
            "value": "Exactly same as actual exam"
        }, {
            "key": "LIKED_SOLUTION_METHODS_AND_TRICKS",
            "value": "Liked solution methods and tricks"
        }, {
            "key": "INTERESTING_AND_CONCEPTUAL_QUESTIONS",
            "value": "Interesting and conceptual questions"
        }
    ],
    ratings: [ 'very_bad', 'bad', 'average', 'good', 'very_good' ]
};
