import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import courseAssignedImg from "../../assets/images/courseAssigned.png"
import courseExpiringImg from "../../assets/images/courseExpiring.png"
import courseCompletedImg from "../../assets/images/courseCompleted.png"
import courseAssignedGif from "../../assets/images/courseAssigned.gif"
import courseExpiringGif from "../../assets/images/courseExpiring.gif"
import courseCompletedGif from "../../assets/images/courseCompleted.gif"

import { useDispatch, useSelector } from "react-redux";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useTheme } from "../../constants/themeProvider";
import { getUserCourse } from "examsbook-react-sdk/actions";

function InfoCard({ setCourseData, setCategorySelect }) {
  const theme = useTheme();
  const [courseCount, setCourseCount] = useState("");
  const [count, setCount] = useState(0);
  const [courseCompletedCount, setCourseCompletedCount] = useState(0);
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState({
    assigned: false,
    expiring: false,
    completed: false,
  });
  
  const handleMouseEnter = (category) => {
    setIsHovered(prevState => ({ ...prevState, [category]: true }));
  };

  const handleMouseLeave = (category) => {
    setIsHovered(prevState => ({ ...prevState, [category]: false }));
  };
  
  const [selectedCategory, setSelectedCategory] = useState('assigned');
  const t = useTranslations();
  const user = useSelector((state) => state.user);

  const handleCourseExpiryDate = (courses = []) => {
    const currentDate = Date.now();
    const { expiringSoonCount, courseCompleted } = courses.reduce((acc, { endDate, progressPercent }) => {
        if (!endDate) return acc;
        const endDateTimestamp = typeof endDate === "number" ? endDate : Date.parse(endDate);
        if (isNaN(endDateTimestamp)) return acc;
        const differenceInDays = Math.ceil((endDateTimestamp - currentDate) / (1000 * 60 * 60 * 24));
        if (currentDate > endDateTimestamp || progressPercent === 100) {
          acc.courseCompleted += 1;
        } else if (differenceInDays <= 7) {
          acc.expiringSoonCount += 1;
        }
        return acc;
      },
      { expiringSoonCount: 0, courseCompleted: 0 }
    );
    setCount(expiringSoonCount);
    setCourseCompletedCount(courseCompleted);
  };

  const getUserCourseApiData = async (userId) => {
    try {
      dispatch(getUserCourse(userId))
      .then((res) => {
        setCourseCount(res?.data[0]?.courses);
        handleCourseExpiryDate(res?.data[0]?.courses);
        setCourseData(res?.data[0]?.courses);
      });
    } catch (error) {
      console.log('Error', error.message);
    }
  };

  useEffect(() => {
    if (user?._id) {
      getUserCourseApiData(user?._id);
    }
  }, [user._id]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCategorySelect(category);
  };


const styles = {
  title: {
    color: "#0C3450",
    fontSize: "2rem",
    fontWeight: 700,
    lineHeight: '36px',
    margin: '0px 4px',
    '@media (max-width:900px)': {
      fontSize: "30px",
    },
    '@media (max-width:1441px)': {
      fontSize: '1.8rem'
    },
    '@media (max-width:700px)': {
      fontSize: "24px",
    },
  },
  subtitle: {
    color: "#393939",
    fontSize: "1.2rem",
    fontWeight: 400,
    margin: '0px 4px',
    lineHeight: '24px',
    '@media (max-width:1441px)': {
      fontSize: '1rem',
      lineHeight: '18px'
    },
    '@media (max-width:555px)': {
      minHeight: "40px",
      fontSize: '14px',
      padding: '0px 4%'
    },
    '@media (min-width:960px) and (max-width:1200px)': { 
      fontSize: '1em',
    },
    '@media (max-width:959px)': {
      fontSize: '1.2em',
    },
    '@media (min-width:550px) and (max-width:600px)': { 
      padding: '0px 16px'
    },
  },
  
  mainDiv: {
    paddingBottom: '16px',
    "&.MuiGrid-spacing-xs-5": {
      width: "calc(100% + 10px)",
    },
    '@media (max-width:960px)': {
      paddingTop: "20px",
    },
  },
  fragment: {
    '@media (min-width:1200px)': {
      marginLeft: "21px !important",
    },
    '@media (min-width:900px) and (max-width:1200px)': { 
      marginLeft: "19px !important",
    },
    '@media (max-width:900px)': {
      textAlign: "center",
      paddingTop: "0px !important",
    },
    '@media (max-width:700px)': {
      padding: "0px 10px 15px !important",
    },
  },
  imgBlock: {
    '@media (min-width:1920px)': {
      textAlign: "center",
    },
    '@media (max-width:900px)': {
      textAlign: "center",
      paddingBottom: "4px !important",
    },
    '@media (max-width:700px)': {
      paddingTop: "15px !important",
      paddingBottom: "0px !important",
    },
    "& svg": {
      '@media (min-width:900px) and (max-width:1200px)': { 
        width: "65px",
        height: "65px",
      },
      '@media (max-width:700px)': {
        width: "30px",
        height: "30px",
      },
    },
  },
  imgAlign: {
    height: '80px',
    width: '80px'
  },
  iconDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  selectedCourseBox: {
    border: `1px solid ${theme?.secondaryColor}`,
    boxShadow: '1px 1px 8px 1px #0C345033',
  },
}

const classes = useStyles(styles)();

  return (
    <Grid container className={classes.mainDiv}>
      <Grid item xs={4} className={classes.dataGrid} onClick={() => handleCategoryClick('assigned')}>
        <Grid container className={classes.wrapper}>
          <div className={`course-count-main-box ${selectedCategory === 'assigned' ? classes.selectedCourseBox : ''}`}
           onMouseEnter={() => handleMouseEnter('assigned')}
           onMouseLeave={() => handleMouseLeave('assigned')}>
            <div className={classes.iconDiv}>
            <img className={classes.imgAlign} src={isHovered.assigned ? courseAssignedGif : courseAssignedImg}/>
            </div>
            <div className="course-count-box">
              <p className={classes.title} style={{color: theme?.secondaryColor}}>{courseCount?.length !== undefined ? courseCount?.length : 0}</p>
              <p className={classes.subtitle}>{t('Courses Assigned')}</p>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={4} className={classes.dataGrid} onClick={() => handleCategoryClick('expiring')}>
        <Grid container className={classes.wrapper}>
          <div className={`course-count-main-box ${selectedCategory === 'expiring' ? classes.selectedCourseBox : ''}`}
          onMouseEnter={() => handleMouseEnter('expiring')}
          onMouseLeave={() => handleMouseLeave('expiring')}>
            <div className={classes.iconDiv}>
            <img className={classes.imgAlign} src={isHovered.expiring ? courseExpiringGif : courseExpiringImg}/>
            </div>
            <div className="course-count-box">
              <p className={classes.title} style={{color: theme?.secondaryColor}}>{count}</p>
            <p className={classes.subtitle}>{t('Courses Expiring')}</p>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={4} className={classes.dataGrid} onClick={() => handleCategoryClick('completed')}>
        <Grid container className={classes.wrapper}>
          <div className={`course-count-main-box course-completed-align ${selectedCategory === 'completed' ? classes.selectedCourseBox : ''}`}
          onMouseEnter={() => handleMouseEnter('completed')}
          onMouseLeave={() => handleMouseLeave('completed')}>
            <div className={classes.iconDiv}>
            <img className={classes.imgAlign} src={isHovered.completed ? courseCompletedGif : courseCompletedImg}/>
            </div>
            <div className="course-count-box">
              <p className={classes.title} style={{color: theme?.secondaryColor}}>{courseCompletedCount}</p>
              <p className={classes.subtitle}>{t('Courses Completed')}</p>
              </div>
            </div>
          </Grid>
      </Grid>
      </Grid>
  );
}

export default InfoCard;
