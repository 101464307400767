import status from "../constants/actionStatus";
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    recentCourseList: {}
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case "RECENT_COURSE_LIST_REQUEST":
      return {
        status: status.PENDING,
        recentCourseList: {}
      };
    case "RECENT_COURSE_LIST_SUCCESS":
      return {
        status: status.SUCCESS,
        recentCourseList: action.payload
      };
    case "RECENT_COURSE_LIST_FAILURE":
      return {
        status: status.ERROR,
        recentCourseList: {}
      };
    default:
      return state;
  }
});