import { useEffect, useState } from "react";
import { timeRemainingFromNow } from "examsbook-react-sdk/common";
import useInterval from "./useInterval";

/**
 * Not sure how much correct it is as it was taken directly from component.
 * @param testData
 * @returns {any}
 */
export default function useTimeRemainingLiveVideo(liveVideoList) {
  const [timeRemaining, setTimeRemaining] = useState(getRemainingTime());
  useInterval(countDown, 1000);
  function getRemainingTime() {
    const _aa = [];
    if (liveVideoList) {
      if (!!liveVideoList && liveVideoList.startDate > new Date().getTime()) {
        const liveVideoData = timeRemainingFromNow(liveVideoList.startDate);
        ;
        _aa.push(liveVideoData);
      } else {
        return 0;
      }
    }
    return _aa;
  }
  function countDown() {
    const liveVideo = getRemainingTime();
    setTimeRemaining(liveVideo);
  }
  return timeRemaining;
}