import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import LayersIcon from '@material-ui/icons/Layers';
import CompletedTestList from "../components/CompletedTest/completedTestList";
import { useSubExamList } from "examsbook-react-sdk/custom-hooks";
import useAnalyticsPageView from "examsbook-react-sdk/custom-hooks/useAnalyticsPageView";
import CTabs from "../components/Tabs/CTabs";
import DashboardIcon from '@material-ui/icons/Dashboard';
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import { useHistory } from "react-router-dom";

/**
 * List of completed tests.
 * @param props
 * @returns {*}
 * @constructor
 */
function CompletedTest(props) {
    const t = useTranslations();
    const [ selectedTab, setSelectedTab ] = useState(2);
    const user = useSelector((state) => state.user);
    //get list of sub exams.
    const subExamList = useSubExamList();
    useAnalyticsPageView();
    const history = useHistory();

    //handle tab change.
    function handleTabChange(event, value) {
        setSelectedTab(value);
    }

    let navBarList = [
        {
            label: t('dashboard'),
            ref: 'dashboard',
            show: true,
            icon: <DashboardIcon/>
        }, {
            label: t('all_test'),
            ref: 'all',
            icon: <LayersIcon/>,
            show: true
        }, {
            label: t('COMPLETED_TEST'),
            ref: 'quiz',
            icon: <i className="fa fa-check-square-o  m-r-18 fa-2x" area-hidden="true"/>
        } ];
    if (selectedTab === 0) {
        history.push('/dashboard');
    }
    if (selectedTab === 1) {
        history.push('/tests');
    }

    return (

        <div className="dashboard-mobile-view">
            <div className={'mock-tabs'}>
                <AppBar position="static" className="tabs">
                    <CTabs nav={navBarList} setActiveTab={selectedTab} handleTabChange={handleTabChange}/>
                </AppBar>
                <CompletedTestList selectedIndex={selectedTab} user={user}
                                   handleTabChange={handleTabChange}
                                   subExamList={subExamList.category}/>
            </div>

        </div>
    );
}

export default CompletedTest;
