import React from 'react';
import { useHistory } from "react-router-dom";
import DashboardTestImage from "../../assets/images/books-stack-of-three.png";
import DashboardTestImageMock from "../../assets/images/notepad.png";
import DashboardTestImagePrac from "../../assets/images/test3.png";
import DashboardTestImageSec from "../../assets/images/test4.png";
import DashboardSavedImageSec from "../../assets/images/bookmark.png";
import DashboardReportImageSec from "../../assets/images/bar-chart.png";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useTestSeriesList, useQuizTypeCount } from "examsbook-react-sdk/custom-hooks";

function TestTypeCard(props) {
    const history = useHistory();
    const t = useTranslations();
    const quizTypeCount = useQuizTypeCount();
    const { testSeriesList } = useTestSeriesList();
    return (
        <div>
            <div className="header test-container">
                <div className="header test-card" onClick={() => history.push('/test-series')}>
                    <img src={DashboardTestImage} alt="dashboard logo"/>
                    <div>
                        <span className="card-data">{t('test_series')}</span>
                    </div>
                    <div className="test-btn">
                        <button className="card-test"> {testSeriesList && testSeriesList.length}+ {t('TESTS')}</button>
                    </div>
                </div>
                <div className="header test-card" onClick={() => history.push('/full-mock-tests')}>
                    <img src={DashboardTestImageMock} alt="dashboard logo"/>
                    <div>
                        <span className="card-data">{t('mock test')}</span>
                    </div>
                    <div className="test-btn">
                        <button className="card-test"> {quizTypeCount.mockTests}+ {t('TESTS')}</button>
                    </div>
                </div>
                <div className="header test-card" onClick={() => history.push('/practice-tests')}>
                    <img src={DashboardTestImagePrac} alt="dashboard logo"/>
                    <div>
                        <span className="card-data">{t('PRACTICE_TEST')}</span>
                    </div>
                    <div className="test-btn">
                        <button className="card-test">{quizTypeCount.practiceTests}+ {t('TESTS')}</button>
                    </div>
                </div>
                <div className="header test-card" onClick={() => history.push('/completed-tests')}>
                    <img src={DashboardTestImageSec} alt="dashboard logo"/>
                    <div>
                        <span className="card-data">{t('COMPLETED_TEST')}</span>
                    </div>
                    <div className="test-btn">
                        <button className="card-test"> {quizTypeCount.completed}+ {t('TESTS')}</button>
                    </div>
                </div>
                <div className="header test-card" onClick={() => history.push('/saved-questions')}>
                    <img src={DashboardSavedImageSec} alt="saved quiz"/>
                    <div>
                        <span className="card-data">{t('saved')}</span>
                    </div>
                </div>
                <div className="header test-card" onClick={() => history.push('/reports')}>
                    <img src={DashboardReportImageSec} alt="reports logo"/>
                    <div>
                        <span className="card-data">{t('reports')}</span>
                    </div>
                </div>
            </div>


        </div>

    );
}

export default TestTypeCard;
