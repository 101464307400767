import React from 'react';

/**
 * Practice dashboard header page
 */

function PracticeQuestionsHeaderPage(props) {
    let stats = getDashboardStats(props.subjects);
    return (
        <div className="practice-page">
            <div className="practice-header">

            </div>
            <div className="practice-page-detail">
                <div className="col-4">
                    <h2>{stats.subjectCount}</h2>
                    <h3 translate="">Subjects</h3>
                </div>
                <div className="col-4">
                    <h2>{stats.topicCount}</h2>
                    <h3 translate="">Chapters</h3>
                </div>
                <div className="col-4">
                    <h2>{stats.questionCount}</h2>
                    <h3 translate="">Questions</h3>
                </div>
            </div>
        </div>
    );
}

function getDashboardStats(subjects) {
    let topicCount = 0;
    let questionCount = 0;
    if(subjects && subjects.length) {
    subjects.forEach((subject) => {
        topicCount = topicCount + subject.topics.length;
        questionCount = questionCount + subject.questionCount;
    });
}
    return { topicCount: topicCount, questionCount: questionCount, subjectCount: subjects.length };
}

export default PracticeQuestionsHeaderPage;
