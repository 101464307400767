import React, { Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import { Settings } from '@material-ui/icons';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import Login from '../Auth/Login';
import ForgotPassword from '../Auth/ForgotPassword';
import ChangePassword from '../Auth/ChangePassword';
import MainContent from '../MainContent/MainContent';
import NewDashboard from '../../pages/NewDashboard/new-dashboard';
import PracticeTestTopics from '../../pages/PracticeTestTopics';
import MyCourses from '../../pages/MyCourses';
import Courses from '../CoursesList/Courses';
import LiveClasses from '../../pages/LiveClasses';
import TestSeries from '../../pages/testSeries';
import PracticeTests from '../../pages/practiceTests';
import CertificateList from '../CoursesList/CertificateList';
import MockTests from '../../pages/AllTests';
import FullMockTests from '../../pages/FullMockTests';
import CompletedTest from '../../pages/completedTest';
import TestSeriesDetail from '../../pages/testSeriesDetail';
import TestListByExams from '../../pages/TestListByExams';
import Reports from '../../pages/Reports/reports';
import Survey from '../../pages/Survey/survey';
import SupportTicket from '../../pages/Support/SupportTicket';
import NotificationDetail from '../common/Header/NotificationDetail';
import CourseTopics from '../../pages/CourseTopics';
import TopicMaterial from '../../pages/TopicMaterial';
import SavedQuestionView from '../../pages/savedQuestionView';
import PracticeQuestionView from '../../pages/practiceQuestionView';
import TestSeriesQuestionView from '../../pages/testSeriesQuestionView';
import PracticeQuestions from '../../pages/practiceQuestions';
import PracticeQuestionsSubjectTopic from '../../pages/practiceQuestionsSubjectTopic';
import Quiz from '../../pages/quiz';
import QuizResults from '../../pages/quizResults';
import NotFoundPage from '../common/Errors/NotFoundPage';

export function Routes() {
  const authRoutes = [
    { path: '/login', component: Login },
    { path: '/forgot-password', component: ForgotPassword },
    { path: '/change-password', component: ChangePassword },
  ];

  const appRoutes = [
    { path: '/dashboard', component: NewDashboard },
    { path: '/practice/subject/:subjectId', component: PracticeTestTopics },
    { path: '/tests', component: MockTests },
    { path: '/my-courses', component: MyCourses },
    { path: '/course-list', component: Courses },
    { path: '/live-classes', component: LiveClasses },
    { path: '/test-series', component: TestSeries },
    { path: '/practice-tests', component: PracticeTests },
    { path: '/certificate-list', component: CertificateList },
    { path: '/full-mock-tests', component: FullMockTests },
    { path: '/completed-tests', component: CompletedTest },
    { path: '/test-series/:id/tests', component: TestSeriesDetail },
    { path: '/exams/:id', component: TestListByExams },
    { path: '/reports', component: Reports },
    { path: '/surveys', component: Survey },
    { path: '/support-ticket', component: SupportTicket },
    { path: '/notification-detail', component: NotificationDetail },
    { path: '/course-material/:id/subject-topics/:id', component: CourseTopics },
    { path: '/course-material/content', component: TopicMaterial },
    { path: '/saved-questions/:slug', component: SavedQuestionView },
    { path: '/practice/subject/:subject/topic/:topic/:type/:slug', component: PracticeQuestionView },
    { path: '/test-series/:id/questions/:slug', component: TestSeriesQuestionView },
    { path: '/practice', component: PracticeQuestions },
    { path: '/practice/subject/:subject/topic/:topic', component: PracticeQuestionsSubjectTopic },
    { path: '/my-profile', component: Settings },
    { path: '/quiz/:mode/:slug', component: Quiz },
    { path: '/tests/result/:id', component: QuizResults },
  ];

  return (
    <BrowserRouter>
      <Switch>
        {/* Auth Routes */}
        {authRoutes.map((ele, index) => (
          <PublicRoute
            exact
            key={`auth-${index}`}
            path={ele.path}
            component={ele.component}
          />
        ))}

        {/* App Routes */}
        <Route
          path="/"
          render={() => (
            <MainContent>
              <Suspense fallback={<div>Loading...</div>}>
                <ScrollToTop>
                  <Switch>
                    {appRoutes.map((ele, index) => (
                      <PrivateRoute
                        exact
                        key={`app-${index}`}
                        path={ele.path}
                        component={ele.component}
                      />
                    ))}
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    <Route component={NotFoundPage} />
                  </Switch>
                </ScrollToTop>
              </Suspense>
            </MainContent>
          )}
        />

        {/* Default Route Redirection */}
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
}
