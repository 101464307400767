import React from 'react';
import './responseScreen.scss';
import MathJax from 'react-mathjax4';
import Button from '@material-ui/core/Button';
import { TimeUtils } from "examsbook-react-sdk/common";
import { useTranslations, useUserLanguage } from "examsbook-react-sdk/custom-hooks";
import { editor } from "examsbook-react-sdk/common";

/**
 * Response screen.
 * @param props
 * @returns {*}
 * @constructor
 */
function ResponseScreen(props) {
    const { quesResponses, quizResultDetailKey, closeModal, changeQuestionIndex } = props;
    const t = useTranslations();
    const { language } = useUserLanguage();

    const quesData = quesResponses.quesList[quizResultDetailKey];

    function changeTimeFormat(timeSpent) {
        return TimeUtils.changeTimeFormat(timeSpent);
    }

    // convert math type question readable format
    editor(quesData?.question?.title);

    return (
        <div className="response-screen overflow-scroll">
            <div className="response-header">
                <h4>{quesData && quesData.section && Object.keys(quesData && quesData.section).length && quesData && quesData.section[language] ? quesData && quesData.section[language] : quesData && quesData.section}</h4>
                <i className="fa fa-close" onClick={closeModal}/>
            </div>
            <div className="response-content">
                <div className="question-number">
                    {quesData.question && quesData.question.timeSpent > 0 ? <div>
                        <h4>{t('your_time')}:<span className="your-time">
                                {changeTimeFormat(quesData.question.timeSpent)}
                            </span>
                        </h4>
                        <h4>{t('avg_time')}:
                            <span className="your-time">
                            {quesResponses.totalTime ? changeTimeFormat(parseInt(quesResponses.totalTime / quesResponses.quesList.length * 60, 10)) : '00:00'}
                            </span>
                        </h4>
                    </div> : null}
                </div>

                <div className="marks-distribution">
                    <span>{t('marks')}</span>
                    <span className="correct-points">+{quesData.question.mark}</span>
                    <span className="incorrect-points">-{quesData.question.penalty}</span>
                </div>
            </div>
            <div className="question-details">
                {quesData.question.id.directionId && quesData.question.id.directionId.name ?
                    quesData.question.id.directionId.locales && quesData.question.id.directionId.locales[language] ?
                        <h4>
                            <MathJax.Context input="tex">
                                <MathJax.Html
                                    html={quesData.question.id.directionId.locales[language].direction}/>
                            </MathJax.Context>
                        </h4> : <h4>
                            <MathJax.Context input="tex">
                                <MathJax.Html html={quesData.question.id.directionId.direction}/>
                            </MathJax.Context>
                        </h4>
                    : null
                }

                <h4>Q <span>{quizResultDetailKey + 1}</span><MathJax.Context input="tex">
                    <MathJax.Html html={quesData.question.title}/>
                </MathJax.Context></h4>
                <ul>
                    {
                        quesData.question.id.options.map((option, key) => {
                            return (
                                <li key={key} className={quesData.question.givenAnswer.indexOf(option._id) === -1 ?
                                    option.correct ? 'correct-option' : '' :
                                    option.correct ? 'correct-option' : 'incorrect-option'}>
                                    <span className="options">{String.fromCharCode(65 + key)}</span>
                                    <div className="quesOptions">
                                        <MathJax.Context input="tex">
                                            <MathJax.Html html={option.text}/>
                                        </MathJax.Context>
                                    </div>
                                    <span className="checked"><i
                                        className={quesData.question.givenAnswer.indexOf(option._id) === -1 ?
                                            option.correct ? 'fa fa-check' : '' :
                                            option.correct ? 'fa fa-check' : 'fa fa-close'}/>
                                        {quesData.question.givenAnswer.indexOf(option._id) === -1 ?
                                            option.correct ? ' Correct' : '' :
                                            option.correct ? ' Correct' : ' Wrong'}</span>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
            <div className="solution-section">
                <div className="solution-left-part">

                    <h4>{t('you_answered')}: {
                        quesData.question.givenAnswer && quesData.question.givenAnswer.length ?
                            quesData.question.id.options.map((option, index) => {
                                if (quesData.question.givenAnswer.indexOf(option._id) > -1) {
                                    return (<span className={option.correct ? '' : 'wrong'}
                                                  key={index}>{String.fromCharCode(65 + index)}</span>);
                                }
                                return '';
                            }) : <span className="notAnswered">{t('not_answered')}</span>
                    }</h4>
                    <p>{t('the_correct_option_is')}:
                        {
                            quesData.question.id.options.map((option, index) => {
                                if (option.correct) {
                                    return (<span key={index}>{' (' + String.fromCharCode(65 + index) + ')'}</span>);
                                }
                                return '';
                            })
                        }
                    </p>
                </div>
                <div className="solution-right-part">
                    <Button disabled={quizResultDetailKey === 0}
                            onClick={() => changeQuestionIndex(quizResultDetailKey - 1)}><i
                        className="fa fa-chevron-left"/></Button>
                    <Button disabled={quizResultDetailKey === quesResponses.quesList.length - 1}
                            onClick={() => changeQuestionIndex(quizResultDetailKey + 1)}><i
                        className="fa fa-chevron-right"/></Button>
                </div>
                {
                    quesData.question.id.explanation ?
                        <div className="explanation-part"><span>{t('explanation')}</span> :
                            <p dangerouslySetInnerHTML={{ __html: quesData.question.id.explanation }}/>
                        </div> : ''
                }

            </div>
        </div>
    );
}

export default ResponseScreen;
