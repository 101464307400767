import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getTestSeries, setTestSeries } from "examsbook-react-sdk/actions";
import status from "../constants/actionStatus";

/**
 * Get data of single test series.
 * @param id
 * @returns {*}
 */
export default function useSingleTestSeries(id) {
  const dispatch = useDispatch();
  //use effect.
  useEffect(() => {
    if (id) {
      dispatch(getTestSeries(id));
      dispatch(setTestSeries(id));
    }
  }, [id]);
  const testSeries = useSelector(state => state.testSeries ? state.testSeries : {});
  return {
    singleTestSeries: testSeries && testSeries.testSeries ? testSeries.testSeries : {},
    singleTestSeriesLoading: testSeries ? testSeries.status === status.PENDING : true
  };
}