import React from 'react';
import ListItem from "@material-ui/core/ListItem/ListItem";

/**
 * Topic list item component
 */
function TopicListItem(props) {
    let { topic, selectedSubjectTopic, subjectId, selectSubjectAndTopic } = props;
    return (
        <ListItem className={topic._id === selectedSubjectTopic.topic ? 'active' : ''}
                  onClick={() => selectSubjectAndTopic(subjectId, topic._id)}>
            <div className="inner-data-name">
                <p>{topic.name}</p>
                <span>{topic.attempted + ' / ' + topic.total} Questions</span>
            </div>
        </ListItem>
    );
}

export default TopicListItem;
