import React from 'react';
import ContentLoader from 'react-content-loader';
import useWindowSize from "examsbook-react-sdk/custom-hooks/useWindowSize";

/**
 * PerformanceSummaryLoader
 * @returns {*}
 * @constructor
 */
function PerformanceSummaryLoader() {
    const windowSize = useWindowSize();
    return (
        <div>
            {!(windowSize && windowSize.width <= 1199) ?
                <ContentLoader
                    height={120}
                    width={400}
                    speed={6}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb">

                    <circle cx="60" cy="20" r="12"/>
                    <rect x="125" y="15" rx="5" ry="5" width="250" height="10"/>
                    <circle cx="60" cy="50" r="12"/>
                    <rect x="125" y="45" rx="5" ry="5" width="250" height="10"/>
                    <circle cx="60" cy="80" r="12"/>
                    <rect x="125" y="75" rx="5" ry="5" width="250" height="10"/>
                    <circle cx="60" cy="110" r="12"/>
                    <rect x="125" y="105" rx="5" ry="5" width="250" height="10"/>

                </ContentLoader> :
                <ContentLoader
                    height={120}
                    width={400}
                    speed={6}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb">
                    <circle cx="40" cy="30" r="28"/>
                    <circle cx="140" cy="30" r="28"/>
                    <circle cx="240" cy="30" r="28"/>
                    <circle cx="340" cy="30" r="28"/>

                </ContentLoader>
            }
        </div>
    );
}

export default PerformanceSummaryLoader;
