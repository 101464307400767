import React, { useState, useEffect } from 'react';
import { timeRemainingFromNow } from "examsbook-react-sdk/common";
import ResultPending from './../../assets/images/result_later-img.svg';
import Button from '@material-ui/core/Button';
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";

/**
 * Quz result pending screen.
 * @param props
 * @returns {*}
 * @constructor
 */
function QuizResultPending(props) {
    const { quizData } = props;
    const t = useTranslations();
    const [ timeRemaining, setTimeRemaining ] = useState(0);
    const [ isNotified, setIsNotified ] = useState(false);
    const [ interval, setIntervel ] = useState();
    const splitTime = timeRemaining.split(':');

    function countDown() {
        let testData = quizData;
        if (testData && testData.resultDate > new Date().getTime()) {
            setTimeRemaining(timeRemainingFromNow(testData.resultDate));
        }
    }

    function notifyMe() {
        setIsNotified(true);
    }

    useEffect(() => {
        if (quizData && quizData.resultDate > new Date().getTime()) {
            setTimeRemaining(timeRemainingFromNow(quizData.resultDate));
            setIntervel(setInterval(countDown, 1000));
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [countDown, interval, quizData]);
    return (
        <div className="result-pending-block">

            <div className="result-section-left">
                <div className="quiz-name">
                    <span>{quizData.name}</span>
                </div>
                <h3>{t('result')} <span>{t('not_live_yet')}</span></h3>

                <div className="time-pending">
                    <p>
                        <div className="border border-hours"/>
                        <span className="time-digit">{splitTime[0]}</span>
                        <span className="time-text">{t('hours')}</span>
                    </p>
                    <p>
                        <div className="border border-min"/>
                        <span className="time-digit">{splitTime[1]}</span>
                        <span className="time-text">{t('minute')}</span>
                    </p>
                    <p>
                        <div className="border"/>
                        <span className="time-digit">{splitTime[2]}</span>
                        <span className="time-text">{t('second')}</span>
                    </p>
                </div>
                <div className={'notify-btn ' + (isNotified ? 'notify-fixed' : '')}>

                    <Button onClick={notifyMe}>{isNotified ?
                        <p className="notified"><span>{t('thank_you')}</span><i className="fa fa-check"/>
                        </p> : t('notify_me')}</Button>

                </div>
            </div>
            <div className="result-section-right">
                <img src={ResultPending} alt=""/>
            </div>
        </div>
    );
}

export default QuizResultPending;
