import { useEffect } from "react";
import ReactGA from "react-ga";
export default function useAnalyticsPageView() {
  useEffect(() => {
    let path = window.location.pathname + window.location.search;
    if (path === '/dashboard') {
      path = path + '/';
    }
    ReactGA.pageview(path);
  }, []);
}