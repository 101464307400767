import React from 'react';
import List from "@material-ui/core/List/List";
import SubjectListItem from "./SubjectListItem";
import { useSelector } from "react-redux";

function SubjectsList(props) {
    let { subjects, subjectSideBarOpensHandler } = props;
    const questionList = useSelector((state) => state.practiceQuestions && state.practiceQuestions.questionList ?
        state.practiceQuestions.questionList : []);
    return (
        <List>
            {
                subjects.map((subject, index) => {
                    return (<SubjectListItem subjectSideBarOpensHandler={subjectSideBarOpensHandler} key={index}
                                             index={index} subject={subject}
                                             quesLength={questionList.length}/>);
                })
            }
        </List>
    );
}

export default SubjectsList;
