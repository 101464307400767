import React from 'react';
import { useNotifications, useStyles, useTranslations } from 'examsbook-react-sdk/custom-hooks';
import ShowMoreText from "react-show-more-text";
import { useState } from 'react';
import COLORS from 'examsbook-react-sdk/constants/colors';
import { getDeleteNotification } from 'examsbook-react-sdk/actions/getDeleteNotification';
import { useDispatch } from 'react-redux';
import LaunchIcon from '@material-ui/icons/Launch';

/**
 * Notification Screen
 * @param props
 * @returns {*}
 * @constructor
 */

function NotificationScreen(props) {
    const [ length, setLength ] = useState(20);
    const {notificationList} = useNotifications(props.refresh,length);
    const classes = useStyles(styles)();
    function openInNewTab(url) {
        window.open(url, '_blank').focus();
      }
    const dispatch = useDispatch()
    const deleteNotificationById = (id) =>{
        dispatch(getDeleteNotification(id));
        props.setRefresh();
    }

    const deleteNotificationAll = () =>{
        dispatch(getDeleteNotification());
        props.setRefresh();
    }
    const t = useTranslations();

    return (
        <div className={classes.mainDiv}>
            { notificationList?.data?.length > 0 ?
                <> 
                <div className={classes.mobileDiv}>
                <p className='section-header'>{t('Notifications')}</p>
                <p className={classes.mobileDivDelete} onClick={()=>deleteNotificationAll()}>{t("Delete_All")}</p>
                </div>
                <div className={classes.deleteAllBox}><h4 className={classes.deleteAll} onClick={()=>deleteNotificationAll()}>{t("Delete_All")}</h4></div>
                </>
                : 
                notificationList?.data?.length === 0 ? <h4 className={classes.empty}>{t("No_new_notification")}</h4>
                :
                null}
            
        {
            notificationList && notificationList.data ?
                <div>
                    {
                        notificationList.data.map((data, index) => {
                            return (
                                <div className={classes.container}>

                                    <div className={classes.header}>
                                    <p className={classes.title}>
                                    {!data?.isRead?<i class="fa fa-circle" aria-hidden="true" style={{fontSize:'10px',marginLeft:'-14px',marginRight:'5px'}} />:<></>}
                                    {data?.notificationId?.title}
                                    {data?.notificationId?.actionLink ?<LaunchIcon className={classes.launch} onClick={()=>openInNewTab(data?.notificationId?.actionLink)}/>:<></>}</p>
                                    <p className={classes.delete} onClick={()=>deleteNotificationById(data._id)}><i className="fa fa-trash"/></p>
                                    </div>
                                    
                                    <div className={classes.content}>
                                    <ShowMoreText
                                        lines={1}
                                        more="see more"
                                        less="see less"
                                        anchorClass="show-more-less-clickable"
                                        expanded={false}
                                        width={350}
                                        truncatedEndingComponent={"... "}
                                        >
                                        {data?.notificationId?.message}
                                    </ShowMoreText>
                                    </div>

                                    <p className={classes.date}>{new Date(data?.notificationId?.scheduledDate).toLocaleString(undefined, {timeZone: 'Asia/Kolkata',year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', hour12: true, minute:'2-digit'})}</p>
                                    
                                </div>
                            );
                        })
                    }
                    {notificationList.data.length>length ? <h4 className={classes.load} onClick={()=>setLength(length+20)}>{t("Load_more")}</h4> : <></>}
                </div>
                :
            <></>
            }
        </div> 
    );
}

const styles = {
    mainDiv:{
        '@media (max-width:767px)': { 
        boxShadow: '0px 4px 5px rgba(91, 91, 91, 0.2)',
        borderRadius: '5px',
        padding: '1px',
        border: '1px solid #BBB9B9'
        }
    },
    mobileDiv:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        background:'#f1f1f1',
        margin:'0px',
        padding:'0px',
        '@media (min-width:768px)': {
            display:'none'
        } 
    },
    mobileDivDelete:{
        border:'1px solid',
        borderColor:COLORS.THEME_COLOR,
        borderRadius:'10px',
        background:'white',
        color:'black',
        padding:'2px 8px',
        fontSize:'14px',
        marginRight:'10px'
    },
    container:{
        borderBottom:'1px solid',
        borderBottomColor:COLORS.THEME_COLOR,
        margin:'1px 5px',
        padding:'12px 12px',
        backgroundColor:COLORS.WHITE,
    },
    load:{
        textAlign:'center',
        color:COLORS.THEME_COLOR,
        cursor:'pointer'
    },
    empty:{
        textAlign:'center',
        color:COLORS.THEME_COLOR
    },
    deleteAllBox:{
        display:'flex',
        justifyContent:'flex-end',
        borderBottom:'1px solid',
        borderBottomColor:COLORS.THEME_COLOR,
        backgroundColor:COLORS.THEME_COLOR,
        '@media (max-width:767px)': {
            display:'none'
        }
    },
    deleteAll:{
        color:COLORS.THEME_COLOR,
        cursor:'pointer',
        margin:'10px',
        fontSize:'13px',
        padding:'3px 12px',
        borderRadius:'20px',
        backgroundColor:COLORS.WHITE,
    },
    header:{
        display:'flex',
        flexDirection:'row',
        alighItems:'center',
        justifyContent:'space-between'
    },
    title:{
        margin:'0px',
        marginRight:'30px',
        paddingLeft:'5px',
        color:COLORS.THEME_COLOR,
        fontSize:'15px',
        fontWeight:'bold'
    },
    launch:{
        fontSize:'14px',
        marginLeft:'3px',
        marginBottom:'-2px',
        cursor:'pointer'
    },
    delete:{
        color:COLORS.THEME_COLOR,
        fontSize:'21px',
        marginTop:'-5px',
        marginBottom:'0px',
        marginRight:'5px',
        textAlign:'center',
        '&:hover':{
            color:'red',
            cursor:'pointer'
        }
    },
    content:{
        margin:'2px 0px 12px',
        fontSize:'15px',
        paddingLeft:'5px'
    },
    date:{
        margin:'0px',
        fontSize:'13px',
        textAlign:'right',
        paddingRight:'5px'
    }
};

export default NotificationScreen;