import React from "react";
import webNotFoundImage from "../assets/images/web-not-found.png";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";

function WebNotFound() {
    const t = useTranslations();
    const classes = useStyles(styles)();
    return (
        <>
            <div className={classes.container}>
                <div className={classes.textAlign}>
                    <h2 className={classes.headingText}>
                        {t('Only available on mobile')}
                    </h2>
                    <p className={classes.descriptionText}>
                        {t('The web version is currently unavailable.')}
                    </p>
                    <p className={classes.descriptionText}>
                        {t('Kindly Contact with Administrator to get the mobile Application.')}
                    </p>
                </div>
                <div className={classes.ImageContainer}>
                    <img src={webNotFoundImage} className={classes.Img} alt="Not Found" />
                </div>
            </div>
        </>
    );
}

const styles = {
    container: {
        display: 'flex',
        position: 'relative',
        top: '20vh',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        textAlign: 'center',
        "@media (max-width: 768px)": {
            flexDirection: 'column',
        }
    },
    headingText: {
        fontSize: '3rem',
        color: '#404041',
        fontWeight: '600',
        margin: '0px',
        paddingBottom: '16px',
        textAlign: 'left',
        lineHeight: '50px',
        width: '40%',
        "@media (max-width: 768px)": {
            textAlign: 'center',
            width: '90%'
        }
    },
    descriptionText: {
        color: '#868686',
        fontSize: '1rem',
        margin: '0px',
        textAlign: 'left',
        width: '40%',
        "@media (max-width: 768px)": {
            textAlign: 'center',
            width: '100%'
        }
    },
    ImageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    Img: {
        width: '100%',
        height: 'auto'
    },
    heading: {
        fontWeight: '700',
        fontSize: '20px',
    },
    content: {
        fontSize: '14px',
        textAlign: 'center',
        color: '#84818A',
        margin: '-17px',
    },
    textAlign: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 16px',
        width: '-webkit-fill-available',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

export default WebNotFound;