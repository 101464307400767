import _objectSpread from "/codebuild/output/src2290389247/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import status from '../constants/actionStatus';
import checkIfAnswerInCorrect from '../common/checkIfAnswerInCorrect';
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    status: status.PENDING,
    questionList: [],
    savedQuestions: []
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  // console.log(action)
  switch (action.type) {
    case 'PRACTICE_QUESTIONS_REQUEST':
      return {
        status: status.PENDING,
        questionList: action.payload.questionList,
        savedQuestions: action.payload.savedQuestions
      };
    case 'PRACTICE_QUESTIONS_SUCCESS':
      return {
        status: status.SUCCESS,
        questionList: action.payload.questionList,
        savedQuestions: action.payload.savedQuestions
      };
    case 'UPDATE_SAVED_QUESTIONS_LIST':
      {
        let savedQuestions = state.savedQuestions ? state.savedQuestions.filter(question => {
          return question.isSaved;
        }) : [];
        return {
          status: status.SUCCESS,
          questionList: state.questionList,
          savedQuestions: savedQuestions
        };
      }
    case 'QUESTION_RESPONSE_SUCCESS':
      {
        try {
          let questionResponse = action.payload;
          let questions = state.questionList.map(question => {
            question = _objectSpread({}, question); // copy
            if (question._id === questionResponse.question) {
              //responded question
              question.givenAnswer = questionResponse.givenAnswer;
              //update status
              question.status = checkIfAnswerInCorrect(question.givenAnswer, question.options, "singleChoice") ? 'CORRECT' : 'INCORRECT';
            }
            return question;
          });
          let savedQuestions = questions ? questions.filter(question => {
            return question.isSaved;
          }) : [];
          return {
            status: status.SUCCESS,
            questionList: questions,
            savedQuestions: savedQuestions
          };
        } catch (e) {
          return state;
        }
      }
    case 'TOGGLE_QUESTION__SAVED_SUCCESS':
      {
        //change state of question from list of questions being shown on screen.
        try {
          let updatedQuestionId = action.payload.questionId;
          let isListView = action.payload.isListView;
          //all questions
          let questions = state.questionList.map(question => {
            question = _objectSpread({}, question); // copy
            if (question._id !== updatedQuestionId) {
              return question;
            } else {
              //toggle status.
              question.isSaved = !question.isSaved;
              return question;
            }
          });
          let savedQuestions = [];
          //if list view then we will filter out un saved questions.
          if (isListView && questions) {
            savedQuestions = questions.filter(question => {
              return question.isSaved;
            });
          } else {
            //saved questions
            savedQuestions = state.savedQuestions.map(question => {
              question = _objectSpread({}, question); // copy
              if (question._id !== updatedQuestionId) {
                return question;
              } else {
                //toggle status.
                question.isSaved = !question.isSaved;
                return question;
              }
            });
          }
          return {
            status: status.SUCCESS,
            questionList: questions,
            savedQuestions: savedQuestions
          };
        } catch (error) {
          console.log('error-------', error);
          return state;
        }
      }
    case 'PRACTICE_QUESTIONS_FAILURE':
      return {
        status: status.SUCCESS,
        questionList: action.payload.questionList,
        savedQuestions: action.payload.savedQuestions
      };
    default:
      return state;
  }
});