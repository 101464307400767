import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { useTheme } from '../constants/themeProvider';


const CommonButton = (props) => {
    const { title, onClick, classStyleHeading, classStyleText } = props;
    const theme = useTheme();

    return (
         <Button style={{background: theme?.primaryColor}} className={classStyleHeading ? classStyleHeading : {}} onClick={onClick}>
            <Box className={classStyleText ? classStyleText : {}}>
            <Typography variant="paragraph" style={{color: theme?.primaryFontText}}>
              {title}
              </Typography>
            </Box>
        </Button>
    )    
};

export default CommonButton;