import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ReactComponent as UnderMaintenanceImage } from "../../../assets/images/underMaintenance.svg";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";


const UnderMaintenance = (props) => {
    const {dynamicSeoData,defaultSupportData} = props;
    const [showImage, setShowImage] = useState();
    const classes = useStyles(styles)();
    const t = useTranslations();

    useEffect(() => {
        const imageValue = 'img/post/original/' + dynamicSeoData?.data?.find(subValue => subValue.key === "Image")?.value;
        const defaultValue = 'img/post/original/' + defaultSupportData?.data?.Images?.find(subValue => subValue.key === "Image")?.defaultValue;
        const dynamicImage = imageValue.split('/').pop();
        if (dynamicImage !== undefined && dynamicImage) {
            setShowImage(imageValue);
        } else {
            setShowImage(defaultValue);
        }
    }, [dynamicSeoData, defaultSupportData]);

    return (
        <Box>
            <Box className={classes.parentDiv}>
            {showImage && <img src={showImage} alt="Site Logo" className={classes.logo}/>}
            </Box>
            <Box className={classes.centerAlign}>
                <Box className={classes.underMaintenanceAlign}>
                    <UnderMaintenanceImage/>
                    <Typography variant="h4" className={classes.text}>{t("This site is Under Maintenance!")}</Typography>
                </Box>
            </Box>
            
        </Box>
    )
};
const styles = {
    parentDiv :{
        float: 'left',
        paddingTop: '12px'
    },
    centerAlign: {
        textAlign: '-webkit-center'
    },
    logo: {
        width: '160px',
        paddingLeft: '12px',
        position: 'absolute' 
    },
    underMaintenanceAlign :{
        height: '80vh',
        width: '80vh',
        paddingTop: '160px'
    },
    "@media (max-width: 768px)": {
        parentDiv :{
            float: 'none'
        },
        logo: {
            height: '120px',
            paddingLeft: '0px',
            position: 'relative'
        },
        underMaintenanceAlign : {
            height: '60vh',
            paddingTop: '0px'
        },
        text : {
            fontSize : '18px',
            width: '100%'
        }

    },
}
export default UnderMaintenance;

