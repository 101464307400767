import status from "../constants/actionStatus";
import { getAllTestSeries, getSingleTestSeriesData } from "../services/testSeriesService";
export const getTestSeries = id => (dispatch, getState) => {
  const {
    testSeries
  } = getState();
  let isLoaded = testSeries.status === status.SUCCESS;
  let condition = '';
  if (!isLoaded) {
    dispatch({
      type: 'GET_TEST_SERIES_WAITING',
      payload: {}
    });
  }
  if (!isLoaded || testSeries.quizGroupId !== id) {
    condition = id;
  } else {
    condition = id + '?updatedAfter=' + testSeries.lastUpdated;
  }
  getSingleTestSeriesData(condition).then(data => {
    // handle success
    if (!isLoaded || testSeries.quizGroupId !== id) {
      dispatch({
        type: 'GET_TEST_SERIES',
        payload: data,
        lastUpdated: new Date().toISOString(),
        quizGroupId: id
      });
    } else {
      dispatch({
        type: 'GET_UPDATED_TEST_SERIES',
        payload: data,
        lastUpdated: new Date().toISOString(),
        quizGroupId: id
      });
    }
  }).catch(error => {
    // handle error
    dispatch({
      type: 'GET_TEST_SERIES_ERROR',
      payload: {}
    });
  });
};
export const getTestSeriesList = (examId, lang) => (dispatch, getState) => {
  let {
    testSeriesList
  } = getState();
  const isLoaded = testSeriesList.status === status.SUCCESS;
  let condition = '';
  if (!isLoaded || examId !== testSeriesList.examId) {
    condition = "exam=" + examId;
  } else {
    condition = "exam=" + examId + '&updatedAfter=' + testSeriesList.lastUpdated;
  }
  if (!isLoaded || examId !== testSeriesList.examId) {
    dispatch({
      type: 'GET_TEST_SERIES_LIST_REQUEST',
      payload: []
    });
  }
  getAllTestSeries(condition, lang).then(data => {
    // handle success
    if (!isLoaded || examId !== testSeriesList.examId) {
      dispatch({
        type: 'GET_TEST_SERIES_LIST_SUCCESS',
        payload: data,
        lastUpdated: new Date().toISOString(),
        examId: examId
      });
    } else {
      dispatch({
        type: 'GET_UPDATED_TEST_SERIES_LIST_SUCCESS',
        payload: data,
        lastUpdated: new Date().toISOString(),
        examId: examId
      });
    }
  }).catch(error => {
    // handle error
    dispatch({
      type: 'GET_TEST_SERIES_LIST_ERROR',
      payload: {}
    });
  });
};