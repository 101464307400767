import { examWithSubjects } from "../services/taxonomyService";
export const getExamsWithSubjects = id => dispatch => {
  dispatch({
    type: 'SUBJECTS_REQUEST',
    payload: []
  });
  // function to get all questions saved by user
  examWithSubjects(id).then(response => {
    // handle success
    // dispatching question list to reducer

    dispatch({
      type: 'SUBJECTS_SUCCESS',
      payload: response
    });
  }).catch(error => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    dispatch({
      type: 'SUBJECTS_FAILURE',
      payload: {
        error: error.message
      }
    });
  });
};