import React from "react";
import useStyles from './styles'
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import CommonButton from "../../../constants/CommonButton";
import { Button } from "@material-ui/core";

const ContinueVideoDialog = (props) => {
  const { 
    showModal, 
    closeModal, 
    handleYes,
    handleNo
  } = props;

  const classes = useStyles();
  const t = useTranslations();
  
  return (
    <div className={classes.modalBg + ` ${showModal ? 'show' : ''}`} onClick={closeModal} >
      <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={classes.modalHeading}>
          <h2 className={classes.modalText}>{t("Do you want to continue watching this video?")}</h2>
          <div className={classes.buttonGroup}>
            <CommonButton onClick={handleYes} title={t('Yes')} classStyleHeading={classes.yesButton} />
            <Button onClick={handleNo} className={classes.noButton}>{t('No')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinueVideoDialog