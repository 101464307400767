import { getSubExams } from "../services/taxonomyService";
export const getSubExamsList = selectedExamId => dispatch => {
  dispatch({
    type: 'GET_SUB_EXAMS_LIST_WAITING',
    payload: []
  });
  getSubExams(selectedExamId).then(data => {
    // handle success
    dispatch({
      type: 'GET_SUB_EXAMS_LIST_SUCCESS',
      payload: data
    });
  }).catch(error => {
    // handle error
    dispatch({
      type: 'GET_SUB_EXAMS_LIST_ERROR',
      payload: []
    });
  });
};