import status from "../constants/actionStatus";
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    completedQuiz: {}
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case "COMPLETED_QUIZ_REQUEST":
      return {
        status: status.PENDING,
        completedQuiz: action.payload.skip === 0 ? {} : state.completedQuiz
      };
    case "COMPLETED_QUIZ_SUCCESS":
      return {
        status: status.SUCCESS,
        completedQuiz: action.payload
      };
    case "COMPLETED_QUIZ_FAILURE":
      return {
        status: status.ERROR,
        completedQuiz: {}
      };
    default:
      return state;
  }
});