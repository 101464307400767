import React from 'react';
import List from "@material-ui/core/List/List";
import CompactQuestion from "../CompactQuestion/compactQuestion";
import NoData from "../common/Nodata/noData";
import SavedQuestionLoader from "../common/loaders/SavedQuestionLoader";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { toggleSavedQuestion } from "examsbook-react-sdk/actions/toggleSavedQuestion";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

function QuestionList(props) {
    const {
        questionsLoading, questionList, singleQuesUrl, selectedSubjectTopic, selectedIndex,
        setTestSeriesIndex, handleChildUnmount
    } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const t = useTranslations();

    function unSaveQuesFromList(id) {
        dispatch(toggleSavedQuestion(id, true));
    }

    function gotoPage() {
        if (selectedSubjectTopic && selectedSubjectTopic.subject) {
            props.selectAllQuestions();
        } else {
            let url = '/practice';
            props.history.push(url);
        }
    }

    function renderList() {
        return (
            questionList && questionList.length ?
                <List className="save-list">
                    {
                        questionList.map((question, i) => {
                            return (<CompactQuestion singleQuesUrl={singleQuesUrl}
                                                     toggleSavedQuestion={() => unSaveQuesFromList(question._id)}
                                                     history={history}
                                                     key={i} questionNo={i + 1}
                                                     index={i} question={question}
                                                     totalQuestions={questionList.length}
                                                     unmountMe={handleChildUnmount}
                                                     selectedSubjectTopic={selectedSubjectTopic}
                                                     setTestSeriesIndex={setTestSeriesIndex}
                                                     selectedIndex={selectedIndex}/>);
                        })
                    }

                </List> : <NoData message={t('you_have_not_saved_any_question')}
                                  buttonText={t('GO_TO_COURSES')}
                                  handleTabChange={() => gotoPage()}/>
        );
    }

    function renderLoader() {
        return (
            <div className="loader-for-saved-question">
                <div className="loader-content">
                    <SavedQuestionLoader/>
                    <SavedQuestionLoader/>
                    <SavedQuestionLoader/>
                    <SavedQuestionLoader/>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            {
                questionsLoading ? renderLoader() : renderList()
            }
        </React.Fragment>
    );
}

export default QuestionList;
