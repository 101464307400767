// import {getSavedTestSeriesQuestions} from "../services/testSeriesService";

// export const getTestSeriesSavedQuestions = (id) => dispatch => {
//     getSavedTestSeriesQuestions(id).then((data) => {
//             // handle success
//         dispatch( {
//                 type: 'GET_TEST_SERIES_SAVED_QUESTIONS',
//                 payload: data
//             });
//         })
//         .catch((error) => {
//             // handle error
//             dispatch({
//                 type: 'GET_TEST_SERIES_SAVED_QUESTIONS',
//                 payload: []
//             });
//         })

// };
import { getSavedTestSeriesQuestions } from "../services/testSeriesService";
export const getTestSeriesSavedQuestions = id => dispatch => {
  getSavedTestSeriesQuestions(id).then(data => {
    // handle success
    dispatch({
      type: 'GET_TEST_SERIES_SAVED_QUESTIONS',
      payload: data
    });
  }).catch(error => {
    // handle error
    dispatch({
      type: 'GET_TEST_SERIES_SAVED_QUESTIONS',
      payload: []
    });
  });
};