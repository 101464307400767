import _objectSpread from "/codebuild/output/src2290389247/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (action.type === 'GET_NEXT_QUIZ_LIST_SUCCESS') {
    return action.payload;
  } else if (action.type === 'TOGGLE_QUIZ__SAVED_SUCCESS') {
    //change state of question from list of questions being shown on screen.
    let quizzes = state;
    let quizList = quizzes.map(quiz => {
      quiz = _objectSpread({}, quiz); // copy
      if (quiz._id !== action.payload.quizId) {
        return quiz;
      } else {
        //toggle status.
        quiz.isSaved = !quiz.isSaved;
        return quiz;
      }
    });
    quizList = [...quizList];
    return quizList;
  }
  return state;
});