/**
 * APi wrapper on exams for frontend.
 */
import { mainExamList, childExamList } from '../services/taxonomyService';

/**
 * Get top level exams list
 * @returns {Function}
 */
export const getMainExamsList = () => dispatch => {
  mainExamList().then(response => {
    // handle success
    dispatch({
      type: 'GET_EXAM',
      payload: response
    });
  }).catch(error => {
    // handle error
    dispatch({
      type: 'GET_EXAM',
      payload: []
    });
  });
};
/**
 * Get child level exams list of one perticular category.
 * @param id
 * @returns {Function}
 */
export const getChildExamsList = id => dispatch => {
  childExamList(id).then(response => {
    dispatch({
      type: 'GET_CHILD_CATEGORY',
      payload: response
    });
  }).catch(error => {
    // handle error
    dispatch({
      type: 'GET_CHILD_CATEGORY',
      payload: []
    });
  });
};