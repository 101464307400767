import React from 'react';
import { useDispatch } from "react-redux";
import { changeExamFilterVal } from "examsbook-react-sdk/actions/changeExamFilterVal";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";

/**
 * Search filter on questions side bar.
 */
function PracticeQuestionsFilter(props) {
    const dispatch = useDispatch();
    const t = useTranslations();

    function onChangeSearchFilter(event) {
        dispatch(changeExamFilterVal(event.target.value));
    }

    return (
        <div className="search-filter">
            <input type="text" placeholder={t('Search Chapter')} onChange={onChangeSearchFilter}/>
            <i className="fa fa-search"/>
        </div>
    );
}

export default PracticeQuestionsFilter;
