import { getQuestionLocationIndex } from "examsbook-react-sdk/common";
import { setActiveQuestionIndex } from "./setActiveQuestionIndex";
export const captureAnswer = givenAnswer => {
  return (dispatch, getState) => {
    const {
      quiz,
      isViewMode,
      activeQuestionIndex,
      selectedQuestion
    } = getState();
    if (!isViewMode) {
      //mark selected question visited.
      let questions = quiz.questions;
      const {
        sectionNo,
        questionNo
      } = getQuestionLocationIndex(quiz, activeQuestionIndex);
      questions[sectionNo].questions[questionNo].givenAnswer = givenAnswer;
      questions[sectionNo].questions[questionNo].answered = givenAnswer && givenAnswer.length > 0;
      questions[sectionNo].questions[questionNo].timeSpent = selectedQuestion.timeSpent;
      quiz.questions = questions;
      //dispatch
      dispatch({
        type: 'QUESTION_ANSWERED',
        payload: quiz
      });
      return dispatch(setActiveQuestionIndex(activeQuestionIndex));
    } else {
      return Promise.resolve();
    }
  };
};