import React, { useEffect, useState } from "react";
import { ReactComponent as VectorImage } from "../../assets/images/module.svg";
import VectorDrop from "../../assets/images/vector-drop.png";
import SelfLearningSubmenu from "./SelfLearningSubmenu";
import "./SelfLearning.scss";
import { useStyles } from "examsbook-react-sdk/custom-hooks";
import { selfLearningVideoUrl } from "examsbook-react-sdk/actions";
import { useDispatch } from "react-redux";
import { LinearProgressWithLabel } from "../MyCourseCard/MyCoursesCard";

export default function SelfLearningCard({ activeIndex, setActiveIndex, index, setCurrentActiveSection, currentActiveSection, setSectionChanged, pdfOpen, onPdfOpen, ...props }) {
  const classes = useStyles(styles)();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedSubmenu, setSelectedSubmenu] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsDropdownOpen(index === activeIndex);
  }, [activeIndex]);

  // Highlighting the submenu items
  useEffect(() => {
    const videoItem = props?.data?.courseSectionData.find(subValue => subValue.refModel === 'RecordedVideo');
    const videoId = videoItem?._id;
    const firstItemId = props?.data?.courseSectionData[0]?._id;
    setSelectedSubmenu(videoId || firstItemId);
  }, []);

  const toggleDropdown = (activeItem, event) => {
    event.stopPropagation();
    props.setActiveItem(activeItem)
    setCurrentActiveSection(activeItem)
    
    let newActiveIndex;
    
    if (activeIndex !== index) {
      newActiveIndex = index;
    } else {
      newActiveIndex = null;
    }
    setActiveIndex(newActiveIndex);
    setSectionChanged(true);
    setIsDropdownOpen(newActiveIndex === index);

    // const videoData = props.data?.courseSectionData.find(subValue => subValue.refModel === "RecordedVideo");

    // dispatch(selfLearningVideoUrl({
    //   data: {
    //     percent: videoData?.contentData[0]?.percent,
    //     _id: videoData?.contentData[0]?._id,
    //     name: videoData?.contentData[0]?.title,
    //     contentData: [{
    //       title: videoData?.contentData[0]?.title,
    //       description: videoData?.contentData[0]?.description,
    //       url: videoData?.contentData[0]?.url
    //     }]
    //   }
    // }));
  };

  const language = localStorage.getItem('language');

  return (
    <>
        <div style={{ border: isDropdownOpen ? 'none' : '1px solid #0C34504D', borderRadius: '6px' }}>
        <div className="outerBox1" style={{ border: isDropdownOpen  ? '1px solid #E7E7E7EE' : 'none', backgroundColor: props?.activeItem?.data?._id === props?.data?._id ? 'aliceblue': 'transparent', borderRadius: '6px 6px 6px 6px' }} onClick={(event)=>toggleDropdown(props, event)}>
          <div className="innerBox">
            <div className="image-box">
            <VectorImage style={{ width: '40px', height: '40px', borderRadius: '2px' }} alt="Vector arrow" />
            </div>
            <div className="card">
              <div className="content-box">
                <p className={classes.genustext} sx={{ m: 0, mb: 1 }}>{language === 'en' ? props.data?.title?.en : (language === 'hn' ? props.data?.title?.hn : '')}</p>
              </div>
              <div style={{ width: '100%' }}>
                <LinearProgressWithLabel value={props.data.sectionPercent} />
              </div>
            </div>
            <div className="button-box">
              <img
                className={isDropdownOpen ? "rotated-img" : ""}
                src={VectorDrop}
              />
            </div>
          </div>
        </div>
        {isDropdownOpen && (
          <div className="dropdown-content">
            {props.data?.courseSectionData !== undefined ? props.data?.courseSectionData.map((item, index) => {
              return (<SelfLearningSubmenu index={index} data={item} section={props.sectionData} sectionId={props.data._id} setSectionChanged={setSectionChanged} pdfOpen={pdfOpen} onPdfOpen={onPdfOpen} activeItem={props?.activeItem} selectedSubmenu={selectedSubmenu} setSelectedSubmenu={setSelectedSubmenu}/>)
            }) : <></>}
          </div>
        )}
      </div>
    </>
  );
}

const styles = {
  liveText: {
    color: "#84818A",
  },
  genustext: {
    color: "#84818A",
    fontWeight: "500",
    margin: 0,
    color: "#000000",
    opacity: '0.6',
    fontSize: "16px",
  },
};
