import React from 'react';
import { useTranslations, useTopic, useStyles } from "examsbook-react-sdk/custom-hooks";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from "react-router-dom";
import NoData from '../components/common/Nodata/noData';
import { reactGaEvent } from 'examsbook-react-sdk/actions';

/**
 * Course Material Tab-screen
 * @returns {*}
 * @constructor
 */
function CourseTopics(props) {
    const classes = useStyles(styles)();
    const {course_id, subject_id, subjectDetail, subject_name}  = props;
    const history = useHistory();
    const { topicDetail } = useTopic(course_id, subject_id);

    function handlePress(item) {
        reactGaEvent("Course Material Topic Content", "Go To Course Topic Content");
        history.push('/course-material/content', {courseId:course_id, subjectId: subject_id, topicId:item._id, subjectDetail:subjectDetail, subjectName:subject_name, topicName:item.name});
    }

    const t = useTranslations();
    return (
        <div class="MuiTypography-root MuiTypography-body1">
            {topicDetail && topicDetail.length ?(
                    <ul className={classes.ul}>
                    {topicDetail.map(
                    (element) => {
                        return (
                            <div className={classes.topicDiv} onClick={()=>handlePress(element)}>
                                <li >{element.name}</li>
                                <ArrowForwardIosIcon className={classes.icon}/>
                            </div>       
                        )
                    })
                    }
                    </ul>)
                    :
                    <NoData message={t('no_data')}/>
                }
        </div>
    );
}

const styles = {
    ul:{
        listStyleType:"none",
        padding:'0px'
    },
    topicDiv: {
        border:'1px solid #F4F4F4',
        borderRadius:'10px',
        backgroundColor:'#F4F4F4',
        minHeight:'60px',
        padding:'10px 20px',
        margin:'15px',
        boxShadow: '2px 2px 2px #ddd',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        maxWidth:'400px',
        cursor: 'pointer'
    },
    icon:{
        fontSize:'medium'
    }
};

export default CourseTopics;
