import React from 'react';
import { Button } from "@material-ui/core";
import { reactGaEvent } from "examsbook-react-sdk/actions/Common";
import { useHistory } from "react-router-dom";

/**
 * TestSeriesCardButtonGroup
 * @param props
 * @returns {*}
 * @constructor
 */
const TestSeriesCardButtonGroup = props => {
    const { testData, t } = props;
    const history = useHistory();

    function handleGoToTestClick() {
        reactGaEvent("Test Series", "Go To Test");
        history.push('/test-series/' + testData._id + '/tests');
    }

    return (
        <div className="hideInMobile">
            <div className="card-btn">
                {/* <div className="date-time test-date-time">
                    {t('expires_on')}:
                    <span>{testData.endDate}</span>
                </div> */}
                <Button disabled={!testData.quizGroup.length} onClick={handleGoToTestClick}>
                    {t('VIEW_MORE')}
                </Button>
            </div>
        </div>
    );
};

export default TestSeriesCardButtonGroup;
