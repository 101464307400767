import { certificateAddApi, downloadCertificatesApi, getCertificatesApi, getCourseCertificateApi } from "../services/certificateService";
export const certificateAddResponse = data => dispatch => {
  dispatch({
    type: "CERTIFICATE_REQUEST",
    payload: data
  });
  return certificateAddApi(data).then(function (response) {
    // handle success
    dispatch({
      type: "CERTIFICATE_SUCCESS",
      payload: response
    });
    return response;
  }).catch(function (error) {
    // handle error
    dispatch({
      type: "CERTIFICATE_ERROR",
      payload: error.toString()
    });
    return error.toString();
  });
};
export const certificateListAction = () => dispatch => {
  dispatch(request());
  return getCertificatesApi().then(response => {
    // handle success
    dispatch(success(response));
    return response;
  }).catch(error => {
    console.log("error", error);
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: "CERTIFICATE_LIST_REQUEST"
    };
  }
  function success(response) {
    return {
      type: "CERTIFICATE_LIST_SUCCESS",
      payload: response
    };
  }
  function failure(error) {
    return {
      type: "CERTIFICATE_LIST_FAILURE",
      payload: error
    };
  }
};
export const certificateDownloadAction = data => dispatch => {
  dispatch(request());
  return downloadCertificatesApi(data).then(response => {
    // handle success
    dispatch(success(response));
    return response;
  }).catch(error => {
    console.log("error", error);
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: "CERTIFICATE_DOWNLOAD_REQUEST"
    };
  }
  function success(response) {
    return {
      type: "CERTIFICATE_DOWNLOAD_SUCCESS",
      payload: response
    };
  }
  function failure(error) {
    return {
      type: "CERTIFICATE_DOWNLOAD_FAILURE",
      payload: error
    };
  }
};
export const courseCertificateListAction = courseId => dispatch => {
  dispatch(request());
  return getCourseCertificateApi(courseId).then(response => {
    // handle success
    dispatch(success(response));
    return response;
  }).catch(error => {
    console.log("error", error);
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: "CERTIFICATE_LIST_REQUEST"
    };
  }
  function success(response) {
    return {
      type: "CERTIFICATE_LIST_SUCCESS",
      payload: response
    };
  }
  function failure(error) {
    return {
      type: "CERTIFICATE_LIST_FAILURE",
      payload: error
    };
  }
};