import { getDefaultSupportDataApi, getSupportApi, getSupportsApi } from "../services/feedbackService";
export const getSupportAction = () => dispatch => {
  dispatch(request());
  return getSupportApi().then(response => {
    // handle success
    dispatch(success(response));
    return response;
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: "GET_DYNAMIC_SEO_SETTINGS_REQUEST"
    };
  }
  function success(response) {
    return {
      type: "GET_DYNAMIC_SEO_SETTINGS_SUCCESS",
      payload: response
    };
  }
  function failure(error) {
    return {
      type: "GET_DYNAMIC_SEO_SETTINGS_FAILURE",
      payload: error
    };
  }
};
export const getSupportsAction = userId => dispatch => {
  dispatch(request(userId));
  return getSupportsApi(userId).then(response => {
    // handle success
    dispatch(success(response));
    return response;
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: "SUPPORTS_REQUEST"
    };
  }
  function success(response) {
    return {
      type: "SUPPORTS_SUCCESS",
      payload: response
    };
  }
  function failure(error) {
    return {
      type: "SUPPORTS_FAILURE",
      payload: error
    };
  }
};
export const getDefaultSupportDataAction = () => dispatch => {
  dispatch(request());
  return getDefaultSupportDataApi().then(response => {
    // handle success
    dispatch(success(response));
    return response;
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: "GET_DEFAULT_SEO_SETTINGS_REQUEST"
    };
  }
  function success(response) {
    return {
      type: "GET_DEFAULT_SEO_SETTINGS_SUCCESS",
      payload: response
    };
  }
  function failure(error) {
    return {
      type: "GET_DEFAULT_SEO_SETTINGS_FAILURE",
      payload: error
    };
  }
};