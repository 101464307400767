import { useDispatch, useSelector } from "react-redux";
import status from "../constants/actionStatus";
import { useEffect } from "react";
import { getAllLiveTestList } from "examsbook-react-sdk/actions";
import useSelectedExam from "./useSelectedExam";
import useUserLanguage from "./useUserLanguage";

/**
 * useLiveTestDashboardData data.
 * @returns {{liveTestLoading: boolean, liveTestList: *, liveTestFullList: *}}
 */
export default function useLiveTestDashboardData() {
  const dispatch = useDispatch();
  const liveTestListData = useSelector(state => state.liveTestList);
  const {
    language
  } = useUserLanguage();
  let liveTestList = liveTestListData.data;
  let liveTestFullList = liveTestList;
  let allQuizzes = [];
  if (liveTestList) {
    allQuizzes = liveTestList || liveTestList.filter(quiz => {
      return !quiz.attempted;
    });
  }
  //we want to display max 6
  if (liveTestList) {
    liveTestList = allQuizzes || allQuizzes.slice(0, 6);
  }
  let liveTestLoading = liveTestListData.status === status.PENDING;
  //effect to get list of all live test.
  const selectedExam = useSelectedExam();

  //load list of live test
  useEffect(() => {
    if (selectedExam) {
      getListOfLiveTest();
    }
  }, [selectedExam, language]);
  function getListOfLiveTest() {
    dispatch(getAllLiveTestList(selectedExam._id));
  }
  return {
    liveTestLoading,
    liveTestList,
    liveTestFullList
  };
}