import { getSelfLearningApi, progressBarApi } from "../services/SelfLearningApi";
export const clearSelfLearningAction = () => ({
  type: "CLEAR_SELF_LEARNING"
});
export const getSelfLearningAction = courseId => dispatch => {
  dispatch(request());
  return getSelfLearningApi(courseId).then(response => {
    // handle success
    dispatch(success(response));
    return response;
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: "SELF_LEARNING_REQUEST"
    };
  }
  function success(response) {
    return {
      type: "SELF_LEARNING_SUCCESS",
      payload: response
    };
  }
  function failure(error) {
    return {
      type: "SELF_LEARNING_FAILURE",
      payload: error
    };
  }
};
export const getProgressBarDetails = data => dispatch => {
  dispatch({
    type: "PROGRESS_BAR_REQUEST",
    payload: data
  });
  return progressBarApi(data).then(function (response) {
    // handle success
    dispatch({
      type: "PROGRESS_BAR_SUCCESS",
      payload: response.data
    });
    return response;
  }).catch(function (error) {
    // handle error
    dispatch({
      type: "PROGRESS_BAR_ERROR",
      payload: error.toString()
    });
    return error;
  });
};
export const getCourseSectionApiData = (courseId, data) => dispatch => {
  dispatch({
    type: "COURSE_SECTION_REQUEST",
    payload: data
  });
  return getSelfLearningApi(courseId).then(function (response) {
    // handle content data
    dispatch({
      type: "SET_CONTENT_DATA",
      payload: response
    });
    return response;
  }).then(function (response) {
    // handle success
    dispatch({
      type: "FETCH_COURSE_SECTION_SUCCESS",
      payload: response
    });
    return response;
  }).catch(function (error) {
    // handle error
    dispatch({
      type: "FETCH_COURSE_SECTION_FAILURE",
      payload: error.message
    });
    return error.toString();
  });
};