import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { List, AccordionSummary, Accordion, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TopicList from "./TopicList";
import { setCurrentSubjectAndTopic } from "examsbook-react-sdk/actions/setCurrentSubjectTopic";
import CommonLinearProgress from '../../CommonLinearProgress/commonLinearProgress';
import { useHistory } from "react-router-dom";

/**
 * One item of subject in practice question side bar.
 */
function SubjectListItem(props) {
    const { subject } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedSubjectTopic = useSelector((state) => state.selectedSubjectTopic);
    let attemptPercentage = 0;
    if (subject && subject._id) {
        attemptPercentage = Math.ceil(subject.attemptedQuesCount / subject.questionCount * 100);
    }
    //check if default exanded.// if this subject is selected then it will be default expanded else it will be closed.
    let isExpanded = (subject._id === selectedSubjectTopic.subject);

    //Select new topic.
    function selectSubjectAndTopic(subjectId, topicId) {
        history.push('/practice/subject/' + subjectId + '/topic/' + topicId);
        dispatch(setCurrentSubjectAndTopic({ subject: subjectId, topic: topicId }));
        props.subjectSideBarOpensHandler();
    }

    return (
        <Accordion className="accordion" defaultExpanded={isExpanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography component={'span'}>
                    {subject.name}
                    <span>{subject.attemptedQuesCount + ' / ' + subject.questionCount} Questions</span>
                    <CommonLinearProgress progressPercentage={attemptPercentage}/>
                </Typography>
            </AccordionSummary>
            {
                subject.topics && subject.topics.length ?
                    <AccordionDetails>
                        <List>
                            <TopicList topics={subject.topics} subjectId={subject._id}
                                       selectSubjectAndTopic={selectSubjectAndTopic}
                                       selectedSubjectTopic={selectedSubjectTopic}/>
                        </List>
                    </AccordionDetails>
                    : null
            }
        </Accordion>
    );
}

export default SubjectListItem;
