import React from "react";
import NotificationItem from "./NotificationItem";

function NotificationView (props) {
    return (
        props?.notifications.sort((a, b) => new Date(b?.updated?.on) - new Date(a?.updated?.on)).map((item, index)=> {
            return <NotificationItem index={index} item={item} deleteNotificationById={props.onDeleteNotification}  />
        })
    );
}

export default NotificationView;