import React from 'react';
import { useSelector } from "react-redux";
import status from "examsbook-react-sdk/constants/actionStatus";
import PracticeQuestionsFilter from "./PracticeQuestionsFilter";
import SubjectsListLoader from "../../common/loaders/SubjectsListLoader";
import SubjectsList from "./SubjectsList";
import { getFilteredExams } from "examsbook-react-sdk/selectors";

/**
 * Practice questions sidebar component
 */
function PracticeQuestionSidebar(props) {
    const { sidebarOpens, subjectSideBarOpensHandler } = props;
    const subjects = useSelector((state) => getFilteredExams(state));
    const subjectStatus = useSelector((state) => state.examData.status);
    //check if data is loading
    let subjectsLoading = (subjects && subjectStatus === status.PENDING);
    return (
        <React.Fragment>
            <div className={'sidebar ' + (sidebarOpens ? 'sidebar-open' : '')}>
                <PracticeQuestionsFilter/>
                <div className="chapter-list">
                    {subjects && !subjectsLoading ?
                        <SubjectsList subjectSideBarOpensHandler={subjectSideBarOpensHandler}
                                      subjects={subjects}/>
                        : <SubjectsListLoader/>
                    }
                </div>

            </div>
        </React.Fragment>
    );
}

export default PracticeQuestionSidebar;
