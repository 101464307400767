import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
//import css
import 'react-confirm-alert/src/react-confirm-alert.css';
import status from "examsbook-react-sdk/constants/actionStatus";
import QuizError from "./../components/Quiz/Loader/QuizError";
import { blockDeveloperTool } from 'examsbook-react-sdk/common';
import QuizPlayground from '../components/Quiz/QuizPlayground';

import { useAnalyticsPageView, useQuizData } from "examsbook-react-sdk/custom-hooks";
import { confirmAlert } from "react-confirm-alert";

/**
 * Quiz module entry module.
 * @param props
 * @returns {*}
 * @constructor
 */
function Quiz(props) {
    const { match } = props;
    const quizSubmissionObj = useSelector(state => state.quizSubmissionObj);
    const quizMode = match.params.mode;
    const slug = match.params.slug;
    const viewMode = (quizMode === 'view');

    //analytics
    useAnalyticsPageView();
    const quizData = useQuizData(slug, viewMode, quizMode);

    useEffect(() => {
        if (!viewMode) {
            blockDeveloperTool();
        }
    }, []);
    // if there is error then show the popup. // it is unlike case.
    useEffect(() => {
        if (quizSubmissionObj.status === status.ERROR) {
            confirmAlert({
                title: "Something went wrong",
                message: "Please try again later",
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            window.location.href = '/';
                        }
                    }
                ]
            });
        }
    }, [ quizSubmissionObj ]);

    let isLoading = (quizData.status === status.PENDING);
    let isError = quizData.status === status.ERROR || (quizData.questions && quizData.questions.length === 0 && quizData.status === status.SUCCESS);
    if (isError) {
        return (<QuizError/>);
    }
    return (
        <QuizPlayground
            quizMode={quizMode}
            viewMode={viewMode}
            slug={slug}
            totalQuestions={quizData.totalQuestions}
            quiz={quizData}
            isLoading={isLoading}/>
    );
}

export default Quiz;
