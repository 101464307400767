import status from "../constants/actionStatus";
import { getAllLiveTests } from "../services/quizService";
export const getAllLiveTestList = examId => (dispatch, getState) => {
  const {
    liveTestList
  } = getState();
  const isLoaded = liveTestList.status === status.SUCCESS;
  if (!isLoaded || examId !== liveTestList.examId) {
    dispatch({
      type: 'GET_LIVE_TEST_WAITING',
      payload: []
    });
  }
  let condition = '';
  if (!isLoaded || examId !== liveTestList.examId) {
    condition = examId;
  } else {
    condition = examId + '?updatedAfter=' + liveTestList.lastUpdated;
  }
  getAllLiveTests(condition).then(response => {
    // handle success
    if (!isLoaded) {
      dispatch({
        type: 'GET_LIVE_TEST_SUCCESS',
        payload: response,
        lastUpdated: new Date().toISOString(),
        examId: examId
      });
    } else {
      dispatch({
        type: 'GET_LIVE_TEST_UPDATED_SUCCESS',
        payload: response,
        lastUpdated: new Date().toISOString(),
        examId: examId
      });
    }
  }).catch(error => {
    // handle error
    dispatch({
      type: 'GET_LIVE_TEST_ERROR',
      payload: []
    });
  });
};