import React, { useEffect, useState } from 'react';
import { CNavLink } from "./c/CHeader";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import MenuIcon from '@material-ui/icons/Menu';
import {IconButton, Typography, Box, Link, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import LmsHeaderLogo from "../../../assets/images/LearnifyLogoDark.png";
import { ReactComponent as DashboardIconDark } from "../../../assets/images/dashboardIconDark.svg";
import { ReactComponent as DashboardIconLight } from "../../../assets/images/dashboardIconLight.svg";
import { ReactComponent as AllCoursesIconDark } from "../../../assets/images/allCoursesIconDark.svg";
import { ReactComponent as AllCoursesIconLight } from "../../../assets/images/allCoursesIconlight.svg";
import { ReactComponent as MyCoursesIconDark } from "../../../assets/images/myCoursesIconDark.svg";
import { ReactComponent as MyCoursesIconLight } from "../../../assets/images/myCoursesIconLight.svg";
import { ReactComponent as SurveyIconDark } from "../../../assets/images/surveyIconDark.svg";
import { ReactComponent as SurveyIconLight } from "../../../assets/images/surveyIconLight.svg";
import { ReactComponent as SupportIconDark } from "../../../assets/images/supportIconDark.svg";
import { ReactComponent as SupportIconLight } from "../../../assets/images/supportIconLight.svg";
import './Header.scss';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getDefaultSupportDataAction, getSupportAction } from 'examsbook-react-sdk/actions/getSupportAction';
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";

/**
 * Single menu component
 * @param props
 * @returns {*}
 * @constructor
 */
function SingleMenu(props) {
  const t = useTranslations();
  const location = useLocation();

  const {
    to,
    href,
    openDrawerStatus,
    screenWidth,
    handleDrawerClose,
    title,
    titleKey,
    icon,
    setSelectedMenu,
    selectedIcon,
    isExact,
  } = props;
  const isSelected = location.pathname === to;
  return (
    <CNavLink to={to} href={href} isExact={isExact}>
      <Tooltip
        placement="right-end"
        title={openDrawerStatus ? "" : title}
        aria-label={title}
      >
        <ListItem
          onClick={
            screenWidth && screenWidth <= 800 ? handleDrawerClose : () => { setSelectedMenu(to); }
          }
        >
          <ListItemIcon>{isSelected ? selectedIcon : icon}</ListItemIcon>
          <ListItemText
            primary={t(titleKey)}
            disableTypography
            style={{fontSize: "16px" }}
          />
        </ListItem>
      </Tooltip>
    </CNavLink>
  );
}

/**
 * Top menu list for web app
 * @param props
 * @returns {*}
 * @constructor
 */
function TopMenuList(props) {
  const { classes, openDrawerStatus, handleDrawerOpen } = props;
  const [isCourseSubMenuOpen, setCourseSubMenuOpen] = useState(true);
  const [supportData, setSupportData] = useState([]);
  const [defaultSupportData, setDefaultSupportData] = useState([]);
  const [showComponent, setShowComponent] = useState(false);
  const [isError, setIsError] = useState('');
  const [dynamicLogos, setDynamicLogos] = useState('');
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState("/dashboard");
  const t = useTranslations();
  localStorage.setItem('openDrawerStatus',openDrawerStatus)  

  const history = useHistory();
  const getSettingApiData = async () => {
    try {
      dispatch(getSupportAction()).then((res) => {
        setSupportData(res);
      });
    } catch (error) {
      console.log('Error - ', error.message);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const getDefaultSeoSettingApiData = async () => {
    try {
      dispatch(getDefaultSupportDataAction()).then((res) => {
        setDefaultSupportData(res);
      });
    } catch (error) {
      setIsError(error.message);
    }
  };

  useEffect(() => {
    getSettingApiData();
    getDefaultSeoSettingApiData();
  }, []);

  const isDynamicLogo = dynamicLogos.split('/').pop();
 
  let API_URL = process.env.REACT_APP_DYNAMIC_URL;
  useEffect(() => {
    const imageValue = supportData?.data?.find(subValue => subValue.key === "Image")?.value;
    const defaultValue = defaultSupportData?.data?.Images?.find(subValue => subValue.key === "Image")?.defaultValue;
    let dynamicLogo = "";    
    if (typeof API_URL != "undefined") {
      if (imageValue) {
        dynamicLogo = API_URL +'/img/post/original/' + imageValue;
      } else if (defaultValue) {
        dynamicLogo = API_URL +'/img/post/original/' + defaultValue;
      }
    }

    setDynamicLogos(dynamicLogo) 
  }, [supportData, defaultSupportData])

  useEffect(() => {
    const savedMenu = localStorage.getItem('selectedMenu');
    setSelectedMenu(savedMenu ? savedMenu : "/dashboard");
  }, []);
  
  useEffect(() => {
    localStorage.setItem('selectedMenu', selectedMenu);
  }, [selectedMenu]);

  return (
    <React.Fragment>
      {
        <>
          <Box
            className="drawer-left-head"
            id='drawerLeftHeadId'>
            {openDrawerStatus && showComponent && (
              <div className={classNames(
                classes.HeaderMenuButton,
                openDrawerStatus
              )}>
                <Typography
                  className="logo-main"
                  variant="h6"
                  noWrap
                >
                  <img src={isDynamicLogo && isDynamicLogo !== 'undefined' ? dynamicLogos : LmsHeaderLogo} alt="LMS logo" onClick={() => history.push('/dashboard')} />
                  <ArrowBackIcon className={"arrow-icon"} onClick={(e) => { handleDrawerOpen(e); setCourseSubMenuOpen(!isCourseSubMenuOpen) }} />
                </Typography>
              </div>
            )}
            {!openDrawerStatus && (
              <IconButton
                aria-label="Open drawer"
                onClick={(e) => {
                  handleDrawerOpen(e);
                  setCourseSubMenuOpen(!isCourseSubMenuOpen)
                }}
                className={classNames(
                  classes.HeaderMenuButton,
                  openDrawerStatus
                )}
              >
                <MenuIcon className={"menu-icon"} />
              </IconButton>
            )}
          </Box>
          <div className={classes.navManus}>
            <div className="nav-menus">
            <SingleMenu
              setSelectedMenu={setSelectedMenu}
              to="/dashboard"
              title="Dashboard"
              titleKey="dashboard"
              isExact={true}
              icon={<DashboardIconDark/>}
              selectedIcon={<DashboardIconLight/>}
              {...props}
            />
            <SingleMenu
              setSelectedMenu={setSelectedMenu}
              to="/course-list"
              title="Courses"
              titleKey="COURSES"
              isExact={false}
              icon={<AllCoursesIconDark/>}
              selectedIcon={<AllCoursesIconLight/>}
              {...props}
            />
              <SingleMenu
                setSelectedMenu={setSelectedMenu}
                to="/my-courses"
                title="MyCourse"
                titleKey="MY_COURSES"
                isExact={false}
                icon={<MyCoursesIconDark/>}
                selectedIcon={<MyCoursesIconLight/>}
                {...props}
              />

              <SingleMenu
                setSelectedMenu={setSelectedMenu}
              to="/surveys"
              title="Surveys"
              titleKey="Surveys"
              isExact={true}
              icon={<SurveyIconDark/>}
              selectedIcon={<SurveyIconLight/>}
              {...props}
            />
            <SingleMenu
              setSelectedMenu={setSelectedMenu}
              to="/support-ticket"
              title="Support"
              titleKey="Support"
              isExact={true}
              icon={<SupportIconDark/>}
              selectedIcon={<SupportIconLight/>}
              {...props}
            />
            </div>
          </div>
          {openDrawerStatus && <Box>
              <Typography className='powered-by'>
                &copy; {t('Powered by')}&nbsp;
                <Link href="http://www.habilelabs.io/" target="_blank" id='habilelabs-text'>{t('HabileLabs')}</Link>
              </Typography>
          </Box>}
        </>
      }
    </React.Fragment>
  );
}

const useStyles = () => {
  const themeData =  localStorage.getItem('theme');
  const theme = JSON?.parse(themeData) 
  let primaryColor = "";
  let primaryFontText = "";
  if (typeof theme !== 'undefined') {
     primaryColor = JSON.parse(themeData)?.primaryBg
     primaryFontText = JSON.parse(themeData)?.primaryFontColor
    }
    return{
      navManus: {
        '& a.active': {
          background: primaryColor,
          transition: '.2s all linear'
        },
        '& a.active li': {
          color: primaryFontText
        },
      },
    }
};


export default withStyles(useStyles, { withTheme: true })(TopMenuList);
