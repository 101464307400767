import React, { useState, useEffect } from 'react';
import { Button, TextField } from '@material-ui/core';
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import COLORS from "examsbook-react-sdk/constants/colors";

import { razorpay } from '../../config/config';
import { isCouponValid } from "examsbook-react-sdk/services/paymentPlanService";
import { useInitiateTransaction, usePaymentPlans, useUserLanguage } from "examsbook-react-sdk/custom-hooks";
import { useDispatch, useSelector } from "react-redux";
import { getTransectionStatus } from "examsbook-react-sdk/actions";

/**
 * Test series card
 * @param props
 * @returns {*}
 * @constructor
 */

function CheckoutModal(props) {
    const { activeCourse, navigateToCheckout } = props;
    const t = useTranslations();
    const history = useHistory();
    const classes = useStyles(styles)();
    const { language } = useUserLanguage();
    const { coupons } = usePaymentPlans();

    const [isCoupon, setIsCoupon] = useState(false);
    const [valid, setValid] = useState();
    const [appliedCoupon, setAppliedCoupon] = useState("");
    const [ couponCode, setCouponCode ] = useState('');

    const dispatch = useDispatch();
    const { mobileNumber } = useSelector((state) => state.user);
    const { razorOrderData } = useInitiateTransaction(activeCourse._id, appliedCoupon);
    const [calculatedPlan, setCalculatedPlan] = useState();
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const options = {
          key: razorpay.KEY_ID, // Enter the Key ID generated from the Dashboard
          amount: razorOrderData.amount,
          currency: razorpay.CURRENCY,
          name: razorpay.BUSINESS_NAME,
          description: activeCourse.name,
          image: razorpay.BUSINESS_LOGO,
          order_id: razorOrderData.id,
          theme: { color: COLORS.LIGHT_BLUE, backdrop_color: COLORS.LIGHT_BLUE },
          readonly : {
            contact: true,
            email: true,
            name: true
          },
          hidden: {
              contact: true,
              email: true,
          },
          config: {
            display: {
                language: language,
            },
          },
          prefill: {
              contact: mobileNumber,
          },
          send_sms_hash: true, // Used to auto-read OTP for cards and net banking pages.
          allow_rotation: false,
          handler: async function (response) {
            if(response){
              dispatch(getTransectionStatus({ ...response, order_ref_no: razorOrderData.orderRefNo }));
              history.push('/payment-response', {orderId: response.razorpay_order_id, amount: calculatedPlan.finalPrice, activeCourse: activeCourse });
            }
          }
      };

      const paymentObject = options && options?.amount && new window.Razorpay(options);
      paymentObject && paymentObject.open();
    }

  const applyCouponFn = async (couponCode) => {
      // call service and validate if coupon is valid.
      const apiResponse = await isCouponValid(couponCode);
      const isValid = apiResponse.valid;
      if (isValid) {
          setAppliedCoupon(couponCode);
          setIsCoupon(true);
          setValid(true);
      } else {
          setAppliedCoupon("");
          setIsCoupon(true);
          setValid(false);
      }
  };
  let filteredCoupons = coupons ? coupons.filter((coupon) => coupon.couponCode === appliedCoupon) : [];
  let selectedCoupon = filteredCoupons && filteredCoupons.length ? filteredCoupons[0] : {};
  useEffect(() => {
      let actualPrice = activeCourse.price;
      let discountBeforeCoupon = actualPrice - activeCourse.discountedPrice || 0;
      let discountViaCoupon = 0;
      if (selectedCoupon && selectedCoupon.course && selectedCoupon.course.filter((p) => p._id === activeCourse._id).length && activeCourse._id === activeCourse._id) {
          discountViaCoupon = Math.min(Math.floor((selectedCoupon.discount * activeCourse?.discountedPrice || activeCourse?.price) / 100), selectedCoupon.maxAmount);
      }
      let totalDiscount = discountBeforeCoupon + discountViaCoupon;
      setCalculatedPlan({
          ...activeCourse,
          actualPrice: activeCourse.price,
          cashDiscount: totalDiscount,
          discountViaCoupon: discountViaCoupon,
          finalPrice: actualPrice - totalDiscount,
          discount: (totalDiscount/actualPrice * 100).toFixed(2)
      });
  }, [activeCourse, selectedCoupon, appliedCoupon]);


    return (
        <div className='card-modal-box'>
          <div className={classes.head}>
            <ArrowBackIcon className={classes.icon} onClick={navigateToCheckout}/>    
            <p className={classes.text}>{t('Checkout')}</p>
          </div>
          <div className={classes.content}>
            <div className={classes.box}>
              <p>{calculatedPlan?calculatedPlan.name:''}</p>
              <p>₹{calculatedPlan?calculatedPlan.price:''}</p>
            </div>
            <div className={classes.box}>
              <p>{t('You_Save')}</p>
              <p>₹{calculatedPlan?calculatedPlan.price - calculatedPlan.finalPrice || 0:''}</p>
            </div>
            <div className={classes.box}>
              <p>{t('Total')} {t('AMOUNT')}</p>
              <p>₹{calculatedPlan?calculatedPlan.finalPrice:''}</p>
            </div>
            <div className={classes.offerBox}>
              <div className={classes.offerBoxInside}>
                <LocalOfferOutlinedIcon className={classes.tag}/>
                <p className={classes.greyText}>{t("Get_Addtional_Discount_Below")}</p>
              </div>
              <div className={classes.EnterCoupon}>
                <TextField
                    id="outlined-basic"
                    type='string'
                    placeholder={t('Coupon_Code')}
                    autoComplete='off'
                    inputProps={{ style: { textTransform: "uppercase", fontSize:'14px' } }}
                    InputProps={{ disableUnderline: true }}
                    onChange={(text) => {
                      setCouponCode(text.target.value.toUpperCase());
                    }}
                    value={couponCode}
                    className={classes.textField}
                    />  
                <Button className={couponCode.length>0?classes.applyButton:classes.applyButtonDis} onClick={()=>{if(couponCode){
                    applyCouponFn(couponCode); 
                    setCouponCode('');
                  }}} disabled={couponCode.length>0?false:true}>{t("Apply")}</Button>
              </div>
              {isCoupon?
              valid? 
                <div>
                  <p className={classes.doneText}>{t('Code')} <span className={classes.doneTextSpan}>{selectedCoupon.couponCode}</span> {t('Applied')}!</p>
                  <p className={classes.validText}>{t('Extra savings with this coupon')} : ₹{calculatedPlan.discountViaCoupon}</p>
                </div>
              : <p className={classes.notvalidText}>{t('This coupon code is not valid or used before.')}<br/>{t('Try different code.')}</p>
            :<></>}
            </div> 
          </div>
          <div className={classes.footer}>
            <div className="card-btn-new" style={{height:'90%'}}>
                <Button onClick={displayRazorpay} className={classes.payButton} style={{fontSize:'14px'}}>
                {t('Pay_Now')}
                </Button>
            </div>
          </div>
        </div>
    );
}

const styles = {
  head:{
    height: '10%',
    padding:'10px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    background: COLORS.THEME_COLOR,
    display: 'flex',
    alignItems: 'center'
  },
  content:{
    minHeight: '80%',
    padding: '0px 20px',
  },
  footer:{
    height: '10%',
    display:'flex',
    alignItems:'flex-end'
  },
  EnterCoupon:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    paddingTop:'10px'
  },
  payButton:{
    height:'100%'
  },
  applyButton:{
    marginLeft:'10px',
    padding:'2px 15px',
    fontSize:'14px',
    borderRadius:'5px',
    backgroundColor: COLORS.THEME_COLOR,
    color: COLORS.WHITE,
    textTransform: 'capitalize',
    '&:hover':{
      backgroundColor:COLORS.THEME_COLOR
    }
  },
  applyButtonDis:{
    marginLeft:'10px',
    padding:'2px 15px',
    fontSize:'14px',
    borderRadius:'5px',
    backgroundColor: COLORS.LIGHT_GREY,
    color: COLORS.SOLID_BLACK,
    textTransform: 'capitalize',
    '&:hover':{
      backgroundColor:COLORS.LIGHT_GREY
    }
  },
  text:{
    color:COLORS.WHITE,
    fontSize:'16px',
    paddingTop:'2px'
  },
  icon:{
    color:COLORS.WHITE,
    marginRight:'12px',
    fontSize:'20px'
  },
  tag:{
    color:COLORS.GREY,
    marginRight:'5px',
    fontSize:'20px'
  },
  greyText:{
    color:COLORS.GREY,
    fontSize:'14.5px'
  },
  box:{
    display:'flex',
    justifyContent:'space-between',
    '& p':{
      fontSize:'15px',
      marginBottom:'1px'
    }
  },
  offerBox:{
    display:'flex',
    height:'45px',
    flexDirection:'column',
    backgroundColor:'#F7F5FF',
    margin:'10px -20px',
    padding:'0px 20px'
  },
  offerBoxInside:{
    display:'flex',
    alignItems:'center'
  },
  textField:{
      border:'1px solid lightgrey',
      width:'85%',
      paddingLeft:'10px',
      borderRadius:'5px'
  },
  doneText:{
    fontSize:'15px',
    marginTop:'15px',
    marginBottom:'4px',
    marginLeft:'4px'
  },
  doneTextSpan:{
    fontSize:'15px',
    fontWeight:'bold',
    color:COLORS.THEME_COLOR
  },
  validText:{
    fontSize:'15px',
    fontWeight:'bold',
    color:COLORS.THEME_COLOR,
    marginTop:'4px',
    marginLeft:'4px'
  },
  notvalidText:{
    fontSize:'15px',
    color:COLORS.DANGER,
    marginLeft:'4px'
  }
};

export default CheckoutModal;
