import _objectSpread from "/codebuild/output/src2290389247/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
const initialState = {
  practiceTests: 0,
  mockTests: 0,
  completed: 0
};
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (action.type === 'GET_QUIZ_TYPE_COUNT') {
    return _objectSpread({}, action.payload);
  }
  return state;
});