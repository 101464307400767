import useSelectedExam from "./useSelectedExam";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getQuizTypeCount } from "examsbook-react-sdk/actions";
/**
 * get list of all tests.
 * @returns {*}
 */
export default function useQuizTypeCount() {
  const selectedExam = useSelectedExam();
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedExam && selectedExam._id) {
      dispatch(getQuizTypeCount(selectedExam._id));
    }
  }, [selectedExam]);
  return useSelector(state => state.quizTypeCount);
}