import React, { useEffect, useState } from 'react';
import bellIcon from "../../../assets/images/notification-bell.png"
import { Box, Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslations } from 'examsbook-react-sdk/custom-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getReadAllNotification } from 'examsbook-react-sdk/actions';
import { useTheme } from '../../../constants/themeProvider';

/**
 * Language switcher.
 * @returns {*}
 * @constructor
 */
function UserNotification(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showDiv, setShowDiv] = useState(true);
    const userNotification = useSelector((state) => state.notificationList.notification);
    const [readNotification, setReadNotification] = useState();
    const t = useTranslations();
    const theme = useTheme();
    let isReadNotificationCount = userNotification.filter(item => item.isRead === false).length;

    const toggleDiv = () => {
        setShowDiv(!showDiv);
    };

    useEffect(()=> {
        setShowDiv(true);
    },[showDiv])

    function handleGoToNotificationDetail() {
        dispatch(getReadAllNotification()).then(response => {
            setReadNotification(response?.data);
        })
        .catch(error => {
            console.error('Failed to fetch notifications:', error);
        });
        history.push('/notification-detail');
    }

    return (
        <React.Fragment>
            <img id="userNotificationId" src={bellIcon} className="notification-image" alt=""/>
            {isReadNotificationCount > 0 && <Box className="badge">
                <Typography variant="inherit" className='notification-count'>{isReadNotificationCount}</Typography>
            </Box>}
            {showDiv && <Box id="notification-sub-menus" className="notification-sub-menus sub-menu-align" onClick={toggleDiv}>
            {userNotification?.length ? (
                <>
                {userNotification?.sort((a, b) => new Date(b?.updated?.on) - new Date(a?.updated?.on)).slice(0,5).map((item, index)=> {
                    const currentTime = Date.now();
                    const scheduledDate = Date.parse(item?.updated?.on);
                    const differenceInMilliseconds = Math.abs(currentTime - scheduledDate);
                    const differenceInSeconds = differenceInMilliseconds / 1000;
                    const differenceInMinutes = differenceInSeconds / 60;
                    const differenceInHours = differenceInMinutes / 60;
                    const differenceInDays = differenceInHours / 24;

                    let timeAgo;
                    if (differenceInMinutes < 60) {
                        timeAgo = `${Math.floor(differenceInMinutes)} min ago`;
                    } else if (differenceInHours < 24) {
                        timeAgo = `${Math.floor(differenceInHours)} h ago`;
                    } else {
                        timeAgo = `${Math.floor(differenceInDays)} d ago`;
                    }

                    return (
                        <Box className="notification-items" onClick={handleGoToNotificationDetail} key={index}>
                            <Box className="notification-list">
                                <Box className="notification">
                                    <img className="profile-pic" src={bellIcon} alt="Profile Picture"/>
                                    <Box className="notification-text">
                                        <Box className="notification-content">
                                            <Typography variant="inherit" className="notification-heading">{item?.notificationId?.title}</Typography>
                                            <Typography variant="inherit" className="notification-info">{item?.notificationId?.message}</Typography>
                                        </Box>
                                        <Typography variant="inherit" className='notification-time'>{timeAgo}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )
                })}
                <Button className="see-all" onClick={handleGoToNotificationDetail}>
                    <Typography variant="inherit" className="see-all-text">{t("See All")} &gt;</Typography>
                </Button>
                </>
            ) : (
                <Box><Typography variant="inherit" className='no-notification-content' style={{color: theme?.primaryColor}}>{t("No_new_notification")}</Typography></Box>
            )}
        </Box>}
        </React.Fragment>
    );
}
export default UserNotification;
