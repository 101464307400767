import { getTransactionsList } from "../services/transactionService";
export const getTransaction = () => dispatch => {
  getTransactionsList().then(data => {
    // handle success
    dispatch({
      type: 'GET_TRANSACTIONS',
      payload: data
    });
  }).catch(error => {
    // handle error
    dispatch({
      type: 'GET_TRANSACTIONS',
      payload: []
    });
  });
};