import { getRazorOrderId } from "../services/transactionService";
export const saveRazorOrderId = (courseId, code) => dispatch => {
  dispatch(request());
  getRazorOrderId(courseId, code).then(data => {
    // handle success
    dispatch(success(data));
  }).catch(error => {
    // handle error
    dispatch(failure(error.toString()));
  });
  function request() {
    return {
      type: "INITIATE_RAZOR_ORDER_ID_WAITING"
    };
  }
  function success(data) {
    return {
      type: "INITIATE_RAZOR_ORDER_ID_SUCCESS",
      payload: data
    };
  }
  function failure(error) {
    return {
      type: "INITIATE_RAZOR_ORDER_ID_ERROR",
      payload: error
    };
  }
};