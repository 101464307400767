import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import FilteredQuizList from "../QuizList/FilteredQuizList";
import { useScrollHeight, useSubExamList, useTranslations } from "examsbook-react-sdk/custom-hooks";
import NavBar from "../../pages/navBar";
import { Dashboard as DashboardIcon, MenuBook as MenuBookIcon } from '@material-ui/icons';

function FilterableTestsContainer(props) {
    const { data, isPending } = props;
    const t = useTranslations();
    const [ selectedTab, setSelectedTab ] = useState(1);

    let subExamList = useSubExamList();
    subExamList = subExamList ? subExamList.category : [];
    //get scroll height
    const scrollHeight = useScrollHeight();

    //on tab change
    function handleTabChange(event, value) {
        setSelectedTab(value);
        window.scrollTo(0, 0);
    }

    let navBarList = [
        {
            label: t('dashboard'),
            ref: 'dashboard',
            show: true,
            icon: <DashboardIcon/>
        },
        {
            label: t('all_test'),
            ref: 'all',
            icon: <MenuBookIcon/>
        }, {
            label: t('REMAINING_TESTS'),
            ref: 'quiz',
            icon: <i className="fa fa-clock-o m-r-15 fa-2x" area-hidden="true"/>
        }, {
            label: t('COMPLETED_TEST'),
            ref: 'attempted',
            icon: <i className="fa fa-check-square-o  m-r-18 fa-2x" area-hidden="true"/>
        } ];
    const tabItemsMap = [ {
        key: 'dashboard'
    }, {
        className: 'Quizes',
        noDataMessage: t('quizzes_are_not_available_for_this_selected_exam'),
        key: 'allTests'
    }, {
        className: 'Quizes',
        noDataMessage: t('quizzes_are_not_available_for_this_selected_exam'),
        key: 'remainingTests'
    }, {
        className: 'Attempted',
        noDataMessage: t('no_attempted_quiz_for_this_exam_attempt_now'),
        key: 'completedTests',
    } ];
    const tabData = selectedTab > 0 ? tabItemsMap[selectedTab] : {};
    //get practice test data.
    return (
        <div className="mock-test-section">
            <div className="top-header">
                <div className={'mock-tabs ' + (scrollHeight > 39 ? 'fixed-header' : '')}>
                    <NavBar navBarList={navBarList}
                            setSelectedTab={setSelectedTab}
                            selectedTab={selectedTab}>

                    </NavBar>
                    <div className={tabData.className}>
                        <Typography component="div" style={{ padding: 8 * 3 }}>
                            <FilteredQuizList className={tabData.className}
                                              quizList={data[tabData.key]}
                                              subExamList={subExamList}
                                              isPending={isPending}
                                              noDataMessage={tabData.noDataMessage}
                                              buttonText={tabData.buttonText}
                                              handleTabChange={handleTabChange}/>
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FilterableTestsContainer;
