import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { LinearProgressWithLabel } from '../MyCourseCard/MyCoursesCard';
import { useTheme } from '../../constants/themeProvider';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        border: '1px solid #0C34504D',
        borderTop: '5px solid #0C3450',
        padding: 10,
        marginBottom: 30,
        borderRadius: '6px'
    },
    title: {
        color: '#0C3450',
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '21px',
        display: 'flex',
        justifyContent: 'space-between',
        textTransform: 'capitalize',
        '& p': {
            margin: 0
        }
    },
    subTitle: {
        maxHeight: '65px',
        overflow: 'auto',
        margin: '0px 0px 8px 0px',
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.50)',
        fontWeight: 275,
        '& span': {
            color: 'rgba(0, 0, 0, 0.50) !important',
        }
    },
    expireTag: {
        fontStyle: 'italic',
        color: 'red',
        fontSize: '12px'
    },
    subTitleCollapsed: {
        maxHeight: '40px',
        margin: '12px 0px 12px 0px',
        '& p': {
            color: 'rgba(0, 0, 0, 0.50)',
            fontSize: '12px',
            fontWeight: 275,
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            lineClamp: 2,
            WebkitBoxOrient: 'vertical',
        },
        '& span': {
            color: 'rgba(0, 0, 0, 0.50) !important',
        }
    },
    seeMore: {
        margin: '32px 0px 0px 0px',
        border: 'none',
        background: 'none',
        padding: '0px',
        position: 'relative',
        cursor: 'pointer',
        width: 'max-content',
        fontWeight: '600',
        fontSize: '12px'
    },
    seeLess: {
        margin: '32px 0px 0px 0px',
        border: 'none',
        background: 'none',
        padding: '0px',
        position: 'relative',
        cursor: 'pointer',
        width: 'max-content',
        fontWeight: '600',
        fontSize: '12px',
        top: '16px'
    }
}));

export default function CourseCard(props) {
    const classes = useStyles();
    const t = useTranslations();
    const percentageCompletion = props?.progressPercent !== undefined ? props?.progressPercent : 0
    const [showExpireTag, setShowExpireTag] = useState(false);
    const [expireDays, setExpireDays] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const theme = useTheme();
    const isNotMobile = useMediaQuery('(min-width:960px)');

    useEffect(() => {
        if (props?.courseData !== undefined && props?.courseData?.endDate) {
            checkExpiration();
        }
    }, []);

    const checkExpiration = () => {
        const currentDate = new Date();
        let courseEndDate = new Date(props?.courseData?.endDate);
        const timeDifference = courseEndDate.getTime() - currentDate.getTime();
        setExpireDays(Math.ceil(timeDifference / (1000 * 60 * 60 * 24)))
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        if (daysDifference <= 7 && daysDifference > 0) {
            setShowExpireTag(true);
        }
    };

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    return (
        isNotMobile ?
        <div className={classes.root}>
            <div className={classes.title}>
                <p>
                    {props?.courseData?.name}
                </p>
                {showExpireTag && (<p className={classes.expireTag}>{`${t('Expires in')} ${expireDays} ${expireDays === 1 ? t('day') : t('days')}`}</p>)}
            </div>
            <div style={{display: 'flex'}}>
                <div style={{paddingRight: '10px'}}>
                    <p className={showFullDescription ? classes.subTitle : classes.subTitleCollapsed} dangerouslySetInnerHTML={{ __html: props?.courseData?.description ? props?.courseData?.description : t('No Description') }}></p>
                </div>
                <div>
                    {props?.courseData?.description?.length > 136 && <button style={{color: showFullDescription ? theme?.secondaryColor : '#000000ab'}} className={showFullDescription ? classes.seeLess : classes.seeMore} onClick={toggleDescription}>{showFullDescription ? t('See Less') : t('See More')}</button>}
                </div>
            </div>
            <div>
                <LinearProgressWithLabel value={percentageCompletion} />
            </div>
        </div>
        :
        <div style={{paddingBottom: '40px'}}>
            <LinearProgressWithLabel value={percentageCompletion} />
        </div>
    );
}