import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LayersIcon from '@material-ui/icons/Layers';

import CTabs from "../components/Tabs/CTabs";
import AllTestContainer from "../components/Tabs/AllTestContainer";
import useAnalyticsPageView from "examsbook-react-sdk/custom-hooks/useAnalyticsPageView";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import useSubExamList from "examsbook-react-sdk/custom-hooks/useSubExamList";

/**
 * All test page.
 * @returns {*}
 * @constructor
 */
function AllTests() {
    const t = useTranslations();
    const subExamList = useSubExamList();
    const [ selectedTab, setSelectedTab ] = useState(1);
    useAnalyticsPageView();

    function handleTabChange(event, value) {
        setSelectedTab(value);
    }

    let tabBarList = [ {
        label: t('dashboard'),
        show: true
    }, {
        label: t('all_test'),
        ref: 'all',
        icon: <LayersIcon/>
    }, {
        label: t('completed'),
        ref: 'attempted',
        icon: <i className="fa fa-check-square-o  m-r-18 fa-2x" area-hidden="true"/>
    }, {
        label: t('test_series'),
        ref: 'mytest',
        icon: <AssignmentIcon/>
    }, {
        label: t('practice_test'),
        ref: 'quiz',
        icon: <i className="fa fa-clock-o  m-r-18" aria-hidden="true"/>
    } ];

    return (
        <div className="mock-test-section">
            <div className="top-header">
                <div className={'mock-tabs'}>
                    <AppBar position="static" className="tabs">
                        <CTabs nav={tabBarList} setActiveTab={selectedTab} handleTabChange={handleTabChange}/>
                    </AppBar>
                    <AllTestContainer selectedIndex={selectedTab}
                                      handleTabChange={handleTabChange}
                                      subExamList={subExamList.category}/>
                </div>
            </div>
        </div>
    );
}

export default AllTests;
