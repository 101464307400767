import React from 'react';
import { useSelector } from "react-redux";
import PracticeQuestionsListContainer from "./PracticeQuestionsListContainer";
import PracticeQuestionsDashboard from "./PracticeQuestionsDashboard";
import BreadcrumbBar from "../../breadcrumbBar";
import status from "examsbook-react-sdk/constants/actionStatus";
import PracticeQuestionDashboardLoader from '../../common/loaders/practiceQuestionDashboardLoader';
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { getFilteredExams } from "examsbook-react-sdk/selectors";

/**
 * This component is gateway between practice questions dashboard and practice questions.
 */

function PracticeQuestionsRightArea(props) {
    const { history, setCurrentSubjectAndTopic, isQuestionList } = props;
    const t = useTranslations();
    const user = useSelector((state) => state.user);
    const subjectStatus = useSelector((state) => state.subjectStatus);
    const subjects = useSelector((state) => getFilteredExams(state));
    const selectedSubjectTopic = useSelector((state) => state.selectedSubjectTopic);
    const selectedSubject = useSelector((state) => state.examData.subjects.find((e) => {
        return e._id === state.selectedSubjectTopic.subject;
    }));

    let selectedTopic = {};
    if (selectedSubject && selectedSubject.topics) {
        selectedTopic = selectedSubject.topics.find((topic) => {
            return (topic._id === selectedSubjectTopic.topic);
        });
    }
    if (Object.keys(selectedTopic).lengh <= 0) {
        setCurrentSubjectAndTopic({ subject: '', topic: '' });
    }

    function handleBreadcrumbLink() {
        history.push('/practice');
        setCurrentSubjectAndTopic({ subject: '', topic: '' });
    }

    let subjectsLoading = (subjects && subjectStatus === status.PENDING);
    return (
        <div className="practice-test">
            <BreadcrumbBar selectedSubject={selectedSubject && selectedSubject.name}
                           selectedTopic={selectedTopic && selectedTopic.name}
                           handleBreadcrumbLink={handleBreadcrumbLink} parentLink="Dashboard"/>
            <div className="practice-question-heading">
                <h4>{user && user.selectedExam ? (user.selectedExam.name + ' ' + t('practice_questions')) : ''}</h4>
            </div>
            {subjects && !subjectsLoading ?
                <div>
                    {
                        isQuestionList ?
                            <PracticeQuestionsListContainer history={history}/> :
                            <PracticeQuestionsDashboard subjects={subjects} history={history}/>
                    }
                </div> : <PracticeQuestionDashboardLoader/>
            }
        </div>
    );
}

export default PracticeQuestionsRightArea;
