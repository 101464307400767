import React, { useState, useEffect } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TestSeriesCard from "../TestSeriesCard/TestSeriesCard";
import "./testSeriesList.scss";
import "../common/cardView.scss";
import TestSeriesLoader from "../common/loaders/TestSeriesLoader";
import CTabs from "../../components/Tabs/CTabs";
import AppBar from "@material-ui/core/AppBar";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import "../TestSeriesList/testSeriesList.scss";

import {
  useTranslations
} from "examsbook-react-sdk/custom-hooks";
import { useCategoryList } from "examsbook-react-sdk/custom-hooks";
import { getFilteredCourseList } from "examsbook-react-sdk/actions/getCourses";
import NoData from "../common/Nodata/noData";

function TestSeriesList(props) {
  const t = useTranslations();
  const history = useHistory();
  const { testSeriesLoading } = props;
  const [selectedTab, setSelectedTab] = useState(1);
  let filteredCourseList = useSelector((state) => state.filteredCourseList);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subCategoryArr, setSubCategoryArr] = useState([]);
  const dispatch = useDispatch();
  const { categoryList } = useCategoryList();

  useEffect(() => {
    if (categoryList && categoryList[0]) {
      setCategory(categoryList[0]._id);
      setSubCategoryArr(categoryList[0].subCategory);
      setSubCategory(categoryList[0].subCategory[0]._id);
      dispatch(
        getFilteredCourseList(
          categoryList[0]._id,
          categoryList[0].subCategory[0]._id
        )
      );
    }
  }, [categoryList, dispatch]);

  function handleCategory(event) {
    setCategory(event.target.value);
    let data = categoryList.find((list) => list._id === event.target.value);
    setSubCategoryArr(data.subCategory);
    setSubCategory("");
  }

  function handleSubCategory(event) {
    setSubCategory(event.target.value);
    dispatch(getFilteredCourseList(category, event.target.value));
  }

  function handleTabMenu(event, value) {
    setSelectedTab(value);
  }

  let tabBarList = [
    {
      label: t("dashboard"),
      ref: "dashboard",
      icon: <DashboardIcon />,
    },
    {
      label: t("test_series"),
      ref: "mytest",
      icon: <MenuBookIcon />,
    },
  ];

  function renderList() {
    if (selectedTab === 0) {
      history.push("/dashboard/");
    }
    return (
      <React.Fragment>
        {selectedTab === 1 && (
          <>
            <div className="select-div">
                <div className="category-select">
                <FormControl>
                <Select
                  IconComponent = {ExpandMore}
                  labelId="select-category-label"
                  id="select-category"
                  value={category}
                  onChange={(event) => {
                    handleCategory(event);
                  }}
                >
                  {categoryList &&
                    categoryList.map((data, index) => {
                      return (
                        <MenuItem value={data._id} key={index}>
                          {data.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                </FormControl>
                </div>
              <div className="category-select">
              <FormControl>
                <Select
                  IconComponent = {ExpandMore}
                  labelId="select-subcategory-label"
                  id="select-subcategory"
                  value={subCategory}
                  onChange={(event) => {
                    handleSubCategory(event);
                  }}
                >
                  {subCategoryArr &&
                    subCategoryArr.map((data, index) => {
                      return (
                        <MenuItem value={data._id} key={index}>
                          {data.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              </div>
            </div>
            {filteredCourseList && filteredCourseList.courses.length > 0 ? (
              <div className="card-view test-series test-series-tabs">
                {filteredCourseList.courses.map((data, index) => {
                  return <TestSeriesCard key={index} testData={data} />;
                })}
              </div>
            ) : (
             <NoData />
            )}
          </>
        )}

        {props.showFooter && (
          <div className="dashboard-mobile-view">
            <div className={"mock-tabs"}>
              <AppBar position="static" className="tabs">
                <CTabs
                  nav={tabBarList}
                  setActiveTab={selectedTab}
                  handleTabChange={handleTabMenu}
                />
              </AppBar>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  function renderLoader() {
    //we will show three cards to fill this space.
    return (
      <div className="card-view test-series">
        <TestSeriesLoader />
        <TestSeriesLoader />
        <TestSeriesLoader />
        <TestSeriesLoader />
      </div>
    );
  }

  return (
    <React.Fragment>
      {!testSeriesLoading ? renderList() : renderLoader()}
    </React.Fragment>
  );
}

export default TestSeriesList;
