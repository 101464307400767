import useTimeRemainingQuiz from "./useTimeRemainingQuiz";
import useTranslations from "./useTranslations";
import { useSelector } from "react-redux";
import useIsPremiumUser from "./useIsPremiumUser";
export default function useQuizButtonStatus(quizData) {
  const timeRemaining = useTimeRemainingQuiz(quizData);
  const isPremium = useIsPremiumUser();
  const t = useTranslations();
  let disabled = false;
  let label = '';
  let action = '';
  const appSettings = useSelector(state => state.appSettings);
  const isPaymentEnabled = !!appSettings.paymentEnabled;
  //quiz already live.
  if (quizData && quizData.startDate <= new Date().getTime() || !(quizData && quizData.startDate)) {
    if (quizData && quizData.attempted) {
      //attempted.
      //either completed or not not completed.
      if (quizData && quizData.progressed) {
        //resume quiz.
        //Missed expired,
        label = t('RESUME_QUIZ');
        action = 'START';
      } else if (!(quizData && quizData.resultDate) || quizData && quizData.resultDate && quizData.resultDate <= new Date().getTime()) {
        // attempted test also may have two conditions. there may be chance that result may not have released yet.
        //view result.
        label = t('view_result');
        action = 'RESULT';
      } else {
        //result pending.
        disabled = true;
        label = t('Result_pending') + "" + timeRemaining;
      }
    } else if (quizData && quizData.endDate && quizData.endDate < new Date().getTime()) {
      //expired.
      //not attempted.
      //either expired quiz or quiz we can start.
      disabled = true;
      label = t('Expired');
    } else if (quizData && quizData.progressed) {
      //resume quiz.
      label = t('RESUME_QUIZ');
      action = 'START';
      //if this is paid quiz and isPaymentEnabled then we will show different
      if (isPaymentEnabled && quizData && quizData.pricing === 'PAID' && !isPremium) {
        action = 'PAYMENT';
        label = t('Unlock_Now');
      }
    } else {
      //start quiz.
      //if quiz is not live yet
      disabled = !(quizData && quizData.status);
      label = t('start_quiz');
      action = 'START';
      //if this is paid quiz and isPaymentEnabled then we will show different
      if (isPaymentEnabled && quizData && quizData.pricing === 'PAID' && !isPremium) {
        action = 'PAYMENT';
        label = t('Unlock_Now');
      }
    }
  } else {
    disabled = true;
    label = t('live_in') + "" + timeRemaining;
  }
  return {
    disabled,
    label,
    action
  };
}