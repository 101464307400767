import React from 'react';
import NoData from '../common/Nodata/noData';
import '../common/cardView.scss';
import QuizListSection from './QuizListSection';
import QuizCardLoader from "../common/loaders/QuizCardLoader";
import { filterQuizAndGroup } from "examsbook-react-sdk/common";

/**
 * Quiz list page for web.
 * @param props
 * @returns {*}
 * @constructor
 */
function QuizList(props) {
    const {
        quizList,
        subExamList,
        filteredExams,
        isPending,
        activeIndex,
        noDataMessage,
        buttonText,
        handleTabChange
    } = props;
    let filteredData = filterQuizAndGroup(quizList, subExamList, filteredExams);

    function renderLoader() {
        return (
            <div className="card-view">
                <div className="card-list-view">
                    <QuizCardLoader/>
                    <QuizCardLoader/>
                    <QuizCardLoader/>
                    <QuizCardLoader/>
                </div>
            </div>
        );
    }

    function renderQuizList(filterData, activeQuizIndex) {
        return (
            filterData && filterData.quizKeysData.length > 0 ?
                filterData.quizKeysData.map((key, index) => {
                    return (
                        <QuizListSection key={index} quizSectionList={filterData.quizData[key]} sectionName={key}/>
                    );
                })
                : activeQuizIndex === 0 ? null : <NoData message={noDataMessage} buttonText={buttonText}
                                                         handleTabChange={handleTabChange}/>
        );
    }

    return (
        <div>
            {
                !isPending ? renderQuizList(filteredData, activeIndex) : renderLoader()

            }
        </div>
    );
}

export default QuizList;
