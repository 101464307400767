import useSelectedExam from "./useSelectedExam";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPracticeTestList } from "examsbook-react-sdk/actions/getPracticeTestList";
import { getCompletedPracticeTest, getRemainingPracticeTest, getPracticeTest } from "examsbook-react-sdk/selectors";
import status from "../constants/actionStatus";

/**
 * get list of all tests.
 * @returns {*}
 */
export default function usePracticeTestList() {
  const selectedExam = useSelectedExam();
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedExam && selectedExam._id) {
      dispatch(getPracticeTestList(selectedExam._id));
    }
  }, [selectedExam]);
  let remainingPracticeTests = useSelector(state => getRemainingPracticeTest(state));
  let completedPracticeTests = useSelector(state => getCompletedPracticeTest(state));
  let practiceTests = useSelector(state => getPracticeTest(state));
  let practiceTestPending = useSelector(state => state.practiceTests.status === status.PENDING);
  return {
    remainingPracticeTests,
    completedPracticeTests,
    practiceTests,
    practiceTestPending
  };
}