import React, { useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Grid } from '@material-ui/core';
import { useScreenWidth, useTranslations } from "examsbook-react-sdk/custom-hooks";
import './reports.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function AttemptCard(props) {
    const {courseResultData} = props
    const t = useTranslations();
    const screenWidth = useScreenWidth();
    const [ open, setOpen ] = useState(false);
    let rightAns = []
    let wrongAns = []
    
    const queData = courseResultData?.result && courseResultData?.result?.map((item, index)=>{
        let newRightAns = 0
        let newWrongAns = 0
        item.map((subItem, subIndex)=>{
            newWrongAns += subItem?.wrongAns;
            newRightAns += subItem?.rightAns;
            return false;
        }) 
        rightAns.push(newRightAns)
        wrongAns.push(newWrongAns)
        return false;
    })

    function handleChange(id) {
        setOpen(prevOpen => ({ ...prevOpen, [id]: !prevOpen[id] }));
    }

    return (
        <div className='attempt-bind'>
            <Box className="attempt-card-box">
            <TableContainer component={Paper} className='table-container'>
            <h2>{t('attempts')}</h2>
                <Table className='table-data'>
                    <TableHead>
                    <TableRow>
                            <TableCell className="column-header">{t('Attempt_Number')}</TableCell>
                            {/* <TableCell className="column-header">{t('Course')}</TableCell> */}
                            <TableCell className="column-header">{t('score')}</TableCell>
                            <TableCell className="column-header">{t('time')}</TableCell>
                            {screenWidth <= 710 && <TableCell className="column-header">{t('Action')}</TableCell>}
                            {screenWidth > 710 && (
                            <>
                            <TableCell className="column-header">{t('Total_Questions')}</TableCell>
                            <TableCell className="column-header">{t('Unanswered')}</TableCell>
                            <TableCell className="column-header">{t('Correct_Answer')}</TableCell>
                            <TableCell className="column-header">{t('incorrect_answer')}</TableCell>
                            </>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {courseResultData?.attemptsField?.map((item, index) => (
                        <>
                            <TableRow onClick={() => handleChange(index)} key={index} className={`body-row ${open[index] ? 'selected-attempt' : ''}`}>
                                <TableCell>{index+1}</TableCell>
                                {/* <TableCell>{item?.course}</TableCell> */}
                                <TableCell>{courseResultData?.bestScore[index]}</TableCell>
                                <TableCell>{((item?.timeSpent)/60).toFixed(2)}</TableCell>
                                {screenWidth <= 710 && (
                                <>
                                    <TableCell className='accordian-icon support-items'>
                                        <Button>
                                            <ExpandMoreIcon style={{ transform: open[index] ? 'rotate(180deg)' : 'rotate(0deg)' }}/>
                                        </Button>
                                    </TableCell>
                                </>)}
                                {screenWidth > 710 && (
                                <>
                                <TableCell>{courseResultData?.attemptsField[index]?.questionTotalCount}</TableCell>
                                <TableCell>{courseResultData?.attemptsField[index]?.unAnsweredQuestion}</TableCell>
                                <TableCell>{rightAns[index]}</TableCell>
                                <TableCell>{wrongAns[index]}</TableCell>
                                </>)}
                            </TableRow>                           
                            {screenWidth <=710 && open[index] && 
                                <TableRow>
                                    <TableCell colSpan={6} className='support-items'>
                                        <Box className='mobile-view-support'>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" className='attempt-table-heading'>{t('Total_Questions')}</Typography>
                                                    <Typography variant="body1" className='attempt-table-sub-heading'>{courseResultData?.attemptsField[index]?.questionTotalCount}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" className='attempt-table-heading'>{t('Unanswered')}</Typography>
                                                    <Typography variant="body1" className='attempt-table-sub-heading'>{courseResultData?.attemptsField[index]?.unAnsweredQuestion}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" className='attempt-table-heading'>{t('Correct_Answer')}</Typography>
                                                    <Typography variant="body1" className='attempt-table-sub-heading'>{rightAns[index]}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" className='attempt-table-heading'>{t('incorrect_answer')}</Typography>
                                                    <Typography variant="body1" className='attempt-table-sub-heading'>{wrongAns[index]}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            }
                        </>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
        </div>
    );
}

export default AttemptCard;
