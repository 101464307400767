import React from 'react';
import ContentLoader from 'react-content-loader';

const QuizResultHeaderLoader = () => (
    <div>
        <ContentLoader
            height={50}
            speed={1}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="5" y="20" rx="0" ry="5" width="97%" height="13"/>
        </ContentLoader>
    </div>
);

export default QuizResultHeaderLoader;
