import React from "react";
import { Route, Redirect } from "react-router-dom";
import ErrorBlock from "../common/Errors/errorBlock";
import useAuthorization from "../../hooks/useAuthorization";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { validateToken } = useAuthorization();
  const token = localStorage.getItem("token");
  const isValid = validateToken(token);

  return (
    <Route
      {...rest}
      render={(props) =>
        isValid ? (
          <ErrorBlock>
            <Component {...props} />
          </ErrorBlock>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
