import React from "react";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { Routes } from "./components/Navigation/Routes";
import theme from "./themes/theme";
import "./App.scss";

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Routes />
    </MuiThemeProvider>
  );
}

export default App;
