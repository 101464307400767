import React from "react";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { ReactComponent as LiveIcon } from "../../assets/images/LiveImg.svg";
import moment from "moment";
import { addLiveTracking } from "examsbook-react-sdk/actions";
import { useDispatch } from "react-redux";
import { Box } from "@material-ui/core";

export default function LiveClassCard(props) {
  const dispatch = useDispatch()
  const classes = useStyles(styles)();
  const t = useTranslations();
  const title = props?.data?.classId?.title
  const currentDate = new Date().getTime();
  let quizStartDate = props?.data?.classId?.startDate;
  let quizEndDate = props?.data?.classId?.endDate;
  const liveClassDisable = ((currentDate >= quizStartDate) && (currentDate > quizEndDate)) || ((currentDate < quizStartDate) && (currentDate < quizEndDate));

  const callLiveTrackingApi = () => {
    let data = {
      classId: props?.data?.classId?._id,
      courseId: props?.sectionData?.courseId?._id,
    }
    dispatch(addLiveTracking(data))
  }

  const handleClick = () => {
    if (!liveClassDisable) {
      window.open(props?.data?.classId?.url, '_blank');
      callLiveTrackingApi();
    }
  }

  return (
    <>
        <Box className={classes.buttonDisabled} disabled={liveClassDisable} onClick={handleClick}>
          <div
            className={
              liveClassDisable
                ? classes.bgDisable + " " + classes.liveclassbox
                : classes.liveclassbox
            }
          >
            <div className={classes.certificatediv}>
              <div className={classes.certificatemaindiv}>
                <LiveIcon className={classes.certificateImg} />
                <div className={classes.liveText}>
                  <p className={classes.genustext}>
                    {title.charAt(0).toUpperCase() + title.slice(1)}
                  </p>
                  <p className={classes.dateText}>
                    {moment(props?.data?.classId?.startDate).format(
                      "DD MMM YYYY"
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ border: (currentDate >= quizStartDate && currentDate < quizEndDate) ? '1px solid green' : currentDate > quizEndDate ? '1px solid red' : "1p solid grey" }} className={classes.expiredQuizStatusDiv}>
            <p style={{ color: (currentDate >= quizStartDate && currentDate < quizEndDate) ? 'green' : currentDate > quizEndDate ? 'red' : "grey" }} className={classes.expiredQuizText}>{(currentDate >= quizStartDate && currentDate < quizEndDate) ? t('live') : currentDate > quizEndDate ? t('Expired') : currentDate < quizStartDate ? t('Upcoming') : ''}</p>
            </div>
            <div className={classes.dashtimeDivBox}>
              <div className={classes.dashtimeDiv}>
                <div className={classes.timeDiv}>
                  <div className={classes.timeSpan}>
                    {moment(props?.data?.classId?.startDate).format("hh:mm A")}
                  </div>
                  <div className={classes.timeSpan}>
                    {moment(props?.data?.classId?.endDate).format("hh:mm A")}
                  </div>
                </div>
                <div className={classes.dashedLine}>
                  <div style={{ ...styles.redPoint, ...styles.leftPoint }}></div>
                  <div style={{ ...styles.redPoint, ...styles.rightPoint }}></div>
                </div>
              </div>
            </div>
          </div>
        </Box>
    </>
  );
}

const styles = theme => ({
  liveclassbox: {
    border: "1px solid #E7E7E7",
    display: "flex",
    margin: "5px 0",
    padding: "16px",
    transition: "background-color 0.3s",
    cursor: "pointer",
    borderRadius: '6px',
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  certificatemaindiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "11px",
    left: "14px",
    top: "14px",
  },
  certificateImg: {
    width: "40px",
    height: "40px",
    flexShrink: 0,
    borderRadius: '2px'
  },
  buttonDisabled: {
    border: "0",
    width: "-webkit-fill-available",
    backgroundColor: "transparent",
    textAlign: "left",
  },
  bgDisable:{
    backgroundColor: "#ececec",
    border: "1px solid #E7E7E7",
    display: "flex",
    margin: "5px 0",
    padding: "14px",
    transition: "background-color 0.3s",
    cursor: "not-allowed",
  },
  expiredQuizStatusDiv: {
    alignSelf: 'center', 
    fontWeight: 'bold',
    marginLeft: 'auto', 
    border: '1px solid', 
    borderRadius: '15px',
    minWidth: '50px' 
  },
  expiredQuizText: {
    fontSize: '12px',
    margin: '0px',
    padding: '4px',
    textAlign: 'center',
    fontWeight: '600',
  },
  genustext: {
    fontWeight: "500",
    margin: 0,
    color: "#000000",
    opacity: "0.6",
    fontSize: "16px",
    '@media (min-width: 960px) and (max-width: 1350px)': {
      fontSize: '13px'
    },
  },
  dateText: {
    margin: 0,
    fontSize: "12px",
    color: "#000000",
    opacity: "60%",
    '@media (min-width: 960px) and (max-width: 1350px)': {
      fontSize: '11px',
      marginTop: '7px'
  },
  },
  dashtimeDiv: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
    // alignItems:'center'
  },
  dashedLine: {
    marginRight: "8px",
    borderLeft: "1px dashed #1868A0",
    height: "25px",
    position: "relative",
  },
  redPoint: {
    position: "absolute",
    width: "4px",
    height: "4px",
    background: "#1868A0",
    borderRadius: "50%",
  },
  leftPoint: {
    top: "-6px",
    left: "-2.2px",
  },
  rightPoint: {
    bottom: "-6px",
    left: "-2.8px",
  },

  timeDiv: {
    color: "#84818A",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  timeSpan: {
    color: "#000000",
    opacity: "60%",
    fontSize: "9px",
    fontWeight: "300",
  },
  dashtimeDivBox: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "2px",
  },
});
