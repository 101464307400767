import React from 'react';

function CUserImageIcon(props) {
    const { src } = props;
    let imageUrl = src || '';
    return (
        <img
            src={imageUrl}
            alt="user image"
            className="my-image"/>
    );
}

export default CUserImageIcon;
