import React from 'react';
import { useTranslations, useUserLanguage } from "examsbook-react-sdk/custom-hooks";
import translatorLogo from "../../../assets/images/newTranslatorLogo.png"

/**
 * Language switcher.
 * @returns {*}
 * @constructor
 */
function LanguageSwitcher() {
    const t = useTranslations();
    const { language, setUserLanguage } = useUserLanguage();
    return (
        <React.Fragment>
            <img id="languageImageId" src={translatorLogo} className="languageImage" alt=""/>
            {/* <span>{language === 'en' ? t('english') : t('hindi')}</span> */}
            <ul className="sub-menus">
                <li className={language === 'en' ? 'selected' : ''} onClick={() => {
                    setUserLanguage('en');
                }}><span>{t('english')}</span></li>
                <li className={language === 'hn' ? 'selected' : ''} onClick={() => {
                    setUserLanguage('hn');
                }}><span>{t('hindi')}</span></li>
            </ul>
            <span></span>
        </React.Fragment>
    );
}

export default LanguageSwitcher;
