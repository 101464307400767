//quiz results.
import { useEffect, useState } from "react";
import { getQuizResult } from "../services/quizResults";

/**
 * Get result of quiz
 * @param quizId
 * @returns {{status: string}}
 */
export default function useGetQuizResult(quizId) {
  const [quizResult, setQuizResult] = useState({
    status: 'PENDING'
  });
  //get results.
  useEffect(() => {
    getQuizResultData(quizId);
  }, [quizId]);
  // get quiz result.
  function getQuizResultData(id) {
    if (id) {
      getQuizResult(id).then(data => {
        setQuizResult({
          data: data
        });
      }).catch(Error => {
        setQuizResult({
          data: {
            error: Error
          }
        });
      });
    }
  }
  return quizResult;
}