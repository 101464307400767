import axios from "./axiosConfig";

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPaymentPlanList() {
  return axios.get('/api/v1/payment-plans').then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export function isCouponValid(code) {
  return axios.get('/api/v1/validate-coupon/' + code).then(response => {
    // handle success
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}