import status from "../constants/actionStatus";
const initialState = {
  status: status.PENDING,
  activeCourse: {}
};
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case "SELECT_USER_COURSE_PENDING":
      return {
        status: status.PENDING,
        activeCourse: {}
      };
    case "SELECT_USER_COURSE_SUCCESS":
      return {
        status: status.PENDING,
        activeCourse: action.payload
      };
    case "SELECT_USER_COURSE_FAILURE":
      return {
        status: status.ERROR,
        activeCourse: action.payload
      };
    default:
      return state;
  }
});