import { useEffect, useState } from "react";
export default function useScrollHeight() {
  const [scrollHeight, setScrollHeight] = useState(window.scrollY);
  useEffect(() => {
    window.addEventListener('scroll', scrollListener, false);
    //on unmount
    return () => {
      window.removeEventListener('scroll', scrollListener, false);
    };
  }, []);
  function scrollListener() {
    setScrollHeight(window.scrollY);
  }
  return scrollHeight;
}