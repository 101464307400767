import React from "react";
import FilterableTestsContainer from "../components/Tabs/FilterableTestsContainer";
import {
    useAnalyticsPageView,
    useScrollHeight,
    useSubExamsList,
    useAllTestListByExam
} from "examsbook-react-sdk/custom-hooks";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import BreadcrumbBar from "../components/breadcrumbBar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

/**
 * Test List By Exams
 * @param props
 * @returns {*}
 * @constructor
 */
function TestListByExams(props) {
    const examId = props.match.params.id;
    const { history } = props;
    const t = useTranslations();
    const { subExamsQuizReport } = useSubExamsList();

    function handleGoToTestClick() {
        history.push("/dashboard");
    }

    function handleBreadcrumbLink() {
        history.push("/dashboard");
    }

    //analytics
    useAnalyticsPageView();
    //get scroll height
    const scrollHeight = useScrollHeight();
    const {
        allTest,
        remainingTests,
        completedTests,
        isLoading
    } = useAllTestListByExam(examId);

    let data = { allTests: allTest, remainingTests: remainingTests, completedTests: completedTests };
    return (
        <div className="mock-test-section">
            {subExamsQuizReport && subExamsQuizReport.length
                ? subExamsQuizReport.filter((exam) => exam._id === examId).map((exam, index) => {
                    return (
                        <div key={index} className="mobile-back-to-test">
                            <ArrowBackIcon onClick={handleGoToTestClick}/>
                            <span>{exam.name}</span>
                        </div>
                    );
                })
                : ""}
            <div
                className={"mock-tabs " + (scrollHeight > 39 ? "fixed-header" : "")}
            >
                {subExamsQuizReport && subExamsQuizReport.length
                    ? subExamsQuizReport.filter((exam) => exam._id === examId).map((exam, index) => {

                        return (
                            <BreadcrumbBar
                                key={index}
                                history={history}
                                selectedSubject={exam.name}
                                parentLink={t("Dashboard")}
                                handleBreadcrumbLink={handleBreadcrumbLink}
                            />
                        );
                    })
                    : ""}
            </div>
            <FilterableTestsContainer
                data={data}
                isPending={isLoading}
            />
        </div>
    );
}

export default TestListByExams;
