import React from "react";
import ReactDOM from 'react-dom';
import "./fonts/fonts.css";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import configureStore from "./helpers/configureStore";
import * as serviceWorker from "./serviceWorker";
import "font-awesome/css/font-awesome.min.css";
import "examsbook-react-sdk/translations";
import { SearchQueryProvider } from "./services/SearchQueryContext";

ReactDOM.render(
  <Provider store={configureStore}>
    <SearchQueryProvider>
      <App />
    </SearchQueryProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
