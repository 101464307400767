import React from 'react';
import './RankerListItem.scss';
import CUserImageIcon from '../CUserImageIcon';

/**
 * single ranker item
 * @param props
 * @returns {*}
 * @constructor
 */
function RankerListItem(props) {
    const { rankerData } = props;
    return (
        <div className="rankers-list">
            <CUserImageIcon src={rankerData.image} alt={rankerData.image}/>
            <div className="rankers-detail">
                <h4>{rankerData.title}</h4>
                <span>{rankerData.rank.value} / {rankerData.rank.outOf}</span>
            </div>
        </div>
    );
}

export default RankerListItem;
