import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { useTheme } from '../constants/themeProvider';
import { ColorsConstant } from "./colorConstant";

const OverlayButton = (props) => {
    const { title, onClick, classStyleHeading, classStyleText, disabled } = props;
    const theme = useTheme();

    return (
        <Button style={{background: theme?.primaryColor, borderColor: ColorsConstant.headerColor}} className={classStyleHeading ? classStyleHeading : ''} onClick={onClick} disabled={disabled && disabled }>
            <Box className={classStyleText ? classStyleText : {}}>
            <Typography variant="paragraph" style={{color: theme?.primaryFontText}}>
              {title}
            </Typography>
            </Box>
        </Button>
    )    
};

export default OverlayButton;