import React from 'react';
import './AllTestsTab.scss';
import NoData from '../common/Nodata/noData';
import LiveTestList from "../LiveTest/LiveTestList";
import FilteredQuizList from "../QuizList/FilteredQuizList";
import TestSeriesList from "../TestSeriesList/TestSeriesList";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import TestTypeCard from "../AllTestTab/TestTypeCard";

/**
 * All test tab.
 * @param props
 * @returns {*}
 * @constructor
 */
function AllTestTab(props) {
    const {
        quizList,
        activeIndex,
        subExamList,
        handleTabChange,
        noDataMessage,
        testSeriesList,
        testSeriesLoading
    } = props;
    const t = useTranslations();
    return (
        <div>
            <TestTypeCard/>
            <div>

                {
                    <LiveTestList allowedExams={subExamList}/>
                }
            </div>
            {
                quizList && quizList.length > 0 ?
                    <FilteredQuizList quizList={quizList} subExamList={subExamList}
                                      activeIndex={activeIndex}
                                      noDataMessage={t('QUIZZES_NOT_AVAILABLE_SELECTED_EXAM')}/>
                    : null
            }
            {
                testSeriesList && testSeriesList.length > 0 ?
                    <div>
                        <h4 className="heading">{t('test_series')}</h4>
                        <TestSeriesList testSeriesList={testSeriesList} testSeriesLoading={testSeriesLoading}
                                        handleTabChange={handleTabChange}/>
                    </div> : null
            }
            {
                (!testSeriesLoading && testSeriesList.length === 0 && !quizList && quizList.length === 0) ?
                    <NoData message={noDataMessage} handleTabChange={handleTabChange}/> : null
            }
        </div>
    );
}

export default AllTestTab;
