import React, { useEffect } from 'react';
import QuizResultScreen from '../components/QuizResultScreen/quizResultScreen';
import { useAnalyticsPageView } from "examsbook-react-sdk/custom-hooks";
import { useDispatch } from "react-redux";

/**
 * Quiz result page.
 * @param props
 * @returns {*}
 * @constructor
 */

function QuizResults(props) {
    //google analytics
    useAnalyticsPageView();
    const dispatch = useDispatch();
    const { match, history } = props;
    const quizId = match.params.id;
    window.onpopstate = () => {
    }
    //clean previous Quiz submissions.
    useEffect(() => {
        dispatch({
            type: 'RESET_QUIZ_SUBMISSION'
        });
    }, []);
    return (
        <div>
            {<QuizResultScreen
                quizId={quizId}
                history={history}
            />}
        </div>
    );
}

export default QuizResults;
