/**
 * This component is used to show linear progress bar changing colors according to percentage.
 */
import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

function CommonLinearProgress(props) {
    const { progressPercentage } = props;
    let className;
    if (progressPercentage >= 66.66) {
        className = 'progressBarHigh';
    } else if (progressPercentage <= 66.66 && progressPercentage >= 33.33) {
        className = 'progressBarMed';
    } else {
        className = 'progressBarLow';
    }
    return (
        <LinearProgress variant="determinate" value={progressPercentage} className={className}/>
    );
}

export default CommonLinearProgress;
