import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import feedback from '../../../constants/feedbackResponse';
import { reactGaEvent } from "examsbook-react-sdk/actions/Common";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import { useSelector } from "react-redux";

/**
 * Quiz feedback model.
 * @param props
 * @returns {*}
 * @constructor
 */
function QuizFeedbackModal(props) {
    const t = useTranslations();
    const user = useSelector((state) => state.user);
    const [ response, setResponse ] = useState([]);
    const [ suggestion, setSuggestion ] = useState('');
    const {
        quizId,
        isFeedbackPopupOpen,
        popupCloseAfterSubmit,
        closeQuizFeedbackModel,
        openFeedbackModal,
        rating,
        saveQuizFeedback
    } = props;

    function submitFeedback() {
        const feedbackData = {
            rating: rating,
            response: response,
            suggestion: suggestion,
            user: user._id
        };
        saveQuizFeedback(quizId, { feedback: [ feedbackData ] });
        reactGaEvent(" Quiz Feedback ", "Feedback Submit");
    }

    function saveResponse(key) {
        const index = response.indexOf(key);
        index === -1 ? response.push(key) : response.splice(index, 1);
        setResponse(response);
    }

    function onTextChange(event) {
        setSuggestion(event.target.value);
    }

    return (
        <Modal className="result-feedback-modal"
               aria-labelledby="simple-modal-title"
               aria-describedby="simple-modal-description"
               open={isFeedbackPopupOpen && popupCloseAfterSubmit}>
            <DialogContent>
                <div>
                    <div className="feedback-screen">
                        <div className="feedback-close" onClick={closeQuizFeedbackModel}>
                            <i className="fa fa-close"/>
                        </div>
                        <div className="feedback-rate">
                            <ul>
                                {
                                    feedback.ratings.map((value, index) => {
                                        return (<li key={index} onClick={() => openFeedbackModal(index)}>
                                            <button data-tooltip={t(value)}><i
                                                className={'fa ' + (index < rating ? 'fa-star' : 'fa-star-o')}/>
                                            </button>
                                        </li>);
                                    })
                                }
                            </ul>
                        </div>
                        <div className="feedback-content">
                            <h4>Hi {user.name}! What Did You Like ?</h4>
                            <ul className="unstyled centered">
                                {
                                    feedback.responses.map((data, index) => {
                                        return (
                                            <li key={index}>
                                                <input className="styled-checkbox"
                                                       onChange={() => saveResponse(data.key)}
                                                       id={"styled-checkbox-" + index + 1} type="checkbox"
                                                       value={data.key}/>
                                                <label htmlFor={"styled-checkbox-" + index + 1}>{t(data.value)}</label>
                                            </li>
                                        );
                                    })
                                }

                            </ul>
                        </div>
                        <div className="feedback-block">
                                <textarea placeholder="Please Type Your Suggestion Here" value={suggestion}
                                          onChange={onTextChange}/>
                        </div>
                        <div className="feedback-submit">
                            <Button onClick={submitFeedback}>{t('submit_feedback')}</Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Modal>
    );
}

export default QuizFeedbackModal;
