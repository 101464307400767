import React, { useEffect, useRef, useState } from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '../../constants/themeProvider';
import { getSupportAction } from "examsbook-react-sdk/actions/getSupportAction";
import { useDispatch } from "react-redux";

export default function CTabs(props) {
    const dispatch = useDispatch()
    const [supportData, setSupportData] = useState([]);
    const certificateValue = supportData?.data?.find(subValue => subValue.key === "Activate Certificate")?.value;
    const t = useTranslations();
    const theme = useTheme();
    const drawerToggle = localStorage.getItem('openDrawerStatus')
    const isNotMobile = useMediaQuery('(min-width:960px)');
    const getSettingApiData = async () => {
      try {
        dispatch(getSupportAction()).then((res) => {
          setSupportData(res);
        });
      } catch (error) {
        console.log("Error - ", error.message);
      }
    };

    useEffect(() => {
      getSettingApiData();
    }, []);

    const styles = {
    root: {
        backgroundColor: 'white',
        padding: '0px !important',
        display: 'flex',
        flexDirection: 'column',
        "@media (max-width: 959px)": {
            display: 'block'
        },
    },
    badge: {
        display: 'block',
        margin: '0px 0px 0px auto'
    },
    customBadge1: {
        background: "red"
    },
    logo: {
        border: '1px solid #0C34504D',
        height: '82px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        gap: '24px',
        paddingLeft: '20%',
        "@media (max-width: 1500px)": {
            justifyContent: 'center',
            paddingLeft: '0px'
        }

    },
    logoResize: {
        border: '1px solid #0C34504D',
        height: '82px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        justifyContent: 'space-around',
        "@media (max-width: 959px)": {
            borderWidth: '0px 0px 1px 0px',
            borderRadius: '0px',
            height: '40px'
        }
    },
    title: {
        width: '50px',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16px',
        display: 'flex',
        justifyContent: 'center',
        color: '#00000099',
        textTransform: 'capitalize',
        textAlign: 'left',
    },
    titleResize: {
        width: '50px',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        display: 'flex',
        justifyContent: 'center',
        color: '#00000099',
        textTransform: 'capitalize',
        textAlign: 'left',
        "@media (max-width: 959px)": {
            width: '100%',
            textAlign: 'center'
        },
        "@media (min-width: 960px) and (max-width: 1200px)": {
            fontSize: '10px !important'
        },
    },
    iconTab: {
        alignItems: 'baseline',
        padding: 0,
        margin: 0,
        "& span": {
            alignItems: 'baseline',
        },
    },
    activeLogo: {
        border: `1px solid ${theme?.secondaryColor}`,
        boxShadow: '2px 4px 8px 0px #C6C6C640',
        borderRadius: '4px',
        transition: '.2s all linear',
        "@media (max-width: 959px)": {
            borderWidth: '0px 0px 1px 0px',
            border: `solid ${theme?.secondaryColor}`,
            borderRadius: '0px'
        }
    },
    activeTitle: {
        color: theme?.secondaryColor,
        fontSize: '14px',
        fontWeight: 600,
        "@media (min-width: 960px) and (max-width: 1200px)": {
            fontSize: '10px'
        }
    },
    };

    const classes = useStyles(styles)();
   
    return ( 
        <Tabs 
        value={props.setActiveTab}
        aria-label="full width tabs example"
        indicatorColor="primary"
        style={{background: 'white'}}
        >
            <Grid container spacing={isNotMobile ? 2 : 0}>
            {props.nav.map((item, index) => {
                const label = item.label;
                const icon = item.icon;
                const isActive = props.setActiveTab === index;
                const activeIcon = item.activeIcon;
                const shouldShowTab = (index < 3 || (index === 3 && certificateValue==='true'));
                return (
                shouldShowTab && (
                <Grid item xs={3} md={6} lg={6} onClick={() => props.handleTabChange(index)} key={index}>
                    <Tab
                    style={{ width: '100%'}}
                        label={
                            <Box className="feature-buttons">
                                <div
                                className={`${drawerToggle && window.innerWidth < 1200 ? classes.logoResize : classes.logo} ${isActive ? classes.activeLogo : ''} `}
                                >
                                    {isNotMobile && (isActive ? activeIcon : icon)}

                                    <div className={`${drawerToggle && window.innerWidth < 1200 ? classes.titleResize : classes.title} ${isActive ? classes.activeTitle: ''}`}>
                                        <p>{t(label)}</p>
                                    </div>
                                </div>
                            </Box>
                        }
                        key={index}
                        className={isActive ? classes.iconTabSelected : classes.iconTab}
                    />
                </Grid>
                ));
            })}
            </Grid>
        </Tabs>
    );    
}
